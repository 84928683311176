<calao-map [options]="(mapOptions$ | async)" class="map"> </calao-map>

<ion-card *ngIf="(tourPanelOptions.value$ | async) as tourPanelOptions" class="fixed-ion-card">
	<ion-card-header>

		<ion-card-subtitle>
			<div class="sub-title">
				<ion-note>
					Informations sur le secteur
				</ion-note>
			</div>

			<ion-button *ngIf="!(isTourPanelVisible.value$ | async)" (click)="toggleTourPanelOn()" class="toogle-button">
				<ion-icon name="chevron-up-outline"></ion-icon>
			</ion-button>
			<ion-button *ngIf="(isTourPanelVisible.value$ | async)" (click)="toggleTourPanelOff()" class="toogle-button">
				<ion-icon name="chevron-down-outline"></ion-icon>
			</ion-button>
		</ion-card-subtitle>

		<ion-card-title *ngIf="(isTourPanelVisible.value$ | async)">
			{{ tourPanelOptions.title }}
		</ion-card-title>
	</ion-card-header>

	<ion-card-content *ngIf="(isTourPanelVisible.value$ | async)">

		<ion-list>
			<ion-item-group *ngFor="let value of tourPanelOptions.data">
				<ion-item>
					<ion-label>{{ value.name }}</ion-label>
					<ion-label>{{ value.value }}</ion-label>
				</ion-item>
			</ion-item-group>
		</ion-list>
	</ion-card-content>

</ion-card>