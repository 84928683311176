import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { ErrorsModule } from '../errors/errors.module';
import { FormModule } from '../forms/form/form.module';
import { ListsModule } from '../lists/lists.module';
import { SlidingListModule } from '../sliding-list/sliding-list.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { EventItemComponent } from './components/event-item/event-item.component';
import { EventParticipantsFieldComponent } from './components/event-participants-field/event-participants-field.component';
import { ShareCalendarModalComponent } from './components/share-calendar-modal/share-calendar-modal.component';
import { CalendarEventsParticipationService } from './services/calendar-events-participation.service';
import { CalendarEventsService } from './services/calendar-events.service';
import { CalendarShareService } from './services/calendar-share.service';



@NgModule({
	declarations: [EventItemComponent, EventParticipantsFieldComponent, ShareCalendarModalComponent],
	exports: [EventItemComponent, EventParticipantsFieldComponent, FormModule, ShareCalendarModalComponent],
	imports: [
		CommonModule,
		IonicModule,
		ListsModule,
		VirtualScrollModule,
		FormModule,
		FormlyModule.forChild({
			types: [{
				name: "eventParticipants",
				component: EventParticipantsFieldComponent
			}]
		}),
		SlidingListModule,
		ErrorsModule
	],
	providers: [CalendarEventsService, CalendarEventsParticipationService, CalendarShareService]
})
export class CalendarEventsModule { }
