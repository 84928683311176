<osapp-header-modal title="Nouveau document"></osapp-header-modal>

<ion-content>
	<ion-label>Type de document</ion-label>
	<ng-container *ngIf="observableCurrentStep.value$ | async as currentStep">
		<ng-container [ngSwitch]="currentStep">
			<!-- Etape de sélection du type d'entité -->
			<ng-container *ngSwitchCase="ECurrentStep.entitySelection">
				<ng-container *ngIf="observableFoldersOptions.changes$ | async as options">
					<div class="selector" *ngIf="options.length > 0; else noSubFolder">
						<osapp-selector [options]="options" [displayMode]="selectorDisplayMode.tags" [scrollWrapper]="false"
							(selectionChanged)="onFolderChanged($event)">
						</osapp-selector>
					</div>
				</ng-container>
			</ng-container>

			<!-- Etape de sélection du type de document -->
			<ng-container *ngSwitchCase="ECurrentStep.typeSelection">
				<ng-container *ngTemplateOutlet="noSubFolder"></ng-container>

				<ng-container *ngIf="observableHasForm.value$ | async; else galleryOnly">
					<ion-radio-group value="form" (ionChange)="onDocumentTypeChanged($event)">
						<!-- Radio pour la sélection d'un formulaire -->
						<div
							[ngClass]="(this.observableSelectedDocumentType.value$ | async) === EDocumentType.form ? 'active' : 'inactive'"
							class="type-selector">
							<div class="radio">
								<ion-radio value="form" labelPlacement="end">
									Utiliser un formulaire
								</ion-radio>
							</div>
							<div class="explanation">
								Entrez toutes les données de votre document via l'application sous forme d'un formulaire complet.
							</div>
						</div>

						<!-- Radio pour l'ajout d'un fichier -->
						<div
							[ngClass]="(this.observableSelectedDocumentType.value$ | async) === EDocumentType.file ? 'active' : 'inactive'"
							class="type-selector">
							<div class="radio">
								<ion-radio value="file" labelPlacement="end">
									Importer un fichier
								</ion-radio>
							</div>
							<ion-item lines="none"
								[disabled]="(this.observableSelectedDocumentType.value$ | async) !== EDocumentType.file"
								(click)="onClick($event)">
								<ng-container *ngTemplateOutlet="gallery"></ng-container>
							</ion-item>
							<ng-container *ngTemplateOutlet="title"></ng-container>
						</div>
					</ion-radio-group>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>

	<ion-fab vertical="bottom" horizontal="end" slot="fixed">
		<ion-fab-button
			[disabled]="!(observableSelectedFolder.value$ | async) || (((observableCurrentStep.value$ | async) === ECurrentStep.typeSelection) && (observableSelectedDocumentType.value$ | async) === EDocumentType.file && !(observableGalleryFile.value$ | async))"
			size="small" color="primary" class="fab-label" (click)="onSubmitAsync()">
			<ng-container *ngIf="observableSubmitButtonLabel?.value$ | async as submitButtonLabel">
				<ion-label>{{ submitButtonLabel }}</ion-label>
			</ng-container>
		</ion-fab-button>
	</ion-fab>
</ion-content>

<ng-template #noSubFolder>
	<div class="selected-folder" *ngIf="observableSelectedFolder.value$ | async as folder">
		<ion-label>{{ folder.name }}</ion-label>
		<ion-icon name="checkmark"></ion-icon>
	</div>
</ng-template>

<ng-template #galleryOnly>
	<div class="gallery-only-container">
		<ion-label>Importer un fichier</ion-label>
		<ng-container *ngTemplateOutlet="gallery"></ng-container>
	</div>
	<ng-container *ngTemplateOutlet="title"></ng-container>
</ng-template>

<ng-template #gallery>
	<div class="gallery">
		<gallery #galleryComponent [readOnly]="false" accept="image" [multiple]="false" [limit]="1"
			(onFilesChanged)="onFilesChanged($event)"></gallery>
	</div>
</ng-template>

<ng-template #title>
	<div class="title" *ngIf="observableGalleryFile.value$ | async">
		<ion-label>Titre</ion-label>
		<ion-input type="text" placeholder="Titre du document" class="title-input"
			(ionInput)="updateTitle($event)"></ion-input>
		<ion-label class="title-explanation"><b>Nommer votre document</b> vous permet de le retrouver plus facilement dans
			l'application.</ion-label>
	</div>
</ng-template>