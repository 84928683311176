<ng-container
	*ngTemplateOutlet="(observableDisplay.value$ | async) === ENumberRangePickerDisplay.slider ? slider : inputs"></ng-container>

<ng-template #slider>
	<div class="slider-container">
		<ion-label>{{ observableMinLabel.value$ | async }}</ion-label>
		<mat-slider step="{{ observableStep.value$ | async }}" discrete="true">
			<input value="{{ observableMinValue.value$ | async }}" matSliderStartThumb (valueChange)="updateMinRange($event)">
			<input value="{{ observableMaxValue.value$ | async }}" matSliderEndThumb (valueChange)="updateMaxRange($event)">
		</mat-slider>
		<ion-label>{{ observableMaxLabel.value$ | async }}</ion-label>
	</div>
</ng-template>

<ng-template #inputs>
	<div class="inputs-container">
		<input matInput value="{{ observableMinValue.value$ | async }}" class="input" type="number" placeholder="Min"
			(input)="updateMinRange($event)" />
		<input matInput value="{{ observableMaxValue.value$ | async }}" class="input" type="number" placeholder="Max"
			(input)="updateMaxRange($event)" />
	</div>
</ng-template>