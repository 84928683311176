import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, Observable, Subject, concat, defer, from, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, mergeMap, reduce, takeUntil, tap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { GuidHelper } from '../../../helpers/guidHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { EApplicationEventType } from '../../../model/application/EApplicationEventType';
import { ConfigData } from '../../../model/config/ConfigData';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { EStoreFlag } from '../../../model/store/EStoreFlag';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { ApplicationService } from '../../../services/application.service';
import { Store } from '../../../services/store.service';
import { FilesystemService } from '../../filesystem/services/filesystem.service';
import { ELogActionId } from '../../logger/models/ELogActionId';
import { LoggerService } from '../../logger/services/logger.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { Queue } from '../../utils/queue/decorators/queue.decorator';
import { DmsFileHelper } from '../helpers/dmsFileHelper';
import { DmsFile } from '../model/DmsFile';
import { EDmsAction } from '../model/EDmsAction';
import { EDmsFlag } from '../model/EDmsFlag';
import { IDms } from '../model/IDms';
import { IDmsData } from '../model/IDmsData';
import { IDmsEvent } from '../model/IDmsEvent';
import { IDmsMeta } from '../model/IDmsMeta';
import { IDmsProgressEvent } from '../model/idms-progress-event';
import { LocalDmsService } from './localDms.service';
import { RemoteDmsService } from './remoteDms.service';

@Injectable({ providedIn: "root" })
export class SyncDmsService extends DestroyableServiceBase implements IDms, OnDestroy {

	//#region FIELDS

	/** Identifiant du service pour les logs. */
	private static readonly C_LOG_ID = "SDMS.S::";

	/** Sujet pour l'envoi d'événements de dms. */
	private readonly moDmsEventSubject = new Subject<IDmsEvent>();

	private readonly moGetProgressSubjectByGuid = new Map<string, Subject<IDmsProgressEvent>>();
	private readonly moSaveProgressSubjectByGuid = new Map<string, Subject<IDmsProgressEvent>>();
	private readonly moGetEndSubjectByGuid = new Map<string, Subject<void>>();
	private readonly moSaveEndSubjectByGuid = new Map<string, Subject<void>>();

	/** Identifiant de la base de données du DMS. */
	private C_DMS_DATABASE_ID: string;

	private readonly C_LOG_ID = "SDMS.S::";

	//#endregion

	//#region METHODS

	constructor(
		/** Service des requêtes en base de données. */
		private readonly isvcStore: Store,
		/** Service de gestion des fichiers DMS en local. */
		private readonly isvcLocalDms: LocalDmsService,
		/** Service de gestion des fichiers DMS côté serveur. */
		private readonly isvcRemoteDms: RemoteDmsService,
		private readonly isvcLogger: LoggerService,
		private readonly isvcFilesystem: FilesystemService,
		psvcApplication: ApplicationService
	) {
		super();
		this.init(psvcApplication);
	}

	public override ngOnDestroy(): void {
		this.completeMapSubjects(this.moGetProgressSubjectByGuid);
		this.completeMapSubjects(this.moSaveProgressSubjectByGuid);
		this.completeMapSubjects(this.moGetEndSubjectByGuid);
		this.completeMapSubjects(this.moSaveEndSubjectByGuid);
		this.moDmsEventSubject.complete();
		super.ngOnDestroy();
	}

	/** Complète les sujets de la map passée en paramètre.
	 * @param poMap Map qui possède les sujets à compléter.
	 */
	private completeMapSubjects(poMap: Map<string, Subject<IDmsProgressEvent | void>>): void {
		MapHelper.valuesToArray(poMap).forEach((poValue: Subject<IDmsProgressEvent | void>) => poValue.complete());
	}

	/** Permet de savoir si le code erreur http correspond à une erreur qui sera permanente.
	 * @param poError Erreur reçue.
	 */
	public isPermanentError(poError: HttpErrorResponse): boolean {
		// "not found" ou "bad request" ou une erreur inconnue.

		// TODO Les erreurs 400 doivent être considérées comme des erreurs définitives.
		/* Cependant le GED de Calaosoft et Textilot retourne une erreur 400 à la première requête après démarrage de la GED.
			Les erreurs 400 sont considérées comme non permanentes jusqu'à la correction des GED.
		*/
		return poError.status === 404;
	}

	/** Initialisation du service en lançant les requêtes en attente après que le service du DMS local soit correctement initialisé.
	 * @param psvcApplication Service de gestion de l'application.
	 */
	private init(psvcApplication: ApplicationService): void {
		psvcApplication.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(tap(_ => this.C_DMS_DATABASE_ID = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.dmsStorage))))
			.subscribe();

		psvcApplication.waitForFlag(EDmsFlag.DmsReady, true)
			.pipe(tap(_ => this.requestExecPendings()))
			.subscribe();
	}

	/** Supprime un fichier en local dans le DMS et tente de le supprimer du serveur ou non.
	 * @param psId Identifiant du fichier à supprimer.
	 */
	public delete(psId: string): Observable<boolean> {
		const lsRemoveDocumentId: string = IdHelper.buildId(EPrefix.dms, psId);

		return this.isvcLocalDms.delete(lsRemoveDocumentId)
			.pipe(
				mergeMap(_ => this.putDeleteRequest(lsRemoveDocumentId)),
				tap(_ => this.requestExecPendingDelete(lsRemoveDocumentId)),
				mapTo(true)
			);
	}

	/** Exécute toutes les suppressions de fichiers.
	 * @param paPendings Tous les documents en attente.
	 * @param pbHasToContinueIfFail `true` si on veut inclure les fichiers tombés en erreur (et permet de continuer les traitements),
	 * `false` sinon (`false` par défaut, tous les traitements s'arrêtent à la moindre erreur).
	 * @returns Le tableau des identifiants des fichiers meta supprimés.
	 */
	private execDeletes(paPendings: IStoreDocument[], pbHasToContinueIfFail: boolean = false): Observable<Array<string>> {
		const laPendingDeleteGuids: string[] = this.extractGuidsFromPendings(paPendings, /del_(.+)/);
		const lfResolve$: (poError) => Observable<boolean | never> = (poError) => pbHasToContinueIfFail ? of(false) : throwError(() => poError);

		if (ArrayHelper.hasElements(laPendingDeleteGuids)) {
			return defer(() => {
				console.debug(`${SyncDmsService.C_LOG_ID}Deleting documents ${laPendingDeleteGuids.join(", ")}...`);
				return from(laPendingDeleteGuids);
			})
				.pipe(
					mergeMap((psId: string) => {
						return this.isvcRemoteDms.delete(psId)
							.pipe(
								tap(
									_ => console.debug(`${SyncDmsService.C_LOG_ID}Deleting document ${psId} SUCCESSED !`),
									_ => console.debug(`${SyncDmsService.C_LOG_ID}Deleting document ${psId} FAILED !`)
								),
								catchError(poError => this.isPermanentError(poError) ?
									this.removeDeleteRequest(psId).pipe(mergeMap(_ => lfResolve$(poError))) : lfResolve$(poError)
								),
								map((pbResult: boolean) => pbResult ? psId : undefined)
							);
					}),
					mergeMap((psId?: string) => StringHelper.isBlank(psId) ? of(null) : this.removeDeleteRequest(psId).pipe(mapTo(psId))),
					toArray()
				);
		}
		else
			return EMPTY;
	}

	/** Exécute tous les téléchargements de fichiers.
	 * @param paPendings Tous les documents en attente.
	 * @param pbHasToContinueIfFail `true` si on veut inclure les fichiers tombés en erreur (et permet de continuer les traitements),
	 * `false` sinon (`false` par défaut, tous les traitements s'arrêtent à la moindre erreur).
	 * @returns Le tableau des identifiants des fichiers meta récupérés.
	 */
	private execDownloads(paPendings: IStoreDocument[], pbHasToContinueIfFail: boolean = false): Observable<Array<string>> {
		const laPendingDownloadGuids: string[] = this.extractGuidsFromPendings(paPendings, /dl_(.+)/);

		if (ArrayHelper.hasElements(laPendingDownloadGuids)) {
			return defer(() => {
				console.debug(`${SyncDmsService.C_LOG_ID}Downloading documents ${laPendingDownloadGuids.join(", ")}...`);
				return from(laPendingDownloadGuids);
			})
				.pipe(
					mergeMap((psGuid: string) => {
						return this.downloadFile(psGuid)
							.pipe(
								tap(
									_ => console.debug(`${SyncDmsService.C_LOG_ID}SDMS.S::Downloading document ${psGuid} SUCCESSED !`),
									_ => console.debug(`${SyncDmsService.C_LOG_ID}SDMS.S::Downloading document ${psGuid} FAILED !`)
								),
								catchError(poError => pbHasToContinueIfFail ? of({} as IDmsMeta) : throwError(() => poError)),
								map((poMeta?: IDmsMeta) => poMeta?._id ?? null)
							);
					}),
					toArray()
				);
		}
		else
			return EMPTY;
	}

	/** Télécharge le fichier et ses méta.
	 * @param psGuid Guid du fichier à télécharger.
	 * @returns Les méta-données du fichier téléchargé, null si le fichier était déjà présent localement et qu'il n'a pas été re-téléchargé.
	 */
	@Queue<SyncDmsService, Parameters<SyncDmsService["downloadFile"]>, ReturnType<SyncDmsService["downloadFile"]>>({
		idBuilder: (psGuid: string) => psGuid,
		keepOnlyLastPending: true
	})
	private downloadFile(psGuid: string): Observable<IDmsMeta> {
		const lsId: string = IdHelper.buildId(EPrefix.dms, psGuid);

		console.debug(`${SyncDmsService.C_LOG_ID}Checking document availability in ${ConfigData.environment.dms.applicationPath} for document ${lsId}...`);
		const loGetProgressSubject: Subject<IDmsProgressEvent> = this.getSubject(psGuid, this.moGetProgressSubjectByGuid);

		return this.isvcLocalDms.get(psGuid)
			.pipe(
				map((poData?: IDmsData) => poData?.meta),
				catchError(() => of(undefined)),
				mergeMap((poMeta?: IDmsMeta) => {
					if (!poMeta?.saved) {
						console.debug(`${SyncDmsService.C_LOG_ID}Downloading file ${psGuid}...`);

						return this.isvcRemoteDms.get(psGuid, undefined, (poEvent: IDmsProgressEvent) => loGetProgressSubject.next(poEvent))
							.pipe(
								catchError(poError => this.onGetError(poError, IdHelper.buildId(EPrefix.dms, psGuid))),
								mergeMap((poResult: IDmsData) => this.isvcLocalDms.save(poResult.file, poResult.meta))
							);
					}
					else {
						console.warn(`${SyncDmsService.C_LOG_ID}Pending download ignored because document ${lsId} already exists locally.`);
						return of(poMeta);
					}
				}),
				mergeMap((poResultMeta: IDmsMeta) => this.removeDownloadRequest(psGuid).pipe(mapTo(poResultMeta)))
			);
	}

	/** Exécute la regex sur chaque élément de la liste et retourne toutes les correspondances.
	 * @param paResults Jeu de résultat.
	 * @param loRegex Regex à exécuter.
	 */
	private extractGuidsFromPendings(paResults: IStoreDocument[], loRegex: RegExp): string[] {
		const laPendingGuids: string[] = [];

		paResults.forEach((poResult: IStoreDocument) => {
			const loRegexResult: RegExpExecArray = loRegex.exec(poResult._id);
			const lsLastElement: string = loRegexResult ? ArrayHelper.getLastElement(loRegexResult) : null;
			if (!StringHelper.isBlank(lsLastElement))
				laPendingGuids.push(lsLastElement);
		});
		return laPendingGuids;
	}

	/** Exécute toutes les tâches de synchro.
	 * @param paResults Résultats de la requête sur la base (toutes les tâches en attente).
	 * @param pbHasToContinueIfFail `true` si on veut inclure les fichiers tombés en erreur (et permet de continuer les traitements),
	 * `false` sinon (`false` par défaut, tous les traitements s'arrêtent à la moindre erreur).
	 * @returns Le tableau des identifiants des fichiers traités (téléchargés, téléversés, supprimés), si `pbHasToReturnFailures` est à `true`,
	 * chaque fichier qui est tombé en erreur renverra la valeur `null`.
	 */
	private execSync(paResults: IStoreDocument[], pbHasToContinueIfFail: boolean): Observable<Array<string>> {
		//NB : concat est utilisé en contournement de problème avec File.checkDir qui semble ne pas retourner de réponse lors d'appels concurrents.
		return concat(
			this.execDownloads(paResults, pbHasToContinueIfFail).pipe(finalize(() => console.debug(`${SyncDmsService.C_LOG_ID}Downloads finished.`))),
			this.execUploads(paResults, pbHasToContinueIfFail).pipe(finalize(() => console.debug(`${SyncDmsService.C_LOG_ID}Uploads finished.`))),
			this.execDeletes(paResults, pbHasToContinueIfFail).pipe(finalize(() => console.debug(`${SyncDmsService.C_LOG_ID}Deletes finished.`)))
		)
			.pipe(reduce((paIds: string[], paResult: string[]) => [...paIds, ...paResult], []));
	}

	/** @implements */
	public execUploads(paPendings: IStoreDocument[], pbHasToContinueIfFail: boolean = false): Observable<Array<string>> {
		const laPendingUploadGuids: string[] = this.extractGuidsFromPendings(paPendings, /ul_(.+)/);
		const lfResolve$: (poError) => Observable<undefined | never> = (poError) => pbHasToContinueIfFail ? of(undefined) : throwError(() => poError);

		if (ArrayHelper.hasElements(laPendingUploadGuids)) {
			return defer(() => {
				console.debug(`${SyncDmsService.C_LOG_ID}Uploading documents ${laPendingUploadGuids.join(", ")}...`);
				return from(laPendingUploadGuids);
			})
				.pipe(
					mergeMap((psGuid: string) => {
						const loSaveProgressSubject: Subject<IDmsProgressEvent> = this.getSubject(psGuid, this.moSaveProgressSubjectByGuid);

						return this.isvcLocalDms.get(IdHelper.buildId(EPrefix.dms, psGuid))
							.pipe(
								mergeMap((poResult: IDmsData) => this.isvcRemoteDms.save(poResult.file, poResult.meta, (poEvent: IDmsProgressEvent) => loSaveProgressSubject.next(poEvent)).pipe(mapTo(psGuid))),
								tap(_ => console.debug(`${SyncDmsService.C_LOG_ID}Uploading document ${psGuid} SUCCESSED`)),
								catchError(poError => {
									console.debug(`${SyncDmsService.C_LOG_ID}Uploading document ${psGuid} FAILED`);
									return this.isPermanentError(poError) ?
										this.removeUploadRequest(psGuid).pipe(mergeMap(_ => lfResolve$(poError))) : lfResolve$(poError);
								})
							);
					}),
					mergeMap((psGuid?: string) => StringHelper.isBlank(psGuid) ? of(null) : this.removeUploadRequest(psGuid).pipe(mapTo(psGuid))),
					toArray()
				);
		}
		else
			return EMPTY;
	}

	/**
	 * Supprime un document de la boîte d'envoi.
	 * ATTENTION Le document local sera définitivement détruit avant d'avoir été transmis, et donc perdu !
	 */
	public cancelDocumentUpload(psId: string): Observable<string> {
		if (!StringHelper.isBlank(psId)) {
			return defer(() => {
				console.debug(`${this.C_LOG_ID}Cancelling document ${psId} upload...`);
				return this.getPendingDoc(EPrefix.pendingUpload, psId);
			})
				.pipe(
					mergeMap(_ => this.removeUploadRequest(psId)),
					mergeMap(_ => this.isvcLocalDms.delete(IdHelper.buildId(EPrefix.dms, psId))),
					tap(() => this.isvcLogger.action(this.C_LOG_ID, `Pending document ${psId} upload was cancelled.`, ELogActionId.dmsDocUploadCancelled, { id: psId })),
					mapTo(psId),
					catchError(poError => {
						console.error(`SDMS.S::Document ${psId} upload cancellation failed.`, poError);

						return throwError(() => poError);
					})
				);
		}
		else
			return EMPTY;
	}

	/** Récupère un fichier du DMS.
	 * @param psId Identifiant du fichier à récupérer.
	 * @param pnQuality Pas pris en compte.
	 * @param pfOnProgress Callback appelée lors de l'avancement de téléchargement.
	 */
	public get(psGuid: string, pnQuality: number, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IDmsData> {
		const lsId: string = IdHelper.buildId(EPrefix.dms, psGuid);
		const loDataSource: IDataSource = {
			databaseId: this.C_DMS_DATABASE_ID,
			viewParams: {
				key: lsId,
				include_docs: true
			}
		};

		return this.isvcStore.getOne<IDmsMeta>(loDataSource, false)
			.pipe(
				mergeMap((poLocalMeta?: IDmsMeta) => {
					if (poLocalMeta && poLocalMeta.saved !== false) // Si le flag `saved` n'est pas marqué à false spécifiquement, alors c'est considéré comme true.
						return this.getFile(poLocalMeta, psGuid, pfOnProgress);
					else {
						return this.putDownloadRequest(psGuid, pfOnProgress)
							.pipe(
								tap(_ => this.requestExecPendingDownload(lsId)),
								mergeMap(_ => this.waitFile(lsId))
							);
					}
				}),
				finalize(() => this.getSubject(psGuid, this.moGetEndSubjectByGuid).next(undefined))
			);
	}

	/** Récupère un fichier.
	 * @param poMeta Méthadonnées du fichier à récupérer.
	 * @param psGuid Guid du fichier à récupérer.
	 */
	private getFile(poMeta: IDmsMeta, psGuid: string, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IDmsData> {
		let lsPath: string;
		return defer(() => this.isvcFilesystem.existsAsync(lsPath = this.isvcLocalDms.getFilePath(poMeta)))
			.pipe(
				mergeMap((pbFileExists: boolean) => {
					if (pbFileExists)
						return this.isvcFilesystem.getFileAsync(lsPath);
					else {
						return this.putDownloadRequest(psGuid, pfOnProgress)
							.pipe(
								tap(_ => this.requestExecPendingDownload(poMeta._id)),
								mergeMap(_ => this.waitFile(poMeta._id)),
								map((poResult: IDmsData) => poResult.file.File)
							);
					}
				}),
				map((poFile: string | Blob) => {
					if (!poFile)
						throw new Error("Le fichier récupéré est nul");
					else {
						return {
							file: new DmsFile(poFile, poMeta),
							meta: poMeta
						};
					}
				})
			);
	}

	private raiseDmsEvent(poEvent: IDmsEvent): void {
		this.moDmsEventSubject.next(poEvent);
	}

	/** Code exécuté lors d'une erreur sur le get.
	 * @param poError Erreur reçue.
	 * @param psId Identifiant du fichier.
	 */
	private onGetError(poError: HttpErrorResponse, psId: string): Observable<never> {
		const lsErrorMessage = `Abandon du téléchargement du contenu du document ${psId}. ${poError.message}`;

		return (this.isPermanentError(poError) ? this.removeDownloadRequest(psId) : of(null))
			.pipe(
				tap(_ => {
					const loDmsEvent: IDmsEvent = {
						type: EApplicationEventType.DmsEvent,
						createDate: new Date(),
						data: { fileId: psId }
					};
					this.raiseDmsEvent(loDmsEvent);
				}),
				mergeMap(_ => throwError(() => lsErrorMessage))
			);
	}

	/** Demande le lancement des requêtes qui sont en attente (téléchargements, téléversements et suppressions). */
	public requestExecPendings(): void {
		this.raiseDmsEvent(this.createDmsEvent(EDmsAction.sync));
	}

	/** Demande le lancement d'une requête de téléchargement qui est en attente.
	 * @param psFileId Identifiant du fichier à télécharger.
	 */
	public requestExecPendingDownload(psFileId: string): void {
		this.raiseDmsEvent(this.createDmsEvent(EDmsAction.download, psFileId));
	}

	/** Demande le lancement des requêtes de téléversement qui sont en attente.
	 * @param psFileId Identifiant du fichier à téléverser.
	 */
	public requestExecPendingUpload(psFileId: string): void {
		this.raiseDmsEvent(this.createDmsEvent(EDmsAction.upload, psFileId));
	}

	/** Demande le lancement des requêtes de suppression qui sont en attente.
	 * @param psFileId Identifiant du fichier à supprimer.
	 */
	public requestExecPendingDelete(psFileId: string): void {
		this.raiseDmsEvent(this.createDmsEvent(EDmsAction.delete, psFileId));
	}

	/** Crée et retourne un objet événement dms.
	 * @param peAction Action souhaitée.
	 * @param psFileId Identifiant du fichier qu'on veut traiter.
	 */
	private createDmsEvent(peAction: EDmsAction, psFileId: string = ""): IDmsEvent {
		return {
			type: EApplicationEventType.DmsEvent,
			createDate: new Date(),
			data: {
				dmsAction: peAction,
				fileId: psFileId
			}
		};
	}

	/** Enregistre une requête de téléchargement dans la base de données locale du DMS.
	 * @param psGuid Guid du fichier à télécharger.
	 */
	private putDownloadRequest(psGuid: string, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IStoreDataResponse> {
		const loGetProgressSubject: Subject<IDmsProgressEvent> = this.getSubject(psGuid, this.moGetProgressSubjectByGuid);

		loGetProgressSubject.asObservable()
			.pipe(
				tap((poEvent: IDmsProgressEvent) => {
					if (pfOnProgress)
						pfOnProgress(poEvent);
				}),
				takeUntil(this.getSubject(psGuid, this.moGetEndSubjectByGuid).asObservable())
			).subscribe();

		return this.putRequest(psGuid, EPrefix.pendingDownload, "Erreur lors de l'enregistrement de la requête de téléchargement : ");
	}

	/** Récupère un `Subject` depuis une `Map` ordonnée par `GUID`. Si le `Subject` pour ce `GUID` n'existe pas, le crée et l'ajoute à la `Map` avant de le retourner.
	 * @param psGuid
	 * @param poSubjectMap
	 */
	private getSubject<T>(psGuid: string, poSubjectMap: Map<string, Subject<T>>): Subject<T> {
		let loSubject: Subject<T> = poSubjectMap.get(psGuid);
		if (!loSubject)
			poSubjectMap.set(psGuid, loSubject = new Subject);
		return loSubject;
	}

	/** Enregistre une requête de suppression dans la base de données locale du DMS.
	 * @param psGuid Guid du fichier à supprimer.
	 */
	private putDeleteRequest(psGuid: string): Observable<IStoreDataResponse> {
		return this.putRequest(psGuid, EPrefix.pendingDelete, "Erreur lors de l'enregistrement de la requête de suppression : ");
	}

	/** Enregistre une requête de téléversement dans la base de données locale du DMS.
	 * @param psId Id du fichier à téléverser.
	 */
	private putUploadRequest(psId: string, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IStoreDataResponse> {
		const lsGuid: string = IdHelper.getLastGuidFromId(psId);
		const loSaveProgressSubject: Subject<IDmsProgressEvent> = this.getSubject(lsGuid, this.moSaveProgressSubjectByGuid);

		loSaveProgressSubject.asObservable().pipe(takeUntil(this.getSubject(lsGuid, this.moSaveEndSubjectByGuid).asObservable())).subscribe((poEvent: IDmsProgressEvent) => {
			if (pfOnProgress)
				pfOnProgress(poEvent);
		});

		return this.putRequest(psId, EPrefix.pendingUpload, "Erreur lors de l'enregistrement de la requête de téléversement : ");
	}

	/** Eregistre une requête dans la base de données locale du DMS.
	 * @param psGuid Guid du document à traiter.
	 * @param pePrefix Prefixe de la requête.
	 * @param psErrorMessage Message d'erreur.
	 */
	private putRequest(psGuid: string, pePrefix: EPrefix, psErrorMessage: string): Observable<IStoreDataResponse> {
		const lsId: string = IdHelper.buildId(pePrefix, GuidHelper.extractGuid(psGuid));

		return this.isvcStore.getOne({ databaseId: this.C_DMS_DATABASE_ID, viewParams: { key: lsId } }, false)
			.pipe(
				mergeMap((poResult?: IStoreDocument) => poResult ?
					of({ ok: true, id: poResult._id, rev: poResult._rev } as IStoreDataResponse) : this.isvcStore.put({ _id: lsId }, this.C_DMS_DATABASE_ID)
				),
				catchError(poError => { console.error(`${SyncDmsService.C_LOG_ID}${psErrorMessage}`, poError); return throwError(() => poError); })
			);
	}

	/** Supprime une requête de téléchargement de la base de données locale du DMS.
	 * @param psGuid Guid du document à supprimer.
	 */
	public removeDownloadRequest(psGuid: string): Observable<IStoreDataResponse> {
		return this.removeRequest(psGuid, EPrefix.pendingDownload, "Erreur lors de la suppression de la requete de téléchargement: ");
	}

	/** Supprime une requête de suppression de la base de données locale du DMS.
	 * @param psGuid Guid du document à supprimer.
	 */
	private removeDeleteRequest(psGuid: string): Observable<IStoreDataResponse> {
		return this.removeRequest(psGuid, EPrefix.pendingDelete, "Erreur lors de la suppression de la requete de suppression: ");
	}

	/** Supprime une requête de téléversement de la base de données locale du DMS.
	 * @param psGuid Guid du document à supprimer.
	 */
	private removeUploadRequest(psGuid: string): Observable<IStoreDataResponse> {
		return this.removeRequest(psGuid, EPrefix.pendingUpload, "Erreur lors de la suppression de la requete de téléversement: ")
			.pipe(tap(() => console.debug(`SDMS.S::Document ${psGuid} upload request removed.`)));
	}

	/** Supprime une requête de la base de données locale du DMS.
	 * @param psGuid Guid du document à traiter.
	 * @param pePrefix Prefixe de la requête.
	 * @param psErrorMessage Message d'erreur.
	 */
	private removeRequest(psGuid: string, pePrefix: EPrefix, psErrorMessage: string): Observable<IStoreDataResponse> {
		return this.isvcStore.delete(IdHelper.buildId(pePrefix, GuidHelper.extractGuid(psGuid)), this.C_DMS_DATABASE_ID)
			.pipe(catchError(poError => { console.error(`${SyncDmsService.C_LOG_ID}${psErrorMessage}`, poError); return throwError(() => poError); }));
	}

	/** Enregistre en local un fichier dans le DMS et tente un téléversement vers le serveur.
	 * @param poData Fichier à enregistrer dans le DMS et à téléverser.
	 * @param poMeta Méta du fichier à enregistrer.
	 * @param pfOnProgress Pas pris en compte.
	 */
	public save(poData: DmsFile, poMeta: IDmsMeta, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IDmsMeta> {
		return this.isvcLocalDms.save(poData, poMeta)
			.pipe(
				mergeMap((poLocalResult: IDmsMeta) => this.putUploadRequest(poLocalResult._id, pfOnProgress).pipe(mapTo(poLocalResult))),
				tap((poResult: IDmsMeta) => this.requestExecPendingUpload(poResult._id)),
				finalize(() => this.getSubject(poMeta._id, this.moSaveEndSubjectByGuid).next(undefined))
			);
	}

	/** Permet de s'abonner aux événements du SyncDmsService.
	 * @param pfNext Fonction appelée lors du next => function(poResult: IDmsEvent).
	 * @param pfError Fonction appelée lors du error => function(poError: any).
	 * @param pfComplete Fonction appelée lors du complete.
	 */
	public subscribe(pfNext: Function, pfError?: Function, pfComplete?: Function): void {
		this.moDmsEventSubject.asObservable().subscribe(
			(poResult: IDmsEvent) => pfNext(poResult),
			poError => pfError(poError),
			() => pfComplete()
		);
	}

	/** Exécute tous les changements en attente enregistrés en base.
	 * @param pbHasToContinueIfFail `true` si on veut inclure les fichiers tombés en erreur (et permet de continuer les traitements),
	 * `false` sinon (`false` par défaut, tous les traitements s'arrêtent à la moindre erreur).
	 * @returns Le tableau des identifiants des fichiers traités (téléchargés, téléversés, supprimés), si `pbHasToReturnFailures` est à `true`,
	 * chaque fichier qui est tombé en erreur renverra la valeur `null`.
	 */
	public syncPendingChanges(pbHasToContinueIfFail: boolean = false): Observable<Array<string>> {
		console.debug(`${SyncDmsService.C_LOG_ID}Processing pending changes...`);

		return this.getPendingDocs(EPrefix.pending)
			.pipe(
				mergeMap((paResults: IStoreDocument[]) => this.execSync(paResults, pbHasToContinueIfFail)),
				mergeMap((paResults: string[]) => paResults.some((psId: string) => StringHelper.isBlank(psId)) ?
					throwError(() => "Un ou plusieurs fichiers n'ont pu être synchronisés.") : of(paResults)
				),
				catchError(poError => {
					console.error(`${SyncDmsService.C_LOG_ID}Pending changes processing failed.`, poError);
					return throwError(() => poError);
				}),
				finalize(() => console.debug(`${SyncDmsService.C_LOG_ID}Pending changes processing completed.`))
			);
	}

	/** Exécute un téléchargement en attente.
	 * @param psFileGuid Guid du fichier à télécharger.
	 */
	public execPendingDownload(psFileGuid: string): Observable<Array<string>> {
		return this.getPendingDoc(EPrefix.pendingDownload, psFileGuid)
			.pipe(mergeMap((poResult: IStoreDocument) => this.execDownloads([poResult])));
	}

	/** Exécute un téléversement en attente.
	 * @param psFileGuid Guid du fichier à téléverser.
	 */
	public execPendingUpload(psFileGuid: string): Observable<Array<string>> {
		return this.getPendingDoc(EPrefix.pendingUpload, psFileGuid)
			.pipe(mergeMap((poResult: IStoreDocument) => this.execUploads([poResult])));
	}

	/** Exécute une suppression en attente.
	 * @param psFileGuid Guid du fichier à supprimer.
	 */
	public execPendingDelete(psFileGuid: string): Observable<Array<string>> {
		return this.getPendingDoc(EPrefix.pendingDelete, psFileGuid)
			.pipe(mergeMap((poResult: IStoreDocument) => this.execDeletes([poResult])));
	}

	/** Récupère un document en attente en fonction d'un préfixe et d'un guid de fichier.
	 * @param pePrefix Préfixe du document en attente à récupérer.
	 * @param psFileGuid Guid du fichier dont il faut récupérer le document en attente.
	 */
	private getPendingDoc(pePrefix: EPrefix, psFileGuid: string): Observable<IStoreDocument> {
		const loDataSource: IDataSource = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.dmsStorage)),
			viewParams: {
				key: `${pePrefix}${GuidHelper.extractGuid(psFileGuid)}`,
				include_docs: true
			}
		};

		return this.isvcStore.getOne<IStoreDocument>(loDataSource, false).pipe(
			filter(poResult => !!poResult)
		);
	}

	/** @implements */
	public getPendingDocs(pePrefix: EPrefix, pbLive: boolean = false): Observable<IStoreDocument[]> {
		try {
			const loDataSource: IDataSource = {
				databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.dmsStorage)),
				viewParams: {
					startkey: pePrefix,
					endkey: `${pePrefix}${Store.C_ANYTHING_CODE_ASCII}`,
					include_docs: true
				},
				live: pbLive,
			};

			return this.isvcStore.get(loDataSource);
		}
		catch {
			console.error(`${SyncDmsService.C_LOG_ID}Impossible de trouver les bases de données correspondant au rôle ${EDatabaseRole.dmsStorage}.`);
			return throwError(() => new Error("Pas d'emplacement de stockage pour les documents."));
		}
	}

	/** Permet d'observer les actions sur un fichier puis de renvoyer ses métas.
	 * @param psId Identifiant du fichier à observer.
	 */
	private waitFile(psId: string): Observable<IDmsData> {
		return DmsFileHelper.waitForFile(psId);
	}

	/** Récupère les fichiers en attente (delete/download/upload). */
	public getPendingFiles(): Observable<IStoreDocument[]> {
		return this.getPendingDocs(EPrefix.pending);
	}

	/** Récupère les documents en attente de téléchargement et téléversement.
	 * @param pbLive Indique si la récupération est continue, `false` par défaut.
	 */
	public getPendingDownloadAndUploadDocs(pbLive?: boolean): Observable<IStoreDocument[]> {
		return this.getPendingDocs(EPrefix.pending, pbLive)
			.pipe(map((paPendingDocs: IStoreDocument[]) => paPendingDocs.filter((poDoc: IStoreDocument) => !poDoc._id.includes(EPrefix.pendingDelete))));
	}

	//#endregion

}