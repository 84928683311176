import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ObservableArray } from "@calaosoft/osapp/modules/observable/models/observable-array";
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { secure } from "@calaosoft/osapp/modules/utils/rxjs/operators/secure";
import { NetworkService } from "@calaosoft/osapp/services/network.service";
import { plainToClass } from "class-transformer";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ISector } from "../models/isector";
import { Sector } from "../models/sector";
import { SectorsService } from "../services/sectors.service";

interface IFrontSector extends ISector {
	readonly namePointOfContact: string;
}
class FrontSector extends Sector implements IFrontSector {
	/** @implements */
	public readonly namePointOfContact: string;
}

@Component({
	selector: 'trade-sectors-list',
	templateUrl: './sectors-list.component.html',
	styleUrls: ['./sectors-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorsListComponent extends DestroyableComponentBase {

	//#region PROPERTIES

	public readonly sectors: ObservableArray<FrontSector>;
	public readonly isOnline$: Observable<boolean>;
	public areSectorsMappable = false;


	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcSectors: SectorsService,
		private readonly ioRoute: ActivatedRoute,
		psvcNetwork: NetworkService
	) {
		super();
		this.sectors = new ObservableArray(this.getSectors$().pipe(
			tap((paSect: FrontSector[]) => paSect.forEach((poSect: FrontSector) => {
				if (poSect.isMappable())
					this.areSectorsMappable = true;
			})),
			secure(this)));
		this.isOnline$ = psvcNetwork.hasNetwork$.pipe(secure(this));
	}

	private getSectors$(): Observable<FrontSector[]> {
		return this.isvcSectors.getSectors$().pipe(
			map((paSector: Sector[]) => paSector.map((poSector: Sector) => {
				const loFrontSector: IFrontSector = { ...poSector, namePointOfContact: poSector.getNamePointOfContact() };
				return plainToClass(FrontSector, loFrontSector);
			}))
		);
	}

	public navigateToSectorVisuModeAsync(poSector: FrontSector): Promise<boolean> {
		return this.isvcSectors.navigateToSectorAsync(poSector._id, this.ioRoute);
	}

	public navigateToSectorMapModeAsync(poSector: FrontSector): Promise<boolean> {
		return this.isvcSectors.navigateToSectorMapAsync(poSector._id, this.ioRoute);
	}

	public navigateToSectorEditModeAsync(poSector: FrontSector): Promise<boolean> {
		return this.isvcSectors.navigateToSectorEditAsync(poSector._id, this.ioRoute);
	}

	public navigateToSectorCreateModeAsync(): Promise<boolean> {
		return this.isvcSectors.navigateToSectorNewAsync(this.ioRoute);
	}

	public onDeleteItemClickedAsync(poBusiness: FrontSector): Promise<boolean> {
		return this.isvcSectors.deleteSectorAsync(poBusiness);
	}

	public navigateToSectorsMapAsync(): Promise<boolean> {
		return this.isvcSectors.navigateToSectorsMapAsync(this.ioRoute);
	}

	//#endregion METHODS
}