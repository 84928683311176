import { ArrayHelper } from "@calaosoft/osapp/helpers/arrayHelper";
import { ContactHelper } from "@calaosoft/osapp/helpers/contactHelper";
import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { NumberHelper } from "@calaosoft/osapp/helpers/numberHelper";
import { ICoordinates } from "@calaosoft/osapp/model/navigation/ICoordinates";
import { Contact } from "@calaosoft/osapp/modules/contacts/models/contact";
import { Entity } from "@calaosoft/osapp/modules/entities/models/entity";
import { GeolocationHelper } from "@calaosoft/osapp/modules/geolocation/helpers/geolocation.helper";
import { ModelMatch } from "@calaosoft/osapp/modules/utils/models/decorators/model-match.decorator";
import { Exclude } from "class-transformer";
import { C_PREFIX_SECTOR } from "../../../app/app.constants";
import { Business } from "../../businesses/model/business";
import { ISector } from "./isector";

@ModelMatch((poSector?: ISector) => IdHelper.hasPrefixId(poSector?._id ?? "", C_PREFIX_SECTOR), Entity)
export class Sector extends Entity implements ISector {

	//#region PROPERTIES
	// TODO Doit dispararaître suite à l'apparition des nouveaux lnk
	/** @implements */
	public pointOfContactIds: string[];

	/** @implements */
	public title: string;

	/** @implements */
	public startDate?: Date | undefined;
	/** @implements */
	public endDate?: Date | undefined;
	/** @implements */
	public poi?: ICoordinates[] | undefined;

	/** @implements */
	public linkedBusinesses?: Business[];
	/** @implements */
	public linkedContacts?: Contact[];

	@Exclude()
	public linkedBusinessesIds?: string[];

	@Exclude()
	public linkedContactsIds?: string[];
	//#endregion

	//#region METHODS

	/** Retourne le nom du référent de Secteur. */
	public getNamePointOfContact(): string {
		// Cas sans référent ou sans contact lié
		if (!ArrayHelper.hasElements(this.pointOfContactIds) || !ArrayHelper.hasElements(this.linkedContacts)) {
			return "";
		}
		else {
			const lspointOfContactId: string = ArrayHelper.getFirstElement(this.pointOfContactIds);
			const loContact: Contact | undefined = this.linkedContacts?.find((poContact: Contact) => poContact._id === lspointOfContactId);
			return ContactHelper.getCompleteFormattedName(loContact, false);
		}
	}

	/** Donne l'aire du polygone du secteur.
	 * @param paSectorPoints la zone du secteur.
	 * @returns l'aire (sans unité : pas en km²).
	 */
	private getPolygonArea(paSectorPoints: ICoordinates[]): number | undefined {
		// Méthode : https://www.wikihow.com/Calculate-the-Area-of-a-Polygon#Finding-the-Area-of-Irregular-Polygons
		if (!paSectorPoints || paSectorPoints?.length < 3)
			return undefined;

		if (paSectorPoints.length >= 3) {
			const loFirstCoords: ICoordinates = ArrayHelper.getFirstElement(paSectorPoints);
			const loLastCoords: ICoordinates = ArrayHelper.getLastElement(paSectorPoints);
			if (loFirstCoords.longitude !== loLastCoords.longitude || loFirstCoords.latitude !== loLastCoords.latitude)
				paSectorPoints.push(loFirstCoords);

			// Twicearea car cette valeur correspondra au double de l'aire une fois son initialisation terminée.
			let lnTwicearea = 0;
			const lnNumberOfPoints: number = paSectorPoints.length;
			let loPoint1: ICoordinates;
			let loPoint2: ICoordinates;
			for (let i = 0, j = lnNumberOfPoints - 1; i < lnNumberOfPoints; j = i++) {
				loPoint1 = paSectorPoints[i];
				loPoint2 = paSectorPoints[j];
				lnTwicearea += (loPoint1.longitude * loPoint2.latitude) - (loPoint2.longitude * loPoint1.latitude);
			}
			return lnTwicearea / 2;
		}
		else
			return undefined;
	}

	/** Retourne le centre de gravité à partir de coordonnées. */
	public getPolygonCentroid(): ICoordinates | undefined {
		// Méthode : https://lexrent.eu/wp-content/uploads/torza/artikel_groep_sub_2_docs/BYZ_3_Polygon-Area-and-Centroid.pdf
		const laSectorsPoints: ICoordinates[] = this.getSectorZoneCoordinates();
		if (laSectorsPoints.length < 3)
			return undefined;

		const loArea: number | undefined = this.getPolygonArea(laSectorsPoints);
		if (!loArea)
			return undefined;
		else {
			const loFirst: ICoordinates = ArrayHelper.getFirstElement(laSectorsPoints);
			const loLast: ICoordinates = ArrayHelper.getLastElement(laSectorsPoints);
			if (loFirst.longitude !== loLast.longitude || loFirst.latitude !== loLast.latitude)
				laSectorsPoints.push(loFirst);
			let lnX = 0;
			let lnY = 0;
			const lnNumberOfPoints: number = laSectorsPoints.length;
			let loPoint1: ICoordinates;
			let loPoint2: ICoordinates;
			let lnF: number;
			for (let i = 0, j = lnNumberOfPoints - 1; i < lnNumberOfPoints; j = i++) {
				loPoint1 = laSectorsPoints[i]; loPoint2 = laSectorsPoints[j];
				lnF = (loPoint1.longitude * loPoint2.latitude) - (loPoint2.longitude * loPoint1.latitude);
				lnX += (loPoint1.longitude + loPoint2.longitude) * lnF;
				lnY += (loPoint1.latitude + loPoint2.latitude) * lnF;
			}
			lnF = loArea * 6;
			return { longitude: lnX / lnF, latitude: lnY / lnF };
		}
	}

	/** Récupère le tableau des coordonnées délimitant la zone d'un secteur. */
	public getSectorZoneCoordinates(): ICoordinates[] {
		if (this.linkedBusinesses) {
			const laPoints: ICoordinates[] =
				this.linkedBusinesses
					.filter((poBiz: Business) => NumberHelper.isValid(poBiz.latitude) && NumberHelper.isValid(poBiz.longitude))
					.map((poBiz: Business) => {
						return { latitude: poBiz.latitude!, longitude: poBiz.longitude! };
					});
			return GeolocationHelper.getConvexHull(laPoints);
		}
		else {
			return [];
		}
	}

	/** Retourne `true` si le secteur est affichable sur une carte, `false` sinon. */
	public isMappable(): boolean {
		if (this.linkedBusinesses) {
			for (let lnIndex = 0; lnIndex < this.linkedBusinesses.length; lnIndex++) {
				if (NumberHelper.isValid(this.linkedBusinesses[lnIndex].latitude) && NumberHelper.isValid(this.linkedBusinesses[lnIndex].longitude))
					return true;
			}
		}
		if (this.linkedContacts) {
			for (let lnIndex = 0; lnIndex < this.linkedContacts.length; lnIndex++) {
				if (NumberHelper.isValid(this.linkedContacts[lnIndex].latitude) && NumberHelper.isValid(this.linkedContacts[lnIndex].longitude))
					return true;
			}
		}
		return false;
	}


	//#endregion
}
