<ion-item *ngIf="observableItem.value$ | async as item" class="ion-no-padding" lines="none"
	(click)="onItemClicked(item)">
	<calao-list-item>
		<calao-list-item-start>
			<trade-list-item-start-priority [priority]="item.observablePriority.value$ | async">
			</trade-list-item-start-priority>
		</calao-list-item-start>
		<calao-list-item-content [detail]="(observablePopOverItemsParams.changes$ | async).length > 0"
			(onDetailClicked)="presentPopOverAsync($event)" detailIcon="ellipsis-vertical">
			<calao-list-item-content-title>
				<ion-icon [name]="(item.observableIsPro.value$ | async) ? 'business' : 'person'"></ion-icon>
				{{ item.businessCode ?? "" }} {{ (item.observableName.value$ | async) ?? "" }}
			</calao-list-item-content-title>
			<span *ngIf="item.getBusinessInfoLabel$() | async as businessInfoLabel">{{ businessInfoLabel }}</span>
			<span *ngIf="item.city" class="city">
				<ion-icon name="location" color="tertiary"></ion-icon>
				<ion-note>{{ item.city }}</ion-note>
			</span>
			<calao-list-item-end *ngIf="item.picture || item.tradingName">
				<div class="item-end">
					<ion-avatar class="avatar">
						<avatar [src]="getBusinessAvatar(item)" cssClass="icon-area opacity60">
						</avatar>
					</ion-avatar>
					<ion-note>{{ item.tradingName }}</ion-note>
				</div>
			</calao-list-item-end>
		</calao-list-item-content>
	</calao-list-item>
</ion-item>