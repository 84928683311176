import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from './components/components.module';
import { SharedComponentsModule } from './components/sharedComponents.module';
import { FilesystemModule } from './modules/filesystem/filesystem.module';
import { GuidModule } from './modules/guid/guid.module';
import { ReminderAlarmsService } from './modules/reminder-alarms/services/reminder-alarms.service';
import { DevicesModule } from './modules/security/devices/devices.module';
import { StoreModule } from './modules/store/store.module';
import { TabsModule } from './modules/tabs/tabs.module';
import { TransfertModule } from './modules/transfert/transfert.module';
import { UnzipModule } from './modules/unzip/unzip.module';
import { VersionsModule } from './modules/versions/versions.module';
import { PipeModule } from './pipes/pipes.module';
import { InjectorService } from './services/injector.service';
import { OrchestratorService } from './services/orchestrator.service';
import { ServiceModule } from './services/services.module';

// Permet d'initialiser des services au démarrage de l'app
function initializeOsappFactory(isvcReminders: ReminderAlarmsService) {
	return () => {
		// Placer ici les initialisations globales à faire au démarrage de l'app
		isvcReminders.initAsync();
	};
}

const importModules: (Type<any> | ModuleWithProviders<any>)[] = [
	IonicModule,
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	ServiceModule,
	PipeModule,
	ComponentsModule,
	TabsModule,
	UnzipModule,
	VersionsModule,
	DevicesModule,
	GuidModule,
	StoreModule,
	FilesystemModule,
	TransfertModule
];
const exportModules: Type<any>[] = [
	ComponentsModule,
	ServiceModule,
	PipeModule,
	SharedComponentsModule
];
const providers: (Provider | EnvironmentProviders)[] = [
	Network,
	FileOpener,
	FileTransfer,
	provideHttpClient(withInterceptorsFromDi()),
	{
		provide: APP_INITIALIZER,
		deps: [ReminderAlarmsService],
		useFactory: initializeOsappFactory,
		multi: true
	}
];

@NgModule({
	imports: importModules,
	exports: exportModules,
	providers: providers
})
export class OsappModule {

	constructor(
		psvcOrchestrator: OrchestratorService,
		//A CONSERVER : Nécessaire à l'initialisation du singleton
		psvcInjector: InjectorService
	) {
		psvcOrchestrator.init();
	}

}