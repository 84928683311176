import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { OsappBottomSheetModule } from '../bottom-sheet';
import { SortSelectorBottomSheetComponent } from './components/sort-selector-bottom-sheet/sort-selector-bottom-sheet.component';
import { SortSelectorComponent } from './components/sort-selector/sort-selector.component';

@NgModule({
	declarations: [SortSelectorComponent, SortSelectorBottomSheetComponent],
	exports: [SortSelectorComponent],
	imports: [
		CommonModule,
		OsappBottomSheetModule,
		IonicModule
	]
})
export class SortModule { }
