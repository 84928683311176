<ng-container *ngIf="observableItem.value$ | async as item">
	<ion-item class="ion-no-padding" lines="none" (click)="onReminderClicked()">
		<calao-list-item>
			<calao-list-item-start [backgroundColor]="observableItemStartBackgroundColor.value$ | async">
				<ion-icon name="calendar-number" slot="start" [style.color]="observableItemStartIconColor.value$ | async">
				</ion-icon>
				<span class="reminder-label">Rapp</span>
			</calao-list-item-start>
			<calao-list-item-content [detail]="!(item.done$ | async)" detailIcon="ellipsis-vertical"
				(onDetailClicked)="presentPopOverAsync($event)">
				<div class="date-label-wrapper">
					<ng-container
						*ngTemplateOutlet="item.status === 'done' ? doneLabelTemplate : importantLabelTemplate; context : { item : item }">
					</ng-container>

					<ion-label *ngIf="item.startDate" class="date-label"
						[ngClass]="(!(item.status === 'done') && (item.isLate$ | async)) ? 'late' : ''">{{
						item.startDate | dateWithLocale: 'fr-FR' :
						'dd/MM/yyyy, HH:mm'
						}}</ion-label>
				</div>
				<span class="title">{{ item.title }}</span>
			</calao-list-item-content>
		</calao-list-item>
	</ion-item>
</ng-container>

<ng-template #doneLabelTemplate let-item="item">
	<div class="status-label-wrapper done">
		<ion-icon name="checkmark-circle"></ion-icon>
		<ion-label>Terminé</ion-label>
	</div>
</ng-template>

<ng-template #importantLabelTemplate let-item="item">
	<ng-container *ngIf="item.important">
		<div class="status-label-wrapper important">
			<ion-icon name="alert-circle"></ion-icon>
			<ion-label>Important</ion-label>
		</div>
	</ng-container>
</ng-template>