export enum ELinkAction {
	call = "call",
	callback = "callback",
	download = "download",
	mailTo = "mailTo",
	route = "route",
	navigate = "navigate", // Navigation en utilisant les routes.
	action = "action",
	sendMail = "sendMail",
	openModal = "openModal"
}