<ion-item *ngIf="observableContent.value$ | async as content" class="ion-no-padding" lines="none"
	(click)="navigateToTodoListPageAsync()">
	<div class="start">
		<ion-icon name="checkbox" slot="start" [style.color]="observableItemStartIconColor.value$ | async"></ion-icon>
		<span class="todo-label">To Do</span>
	</div>
	<div class="content">
		{{ content }}
	</div>
	<ng-container *ngIf="observableDiffCount.value$ | async as diffCount">
		<div slot="end">
			<span *ngIf="diffCount > 0">+{{ diffCount }}</span>
		</div>
	</ng-container>
	<ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
</ion-item>