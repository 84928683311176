import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { IDateRange } from '../../../components/date/date-range-picker/model/IDateRange';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper, IDateTypes } from '../../../helpers/dateHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { IContact } from '../../../model/contacts/IContact';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IDataSourceViewParams } from '../../../model/store/IDataSourceViewParams';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { ContactsService } from '../../../services/contacts.service';
import { GalleryService } from '../../../services/gallery.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../../services/interfaces/ShowMessageParamsToast';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { Contact } from '../../contacts/models/contact';
import { LogAction } from '../../logger/decorators/log-action.decorator';
import { ELogActionId } from '../../logger/models/ELogActionId';
import { ILogActionHandler } from '../../logger/models/ILogActionHandler';
import { ILogSource } from '../../logger/models/ILogSource';
import { LogActionHandler } from '../../logger/models/log-action-handler';
import { LoggerService } from '../../logger/services/logger.service';
import { PerformanceManager } from '../../performance/PerformanceManager';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { ModelResolver } from '../../utils/models/model-resolver';
import { IRange } from '../../utils/models/models/irange';
import { BaseEvent } from '../models/base-event';
import { BaseEventOccurrence } from '../models/base-event-occurrence';
import { EEventOccurrenceDifferentialDataArrayOperation } from '../models/eevent-occurrence-differential-data-array-operation';
import { EEventSubtype } from '../models/eevent-subtype';
import { EventDuration } from '../models/event-duration';
import { EventOccurrenceConstraint } from '../models/event-occurrence-constraint';
import { EventOccurrenceDateCriterion } from '../models/event-occurrence-date-criterion';
import { EventOccurrenceDelayAction } from '../models/event-occurrence-delay-action';
import { EventOccurrenceDifferential } from '../models/event-occurrence-differential';
import { EventOccurrenceParticipantAction } from '../models/event-occurrence-participant-action';
import { EventOccurrencePlaceAction } from '../models/event-occurrence-place-action';
import { EventState } from '../models/event-state';
import { IEvent } from '../models/ievent';
import { IEventFilterParams } from '../models/ievent-filter-params';
import { IEventOccurrenceDifferential } from '../models/ievent-occurrence-differential';
import { IEventOccurrencesFilterParams } from '../models/ievent-occurrences-filter-params';
import { IEventParticipantStatus } from '../models/ievent-participant-status';
import { IEventState } from '../models/ievent-state';
import { CalendarEventsInvitationService } from './calendar-events-invitation.service';
import { CalendarEventsParticipationService } from './calendar-events-participation.service';

@Injectable()
export class CalendarEventsService implements ILogActionHandler, ILogSource {

	//#region FIELDS

	private static readonly C_LOG_ID = "CALEVT.S::";
	private static readonly C_DIFFERENTIAL_EXCLUDED_KEYS: (keyof BaseEventOccurrence)[] = [
		"startDate",
		"participantIds",
		"place",
		"isDelegated"
	];

	private static readonly C_ICON_EVENT_DEFAULT = "star";
	private static readonly C_ICON_EVENT_MEETING = "people";
	private static readonly C_ICON_EVENT_CALL = "call";
	private static readonly C_ICON_EVENT_APPOINTMENT = "time";

	//#endregion

	//#region PROPERTIES

	public readonly logActionHandler = new LogActionHandler(this);

	public readonly logSourceId = "CALENDAR.EVT.S";

	//#endregion

	//#region METHODS

	constructor(
		protected readonly isvcStore: Store,
		protected readonly isvcContacts: ContactsService,
		protected readonly isvcUiMessage: UiMessageService,
		public readonly isvcLogger: LoggerService,
		private readonly isvcGallery: GalleryService,
		private readonly isvcInvitation: CalendarEventsInvitationService,
		protected readonly isvcParticipation: CalendarEventsParticipationService
	) { }

	public getEventAsync<T extends BaseEvent = BaseEvent>(psEventId: string): Promise<T | undefined> {
		return this.innerGetEvent$<T>(psEventId).pipe(take(1)).toPromise();
	}

	public getEvent$<T extends BaseEvent = BaseEvent>(psEventId: string, poActivePageManager: ActivePageManager): Observable<T | undefined> {
		return this.innerGetEvent$<T>(psEventId, poActivePageManager);
	}

	private innerGetEvent$<T extends BaseEvent = BaseEvent>(psEventId: string, poActivePageManager?: ActivePageManager): Observable<T | undefined> {
		return this.isvcStore.getOne({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: psEventId,
				include_docs: true
			},
			baseClass: BaseEvent,
			live: !!poActivePageManager,
			activePageManager: poActivePageManager,
			remoteChanges: !!poActivePageManager,
			withExternal: true
		}, false).pipe(
			// Ajout des informations d'état aux évènements
			switchMap((poEvent?: T) => {
				return this.getEventsStates$(poEvent ? [poEvent] : [], poActivePageManager).pipe(
					map((paResolvedStates: EventState[]) => {
						// Association des états récupérés aux évènements
						paResolvedStates.forEach((poEventState: EventState) =>
							poEvent.addState(poEventState)
						);
						return poEvent;
					})
				);
			})
		);
	}

	public getEventOccurrenceAsync<T extends BaseEventOccurrence = BaseEventOccurrence>(
		psEventId: string,
		pdDate: Date,
		psRev?: string
	): Promise<T | undefined> {
		return this.innerGetEventOccurrence$<T>(psEventId, pdDate, psRev).pipe(take(1)).toPromise();
	}

	public getEventOccurrence$<T extends BaseEventOccurrence = BaseEventOccurrence>(
		psEventId: string,
		poActivePageManager?: ActivePageManager,
		pdDate?: Date,
		psRev?: string,
	): Observable<T | undefined> {
		return this.innerGetEventOccurrence$<T>(psEventId, pdDate, psRev, poActivePageManager);
	}

	private innerGetEventOccurrence$<T extends BaseEventOccurrence = BaseEventOccurrence>(
		psEventId: string,
		pdDate?: Date,
		psRev?: string,
		poActivePageManager?: ActivePageManager
	): Observable<T | undefined> {
		return this.innerGetEvent$(psEventId, poActivePageManager).pipe(
			switchMap((poEvent?: BaseEvent<T>) => {
				if (!poEvent)
					return of(undefined);

				const loOccurrence: T | undefined = poEvent?.generateOccurrence(
					pdDate,
					psRev
				);

				if (loOccurrence) {
					const laParticipantIds: string[] = [...loOccurrence.participantIds];
					const lsLastChangeStatusContactId: string = loOccurrence.lastChangeStatusContactId;

					if (!StringHelper.isBlank(lsLastChangeStatusContactId))
						laParticipantIds.push(lsLastChangeStatusContactId);
					return combineLatest([
						this.isvcContacts.getContactById(laParticipantIds, undefined, !!poActivePageManager, poActivePageManager),
						this.isvcParticipation.getEventParticipationsIndexedByDate$(poEvent, !!poActivePageManager, poActivePageManager)
					]).pipe(
						map(([poContactsById, poEventParticipations]: [Map<string, IContact>, Map<number, IEventParticipantStatus[]>]) => {
							loOccurrence.participants = MapHelper.valuesToArray(poContactsById);
							this.applyParticipantStatusData(
								loOccurrence,
								loOccurrence.event.createOccurrence(loOccurrence.event, loOccurrence.event.startDate),
								poEventParticipations
							);
							if (!StringHelper.isBlank(lsLastChangeStatusContactId) && loOccurrence)
								loOccurrence.lastChangeStatusContact = poContactsById.get(lsLastChangeStatusContactId);
							return loOccurrence;
						})
					);
				}
				return of(loOccurrence);
			})
		);
	}

	/** Collecte en base les évènements respectant les conditions de filtrage passées en paramètre.
	* @param poFilterParams Conditions de filtrage : identifiants spécifiques, plage de dates, type...
	*/
	public getEvents$(poFilterParams?: IEventFilterParams, poActivePageManager?: ActivePageManager): Observable<BaseEvent[]> {
		return this.isvcStore.get<IEvent>(this.buildEventsDataSource(poFilterParams, poActivePageManager)).pipe(
			// Ajout des informations d'état aux évènements
			switchMap((paEvents: BaseEvent[]) => {
				return this.getEventsStates$(paEvents, poActivePageManager).pipe(
					map((paResolvedStates: EventState[]) => {
						// Association des états récupérés aux évènements
						paResolvedStates.forEach((poEventState: EventState) => {
							const lsEventId = IdHelper.getIdFromComposedId(poEventState._id.replace(EPrefix.eventState, ""), EPrefix.event);
							paEvents.find((poEvent: BaseEvent) => poEvent._id === lsEventId)?.addState(poEventState);
						});
						return paEvents;
					})
				);
			})
		);
	}

	private buildEventsDataSource(poFilterParams?: IEventFilterParams, poActivePageManager?: ActivePageManager): IDataSourceRemoteChanges {
		const loViewParams: IDataSourceViewParams = {
			include_docs: true
		};
		if (poFilterParams?.ids)
			loViewParams.keys = poFilterParams.ids;
		else {
			loViewParams.startkey = EPrefix.event;
			loViewParams.endkey = `${EPrefix.event}${Store.C_ANYTHING_CODE_ASCII}`;
		}
		return {
			baseClass: BaseEvent,
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			withExternal: true,
			// TODO : Remplacer ce filtrage par l'utilisation de l'indexeur
			filter: ((poEvent: IEvent) => {
				let lbKeepEvent = true;
				if (poFilterParams?.dateRange) {
					if (!poEvent.startDate) lbKeepEvent = false;
					else {
						const loEventDateRange: IRange<IDateTypes> = {
							from: poEvent.startDate,
							to: poEvent.endDate
						};

						try {
							lbKeepEvent = DateHelper.areRangesOverlaping(poFilterParams.dateRange, loEventDateRange);
						}
						catch (poError) {
							console.warn(`${CalendarEventsService.C_LOG_ID}Error while checking interval for ${poEvent._id}.`, loEventDateRange);
							lbKeepEvent = false;
						}
					}
				}
				if (lbKeepEvent && poFilterParams?.types) {
					lbKeepEvent = poFilterParams.types.includes(poEvent.eventType);
				}
				if (lbKeepEvent && poFilterParams?.subtypes) {
					lbKeepEvent = poFilterParams.subtypes.includes(poEvent.eventSubtype);
				} // On ne filtre pas par id de participant pour laisser l'opportunité de filtrer les occurrences après l'application des contraintes.

				return lbKeepEvent;
			}),
			viewParams: loViewParams,
			live: !!poActivePageManager,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager
		};
	}

	public getEventsStates$(paEvents: BaseEvent[], poActivePageManager?: ActivePageManager): Observable<EventState[]> {
		// Construction de tous les identifiants possibles pour les documents d'état
		const laEvtStateKeys: string[] = [];
		paEvents.forEach((poEvent: BaseEvent) => {
			poEvent.participantIds.forEach((psParticipantId: string) => {
				const lsUserId = `${EPrefix.user}${IdHelper.extractIdWithoutPrefix(psParticipantId, EPrefix.contact)}`;
				laEvtStateKeys.push(this.getEventStateId(lsUserId, poEvent));
			});
		});
		return this.isvcStore.get(this.getEventsStatesDataSource(laEvtStateKeys, true, poActivePageManager));
	}

	public getEventStateId(psUserId: string, poEvent: BaseEvent): string {
		return `${EPrefix.eventState}${EPrefix.event}${psUserId}_${IdHelper.getGuidFromId(poEvent._id, EPrefix.event)}`;
	}

	private getEventsStatesDataSource(paKeys: string[], pbIncludeDocs: boolean, poActivePageManager?: ActivePageManager): IDataSourceRemoteChanges<EventState> {
		return {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			filter: undefined,
			viewParams: {
				keys: paKeys,
				include_docs: pbIncludeDocs
			},
			live: !!poActivePageManager,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager,
			baseClass: EventState
		};
	}

	/** Génère, à partir des évènements présents en base, l'ensemble de leurs occurrences sur une plage donnée et selon les autres critères présents dans l'objet de paramétrage.
	* @param poFilterParams Permet de fixer la plage de date, le type d'évènement ou bien des identifiants spécifiques
	* @param pbLive Indique si la requête au Store sera live ou non
	*/
	public getEventsOccurrences$(poActivePageManager: ActivePageManager, poFilterParams: IEventOccurrencesFilterParams): Observable<BaseEventOccurrence[]> {
		return this.getEvents$(poFilterParams, poActivePageManager).pipe(
			switchMap((paEvents: BaseEvent[]) => this.generateEventsOccurrences$(paEvents, poActivePageManager, poFilterParams))
		);
	}

	protected generateEventsOccurrences$<T extends BaseEventOccurrence>(
		paEvents: BaseEvent<T>[],
		poActivePageManager: ActivePageManager,
		poFilterParams: IEventOccurrencesFilterParams
	): Observable<T[]> {
		const laParticipantIds: string[] = [];
		let laOccurrences: T[] = [];

		const loGenerationPerformanceManager = new PerformanceManager().markStart();
		paEvents.forEach((poEvent: BaseEvent<T>) => {
			try {
				laOccurrences.push(...poEvent.generateOccurrences(
					poFilterParams?.dateRange,
					poFilterParams?.limit
				));
			}
			catch (_) {
				console.error(`${CalendarEventsService.C_LOG_ID}Error while generating occurrences for ${poEvent._id}.`);
			}
		});

		console.debug(`${CalendarEventsService.C_LOG_ID}Generated ${laOccurrences.length} occurrence(s) in ${loGenerationPerformanceManager.markEnd().measure()}ms.`);

		if (ArrayHelper.hasElements(poFilterParams?.participantIds))
			laOccurrences = this.filterEventOccurrencesByParticipantsIds(laOccurrences, poFilterParams.participantIds);

		if (ArrayHelper.hasElements(poFilterParams?.businessIds)) {
			laOccurrences = this.filterEventOccurrencesByBusinessIds(laOccurrences, poFilterParams.businessIds);
		}

		const loOccurrencesByEventId: Map<string, T[]> = ArrayHelper.groupBy(laOccurrences, (poEventOccurrence: T) => {
			// On gère les id de contacts ici pour gagner un parcours du tableau.
			laParticipantIds.push(...poEventOccurrence.participantIds);
			const lsContactId: string | undefined = poEventOccurrence.lastChangeStatusContactId;
			if (!StringHelper.isBlank(lsContactId))
				laParticipantIds.push(lsContactId);

			return poEventOccurrence.eventId;
		});

		return this.prepareEventsOccurrences$<T>(laParticipantIds, paEvents, loOccurrencesByEventId, poActivePageManager);
	}

	protected applyParticipantStatusData<T extends BaseEventOccurrence>(
		poEventOccurrence: T,
		poBaseOccurrence: T,
		poParticipantStatusDataByOccurrenceDate?: Map<number, IEventParticipantStatus[]>
	): void {
		poEventOccurrence.participantsStatus = [];
		const loEventParticipantStatusByParticipantId: Map<string, IEventParticipantStatus> = ArrayHelper.groupByUnique(
			poParticipantStatusDataByOccurrenceDate?.get(poEventOccurrence.startDate?.getTime()) ?? [],
			(poItem: IEventParticipantStatus) => poItem.participantId
		);

		const loBaseEventParticipantStatusByParticipantId: Map<string, IEventParticipantStatus> = ArrayHelper.groupByUnique(
			poParticipantStatusDataByOccurrenceDate?.get(undefined) ?? [],
			(poItem: IEventParticipantStatus) => poItem.participantId
		);

		poEventOccurrence.participants.forEach((poParticipant: Contact) => {
			let loEventParticipantStatus: IEventParticipantStatus | undefined =
				loEventParticipantStatusByParticipantId.get(poParticipant._id);

			if (
				!loEventParticipantStatus || !this.occurrenceMatchParticipantStatus(poEventOccurrence, loEventParticipantStatus)
			) {
				const loBaseParticipantStatus: IEventParticipantStatus =
					loBaseEventParticipantStatusByParticipantId.get(poParticipant._id) ??
					this.isvcParticipation.getDefaultEventParticipantStatus(poParticipant, poBaseOccurrence, true);

				if (this.occurrenceMatchBaseParticipantStatus(poEventOccurrence, poBaseOccurrence, loBaseParticipantStatus))
					loEventParticipantStatus = loBaseParticipantStatus;
				else
					loEventParticipantStatus =
						this.isvcParticipation.getDefaultEventParticipantStatus(poParticipant, poBaseOccurrence);
			}

			poEventOccurrence.participantsStatus.push({
				...loEventParticipantStatus,
				participant: poParticipant
			});
		});
	}

	private occurrenceMatchBaseParticipantStatus<T extends BaseEventOccurrence>(
		poEventOccurrence: T,
		poBaseOccurrence: T,
		poBaseParticipantStatus: IEventParticipantStatus
	): boolean {
		return this.occurrenceMatchParticipantStatus(poEventOccurrence, poBaseParticipantStatus) &&
			this.occurrenceMatchParticipantStatus(poBaseOccurrence, poBaseParticipantStatus);
	}

	private occurrenceMatchParticipantStatus<T extends BaseEventOccurrence>(
		poEventOccurrence: T,
		poBaseParticipantStatus: IEventParticipantStatus
	): boolean {
		const lnStartOccurrenceHours: number | undefined = poEventOccurrence.startDate?.getHours();
		const lnStartOccurrenceMinutes: number | undefined = poEventOccurrence.startDate?.getMinutes();
		const lnStartParticipantStatusHours: number | undefined = poBaseParticipantStatus.startDate?.getHours();
		const lnStartParticipantStatusMinutes: number | undefined = poBaseParticipantStatus.startDate?.getMinutes();

		const lnEndOccurrenceHours: number | undefined = poEventOccurrence.observableEndDate.value?.getHours();
		const lnEndOccurrenceMinutes: number | undefined = poEventOccurrence.observableEndDate.value?.getMinutes();
		const lnEndParticipantStatusHours: number | undefined = poBaseParticipantStatus.endDate?.getHours();
		const lnEndParticipantStatusMinutes: number | undefined = poBaseParticipantStatus.endDate?.getMinutes();

		return lnStartOccurrenceHours === lnStartParticipantStatusHours &&
			lnStartOccurrenceMinutes === lnStartParticipantStatusMinutes &&
			lnEndOccurrenceHours === lnEndParticipantStatusHours &&
			lnEndOccurrenceMinutes === lnEndParticipantStatusMinutes &&
			poBaseParticipantStatus.place === poEventOccurrence.place;
	}


	/** Permet d'obtenir les occurrences d'évènements pour aujourd'hui, triées par date de début
	 * @param poFilterParams Filtres à appliquer (ex: limite du nombre d'évènements à renvoyer)
	 * @returns Un Observable des occurrences du jour
	 */
	public getTodaysEventOccurrences$(poFilterParams: IEventFilterParams, poActivePageManager: ActivePageManager): Observable<BaseEventOccurrence[]> {
		const ldNow = new Date();
		const loParams: IEventOccurrencesFilterParams = {
			...poFilterParams,
			dateRange: {
				from: DateHelper.resetDay(ldNow),
				to: DateHelper.resetDay(DateHelper.addDays(ldNow, 1))
			}
		};

		return this.getEventsOccurrences$(poActivePageManager, loParams).pipe(
			switchMap((paEventOccurrences: BaseEventOccurrence[]) => {
				if (ArrayHelper.hasElements(paEventOccurrences)) {
					return combineLatest(paEventOccurrences.map((poEventOccurrence: BaseEventOccurrence) =>
						poEventOccurrence.isLate$.pipe(
							map((pbLate: boolean) => pbLate ? undefined : poEventOccurrence)
						)
					));
				}
				return of([]);
			}),
			map((paEventOccurrences: (BaseEventOccurrence | undefined)[]) => ArrayHelper.getValidValues(paEventOccurrences)),
			map((paOccurrences: BaseEventOccurrence[]) => {
				paOccurrences = this.sortOccurencesByStartDate(paOccurrences);
				return poFilterParams?.limit ? paOccurrences.slice(0, poFilterParams.limit) : paOccurrences;
			})
		);
	}

	/** Permet de filtrer un tableau d'occurrences pour ne garder que celles ayant les participants spécifiés
	 * @param paOccurrences Tableau d'occurrences à filtrer
	 * @param paParticipantsIds Identifiants (contact ou utilisateur) des participants pour lequels on veut garder les occurrences
	 * @returns Un tableau d'occurrences concernant uniquement les participants spécifiés
	 */
	public filterEventOccurrencesByParticipantsIds<T extends BaseEventOccurrence>(paOccurrences: T[], paParticipantsIds: string[]): T[] {
		const loGenerationPerformanceManager = new PerformanceManager().markStart();

		const laOccurrences: T[] = paOccurrences.filter((loOccurrence: T) =>
			loOccurrence.hasSomeParticipants(paParticipantsIds)
		);

		console.debug(`${CalendarEventsService.C_LOG_ID}Filtered from ${paOccurrences.length} occurrence(s) to ${laOccurrences.length} in ${loGenerationPerformanceManager.markEnd().measure()}ms.`);

		return laOccurrences;
	}

	private filterEventOccurrencesByBusinessIds<T extends BaseEventOccurrence>(paOccurrences: T[], paCustomerIds: string[]): T[] {
		const loGenerationPerformanceManager = new PerformanceManager().markStart();
		const laOccurrences: T[] = paOccurrences.filter((loOccurrence: T) => paCustomerIds.includes(loOccurrence.businessId));
		console.debug(`${CalendarEventsService.C_LOG_ID}Filtered from ${paOccurrences.length} occurrence(s) to ${laOccurrences.length} in ${loGenerationPerformanceManager.markEnd().measure()}ms.`);
		return laOccurrences;
	}

	/** Trie les occurrences selon leur date de début, par ordre croissant
	 * @param paOccurrences Tableau d'occurrences à trier
	 * @returns Tableau d'occurrences trié, celle avec la date de début la plus ancienne en premier
	 */
	public sortOccurencesByStartDate(paOccurrences: BaseEventOccurrence[]): BaseEventOccurrence[] {
		return paOccurrences.sort((poOccurrenceA, poOccurrenceB) => DateHelper.compareTwoDates(poOccurrenceA.startDate, poOccurrenceB.startDate));
	}

	/** Sauvegarde un évènement en base de données.
	 * @param poEvent Evènement à sauvegarder
	 * @returns Un observable de la réponse du Store suite à l'enregistrement
	 */
	public async saveAsync(poEvent: BaseEvent, poSourceModel?: BaseEvent, pbIgnoreInvitations?: boolean): Promise<void> {
		const loSavedEvent: BaseEvent | undefined = poSourceModel ?? await this.getEventAsync(poEvent._id);
		// Dans le cas d'une mise à jour, on sauvegarde le numéro de la révision précédente pour faciliter les comparaisons ultérieures (ex: notifications)
		if (loSavedEvent?._rev) poEvent.previousRev = loSavedEvent._rev;

		const loCurrentEventState: EventState | undefined = loSavedEvent?.stateByUserId?.get(UserHelper.getUserId());
		const loUserState: EventState | undefined = poEvent.stateByUserId?.get(UserHelper.getUserId());

		if (loUserState && (!loCurrentEventState || (!DateHelper.areEqual(loUserState.lastUpdate, loCurrentEventState.lastUpdate)))) {
			// L'évènement est porteur d'informations d'état nouvelles pour l'utilisateur courant, il faut les sauvegarder
			await this.isvcStore.put(
				loUserState,
				ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))
			).toPromise();
		}

		if (poEvent.status !== loSavedEvent?.status)
			poEvent.statusChangeDate = new Date;

		if (ArrayHelper.hasElements(poEvent.attachments) || ArrayHelper.hasElements(loSavedEvent?.attachments))
			await this.isvcGallery.save$(poEvent.attachments, loSavedEvent?.attachments).toPromise();

		if (!ObjectHelper.areEqual(poEvent, poSourceModel)) {
			await this.isvcStore.put(poEvent, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))).toPromise();
			if (!pbIgnoreInvitations)
				this.isvcInvitation.updateEventInvitations(poEvent, poSourceModel);
		}
	}

	/** Sauvegarde en base les données saisies dans un formulaire d'évènement
	 * @param poModel
	 * @returns Un observable de la réponse du store
	 */
	public async saveOccurrenceFromFormModelAsync(poSourceOccurrence: BaseEventOccurrence, poModel: BaseEventOccurrence): Promise<void> {
		const loConstraint = new EventOccurrenceConstraint({
			criteria: [new EventOccurrenceDateCriterion({ date: poSourceOccurrence.startDate })],
			rev: poSourceOccurrence.event._rev
		});

		ArrayHelper.pushIfNotPresent(poModel.participantIds, UserHelper.getUserContactId(poModel.authorId)); // L'organisateur d'un évènement ne doit pas pouvoir s'en retirer.

		this.prepareConstraintActions(poModel, poSourceOccurrence, loConstraint);

		if (poModel.status !== poSourceOccurrence?.status)
			poModel.statusChangeDate = new Date;

		const loDifferential: EventOccurrenceDifferential | undefined = await this.prepareDifferential(poSourceOccurrence, poModel);

		if (ArrayHelper.hasElements(poModel.attachments) || ArrayHelper.hasElements(poSourceOccurrence?.attachments))
			await this.isvcGallery.save$(poModel.attachments, poSourceOccurrence?.attachments).toPromise();

		if (loDifferential) {
			if (loDifferential?._rev) loDifferential.previousRev = loDifferential._rev;
			await this.isvcStore.put(loDifferential, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))).toPromise();
		}

		if (ArrayHelper.hasElements(loConstraint.actions)) {
			poModel.event.occurrenceConstraints.push(loConstraint);

			await this.saveAsync(poModel.event, poSourceOccurrence.event, true);
		}

		if (loDifferential || ArrayHelper.hasElements(loConstraint.actions))
			this.isvcInvitation.updateEventOccurrenceInvitations(poModel, poSourceOccurrence);
	}

	private prepareConstraintActions(
		poModel: BaseEventOccurrence,
		poSourceOccurrence: BaseEventOccurrence,
		poConstraint: EventOccurrenceConstraint
	): void {
		if (poModel.startDate && poSourceOccurrence.startDate) {
			const lnMinutesDiff: number = DateHelper.diffMinutes(poModel.startDate, poSourceOccurrence.startDate);
			if (lnMinutesDiff !== 0) {
				poConstraint.actions.push(new EventOccurrenceDelayAction({ delayInPast: lnMinutesDiff < 0, delay: new EventDuration({ minutes: Math.abs(lnMinutesDiff) }) }));
			}
		}
		if (!ArrayHelper.areArraysEqual(poModel.participantIds, poSourceOccurrence.participantIds)) {
			poConstraint.actions.push(new EventOccurrenceParticipantAction({
				add: ArrayHelper.getDifferences(poModel.participantIds, poSourceOccurrence.participantIds),
				remove: ArrayHelper.getDifferences(poSourceOccurrence.participantIds, poModel.participantIds)
			}));
		}
		if (poModel.place !== poSourceOccurrence.place)
			poConstraint.actions.push(new EventOccurrencePlaceAction({ place: poModel.place }));
	}

	private getEventOccurrenceDifferentialAsync(poEventOccurrence: BaseEventOccurrence): Promise<EventOccurrenceDifferential | undefined> {
		const lsStartKey: string = IdHelper.buildChildId(
			EPrefix.eventOccurrenceDifferential,
			IdHelper.buildVirtualNode([poEventOccurrence.eventId, EPrefix.user]),
			""
		);

		return this.isvcStore.getOne({
			baseClass: EventOccurrenceDifferential,
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			filter: (poDoc: IEventOccurrenceDifferential) => DateHelper.areEqual(poDoc.occurrenceStartDate, poEventOccurrence.startDate)
		}, false).toPromise();
	}

	protected getEventOccurrenceDifferentialsByEventIds$(paEventIds: string[], poActivePageManager?: ActivePageManager): Observable<Map<string, EventOccurrenceDifferential[]>> {
		return this.getEventOccurrenceDifferentialIdsByEventIds$(paEventIds, poActivePageManager).pipe(
			switchMap((paIds: string[]) => this.isvcStore.get({
				baseClass: EventOccurrenceDifferential,
				role: EDatabaseRole.workspace,
				viewParams: {
					keys: paIds,
					include_docs: true
				},
				live: !!poActivePageManager,
				remoteChanges: !!poActivePageManager,
				activePageManager: poActivePageManager
			})),
			map((paDifferentials: EventOccurrenceDifferential[]) => ArrayHelper.groupBy(paDifferentials, (poDifferential: EventOccurrenceDifferential) => poDifferential.eventId))
		);
	}

	private getEventOccurrenceDifferentialIdsByEventIds$(paEventIds: string[], poActivePageManager?: ActivePageManager): Observable<string[]> {
		return this.isvcStore.get({
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: EPrefix.eventOccurrenceDifferential,
				endkey: `${EPrefix.eventOccurrenceDifferential}${Store.C_ANYTHING_CODE_ASCII}`
			},
			filter: (poDoc: IStoreDocument) => paEventIds.includes(EventOccurrenceDifferential.getEventIdFromId(poDoc._id)),
			live: !!poActivePageManager,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager
		}).pipe(
			distinctUntilChanged(ArrayHelper.areArraysFromDatabaseEqual),
			map((paDocs: IStoreDocument[]) => paDocs.map((poDoc: IStoreDocument) => poDoc._id))
		);
	}

	private async prepareDifferential(
		poSourceOccurrence: BaseEventOccurrence,
		poOccurrence: BaseEventOccurrence
	): Promise<EventOccurrenceDifferential | undefined> {
		let lbChanged = false;
		let loEventOccurrenceDifferential: EventOccurrenceDifferential | undefined = await this.getEventOccurrenceDifferentialAsync(poSourceOccurrence);

		if (!loEventOccurrenceDifferential)
			loEventOccurrenceDifferential = this.buildNewDifferential(poSourceOccurrence);
		else {
			loEventOccurrenceDifferential.occurrenceStartDate = poSourceOccurrence.startDate;
			loEventOccurrenceDifferential.eventRev = poSourceOccurrence.event._rev;
			loEventOccurrenceDifferential.isDelegated = poOccurrence.isDelegated;
		}

		// On transforme en JSON pour éviter d'avoir les ObservableProperties.
		const loOccurrence: BaseEventOccurrence = ModelResolver.toPlain(poOccurrence);
		const loSourceOccurrence: BaseEventOccurrence = ModelResolver.toPlain(poSourceOccurrence);

		Object.keys(loOccurrence).forEach((psKey: keyof BaseEventOccurrence) => {
			if (CalendarEventsService.C_DIFFERENTIAL_EXCLUDED_KEYS.includes(psKey))
				return;

			const loSourceValue: any = loSourceOccurrence[psKey];
			const loNewValue: any = loOccurrence[psKey];
			if (!ObjectHelper.areEqual(loSourceValue, loNewValue)) {
				if (!loEventOccurrenceDifferential.differential[psKey])
					loEventOccurrenceDifferential.differential[psKey] = [];

				if (loNewValue instanceof Array) {
					const laRemoved: any[] = ArrayHelper.getDifferences(loSourceValue, loNewValue, (poItemA: any, poItemB: any) => ObjectHelper.areEqual(poItemA, poItemB));
					const laAdded: any[] = ArrayHelper.getDifferences(loNewValue, loSourceValue, (poItemA: any, poItemB: any) => ObjectHelper.areEqual(poItemA, poItemB));

					laAdded.forEach((poValue: any) =>
						loEventOccurrenceDifferential.differential[psKey].push({
							date: new Date, userId: UserHelper.getUserId(), value: poValue, arrayOperation: EEventOccurrenceDifferentialDataArrayOperation.add
						})
					);

					laRemoved.forEach((poValue: any) =>
						loEventOccurrenceDifferential.differential[psKey].push({
							date: new Date, userId: UserHelper.getUserId(), value: poValue, arrayOperation: EEventOccurrenceDifferentialDataArrayOperation.remove
						})
					);
				}
				else
					loEventOccurrenceDifferential.differential[psKey].push({ date: new Date, userId: UserHelper.getUserId(), value: loNewValue });

				lbChanged = true;
			}
		});

		return lbChanged ? loEventOccurrenceDifferential : undefined;
	}

	private buildNewDifferential(poSourceOccurrence: BaseEventOccurrence): EventOccurrenceDifferential {
		return new EventOccurrenceDifferential({
			_id: IdHelper.buildChildId(
				EPrefix.eventOccurrenceDifferential,
				IdHelper.buildVirtualNode([poSourceOccurrence.eventId, IdHelper.buildId(EPrefix.user, "common")])
			),
			eventRev: poSourceOccurrence.event._rev,
			occurrenceStartDate: poSourceOccurrence.startDate,
			createDate: new Date,
			isDelegated: poSourceOccurrence.isDelegated,
			eventType: poSourceOccurrence.eventType
		});
	}

	@LogAction<Parameters<CalendarEventsService["deleteEventAsync"]>, ReturnType<CalendarEventsService["deleteEventAsync"]>>({
		actionId: ELogActionId.eventDelete,
		successMessage: (_, poEvent: BaseEvent) => `Suppression de l'évènement ${poEvent._id}`,
		errorMessage: (_, poEvent: BaseEvent) => `Erreur lors de la suppression de l'évènement ${poEvent._id}`
	})
	public async deleteEventAsync(poEvent: BaseEvent): Promise<boolean> {
		this.isvcInvitation.deleteEventInvitation(poEvent);
		await this.deleteEventOccurrenceDifferentialAsync(poEvent);
		const pbDeleted: boolean = (await this.isvcStore.delete(poEvent).toPromise()).ok;
		if (pbDeleted)
			this.isvcUiMessage.showToastMessage(new ShowMessageParamsToast({ message: `"${poEvent.title}" supprimé.`, color: "dark" }));
		return pbDeleted;
	}

	@LogAction<Parameters<CalendarEventsService["deleteEventOccurrenceDifferentialAsync"]>, ReturnType<CalendarEventsService["deleteEventOccurrenceDifferentialAsync"]>>({
		actionId: ELogActionId.eventOccDiffDelete,
		successMessage: (_, poEvent: BaseEvent) => `Suppression du document occDiff ${poEvent._id}`,
		errorMessage: (_, poEvent: BaseEvent) => `Erreur lors de la suppression du document occDiff ${poEvent._id}`
	})
	public async deleteEventOccurrenceDifferentialAsync(poEvent: BaseEvent): Promise<boolean> {
		return await this.isvcStore.deleteMultipleDocuments(
			MapHelper.valuesToArray(await this.getEventOccurrenceDifferentialsByEventIds$([poEvent._id]).toPromise()).flat(), // Important pour la gestion du getLive avec vue
			ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))
		).toPromise();
	}

	public askForDeletetionAsync(poEvent: BaseEvent): Promise<boolean> {
		if (DateHelper.compareTwoDates(poEvent.startDate, new Date) < 0) {
			return this.showAskingForDeletePopupAsync(
				undefined,
				`Vous allez supprimer des informations d'évènement(s) passé(s), êtes-vous sûr?`
			);
		}

		return this.showAskingForDeletePopupAsync(
			undefined,
			`Voulez-vous vraiment supprimer cet évènement ${ArrayHelper.hasElements(poEvent.recurrences) ? "et l'ensemble des occurrences de la série à laquelle il appartient " : ""}?`
		);
	}

	protected showAskingForDeletePopupAsync(psMessage?: string, psTitle?: string): Promise<boolean> {
		return this.isvcUiMessage.showAsyncMessage<boolean, any>(new ShowMessageParamsPopup({
			header: psTitle,
			message: psMessage,
			buttons: [
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui, supprimer", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }],
		}))
			.pipe(
				map((poResponse: IUiResponse<boolean>) => !!poResponse.response)
			)
			.toPromise();
	}

	public async getParticipantEventStateAsync(psEventId: string, psParticipantId: string): Promise<EventState | undefined> {
		const lsEventStateId = `${EPrefix.eventState}${EPrefix.event}${psParticipantId}_${IdHelper.getLastGuidFromId(psEventId)}`;
		const loDataSource: IDataSource = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)),
			viewParams: {
				include_docs: true,
				key: lsEventStateId
			}
		};
		return this.isvcStore.getOne<IEventState>(loDataSource, false).pipe(
			map((poEventState?: IEventState) => poEventState ? new EventState(poEventState) : undefined)
		).toPromise();
	}

	@LogAction<Parameters<CalendarEventsService["deleteEventStatesAsync"]>, ReturnType<CalendarEventsService["deleteEventStatesAsync"]>>({
		actionId: ELogActionId.eventStateDelete,
		successMessage: (_, paEventIds: string[]) => `Suppression des documents evtState pour les évènements ${paEventIds?.join(", ")}.`,
		errorMessage: (_, paEventIds: string[]) => `Erreur lors de la suppression des documents evtState pour les évènements ${paEventIds?.join(", ")}.`
	})
	public async deleteEventStatesAsync(paEventIds: string[]): Promise<boolean> {
		const lsDatabaseId: string = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
		const loDataSource: IDataSource = {
			databaseId: lsDatabaseId,
			viewParams: {
				startkey: EPrefix.eventState,
				endkey: EPrefix.eventState + Store.C_ANYTHING_CODE_ASCII
			}
		};

		const laEventStates: IStoreDocument[] = await this.isvcStore.get(loDataSource).toPromise();

		const laEvtStateToDeleteIds: string[] = [];
		const loEventStatesByEventGuid: Map<string, IStoreDocument[]> = ArrayHelper.groupBy(laEventStates, (poItem: IStoreDocument) => IdHelper.getLastGuidFromId(poItem._id));
		const laEventGuids: string[] = paEventIds.map((psId: string) => IdHelper.getLastGuidFromId(psId));

		laEventGuids.forEach((psGuid: string) => laEvtStateToDeleteIds.push(...(loEventStatesByEventGuid.get(psGuid) ?? []).map((poDoc: IStoreDocument) => poDoc._id)));

		return this.isvcStore.deleteMultipleDocuments(laEvtStateToDeleteIds, lsDatabaseId).toPromise();
	}

	public async updateEventStateAsync(poEventState: EventState): Promise<IStoreDataResponse> {
		return this.isvcStore.put(poEventState, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))).toPromise();
	}

	public getEventStateInstance(psUserId: string, poEvent: BaseEvent): EventState {
		return new EventState({
			_id: this.getEventStateId(psUserId, poEvent),
			userId: psUserId,
			lastUpdate: new Date()
		});
	}

	/**
	 * Récupère les occurrences en attente de réponse de participation pour l'utilisateur connecté.
	 */
	public getUserWaitingParticipationOccurrences$(poActivePageManager: ActivePageManager, psType: string, poRange?: IDateRange): Observable<BaseEventOccurrence[]> {
		return this.getEvents$({ types: [psType] }, poActivePageManager).pipe(
			switchMap((paEvents: BaseEvent[]) => {
				const lsUserContactId: string = UserHelper.getUserContactId();
				let laOccurrences: BaseEventOccurrence[] = [];
				const laParticipantIds: string[] = [];

				paEvents.forEach((poEvent: BaseEvent) => {
					const loBaseOccurrence: BaseEventOccurrence | undefined = poEvent.getBaseOccurrence(poRange);
					if (loBaseOccurrence)
						laOccurrences.push(loBaseOccurrence);

					laOccurrences.push(...poEvent.generateExceptionalOccurrences(poRange));
				});

				laOccurrences = this.filterEventOccurrencesByParticipantsIds(laOccurrences, [lsUserContactId]);

				const loOccurrencesByEventId: Map<string, BaseEventOccurrence[]> =
					ArrayHelper.groupBy(laOccurrences, (poEventOccurrence: BaseEventOccurrence) => {
						// On gère les id de contacts ici pour gagner un parcours du tableau.
						laParticipantIds.push(...poEventOccurrence.participantIds);
						const lsContactId: string | undefined = poEventOccurrence.lastChangeStatusContactId;
						if (!StringHelper.isBlank(lsContactId))
							laParticipantIds.push(lsContactId);

						return poEventOccurrence.eventId;
					});

				return this.prepareEventsOccurrences$(laParticipantIds, paEvents, loOccurrencesByEventId, poActivePageManager);
			}),
			map((paEventOccurrences: BaseEventOccurrence[]) => this.sortOccurencesByStartDate(paEventOccurrences.filter(
				(poEventOccurrence: BaseEventOccurrence) => poEventOccurrence.hasToRequestParticipation
			)))
		);
	}

	private prepareEventsOccurrences$<T extends BaseEventOccurrence>(
		paParticipantIds: string[],
		paEvents: BaseEvent<T>[],
		poOccurrencesByEventId: Map<string, T[]>,
		poActivePageManager: ActivePageManager
	): Observable<T[]> {
		return combineLatest([
			this.isvcContacts.getContactById(paParticipantIds, undefined, true, poActivePageManager),
			this.isvcParticipation.getEventsParticipationsIndexedByDate$(paEvents, true, poActivePageManager)
		]).pipe(
			map(([poContactsById, poEvent]: [Map<string, IContact>, Map<string, Map<number, IEventParticipantStatus[]>>]) => {
				const laOccurrences: T[] = [];
				poOccurrencesByEventId.forEach((paOccurrences: T[], psEventId: string) => {
					let loBaseEvent: BaseEventOccurrence;
					const loParticipantStatusDataByOccurrenceDate: Map<number, IEventParticipantStatus[]> | undefined = poEvent.get(psEventId);
					paOccurrences.forEach((poEventOccurrence: T) => {
						if (!loBaseEvent)
							loBaseEvent = poEventOccurrence.event.createOccurrence(poEventOccurrence.event, poEventOccurrence.event.startDate);
						poEventOccurrence.participants = ArrayHelper.getValidValues(poEventOccurrence.participantIds.map((psId: string) => poContactsById.get(psId)));

						this.applyParticipantStatusData(poEventOccurrence, loBaseEvent, loParticipantStatusDataByOccurrenceDate);
						const lsLastChangeStatusContactId: string | undefined = poEventOccurrence.lastChangeStatusContactId;
						if (!StringHelper.isBlank(lsLastChangeStatusContactId))
							poEventOccurrence.lastChangeStatusContact = poContactsById.get(lsLastChangeStatusContactId);

						laOccurrences.push(poEventOccurrence);
					});

				});

				return laOccurrences;
			})
		);
	}

	public getIconNameFromSubType(psSubtype?: string): string {
		switch (psSubtype) {
			case EEventSubtype.appointment:
				return CalendarEventsService.C_ICON_EVENT_APPOINTMENT;
			case EEventSubtype.call:
				return CalendarEventsService.C_ICON_EVENT_CALL;
			case EEventSubtype.meeting:
				return CalendarEventsService.C_ICON_EVENT_MEETING;
			default:
				return CalendarEventsService.C_ICON_EVENT_DEFAULT;
		}
	}

	/** Permet de connaître le sous-type d'un évènement.
	 * @param poEvent Evènement dont on cherche le sous-type
	 */
	public getEventSubtypeLabel(poEvent?: BaseEvent): string {
		switch (poEvent?.eventSubtype) {
			case EEventSubtype.appointment:
				return "Rendez-vous";
			case EEventSubtype.call:
				return "Appel";
			case EEventSubtype.meeting:
				return "Réunion";
			default:
				return "Autre";
		}
	}

	/** Renvoie un libellé court pour un évènement (déduit d'après son sous-type)
	 * @param poEvent Evènement dont on souahite connaitre le libellé
	 * @returns Observable du libellé court associé à l'évènement
	 */
	public getShortTypeLabelFromSubtype$(poEvent: BaseEventOccurrence): Observable<string> {
		return poEvent.observableSubtype.value$.pipe(
			map((psSubtype: string) => {
				switch (psSubtype) {
					case EEventSubtype.appointment:
						return "Rdv";
					case EEventSubtype.call:
						return "Tel";
					case EEventSubtype.meeting:
						return "Réu";
					default:
						return "Evt";
				}
			})
		);
	}

	//#endregion

}
