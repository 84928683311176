import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DateHelper } from "@calaosoft/osapp/helpers/dateHelper";
import { StringHelper } from "@calaosoft/osapp/helpers/stringHelper";
import { UserHelper } from "@calaosoft/osapp/helpers/user.helper";
import { ConfigData } from "@calaosoft/osapp/model/config/ConfigData";
import { ETimetablePattern } from "@calaosoft/osapp/model/date/ETimetablePattern";
import { OsappApiHelper } from "@calaosoft/osapp/modules/osapp-api/helpers/osapp-api.helper";
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { environment } from "../../../config";
import { IPlanningRH } from '../model/IPlanningRH';

export class PlanningApiClient {

	//#region METHODS

	constructor(
		private ioHttpClient: HttpClient,
	) { }

	private getApiPath(): string {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_suffix}apps/${ConfigData.appInfo.appId}`;
	}

	private getPlanningApiPath(psWorkspaceId: string, psPlanningId?: string): string {
		return `${this.getApiPath()}/workspaces/${psWorkspaceId}/plannings${!StringHelper.isBlank(psPlanningId) ? `/${psPlanningId}` : ""}`;
	}

	private addRequestParam(psUrl: string, psParamName: string, poParamValue?: any): string {
		if (poParamValue !== undefined && poParamValue !== null)
			psUrl = `${psUrl}${psUrl.includes("?") ? "&" : "?"}${psParamName}=${poParamValue}`;
		return psUrl;
	}

	public getPlanning(psWorkspaceId: string, psDate: string): Observable<IPlanningRH> {
		let lsUrl: string = this.getPlanningApiPath(psWorkspaceId);
		lsUrl = this.addRequestParam(lsUrl, 'siteId', UserHelper.getCurrentSiteId());
		lsUrl = this.addRequestParam(lsUrl, 'date', psDate);

		return this.ioHttpClient.get<IPlanningRH>(lsUrl, { headers: this.getApiHeaders() })
			.pipe(catchError(poError => { console.error("PLAN.API.CLIENT:: Error while getting planning.", poError); return throwError(() => poError); }));
	}

	public getPlannings(psWorkspaceId: string, pdFrom: Date, pdTo: Date): Observable<IPlanningRH[]> {
		let lsUrl: string = this.getPlanningApiPath(psWorkspaceId);
		lsUrl = this.addRequestParam(lsUrl, 'siteId', UserHelper.getCurrentSiteId());
		lsUrl = this.addRequestParam(lsUrl, 'from', pdFrom.toISOString());
		lsUrl = this.addRequestParam(lsUrl, 'to', pdTo.toISOString());

		return this.ioHttpClient.get<IPlanningRH[]>(lsUrl, { headers: this.getApiHeaders() })
			.pipe(catchError(poError => { console.error("PLAN.API.CLIENT:: Error while getting plannings.", poError); return throwError(() => poError); }));
	}

	public createPlanning(psWorkspaceId: string, psDate: string): Observable<IPlanningRH> {
		let lsUrl = `${this.getPlanningApiPath(psWorkspaceId)}/${DateHelper.transform(psDate, ETimetablePattern.isoFormat_hyphen)}`;
		lsUrl = this.addRequestParam(lsUrl, 'siteId', UserHelper.getCurrentSiteId());

		return this.ioHttpClient.post<IPlanningRH>(lsUrl, null, { headers: this.getApiHeaders() })
			.pipe(catchError(poError => { console.error("PLAN.API.CLIENT:: Error while creating planning.", poError); return throwError(() => poError); }));
	}

	public updatePlanning(psWorkspaceId: string, poPlanningRH: IPlanningRH): Observable<IPlanningRH> {
		const lsUrl: string = this.getPlanningApiPath(psWorkspaceId, poPlanningRH._id);

		return this.ioHttpClient.put<IPlanningRH>(lsUrl, poPlanningRH, { headers: this.getApiHeaders() })
			.pipe(catchError(poError => { console.error("PLAN.API.CLIENT:: Error while updating planning.", poError); return throwError(() => poError); }));
	}

	public deletePlanning(psWorkspaceId: string, poPlanningRH: IPlanningRH): Observable<IPlanningRH> {
		const lsUrl: string = this.getPlanningApiPath(psWorkspaceId, poPlanningRH._id);

		return this.ioHttpClient.delete<IPlanningRH>(lsUrl, { headers: this.getApiHeaders() })
			.pipe(catchError(poError => { console.error("PLAN.API.CLIENT:: Error while deleting planning.", poError); return throwError(() => poError); }));
	}

	private getApiHeaders(): HttpHeaders {
		return new HttpHeaders({
			"api-key": environment.API_KEY as string,
			appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
			token: ConfigData.authentication.token as string
		});
	}

	//#endregion
}