import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { StringHelper } from '../../../../helpers/stringHelper';
import { EAvatarSize } from '../../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { IPicture } from '../../../../model/picture/IPicture';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { IFormListDetailEvent } from '../../../forms/models/iform-list-detail-event';
import { IListDefinitionsField } from '../../../forms/models/IListDefinitionsField';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';

interface IIcon {
	icon?: string;
	url?: string;
	iconName?: string;
}

interface IImage {
	url?: string;
	alt?: string;
}

@Component({ template: "" })
export class EntityEntriesListItemBaseComponent<T extends IStoreDocument> extends ComponentBase {

	//#region FIELDS

	/** Événement lors du clic sur le détail d'un item. */
	@Output("onDetailClicked") private readonly moDetailClickedEvent = new EventEmitter<IFormListDetailEvent<T>>();

	/** Événement lors du clic sur un item. */
	@Output("onItemClicked") private readonly moItemClickedEvent = new EventEmitter<T>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Doc propriété. */
	@Input() public item?: T;
	@ObserveProperty<EntityEntriesListItemBaseComponent<T>>({ sourcePropertyKey: "item" })
	public readonly observableItem = new ObservableProperty<T>();

	/** Doc propriété. */
	@Input() public index?: number;
	@ObserveProperty<EntityEntriesListItemBaseComponent<T>>({ sourcePropertyKey: "index" })
	public readonly observableindex = new ObservableProperty<number>();

	/** Indique si on doit afficher l'icône de détail de l'item. */
	@Input() public detail?: boolean | string | null;
	@ObserveProperty<EntityEntriesListItemBaseComponent<T>>({
		sourcePropertyKey: "detail",
		transformer: (poNewValue: any) => coerceBooleanProperty(poNewValue)
	})
	public readonly observableDetail = new ObservableProperty<boolean>(false);

	/** Représente l'énumération sous forme d'objet. */
	public readonly avatarSize: typeof EAvatarSize = EAvatarSize;

	//#endregion PROPERTIES

	//#region METHODS

	public onDetailClicked(poEvent: MouseEvent): void {
		if (this.observableItem.value)
			this.moDetailClickedEvent.emit({ event: poEvent, item: this.observableItem.value });
	}

	public onItemClicked(): void {
		if (this.observableItem.value)
			this.moItemClickedEvent.emit(this.observableItem.value);
	}

	/** Crée et retourne un avatar depuis les données passées en paramètre.
 * @param poData Données contenant les informations de l'avatar à récupérer.
 */
	public getAvatar(poData: IPicture): IAvatar {
		return {
			text: "",
			size: EAvatarSize.big,
			data: StringHelper.isBlank(poData.url) ? poData.base64 : poData.url,
			mimeType: poData.mimeType,
			guid: poData.guid
		};
	}

	public getPictureCol(poCol: IListDefinitionsField<T>, poItem: T): IPicture | undefined {
		if (poCol.isPicture)
			return poItem[poCol.key] as any as IPicture;
		return undefined;
	}

	public getIconCol(poCol: IListDefinitionsField<T>, poItem: T): IIcon | undefined {
		if (poCol.isIcon)
			return poItem[poCol.key] as any as IIcon;
		return undefined;
	}

	public getImageCol(poCol: IListDefinitionsField<T>, poItem: T): IImage | undefined {
		if (poCol.isImage)
			return poItem[poCol.key] as any as IImage;
		return undefined;
	}

	//#endregion METHODS

}
