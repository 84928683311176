import { Exclude } from "class-transformer";
import { IdHelper } from "../../../helpers/idHelper";
import { ObjectHelper } from "../../../helpers/objectHelper";
import { EGender } from "../../../model/EGender";
import { EPrefix } from "../../../model/EPrefix";
import { IContact } from "../../../model/contacts/IContact";
import { IGroup } from "../../../model/contacts/IGroup";
import { Picture } from "../../../model/picture/picture";
import { Entity } from "../../entities/models/entity";
import { ModelMatch } from "../../utils/models/decorators/model-match.decorator";

@ModelMatch((poContact?: IContact) => IdHelper.hasPrefixId(poContact?._id ?? "", EPrefix.contact), Entity)
export class Contact extends Entity implements IContact {

	//#region PROPERTIES

	/** @implements */
	public firstName?: string;
	/** @implements */
	public lastName?: string;
	/** @implements */
	public displayName?: string;
	/** @implements */
	public maidenName?: string;
	/** @implements */
	public birthDate?: Date;
	/** @implements */
	public emailChecked?: boolean;
	/** @implements */
	public gender?: EGender;
	/** @implements */
	public profession?: string;
	/** @implements */
	public userId?: string;
	/** @implements */
	public latitude?: number;
	/** @implements */
	public longitude?: number;
	/** @implements */
	public htmlBody?: string;
	/** @implements */
	public avatarUrl?: string;
	/** @implements */
	public roles?: string[];
	/** @implements */
	public agendaId?: string;
	/** @implements */
	public email?: string;
	/** @implements */
	public phone?: string;
	/** @implements */
	public picture?: Picture;

	@Exclude({ toPlainOnly: true })
	public groups?: IGroup[];

	//#endregion

	//#region METHODS

	constructor(poData?: Partial<IContact>) {
		super();

		if (poData)
			ObjectHelper.assign(this, poData);
	}

	//#endregion METHODS
}
