import { ChangeDetectorRef, Component } from '@angular/core';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { DynHostItem } from '../../model/DynHostItem';
import { EBarElementProperty } from '../../model/barElement/EBarElementProperty';
import { IBarElement } from '../../model/barElement/IBarElement';
import { FabButtonComponent } from './toolbarDynComponents/fabButton.component';

@Component({
	selector: 'calao-tool-bar',
	templateUrl: 'toolbar.component.html',
	styleUrls: ['toolbar.component.scss'],
})
export class ToolbarComponent extends ComponentBase {

	//#region FIELDS

	private static readonly C_FAB_BUTTON_ID: string = "fabButton";

	/** Liste des templates html à appliquer dans le composant toolbar. */
	private maDynComponents: Array<DynHostItem<FabButtonComponent>> = [];

	//#endregion

	//#region PROPERTIES

	/** Valeur de priorité par défaut d'un bouton de la toolbar (10), (les plus élevées seront le plus à droite possible). */
	public static readonly C_DEFAULT_TOOLBAR_BUTTON_PRIORITY: number = 10;

	public showToolbar = false;
	public eltContainer: Array<IBarElement>;

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);

		// Création de l'index des composants dynamiques (eq. contenue et themes graphique des boutons de la toolbar).
		this.maDynComponents[ToolbarComponent.C_FAB_BUTTON_ID] = new DynHostItem(FabButtonComponent);

		this.reset();
	}

	/** Ajoute un ou plusieurs nouveaux éléments à la toolbar (les éléments sont pour l'instant uniquement des boutons).
	 * @param paElements Tableau de descripteurs de boutons à ajouter.
	 * @param psComponentId Identifiant du composant qui souhaite ajouter ses propres boutons.
	 */
	public add(paElements: Array<IBarElement> = [], psComponentId: string): void {
		console.debug(`TLB.C::Adding toolbar ${ArrayHelper.hasElements(paElements) ? paElements.length : 0} elements for component ${psComponentId}...`);

		if (ArrayHelper.hasElements(paElements)) {
			paElements.forEach((poBarElement: IBarElement) => {

				poBarElement.componentId = StringHelper.isBlank(poBarElement.componentId) ? psComponentId : poBarElement.componentId;
				poBarElement.inlineCssRender = this.cssInlineRender(poBarElement);

				if (poBarElement.priority === undefined || isNaN(poBarElement.priority))
					poBarElement.priority = ToolbarComponent.C_DEFAULT_TOOLBAR_BUTTON_PRIORITY;

				// On vérifie que le composant demandé existe bien dans la liste des composants de la toolbar.
				if (this.maDynComponents[poBarElement.component]) {

					this.eltContainer.push(poBarElement);

					// Détecte les changements -> refresh DOM.
					this.detectChanges();
				}
			});

			if (this.hasElements())
				this.show();
		}
	}

	/** Supprime la toolbar et ses éléments.
	 * @param psComponentId Identifiant du composant qui souhaite supprimer ses propres boutons.
	 * @param pbClearAll Indique si on doit tout supprimer, conteneurs compris ou seulement vider la toolbar apparente, "false" par defaut.
	 */
	public clear(psComponentId: string, pbClearAll: boolean = false): void {
		if (pbClearAll) {
			console.debug("TLB.C::Clearing all toolbar elements...");
			this.reset();
		}
		else {// On supprime les éléments qui doivent être supprimés de chaque toolbar.
			console.debug(`TLB.C::Clearing toolbar elements for component ${psComponentId}...`);
			this.eltContainer = this.eltContainer.filter((poBarElement: IBarElement) => poBarElement.componentId !== psComponentId);
		}

		// Si plus aucun élément n'est présent dans les conteneurs, on peut cacher la toolbar.
		!this.hasElements() ? this.hide() : this.show();
	}

	/** Transforme un objet JSON décrivant des attributs CSS simple, en chaine de caractaire décrivant des régles CSS à appliquer en "inline" sur la toolbar.
	 * @param poElement Représentation JSON d'attributs CSS (background | color).
	 */
	private cssInlineRender(poElement: any): any {
		const lsInlinedCssProperty: any = {};

		// On parcourt l'objet pour trouver les attributs et les transformer en css inline.
		for (const lsKey in poElement) {
			switch (lsKey) {

				case EBarElementProperty.Background:
					lsInlinedCssProperty["background-color"] = poElement[lsKey];
					break;

				case EBarElementProperty.Color:
					lsInlinedCssProperty["color"] = poElement[lsKey];
					break;
			}
		}

		return lsInlinedCssProperty;
	}

	/** Retourne `true` si au moins un élément est présent dans les conteneurs de barElement, `false` sinon. */
	public hasElements(): boolean {
		return ArrayHelper.hasElements(this.eltContainer);
	}

	/** Cache la toolbar. */
	private hide(): void {
		if (!this.hasElements()) // Si aucun élément n'est présent dans la toolbar, on peut la cacher.
			this.showToolbar = false;
	}

	/** Initialise en supprimant et recréant la toolbar avec les nouveaux éléments à insérer.
	 * @param paElements Éléments pour initialiser la toolbar.
	 */
	public init(paElements: Array<IBarElement>, psId: string): void {
		this.clear(psId); // On commence par vider la toolbar.
		this.add(paElements.filter(poElement => !poElement.hidden), psId);
	}

	/** Initialise ou remet à zéro les tableaux conteneurs de boutons. */
	private reset(): void {
		this.eltContainer = [];
	}

	/** Montre la toolbar. */
	private show(): void {
		this.showToolbar = true;
	}

	//#endregion
}
