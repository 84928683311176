<ng-container *ngIf="organizerLabel$ | async as organizerLabel">
	<ion-label>Organisateur</ion-label>
	<ion-label>{{organizerLabel}}</ion-label>
</ng-container>

<ng-container *ngIf="model.hasToRequestParticipation$ | async">
	<span>Vous êtes invité à participer.</span>
	<span>{{
		model.hasToRequestGlobalParticipation ?
		"Vous devez répondre pour la série d'évènement" :
		"Vous devez répondre pour cette occurrence, car elle diffère de la série"
		}}</span>
	<div class="buttons-wrapper">
		<ion-button (click)="accept()" shape="round" color="success">
			<ion-icon slot="start" name="checkmark"></ion-icon>
			Accepter
		</ion-button>
		<ion-button (click)="reject()" shape="round" color="danger">
			<ion-icon slot="start" name="close"></ion-icon>
			Refuser
		</ion-button>
	</div>
</ng-container>

<ng-container *ngIf="acceptedLabel$ | async as acceptedLabel">
	<ion-label color="success">Accepté ({{acceptedLength$ | async}})</ion-label>
	<ion-label>{{acceptedLabel}}</ion-label>
</ng-container>

<ng-container *ngIf="rejectedLabel$ | async as rejectedLabel">
	<ion-label color="danger">Refusé ({{rejectedLength$ | async}})</ion-label>
	<ion-label>{{rejectedLabel}}</ion-label>
</ng-container>

<ng-container *ngIf="waitingLabel$ | async as waitingLabel">
	<ion-label>Sans réponse ({{waitingLength$ | async}})</ion-label>
	<ion-label>{{waitingLabel}}</ion-label>
</ng-container>