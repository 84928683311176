import { Exclude, Transform, TransformFnParams } from "class-transformer";
import { StoreDocumentHelper } from "../../../helpers/storeDocumentHelper";
import { ILastChange } from "../../hooks/models/ilast-change";
import { ObservableProperty } from "../../observable/models/observable-property";
import { StoreDocument } from "../../store/model/store-document";
import { IEntity } from "./ientity";
import { IEntityMeta } from "./ientity-meta";

export abstract class Entity extends StoreDocument implements IEntity {

	//#region PROPERTIES

	@Exclude()
	public observableIsValid?: ObservableProperty<boolean> = new ObservableProperty<boolean>();

	public lastChange?: ILastChange | undefined;

	public meta?: IEntityMeta;

	@Transform((poParams: TransformFnParams) =>
		StoreDocumentHelper.hasRevision(poParams.obj) ? poParams.value ?? new Date : new Date,
		{ toPlainOnly: true }
	)
	public createDate?: Date | string | null = null;

	//#endregion PROPERTIES

}
