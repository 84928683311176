<calao-filter-bar #filterBar [filterValues]="observableFilterValues.value$ | async"
	[nbTmpResults]="observableNbTmpResults.value$ | async" (onFilterValuesChange)="onFilterValuesChange($any($event))"
	(onTmpFilterValuesChange)="onTmpFilterValuesChange($any($event))">
	<calao-filter-bar-main-content>
		<calao-filter-bar-item key="name">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher un dossier ou un document"
					[value]="observableValue.value$ | async" (onValueChange)="observableValue.value = $event"
					[filterBadgeLabel]="nbFiltersLabel$ | async" [debounce]="500">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-tags>
			<calao-filter-bar-item key="notRead">
				<ng-template let-observableValue="observableValue">
					<ion-button shape="round" fill="outline" mode="md" size="small" class="toggle-btn"
						[ngClass]="(observableValue.value$ | async) ? 'toggle-btn-active' : ''"
						(click)="observableValue.value = !observableValue.value || undefined">
						Non lus
					</ion-button>
				</ng-template>
			</calao-filter-bar-item>
		</calao-filter-bar-tags>
	</calao-filter-bar-main-content>
	<calao-filter-bar-modal-content canValidate>
		<calao-filter-bar-item key="name">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher un dossier ou un document"
					[value]="observableValue.value$ | async" [hasModal]="false" (onValueChange)="observableValue.value = $event">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-item key="dateRange" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Plage de dates
					<calao-filter-bar-item-reset>
						<ion-label class="label" (click)="observableValue.value = undefined">Effacer</ion-label>
					</calao-filter-bar-item-reset>
				</calao-filter-bar-item-title>
				<calao-date-range-picker [model]="observableValue.value$ | async"
					(dateRangeChanged)="observableValue.value = $event">
				</calao-date-range-picker>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-item key="authorIds" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Ajouté par</calao-filter-bar-item-title>
				<contacts-list [params]="contactsSelectorParams" [model]="$any(observableValue.value$ | async)"
					(modelChange)="observableValue.value = $event"></contacts-list>
			</ng-template>
		</calao-filter-bar-item>
		<ng-container *ngIf="observableNotReadSelectorParams.value$ | async as selectorParams">
			<calao-filter-bar-item key="notRead">
				<ng-template let-observableValue="observableValue">
					<calao-filter-bar-item-title>Non Lus</calao-filter-bar-item-title>
					<osapp-selector multiple [preselectedValues]="selectorParams.preselectedValues"
						[displayMode]="selectorParams.displayMode" [options]="selectorParams.options" [showItemBorder]="false"
						listDirection="row"
						(selectionChanged)="onNotReadSelectionChanged($event, observableValue)"></osapp-selector>
				</ng-template>
			</calao-filter-bar-item>
		</ng-container>
	</calao-filter-bar-modal-content>
</calao-filter-bar>