import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { GuidHelper } from "@calaosoft/osapp/helpers/guidHelper";
import { NumberHelper } from "@calaosoft/osapp/helpers/numberHelper";
import { ICoordinates } from "@calaosoft/osapp/model/navigation/ICoordinates";
import { Contact } from "@calaosoft/osapp/modules/contacts/models/contact";
import { GeolocationHelper } from "@calaosoft/osapp/modules/geolocation/helpers/geolocation.helper";
import { IMapOptions } from "@calaosoft/osapp/modules/maps/models/imap-options";
import { IMapPopupOptions } from "@calaosoft/osapp/modules/maps/models/imap-popup-options";
import { IPoiOptions } from "@calaosoft/osapp/modules/maps/models/ipoi-options";
import { IStartMap } from "@calaosoft/osapp/modules/maps/models/istart-map";
import { ObservableProperty } from "@calaosoft/osapp/modules/observable/models/observable-property";
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { secure } from "@calaosoft/osapp/modules/utils/rxjs/operators/secure";
import { tapError } from "@calaosoft/osapp/modules/utils/rxjs/operators/tap-error";
import { Observable } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { C_PREFIX_SECTOR } from "../../../app/app.constants";
import { Business } from "../../businesses/model/business";
import { EIconUrl } from "../models/eicons-url";
import { Sector } from "../models/sector";
import { SectorsService } from "../services/sectors.service";

interface IPoiSector {
	coordinates: ICoordinates;
	type: EPoiType;
	name?: string;
	_id?: string;
}

enum EPoiType {
	business = "business",
	contact = "contact",
	referent = "referent"
}

interface ITourPanel {
	title: string;
	data: { name: string, value: string }[];
}

@Component({
	selector: "sector-map",
	templateUrl: "./sector-map.page.html",
	styleUrls: ["./sector-map.page.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorMapPage extends DestroyableComponentBase {
	//#region FIELDS
	private static readonly C_LOG_ID = "SECTMAP.C::";

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly mapOptions$: Observable<IMapOptions>;
	public readonly tourPanelOptions = new ObservableProperty<ITourPanel>({ title: "", data: [] });
	public readonly isTourPanelVisible = new ObservableProperty<boolean>(false);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcSectors: SectorsService,
		private readonly ioActivatedRoute: ActivatedRoute,
		private readonly ioRouter: Router
	) {
		super();
		this.mapOptions$ = this.ioActivatedRoute.params.pipe(
			mergeMap((poParams: Params) => this.loadMapOptions$(poParams.entityGuid)),
			secure(this)
		);
	}

	/** Déplie le TourPanel. */
	public toggleTourPanelOn(): void {
		this.isTourPanelVisible.value = true;
	}

	/** Replie le TourPanel. */
	public toggleTourPanelOff(): void {
		this.isTourPanelVisible.value = false;
	}

	/** Charge les options de la carte en fonction de l'ID du secteur.
	 * @param sectorId L'ID du secteur.
	 */
	private loadMapOptions$(psSectorGuid: string): Observable<IMapOptions> {
		return this.isvcSectors.getSector$(`${C_PREFIX_SECTOR}${psSectorGuid}`)
			.pipe(
				tap((poSector: Sector) => this.setTourPanel(poSector)),
				map((poSector: Sector): IMapOptions => {
					const laPois: IPoiOptions[] = poSector ? this.getPoiOptions(poSector) : [];
					const loStartMap: IStartMap = this.getStartMapOptions(laPois);
					return {
						start: loStartMap,
						pois: laPois,
						buttons: [],
						polygons: []
					};
				}),
				tapError(poError => {
					console.error(`${SectorMapPage.C_LOG_ID} Error loading map options: `, poError);
				})
			);
	}

	/** Configure les options du tourpanel en fonction des données du secteur fourni. */
	private setTourPanel(poSector: Sector): void {
		let lnNumberBusinessCAHT = 0;
		let lnTotalBusinessCAHT = 0;
		poSector.linkedBusinesses?.forEach((poBiz: Business) => {
			if (poBiz.CAHT) {
				lnTotalBusinessCAHT += poBiz.CAHT;
				lnNumberBusinessCAHT++;
			}
		});
		this.tourPanelOptions.value =
		{
			title: poSector.title,
			data: [
				{
					name: "Nombre de clients",
					value: `${poSector.linkedBusinesses?.length}`
				},
				{
					name: "CAHT cumulé",
					value: `${lnTotalBusinessCAHT !== 0 ? lnTotalBusinessCAHT : "Non renseigné"} `
				}, {
					name: "CAHT moyen",
					value: lnNumberBusinessCAHT > 0 ? `${Math.round(lnTotalBusinessCAHT / lnNumberBusinessCAHT)}` : "Non renseigné"
				},
			]
		};
	}

	/** Obtient les options pour la vue de la carte.
	 * @param pois Liste des POI à considérer.
	 * @returns Les options de démarrage de la carte.
	 */
	private getStartMapOptions(paPois: IPoiOptions[]): IStartMap {
		let lnMaxDist = 0;
		let lnSumLon = 0;
		let lnSumLat = 0;

		paPois.forEach((poStartPoi: IPoiOptions, pnStartPoiIndex: number) => {
			lnSumLat += poStartPoi.coordinates.latitude;
			lnSumLon += poStartPoi.coordinates.longitude;
			for (let lnEndPoiIndex: number = pnStartPoiIndex + 1; lnEndPoiIndex < paPois.length; lnEndPoiIndex++) {
				const loEndPoi: IPoiOptions = paPois[lnEndPoiIndex];
				const lnDist: number = GeolocationHelper.calculateDistanceUsingCoordinatesKm(poStartPoi.coordinates, loEndPoi.coordinates);
				lnMaxDist = NumberHelper.getMax([lnMaxDist, lnDist]);
			}
		});

		return {
			center: GeolocationHelper.getCenter(paPois.length, lnSumLat, lnSumLon),
			zoom: GeolocationHelper.getZoom(lnMaxDist)
		};
	}

	/** Crée la liste des options de POI à partir du secteur donné.
	 * @param poSector Le secteur à partir duquel créer les POI.
	 * @returns La liste des options de POI.
	 */
	private getPoiOptions(poSector: Sector): IPoiOptions[] {
		return this.getPoiSectors(poSector).map((poPoi: IPoiSector): IPoiOptions => {
			let lsIconUrl: string;
			const lsClassCss = `btn-${GuidHelper.newGuid()}`;
			let loPopup: IMapPopupOptions | undefined;
			if (poPoi.type === EPoiType.business) {
				const lsSectorId: string | undefined = poPoi._id?.split("_")[1];
				lsIconUrl = EIconUrl.business;
				loPopup = {
					htmlText: this.getHtmlPopup(
						poPoi.name ?? "Business", undefined, lsClassCss, "voir"),
					buttons: {
						[`.${lsClassCss}`]: {
							onClick: () => this.ioRouter.navigate(["/businesses/", lsSectorId])
						}
					}
				};
			}
			else {
				lsIconUrl = EIconUrl.contact;
				loPopup = {
					htmlText: this.getHtmlPopup(
						poPoi.name ?? "Contact", undefined, lsClassCss, "voir"),
					buttons: {
						[`.${lsClassCss}`]: {
							onClick: () => this.ioRouter.navigate(["/contacts/", poPoi._id])
						}
					}
				};
			}

			return {
				coordinates: poPoi.coordinates,
				icon: {
					iconUrl: lsIconUrl,
					iconSize: { height: 40, width: 40 },
				},
				popup: loPopup
			};
		});
	}

	/** Crée la chaîne HTML pour une popup.
	 * @param title Le titre de la popup.
	 * @param info Informations supplémentaires pour la popup.
	 * @param classCss La classe css du bouton de la popup.
	 * @param buttonText Le texte du bouton.
	 * @returns Le HTML de la popup.
	 */
	private getHtmlPopup(title: string, info?: string, classCss?: string, buttonText?: string): string {
		return `<h1>${title}</h1>${info ? `<br>	<p>	${info}</p>` : ``}${buttonText ? `<ion-button class="${classCss}">${buttonText}</ion-button>` : ``}`;
	}

	/** Crée la liste des POI de secteur à partir du secteur donné.
	 * @param poSector Le secteur à partir duquel créer les POI.
	 * @returns La liste des POI du secteur.
	 */
	private getPoiSectors(poSector: Sector): IPoiSector[] {
		const laPois: IPoiSector[] = [];
		poSector.linkedBusinesses?.forEach((poBiz: Business) => {
			if (NumberHelper.isValid(poBiz.latitude) && NumberHelper.isValid(poBiz.longitude))
				laPois.push(
					{
						coordinates:
							{ latitude: poBiz.latitude, longitude: poBiz.longitude },
						type: EPoiType.business,
						_id: poBiz._id,
						name: poBiz.lastName
					});
		});

		poSector.linkedContacts?.forEach((poContact: Contact) => {
			if (NumberHelper.isValid(poContact.latitude) && NumberHelper.isValid(poContact.longitude))
				laPois.push({
					coordinates: { latitude: poContact.latitude, longitude: poContact.longitude },
					type: EPoiType.contact,
					_id: poContact._id,
					name: poContact.lastName
				});
		});
		return laPois;
	}

	//#endregion METHODS
}