import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { ObjectHelper } from '@calaosoft/osapp/helpers/objectHelper';
import { ESortOrder } from '@calaosoft/osapp/model/ESortOrder';
import { ActivePageManager } from '@calaosoft/osapp/model/navigation/ActivePageManager';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { IEventOccurrenceDateRange } from '@calaosoft/osapp/modules/calendar-events/models/ievent-occurrence-date-range';
import { ObservableArray } from '@calaosoft/osapp/modules/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { ESelectorDisplayMode } from '@calaosoft/osapp/modules/selector/selector/ESelectorDisplayMode';
import { ISelectorParams } from '@calaosoft/osapp/modules/selector/selector/ISelectorParams';
import { ISortOption } from '@calaosoft/osapp/modules/sort/models/isort-option';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { Queue } from '@calaosoft/osapp/modules/utils/queue/decorators/queue.decorator';
import { secure } from '@calaosoft/osapp/modules/utils/rxjs/operators/secure';
import { tapError } from '@calaosoft/osapp/modules/utils/rxjs/operators/tap-error';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, scan, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { C_LOAD_MORE_NB_DAYS, C_NB_LATE_EVENTS_IN_LATE_ITEMS, C_NB_TODO_TASKS_IN_TODO_ITEMS } from '../../../../app/app.constants';
import { ETradeEventType } from '../../../trade-events/models/etrade-event-type';
import { IEventsFilterValues } from '../../../trade-events/models/ievents-filter-values';
import { ReminderOccurrence } from '../../../trade-events/models/reminder-occurrence';
import { TaskOccurrence } from '../../../trade-events/models/task-occurrence';
import { TradeActivitiesService } from '../../../trade-events/services/trade-activities.service';
import { TradeEventsService } from '../../../trade-events/services/trade-events.service';
import { IBusiness } from '../../model/ibusiness';
import { IBusinessActivitiesSlideParams } from './models/ibusiness-activities-slide-params';

@Component({
	selector: 'business-activities-slide',
	templateUrl: './business-activities-slide.component.html',
	styleUrls: ['./business-activities-slide.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessActivitiesSlideComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	private readonly moObservableUndatedTasksFilterValues = new ObservableProperty<IEventsFilterValues>();
	private readonly moParams = new ObservableProperty<IBusinessActivitiesSlideParams>();
	private readonly moObservableEventType = new ObservableProperty<string>();
	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter);
	/** `true` si l'utilisateur a des rappels en retard, sinon `false`. Est à `false` par défaut. */
	public readonly moObservableHasLateReminders = new ObservableProperty<boolean>(false);
	/** `true` si l'utilisateur a des tâches en retard, sinon `false`. Est à `false` par défaut. */
	public readonly moObservableHasLateTasks = new ObservableProperty<boolean>(false);

	//#endregion FIELDS

	//#region PROPERTIES

	/** Paramètres pour le composant. */
	@Input() public params: IBusinessActivitiesSlideParams;

	public readonly now = new Date();
	public readonly observableIsLoading = new ObservableProperty<boolean>(true);
	public readonly observableCanCreate = new ObservableProperty<boolean>(false);
	public readonly observableBusiness = new ObservableProperty<IBusiness>();
	public readonly observableActivitiesListItems = new ObservableArray<BaseEventOccurrence>();

	public readonly observableUndatedNbResults = new ObservableProperty<number>(0);
	public readonly observableUndatedNbDiff = new ObservableProperty<number>(0);
	public readonly observableUndatedActivitiesListItems = new ObservableArray<TaskOccurrence>();

	public readonly observableFilterValues = new ObservableProperty<IEventsFilterValues>();
	public readonly observableDefaultSort = new ObservableProperty<keyof TaskOccurrence>();
	public readonly observableNbTmpResults = new ObservableProperty<number>(0);

	public readonly observableSortOptions: ObservableArray<ISortOption<keyof TaskOccurrence | ReminderOccurrence>> =
		new ObservableArray<ISortOption<keyof TaskOccurrence | ReminderOccurrence>>(this.getDefaultSortOptions());

	public readonly observableHideTodoItem = new ObservableProperty<boolean>(false);

	/** Date mini pour le sélecteur de date. */
	public readonly observableMinDate = new ObservableProperty<Date>();
	/** Date maxi pour le sélecteur de date. */
	public readonly observableMaxDate = new ObservableProperty<Date>();

	public readonly observableHideLateItem = new ObservableProperty<boolean>(false);
	public readonly observableLateTasksCount = new ObservableProperty<number>(0);
	public readonly observableLateNbDiff = new ObservableProperty<number>();
	public readonly observableLateTasks = new ObservableArray<BaseEventOccurrence>();

	/** Configuration du composant osapp-selector pour le filtrage par type de'évènement. */
	public readonly observableTypeSelectorParams = new ObservableProperty<ISelectorParams>(this.getTypeSelectorParams());

	/** `true` si l'item des tâches todo peut être affiché, sinon `false`. Est à true par défaut.
 * Passe à `false` si le filtrage par type ne contient pas le type des tâches. */
	public readonly observableCanDisplayTodoItem = new ObservableProperty<boolean>(true);
	/** `true` si l'item des évènements en retard peut être affiché, sinon `false`. Est à true par défaut.
	 * Passe à `false` si le filtrage par type ne contient pas le type des tâches ET des rappels. */
	public readonly observableCanDisplayLateItem = new ObservableProperty<boolean>(true);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcTradeEvents: TradeEventsService,
		private readonly isvcActivities: TradeActivitiesService,
		private readonly ioActivatedRoute: ActivatedRoute,
		private readonly ioRouter: Router
	) {
		super();
	}

	public ngOnInit(): void {
		this.moParams.value = this.params;
		this.observableBusiness.value = this.params.business;
		this.observableCanCreate.value = !!this.params.showCreateButtons;

		this.moObservableUndatedTasksFilterValues.value = this.getUndatedTasksFilterValues();

		this.isvcTradeEvents.getUndatedOccurrences$(this.moActivePageManager, this.moObservableUndatedTasksFilterValues.value)
			.pipe(
				tap((paOccurrences: TaskOccurrence[]) => {
					const lnOccurrencesCount: number = paOccurrences.length;
					this.observableUndatedNbResults.value = lnOccurrencesCount;
					const lnDiff = this.isvcTradeEvents.getTodoItemDiff(lnOccurrencesCount, C_NB_TODO_TASKS_IN_TODO_ITEMS); // On calcule la différence entre le total des tâches non datées et celles affichées dans l'item ToDo.
					this.observableUndatedNbDiff.value = (lnDiff > 0) ? lnDiff : 0;
					this.observableUndatedActivitiesListItems.resetArray(this.isvcTradeEvents.prepareTodoItems(paOccurrences));
				}),
				takeUntil(this.destroyed$)
			).subscribe();

		this.moObservableEventType.value = this.moParams.value.events;

		if (this.moObservableEventType.value === "pending") {
			this.initPendingActivitiesListItems();
			this.initLateOccurrences();
			this.observableSortOptions.push({ label: "Priorité", value: "priority" });
			this.observableDefaultSort.value = "priority";
			this.observableMinDate.value = new Date();
		}
		else {
			this.initDoneActivitiesListItems();
			this.observableHideTodoItem.value = true;
			this.observableHideLateItem.value = true;
			this.observableDefaultSort.value = "sortDate";
			this.observableMaxDate.value = new Date();
		}

		this.observableFilterValues.value = this.getDefaultFilterValues();
	}

	private initPendingActivitiesListItems(): void {
		this.getObservablePendingActivitiesListItems$().pipe(
			tap((paOccurrences: BaseEventOccurrence[]) => {
				this.observableActivitiesListItems.resetArray(paOccurrences);
				this.observableIsLoading.value = false;
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	private initDoneActivitiesListItems(): void {
		this.getObservableDoneActivitiesListItems$().pipe(
			tap((paOccurrences: BaseEventOccurrence[]) => {
				this.observableActivitiesListItems.resetArray(paOccurrences);
				this.observableIsLoading.value = false;
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	private getObservablePendingActivitiesListItems$(): Observable<BaseEventOccurrence[]> {
		return this.getDateRangeFromObservableFilterValues$().pipe(
			map((poRange: IEventOccurrenceDateRange, pnIndex: number) => {
				const loRange: IEventOccurrenceDateRange = {
					from: pnIndex === 0 ? undefined : poRange.from,
					to: poRange.to
				};

				return this.isvcActivities.getPendingActivity$(loRange, this.moActivePageManager).pipe(
					secure(this)
				);
			}),
			scan((paObservables: Observable<BaseEventOccurrence[]>[], poEventOccurrences$: Observable<BaseEventOccurrence[]>) =>
				[...paObservables, poEventOccurrences$],
				[]
			),
			mergeMap((paObservables: Observable<BaseEventOccurrence[]>[]) => combineLatest(paObservables)),
			map((paEvents: BaseEventOccurrence[][]) => ArrayHelper.flat(paEvents)),
			switchMap((paEventOccurrences: BaseEventOccurrence[]) => this.observableFilterValues.value$.pipe(
				map((poFilterValues: IEventsFilterValues) => this.isvcActivities.filterEventOccurrences(paEventOccurrences, poFilterValues))
			)),
			tapError((poError: any) => console.error(poError)),
			takeUntil(this.destroyed$)
		);
	}

	private getObservableDoneActivitiesListItems$(): Observable<BaseEventOccurrence[]> {
		return this.getDateRangeFromObservableFilterValues$().pipe(
			map((poRange: IEventOccurrenceDateRange) => {
				const loRange: IEventOccurrenceDateRange = {
					from: poRange.from,
					to: poRange.to
				};

				return this.isvcActivities.getDoneActivity$(loRange, this.moActivePageManager).pipe(
					secure(this)
				);
			}),
			scan((paObservables: Observable<BaseEventOccurrence[]>[], poEventOccurrences$: Observable<BaseEventOccurrence[]>) =>
				[...paObservables, poEventOccurrences$],
				[]
			),
			mergeMap((paObservables: Observable<BaseEventOccurrence[]>[]) => combineLatest(paObservables)),
			map((paEvents: BaseEventOccurrence[][]) => ArrayHelper.flat(paEvents)),
			switchMap((paEventOccurrences: BaseEventOccurrence[]) => this.observableFilterValues.value$.pipe(
				map((poFilterValues: IEventsFilterValues) => this.isvcActivities.filterEventOccurrences(paEventOccurrences, poFilterValues))
			)),
			tapError((poError: any) => console.error(poError)),
			takeUntil(this.destroyed$)
		);
	}

	private getDateRangeFromObservableFilterValues$(): Observable<IEventOccurrenceDateRange> {
		const laCachedRanges: IEventOccurrenceDateRange[] = [];
		return this.observableFilterValues.value$.pipe(
			map((poFilterValues: IEventsFilterValues) => poFilterValues.dateRange),
			filter((poRange: IEventOccurrenceDateRange) => !!poRange),
			distinctUntilChanged(),
			map((poRange: IEventOccurrenceDateRange) => {
				const laRanges: IEventOccurrenceDateRange[] = DateHelper.getRangeDifferences(laCachedRanges, poRange);

				laCachedRanges.push(...laRanges);

				DateHelper.mergeRanges(laCachedRanges);

				return laRanges;
			}),
			mergeMap((paRanges: IEventOccurrenceDateRange[]) => paRanges),
			distinctUntilChanged()
		);
	}

	public onLoadMorePendingClicked(): void {
		this.observableFilterValues.value = {
			...this.observableFilterValues.value,
			dateRange: this.getMoreDateRange()
		};
	}

	private getDefaultFilterValues(): IEventsFilterValues {
		return {
			dateRange: this.getDefaultDateRange(),
			priority: 5,
			sort: { order: ESortOrder.ascending, by: "sortDate" },
			businessIds: this.observableBusiness.value ? [this.observableBusiness.value._id] : []
		};
	}

	private getDefaultDateRange(): IEventOccurrenceDateRange {
		if (this.moParams.value?.events === "pending")
			return {
				from: DateHelper.resetDay(this.now),
				to: DateHelper.fillDay(DateHelper.addDays(this.now, C_LOAD_MORE_NB_DAYS))
			};
		else
			return {
				from: DateHelper.resetDay(DateHelper.addDays(this.now, -C_LOAD_MORE_NB_DAYS)),
				to: DateHelper.fillDay(this.now)
			};
	}

	private getMoreDateRange(): IEventOccurrenceDateRange {
		if (this.moParams.value?.events === "pending")
			return {
				from: this.observableFilterValues.value?.dateRange?.from,
				to: DateHelper.addDays(this.observableFilterValues.value?.dateRange.to!, C_LOAD_MORE_NB_DAYS + 1)
			};
		else
			return {
				from: DateHelper.addDays(this.observableFilterValues.value?.dateRange.from!, -C_LOAD_MORE_NB_DAYS),
				to: this.observableMaxDate.value ? DateHelper.fillDay(this.observableMaxDate.value) : new Date
			};
	}

	private getUndatedTasksFilterValues(): IEventsFilterValues {
		return {
			priority: 5,
			sort: { order: ESortOrder.ascending, by: "sortDate" },
			businessIds: this.observableBusiness.value ? [this.observableBusiness.value._id] : [],
			dateRange: { to: new Date }
		};
	}

	public onFilterValuesChange(poFilterValues: IEventsFilterValues): void {
		this.observableNbTmpResults.value = 0;
		this.observableFilterValues.value = ObjectHelper.isEmpty(poFilterValues) ? this.getDefaultFilterValues() : poFilterValues;
	}

	public onTmpFilterValuesChange(poFilterValues: IEventsFilterValues): void {
		const lbFilterIncludeTask: boolean = !poFilterValues.eventType || poFilterValues.eventType.includes(ETradeEventType.task);
		const lbFilterIncludeReminder: boolean = !poFilterValues.eventType || poFilterValues.eventType.includes(ETradeEventType.reminder);
		this.observableCanDisplayTodoItem.value = lbFilterIncludeTask;
		this.observableCanDisplayLateItem.value = (lbFilterIncludeTask && this.moObservableHasLateTasks.value) ||
			(lbFilterIncludeReminder && this.moObservableHasLateReminders.value);
		if (this.moObservableEventType.value === "pending")
			this.loadPendingNbTmpResults$(poFilterValues).subscribe();
		else
			this.loadDoneNbTmpResults$(poFilterValues).subscribe();
	}

	@Queue<BusinessActivitiesSlideComponent, Parameters<BusinessActivitiesSlideComponent["loadPendingNbTmpResults$"]>, ReturnType<BusinessActivitiesSlideComponent["loadPendingNbTmpResults$"]>>({
		keepOnlyLastPending: true
	})
	private loadPendingNbTmpResults$(poFilterValues: IEventsFilterValues): Observable<number> {
		if (ObjectHelper.areEqual(poFilterValues, this.observableFilterValues.value))
			return of(this.observableNbTmpResults.value = this.observableActivitiesListItems.length);

		return this.isvcActivities.getPendingActivity$({ to: poFilterValues.dateRange.to }, this.moActivePageManager).pipe(
			take(1), // On le veut que le premier résultat
			map((paEventOccurrences: BaseEventOccurrence[]) =>
				this.observableNbTmpResults.value = this.isvcActivities.filterEventOccurrences(paEventOccurrences, poFilterValues).length
			),
			takeUntil(this.destroyed$)
		);
	}

	@Queue<BusinessActivitiesSlideComponent, Parameters<BusinessActivitiesSlideComponent["loadDoneNbTmpResults$"]>, ReturnType<BusinessActivitiesSlideComponent["loadDoneNbTmpResults$"]>>({
		keepOnlyLastPending: true
	})
	private loadDoneNbTmpResults$(poFilterValues: IEventsFilterValues): Observable<number> {
		if (ObjectHelper.areEqual(poFilterValues, this.observableFilterValues.value))
			return of(this.observableNbTmpResults.value = this.observableActivitiesListItems.length);

		return this.isvcActivities.getDoneActivity$({ to: poFilterValues.dateRange.to }, this.moActivePageManager).pipe(
			take(1), // On le veut que le premier résultat
			map((paEventOccurrences: BaseEventOccurrence[]) =>
				this.observableNbTmpResults.value = this.isvcActivities.filterEventOccurrences(
					paEventOccurrences,
					poFilterValues
				).length
			),
			takeUntil(this.destroyed$)
		);
	}

	private getDefaultSortOptions(): ISortOption<keyof TaskOccurrence | ReminderOccurrence>[] {
		return [
			{ label: "Date", value: "sortDate" },
			{ label: "Échéance", value: "deadline" }
		];
	}

	public getNoContentSubtitle(): string {
		return `Aucune activité ${this.moObservableEventType.value === "pending" ? "à venir" : "terminée"} correspondant aux critères de filtrage pour ce Business`;
	}

	/** Navigue vers la page de création d'une nouvelle tâche. */
	public routeToNewTask(): void {
		this.isvcTradeEvents.routeToNewTaskAsync(this.ioActivatedRoute, { businessId: this.observableBusiness.value?._id });
	}

	/** Navigue vers la page de création d'un nouvel évènement. */
	public routeToNewEvent(): void {
		this.isvcTradeEvents.routeToNewEventAsync(this.ioActivatedRoute, { businessId: this.observableBusiness.value?._id });
	}

	private initLateOccurrences(): void {
		DateHelper.getDays$()
			.pipe(
				switchMap((pdDate: Date) => this.isvcTradeEvents.getLateTasksOccurrences$(
					this.moActivePageManager,
					{ businessIds: [this.observableBusiness.value?._id], dateRange: { to: DateHelper.fillDay(pdDate) } }
				)),
				tap((paOccurrences: TaskOccurrence[]) => {
					const lnOccurrencesCount: number = paOccurrences.length;
					this.moObservableHasLateTasks.value = !!lnOccurrencesCount;
					this.observableLateTasksCount.value = lnOccurrencesCount;
					const lnDiff = this.isvcTradeEvents.getTodoItemDiff(lnOccurrencesCount, C_NB_LATE_EVENTS_IN_LATE_ITEMS); // On calcule la différence entre le total des tâches/rappels en retard et celles affichées dans l'item Late.
					this.observableLateNbDiff.value = (lnDiff > 0) ? lnDiff : 0;
					this.observableLateTasks.resetArray(this.isvcTradeEvents.prepareLateItems(paOccurrences));
				}),
				takeUntil(this.destroyed$),
			).subscribe();
	}

	private getTypeSelectorParams(): ISelectorParams {
		return {
			preselectedValues: [ETradeEventType.standard, ETradeEventType.task],
			displayMode: ESelectorDisplayMode.list,
			multiple: true,
			options: [
				{
					label: "Evènement",
					value: ETradeEventType.standard
				},
				{
					label: "Tâche",
					value: ETradeEventType.task
				}
			]
		};
	}

	//#endregion METHODS
}
