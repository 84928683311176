export class ConsentVersion {

	//#region PROPERTIES

	/** Contient le numéro de version actuelle de la demande de consentement. Incrémentez pour redemander. */

	public static aiConv = 1;
	public static aiReport = 1;

	//#endregion PROPERTIES

}