import { Exclude } from "class-transformer";
import { DateHelper } from "../../../helpers/dateHelper";
import { IdHelper } from "../../../helpers/idHelper";
import { EEntityLinkCacheData } from "../../../model/entities/EEntityLinkCacheData";
import { EPrefix } from "../../../model/EPrefix";
import { StoreDocument } from "../../store/model/store-document";
import { ModelMatch } from "../../utils/models/decorators/model-match.decorator";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { EntityLinkEntity } from "./entity-link-entity";
import { IEntityLink } from "./ientity-link";

@ModelMatch((poData: IEntityLink) => poData._id.startsWith(EPrefix.link), StoreDocument)
export class EntityLink extends StoreDocument implements IEntityLink {

	//#region PROPERTIES

	@ResolveModel(Date)
	/** @implements */
	public readonly creationDate: Date;
	@ResolveModel(EntityLinkEntity)
	/** @implements */
	public readonly entities: EntityLinkEntity[] = [];

	@Exclude()
	/** État du lien pour savoir quelle opération réaliser dessus. */
	public readonly cacheDataState?: EEntityLinkCacheData;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: Partial<IEntityLink>) {
		super({ ...(poData ?? {}), _id: poData?._id ?? IdHelper.buildId(EPrefix.link) });
		this.creationDate = DateHelper.toDate(poData?.creationDate) ?? new Date;
		this.entities = poData?.entities ?? [];
	}

	public getTargetEntitiesBySourceId(psSourceId: string): EntityLinkEntity[] {
		return this.entities.filter((poEntity: EntityLinkEntity) => poEntity.id !== psSourceId);
	}

	public getTargetEntitiesByTargetPrefix(psTargetPrefix: string): EntityLinkEntity[] {
		return this.entities.filter((poEntity: EntityLinkEntity) => poEntity.id.startsWith(psTargetPrefix));
	}

	//#endregion METHODS

}
