import { Observable, ReplaySubject } from 'rxjs';
import { FileHelper } from '../../../helpers/fileHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { NumberHelper } from '../../../helpers/numberHelper';
import { PathHelper } from '../../../helpers/path-helper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { UserData } from '../../../model/application/UserData';
import { ConfigData } from '../../../model/config/ConfigData';
import { IBase64Data } from '../../../model/file/IBase64Data';
import { DmsFileHelper } from '../helpers/dmsFileHelper';
import { IDmsMeta } from './IDmsMeta';

export class DmsFile {

	//#region PROPERTIES

	private static readonly C_DEFAULT_NAME: string = "file";

	/** Données du fichier. */
	private moFile: string | Blob;
	public get File(): string | Blob { return this.moFile; }

	private readonly moPathSubject = new ReplaySubject<string>(1);
	private msPath: string;
	/** Chemin vers le fichier. */
	public get Path(): string { return this.msPath; }
	public set Path(psNewPath: string) {
		if (psNewPath !== this.msPath)
			this.moPathSubject.next(this.msPath = psNewPath);
	}
	public get Path$(): Observable<string> { return this.moPathSubject.asObservable(); }

	/** Mimetype du fichier. */
	private msMimeType: string;
	public get MimeType(): string { return this.msMimeType; }

	/** Nom du fichier. */
	private msName: string;
	public get Name(): string { return this.msName; }
	public set Name(psName: string) {
		this.setName(psName);
	}

	/** Nom unique du fichier. */
	private msOriginalName: string;
	public get OriginalName(): string { return this.msOriginalName; }

	/** Taille du fichier. */
	private mnSize: number;
	public get Size(): number { return this.mnSize; }
	public get SizeKb(): number { return this.mnSize / 1000; }

	//#endregion

	//#region METHODS

	/** Constructeur d'un fichier DMS.
	 * @param poData Un fichier IDmsMeta.
	 */
	constructor(poData: IDmsMeta);
	/** Constructeur d'un fichier DMS.
	 * @param poData Données du fichier sous forme de base64 ou de blob.
	 * @param poMeta Un fichier IDmsMeta.
	 */
	constructor(poData: string | Blob, poMeta: IDmsMeta);
	/** Constructeur d'un fichier DMS.
	 * @param poData Un fichier Blob.
	 * @param psName Nom du fichier ou fichier DmsMeta.
	 */
	constructor(poData: Blob, psName: string);
	/** Constructeur d'un fichier DMS.
	 * @param poData Données du fichier sous forme de base64 ou de chemin.
	 * @param psName Nom du fichier.
	 * @param psMimeType MimeType du fichier.
	 * @param pnSize Taille du fichier.
	 */
	constructor(poData: string, psName: string, psMimeType: string, pnSize: number);
	/** Constructeur d'un fichier DMS.
	 * @param poData Données du fichier sous forme de base64 ou de Blob ou de chemin.
	 * @param poNameOrMeta Nom du fichier ou Meta.
	 * @param psMimeType MimeType du fichier.
	 * @param pnSize Taille du fichier.
	 */
	constructor(poData: string | Blob | IDmsMeta, poNameOrMeta: string | IDmsMeta = DmsFile.C_DEFAULT_NAME, psMimeType?: string, pnSize: number = 0) {
		let loDmsMeta: IDmsMeta;

		if (poNameOrMeta && typeof poNameOrMeta !== "string")
			loDmsMeta = poNameOrMeta;
		else if (typeof poData !== "string" && !(poData instanceof Blob))
			loDmsMeta = poData;

		const lsName: string = loDmsMeta ? loDmsMeta.name : poNameOrMeta as string;
		const lnSize: number = NumberHelper.isValid(pnSize) ? pnSize : loDmsMeta.size;
		let lsPath: string | undefined;

		if (loDmsMeta && !StringHelper.isBlank(ConfigData.environment.dms.applicationPath))
			lsPath = `${ConfigData.environment.dms.applicationPath}/${loDmsMeta._id}/${loDmsMeta._id}.${FileHelper.getFileExtensionFromFileName(loDmsMeta.name)}`;

		if (poData instanceof Blob) // Cas Blob.
			this.initFromBlob(poData, lsName, lsPath);
		else if (typeof poData === "string") // Cas string.
			this.initFromString({ base64: poData } as IBase64Data, lsName, this.getMimeType(lsName, psMimeType), lnSize);
		else // Cas DmsMeta.
			this.initFromDmsMeta(loDmsMeta, this.getMimeType(lsName), lsPath);

		this.msOriginalName = this.Name;
	}

	/** Initialise l'instance à partir d'un Blob.
	 * @param poBlob Donnée sous forme de Blob.
	 * @param psName Nom de la donnée.
	 * @param psPath Chemin de la donnée.
	 */
	private initFromBlob(poBlob: Blob, psName: string, psPath?: string): void {
		this.moFile = poBlob;
		this.msMimeType = poBlob.type;
		this.mnSize = poBlob.size;
		this.Name = psName;
		this.Path = psPath;
	}

	/** Initialise l'instance à partir d'un chemin ou d'une base64.
	 * @param poPathOrBase64Data Donnée d'un chemin ou d'une base64.
	 * @param psName Nom de la donnée.
	 * @param psMimeType Type mime de la donnée.
	 * @param pnSize Taille de la donnée.
	 */
	private initFromString(poPathOrBase64Data: IBase64Data, psName: string, psMimeType: string, pnSize: number): void {
		this.msMimeType = psMimeType;
		this.Name = psName;

		if (poPathOrBase64Data.base64.indexOf("://") >= 0) { // Cas chemin.
			this.Path = poPathOrBase64Data.base64;
			this.mnSize = pnSize;
		}
		else { // Cas base64.
			this.moFile = FileHelper.crushBase64DataPrefix(poPathOrBase64Data).base64;
			this.mnSize = FileHelper.getBase64BytesSize(poPathOrBase64Data);
		}
	}

	/** Initialise l'instance à partir d'un objet `IDmsMeta`.
	 * @param poDmsMeta Objet `IDmsMeta` qui représente la donnée.
	 * @param psMimeType Type mime de la donnée.
	 * @param psPath Chemin vers la donnée.
	 */
	private initFromDmsMeta(poDmsMeta: IDmsMeta, psMimeType: string, psPath: string): void {
		this.msMimeType = psMimeType;
		this.Name = poDmsMeta.originalName ?? poDmsMeta.name;
		this.mnSize = poDmsMeta.size;
		this.Path = psPath;
	}

	/** Récupère le type mime de la donnée en fonction du paramètre renseigné ou du nom du fichier (avec extension) par défaut.
	 * @param psName Nom du fichier.
	 * @param psMimeType Type mime de la donnée.
	 */
	private getMimeType(psName: string, psMimeType?: string): string {
		return StringHelper.isBlank(psMimeType) ? (FileHelper.extractMimeTypeFromFileNameWithExtension(psName) ?? "") : psMimeType;
	}

	/** Crée un objet IDmsMeta à partir d'un objet DmsFile.
	 * @param psGuid Guid du fichier.
	 * @param psDocumentType Type du document méta DmsFileHelper.getDefaultDocumentType() par défaut.
	 * @param psDocumentSubType Sous-type du document méta DmsFileHelper.getDefaultDocumentSubType() par défaut.
	 */
	public createDmsMeta(psGuid?: string, psDocumentType?: string, psDocumentSubType?: string, paPaths?: string[]): IDmsMeta {
		return {
			_id: IdHelper.buildId(EPrefix.dms, psGuid),
			lastAccess: undefined,
			description: "",
			createDate: new Date(),
			size: this.Size,
			modifyDate: undefined,
			name: this.Name,
			originalName: this.OriginalName,
			criteria: [],
			documentType: StringHelper.isBlank(psDocumentType) ? DmsFileHelper.getDefaultDocumentType() : psDocumentType,
			documentSubType: psDocumentSubType,
			paths: paPaths?.map((psPath: string) => PathHelper.preparePath(psPath)) ?? [],
			authorId: UserData.current ? IdHelper.buildId(EPrefix.contact, UserHelper.getUserGuid(UserData.current.name)) : undefined,
			title: ""
		};
	}

	/** Affecte le nom de la donnée.
	 * @param psName Nom de la donnée.
	 */
	private setName(psName: string): void {
		if (!StringHelper.isBlank(psName) && psName !== this.msName) {
			const laFragments: Array<string> = psName.split(".");

			if (laFragments.length > 1) { // Extension considérée comme présente dans le nom de fichier (jusqu'à preuve du contraire).
				if (FileHelper.getFileExtensionFromFileName(psName)) // Si extension dans `psName`, affectation simple.
					this.msName = psName;
				else // Pas d'extension, il faut l'ajouter à partir du type mime.
					this.setNameFromMimeType(psName);
			}
			else // Pas d'extension.
				this.setNameFromMimeType(psName);
		}
	}

	/** Affecte le nom de la donnée en fonction de son type mime.
	 * @param psName Nom de la donnée.
	 */
	private setNameFromMimeType(psName: string): void {
		const lsExtension: string | undefined = FileHelper.getExtensionFromMimeType(this.msMimeType);
		this.msName = lsExtension ? `${psName}.${lsExtension}` : psName;
	}

	public getExtension(): string | undefined {
		return FileHelper.getExtensionFromMimeType(this.msMimeType);
	}

	//#endregion
}