import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EDynamicTitle } from '../../../../components/dynamicPage/EDynamicTitle';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { IdHelper } from '../../../../helpers/idHelper';
import { ObjectHelper } from '../../../../helpers/objectHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { UserData } from '../../../../model/application/UserData';
import { IContact } from '../../../../model/contacts/IContact';
import { IGroup } from '../../../../model/contacts/IGroup';
import { IGroupSelection } from '../../../../model/contacts/IGroupSelection';
import { IGroupsChecklistParams } from '../../../../model/contacts/IGroupsChecklistParams';
import { ContactsService } from '../../../../services/contacts.service';
import { GroupsService } from '../../../../services/groups.service';
import { ObserveArray } from '../../../observable/decorators/observe-array.decorator';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { C_SECTORS_ROLE_ID, C_SUPER_ADMIN_ROLE_ID, PermissionsService, Roles } from '../../../permissions/services/permissions.service';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { ISite } from '../../../sites/models/isite';
import { SitesService } from '../../../sites/services/sites.service';

interface IGroupOptions {
	/** Titre du regroupement. */
	title: string;
	/** Liste des options. */
	groupOptions: ISelectOption<IGroup>[];
	/** Mode d'affichage, `liste` par défaut. */
	mode?: "liste" | "tags";
}
/** Composant pour lister des groupes avec une case à cocher. */
@Component({
	selector: "calao-groups-checklist",
	templateUrl: './groups-checklist.component.html',
	styleUrls: ['./groups-checklist.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsChecklistComponent<T extends IGroup> extends ComponentBase implements OnInit, OnDestroy, IGroupsChecklistParams<T> {

	//#region FIELDS

	/** Texte par défaut du bouton de création d'un groupe. */
	private static readonly C_DEFAULT_NEW_GROUP_BUTTON_TEXT = "Nouveau groupe";
	/** Texte par défaut du bouton de création d'un groupe. */
	private static readonly C_DEFAULT_TITLE = "Groupes";
	/** Texte par défaut si aucun groupe. */
	private static readonly C_DEFAULT_EMPTY_TEXT = "Aucun groupe";

	/** Événement qui envoie le tableau des éléments sélectionnés à chaque changement opéré. */
	@Output("onSelectionChanged") private readonly moOnSelectionChangedEvent = new EventEmitter<IGroupSelection<T>[]>();

	/** Raccourci vers l'identifiant du modèle. */
	private msModelId: string;
	private moInitGroupsSubject = new ReplaySubject<T[] | string[]>();
	private maSelectedGroups: T[] = [];
	private moContactsByGroupId = new Map<string, IContact[]>();

	//#endregion

	//#region PROPERTIES

	private maData: T[];
	/** @implements */
	public get data(): T[] {
		return this.maData;
	}
	@Input() public set data(poData: T[]) {
		if (!ArrayHelper.areArraysFromDatabaseEqual(this.maData, poData)) {
			this.maData = poData;
			this.moInitGroupsSubject.next(poData);
		}
	}

	private maGroupIds: string[];
	/** @implements */
	public get groupIds(): string[] {
		return this.maGroupIds;
	}
	@Input() public set groupIds(paGroupIds: string[]) {
		if (!ArrayHelper.areArraysEqual(this.maGroupIds, paGroupIds)) {
			this.maGroupIds = paGroupIds;
			this.moInitGroupsSubject.next(paGroupIds);
		}
	}

	@Input() public set loadAllGroups(pbLoadAllGroups: string | boolean) {
		if (coerceBooleanProperty(pbLoadAllGroups))
			this.moInitGroupsSubject.next(undefined);
	}

	/** @implements */
	@Input() public model?: IContact | ISite;
	/** @implements */
	@Input() public createButtonText?: string;
	/** @implements */
	@Input() public readOnly?: boolean;
	/** @implements */
	@Input() public title?: string;
	/** @implements */
	@Input() public multiple?: boolean;
	/** @implements */
	@Input() public hideTitle?: boolean;
	/** @implements */
	@Input() public roles?: string[];
	/** @implements */
	@Input() public emptyText?: string;
	/** @implements */
	@Input() public sortGroupsByType?: boolean;

	private mnMin: number;
	public get min(): number {
		return this.mnMin;
	}
	@Input()
	public set min(pnMin: number) {
		if (pnMin !== this.mnMin) {
			this.mnMin = pnMin;
			this.detectChanges();
		}
	}

	/** Mode de sélection. */
	@Input() public displayMode?: ESelectorDisplayMode;
	@ObserveProperty<GroupsChecklistComponent<T>>({ sourcePropertyKey: "displayMode" })
	public readonly observableDisplayMode = new ObservableProperty<ESelectorDisplayMode>(ESelectorDisplayMode.list);

	/** Indique si on doit masquer le bouton de création. */
	@Input() public hideCreateButton?: boolean | null | string;
	@ObserveProperty<GroupsChecklistComponent<T>>({ sourcePropertyKey: "hideCreateButton", transformer: coerceBooleanProperty })
	public readonly observableHideCreateButton = new ObservableProperty<boolean>();

	/** Groupes séléctionnés. */
	@Input() public groupSelections?: IGroupSelection<T>[];
	@ObserveArray<GroupsChecklistComponent<T>>("groupSelections")
	public readonly observableGroupSelections = new ObservableArray<IGroupSelection<T>>();

	/** Masque les groupes des roles du sélécteur.. */
	@Input() public hideRoleGroups?: boolean;
	@ObserveProperty<GroupsChecklistComponent<T>>({ sourcePropertyKey: "hideRoleGroups" })
	public readonly observableHideRoleGroups = new ObservableProperty<boolean>(false);

	/** Indique si l'utilisateur peut créer un nouveau groupe. */
	public canCreate = false;
	/** Tableau des groupes sélectionnables à manipuler. */
	public groupOptions: ISelectOption<T>[] = [];
	/** Tableau des groupes sélectionnables à manipuler triés par type. */
	private maGroupsOptionsByTypes: IGroupOptions[] = [];
	public get groupsOptionsByTypes(): IGroupOptions[] { return this.maGroupsOptionsByTypes; }
	public preSelectedGroups: T[] = [];

	public get tagsDisplayMode(): string {
		return "tags";
	}

	@Roles(C_SUPER_ADMIN_ROLE_ID)
	public get isSuperAdmin(): boolean {
		return true;
	}

	/** Ignore la désactivation des groupes en fonction des rôles. */
	@Input() public ignoreRoles?: boolean | string | null;
	@ObserveProperty<GroupsChecklistComponent<T>>({ sourcePropertyKey: "ignoreRoles", transformer: coerceBooleanProperty })
	public readonly observableIgnoreRoles = new ObservableProperty<boolean>(false);

	//#endregion

	//#region METHODS

	constructor(
		private isvcPermissions: PermissionsService,
		private isvcGroups: GroupsService,
		private isvcContacts: ContactsService,
		private ioRouter: Router,
		private isvcSites: SitesService,
		poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** @implements */
	public ngOnInit(): void {
		// Sélection multiple par défaut.
		this.multiple = this.multiple ?? true;

		if (StringHelper.isBlank(this.createButtonText))
			this.createButtonText = GroupsChecklistComponent.C_DEFAULT_NEW_GROUP_BUTTON_TEXT;
		if (StringHelper.isBlank(this.title))
			this.title = GroupsChecklistComponent.C_DEFAULT_TITLE;
		else {
			if (this.title && this.title.includes(EDynamicTitle.site))
				this.title = this.title.replace(EDynamicTitle.site, !ObjectHelper.isNullOrEmpty(UserData.currentSite) ? UserData.currentSite.name : "");
		}
		if (StringHelper.isBlank(this.emptyText))
			this.emptyText = GroupsChecklistComponent.C_DEFAULT_EMPTY_TEXT;

		if (this.model)
			this.msModelId = this.model._id;

		this.isvcContacts.checkCreatePermissionAsync().then((pbHasPermission: boolean) => this.canCreate = pbHasPermission && !this.readOnly);

		this.moInitGroupsSubject.asObservable()
			.pipe(
				switchMap((paData: T[] | string[]) => this.initGroups(paData)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moInitGroupsSubject.complete();
	}

	/** Initialise les groupes sélectionnables ou non et les retourne. */
	private initGroups(paData: T[] | string[]): Observable<boolean> {
		const lbHasCurrentSite = !ObjectHelper.isNullOrEmpty(UserData.currentSite);
		let loGroups$: Observable<T[]>;

		if (lbHasCurrentSite && ArrayHelper.getFirstElement(this.roles) === C_SECTORS_ROLE_ID && UserData.currentSite)
			loGroups$ = (this.isvcSites.getSiteSectors(UserData.currentSite._id, true) as Observable<T[]>);
		else if (ArrayHelper.hasElements(paData as T[]) && typeof ArrayHelper.getFirstElement(paData as unknown as string[]) !== "string")
			loGroups$ = of(this.data);
		else if (this.model?._id.startsWith(EPrefix.site))
			loGroups$ = (this.isvcSites.getSiteSectors(this.model._id, true) as Observable<T[]>);
		else
			loGroups$ = this.isvcGroups.getDisplayableGroups(paData as string[]) as Observable<T[]>;

		return loGroups$.pipe(
			switchMap((paResults: T[]) => {
				return this.observableIgnoreRoles.value$.pipe(
					map((pbIgnoreRoles: boolean) => {
						this.groupOptions = this.sortOptions(
							paResults.map((poGroup: T) => {
								return ({
									label: poGroup.name,
									value: poGroup,
									disabled: pbIgnoreRoles ? false : !this.isvcPermissions.canApplyRoles(...(poGroup.roles ?? []))
								} as ISelectOption<T>);
							})
						);
						this.maGroupsOptionsByTypes = this.getGroupOptionsByTypes(this.groupOptions);

						return paResults;
					})
				);
			}),
			mergeMap((paResults: T[]) => {
				return combineLatest([
					this.isvcGroups.getGroupContacts(paResults, [IdHelper.getPrefixFromId(this.msModelId)]),
					this.observableGroupSelections.changes$
				])
					.pipe(
						tap(([poContactsByGroupId, paGroupsSelections]: [Map<string, IContact[]>, IGroupSelection<T>[]]) => {
							this.preSelectedGroups = [];
							this.moContactsByGroupId = poContactsByGroupId;
							const laGroupIds: string[] = [];

							paGroupsSelections.forEach((poSelection: IGroupSelection<T>) => {
								if (poSelection.selected)
									ArrayHelper.binaryInsert(laGroupIds, poSelection.group._id);
							});

							paResults.forEach((poGroup: T) => {
								if (
									(this.model && poContactsByGroupId.get(poGroup._id)?.some((poContact: IContact) => this.msModelId === poContact._id)) ||
									ArrayHelper.binarySearch(laGroupIds, poGroup._id)
								)
									this.preSelectedGroups.push(poGroup);
							});

							this.maSelectedGroups = [...this.preSelectedGroups];
							this.moOnSelectionChangedEvent.emit(this.getGroupSelections());
						})
					);
			}),
			mapTo(true),
			tap(_ => this.detectChanges()),
			takeUntil(this.destroyed$)
		);
	}

	private sortOptions(paOptions: ISelectOption<T>[]): ISelectOption<T>[] {
		return paOptions.sort((poItemA: ISelectOption<T>, poItemB: ISelectOption<T>) => poItemA.label.localeCompare(poItemB.label));
	}

	/** Transforme des groupes en groupe avec un champ de sélection. */
	private getGroupSelections(): IGroupSelection<T>[] {
		return this.groupOptions.map((poGroupOption: ISelectOption<T>) => {
			return {
				group: poGroupOption.value,
				selected: this.maSelectedGroups.includes(poGroupOption.value),
				contacts: this.moContactsByGroupId.get(poGroupOption.value._id) || [],
				disabled: !this.isvcPermissions.canApplyRoles(...(poGroupOption.value.roles ?? []))
			} as IGroupSelection<T>;
		});
	}

	/** Traitement d'un groupe sélectionné (préparation de nouveaux liens).
	 * @param paGroups Liste des groupes sélectionnés.
	 */
	public onGroupSelectionChanged(paGroups: IGroup[]): void {
		if (!ArrayHelper.areArraysEqual(this.maSelectedGroups, paGroups)) {
			this.maSelectedGroups = [...(paGroups as T[])];
			this.moOnSelectionChangedEvent.emit(this.getGroupSelections());
		}
	}

	/** Création d'un nouveau groupe. */
	public createNewGroup(): void {
		this.isvcGroups.openCreateGroupeModal({
			_id: IdHelper.buildId(EPrefix.group),
			name: "",
			roles: this.roles
		}) // On ouvre la modale de création d'un groupe.
			.pipe(
				mergeMap((poNewGroup: T) => {
					if (ArrayHelper.hasElements(this.roles)) {
						poNewGroup.roles = ArrayHelper.unique([...(poNewGroup.roles ?? []), ...(this.roles ?? [])]);
						return this.isvcGroups.updateGroup(poNewGroup).pipe(mapTo(poNewGroup));
					}
					return of(poNewGroup);
				}),
				mergeMap((poNewGroup: T) => {
					return this.isvcGroups.getGroupContacts(poNewGroup, [IdHelper.getPrefixFromId(this.msModelId)]) // On récupère les contacts liés au nouveau groupe.
						.pipe(
							tap((paNewGroupContacts: IContact[]) => {
								// On transforme le nouveau groupe en donnée exploitable et on l'ajoute au tableau existant.
								ArrayHelper.pushIfNotPresent(this.groupOptions,
									({ label: poNewGroup.name, value: poNewGroup, disabled: !this.isvcPermissions.canApplyRoles(...(poNewGroup.roles ?? [])) }),
									(poGroupOption: ISelectOption<T>) => poGroupOption.value._id === poNewGroup._id
								);

								this.groupOptions = this.sortOptions(this.groupOptions);
								this.maGroupsOptionsByTypes = this.getGroupOptionsByTypes(this.groupOptions);

								if (!!this.multiple) {
									this.preSelectedGroups.push(poNewGroup);
									this.maSelectedGroups.push(poNewGroup);
								}
								else {
									this.preSelectedGroups = [poNewGroup];
									this.maSelectedGroups = [poNewGroup];
								}

								this.moContactsByGroupId[poNewGroup._id] = paNewGroupContacts;

								this.onGroupSelectionChanged(this.maSelectedGroups);
								this.detectChanges();
							})
						);
				}),
				tap(_ => this.moOnSelectionChangedEvent.emit(this.getGroupSelections())),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public navigateToGroupIfAllowed(poClickedGroup: IGroup): void {
		if (this.isSuperAdmin || !ArrayHelper.hasElements(poClickedGroup.roles))
			this.navigateToGroup(poClickedGroup);
	}

	/** Va sur la page de visualisation du groupe cliqué.
	 * @param poClickedGroup Groupe qu'on veut afficher en mode visu.
	 */
	public navigateToGroup(poClickedGroup: IGroup): void {
		this.ioRouter.navigate(["contacts", "groupes", poClickedGroup._id]);
	}

	/** Retourne un tableau des groupes sélectionnables trier par type.
	 * @param paGroupOptions Tableau des groupes sélectionnables à manipuler.
	 */
	private getGroupOptionsByTypes(paGroupOptions: ISelectOption<T>[]): IGroupOptions[] {
		const laGroupOptionsByTypes: IGroupOptions[] = [];
		const laRoleOptions: ISelectOption<T>[] = [];
		const laGroupOptions: ISelectOption<T>[] = [];
		const laSectorOptions: ISelectOption<T>[] = [];

		paGroupOptions.forEach((poSelectOption: ISelectOption<T>) => {
			if (ArrayHelper.hasElements(poSelectOption.value.roles)) {
				if (poSelectOption.value.roles.includes(C_SECTORS_ROLE_ID))
					laSectorOptions.push(poSelectOption);
				else
					laRoleOptions.push(poSelectOption);
			}
			else
				laGroupOptions.push(poSelectOption);
		});

		if (ArrayHelper.hasElements(laRoleOptions))
			laGroupOptionsByTypes.push({ title: "Rôles", groupOptions: laRoleOptions, mode: "tags" });
		if (ArrayHelper.hasElements(laGroupOptions))
			laGroupOptionsByTypes.push({ title: this.title, groupOptions: laGroupOptions });
		if (ArrayHelper.hasElements(laSectorOptions))
			laGroupOptionsByTypes.push({ title: "Secteurs", groupOptions: laSectorOptions });

		return laGroupOptionsByTypes;
	}

	public getPlaceholder(pbMultiple: boolean): string {
		let lsPlaceholderVariablePart = "";

		if (pbMultiple)
			lsPlaceholderVariablePart += "des groupes";
		else
			lsPlaceholderVariablePart += "un groupe";

		return `Séléctionner ${lsPlaceholderVariablePart} ...`;
	}

	//#endregion

}