import { Injectable } from '@angular/core';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { AlertButton } from '@ionic/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class TradeActionButtonService {

	//#region METHODS

	constructor(private isvcUiMessage: UiMessageService) { }

	/** Affiche un avertissement à l'utilisateur indiquant que des "events/conversations" sont déjà en cours pour ce customer.
	 * @param psMessage Message à afficher.
	 * @param paButtons Liste de boutons personnalisés.
	 */
	public warningCreationFromActionButton(psMessage: string, paButtons?: AlertButton[]): Observable<boolean> {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: psMessage, header: "Attention", buttons: paButtons }));
		return of(false);
	}

	//#endregion
}