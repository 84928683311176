export enum ETradeLogActionId {
	eventOccurrencesExport = "trade-eventOccurrences-export",
	eventOccurrencesBill = "trade-eventOccurrences-bill",
	eventOccurrencesCancel = "trade-eventOccurrences-cancel",
	eventOccurrencesReactivate = "trade-eventOccurrences-reactivate",
	eventOccurrencesValidate = "trade-eventOccurrences-validate",
	eventOccurrencesExportCancel = "trade-eventOccurrences-export-cancel",
	eventOccurrencesBillingLock = "trade-eventOccurrences-billing-lock",
	eventDelete = "trade-event-delete",
	eventCreate = "trade-event-create",
	eventStop = "trade-event-stop",
	eventReactivate = "trade-event-reactivate",
	eventUpdate = "trade-event-update",
	eventBillingLock = "trade-event-billing-lock",
	eventDuplicate = "trade-event-duplicate",
	customerCreate = "trade-customer-create",
	customerDelete = "trade-customer-delete",
	customerUpdate = "trade-customer-update",
	planningCreate = "trade-planning-create",
	planningDelete = "trade-planning-delete",
	planningApply = "trade-planning-apply",
	roleUpdate = "trade-role-update",
	userCreate = "trade-user-create"
}