import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAuthenticatedGuard } from '@calaosoft/osapp/guards/not-authenticated.guard';
import { PageInfo } from '@calaosoft/osapp/model/PageInfo';

const RESET_PASSWORD_REQUEST_PAGE_INFO = new PageInfo({
	hasBackButton: true,
	hasHomeButton: false
});

const RESET_PASSWORD_PAGE_INFO = new PageInfo({
	hasBackButton: true,
	hasHomeButton: false
});

export const routes: Routes = [
	{
		path: 'authenticator',
		loadChildren: () => import('./authentication/authentication-page.module').then(poModule => poModule.AuthenticationPageModule),
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: 'password/create-password',
		loadChildren: () => import('@calaosoft/osapp/modules/password/create-password/create-password.module').then(poModule => poModule.CreatePasswordPageModule)
	},
	{
		path: 'password/reset-password-request',
		loadChildren: () => import('@calaosoft/osapp/modules/password/reset-password-request-page/reset-password-request-page.module').then(poModule => poModule.ResetPasswordRequestPageModule),
		data: {
			pageInfo: RESET_PASSWORD_REQUEST_PAGE_INFO
		}
	},
	{
		path: 'password/reset-password',
		loadChildren: () => import('@calaosoft/osapp/modules/password/reset-password-page/reset-password-page.module').then(poModule => poModule.ResetPasswordPageModule),
		data: {
			pageInfo: RESET_PASSWORD_PAGE_INFO
		}
	},
	{
		path: '',
		loadChildren: () => import('./tab-bar/tab-bar.module').then(m => m.TabBarPageModule)
	},
	{
		path: 'guest-access/event',
		loadChildren: () => import('./guest-access/pages/guest-event-access.module').then(m => m.GuestAccessPageModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule { }