<ng-container *ngIf="(sectors.changes$ | async); else loadingSpinner;">

	<!-- Affichage de la liste. -->
	<ion-button [disabled]="!(isOnline$ | async)  || !areSectorsMappable" (click)=" navigateToSectorsMapAsync()"
		expand="block">
		<ion-icon name="map"></ion-icon>
	</ion-button>

	<ng-container *ngIf="(sectors.changes$ | async)?.length !== 0; else emptyScreen;">

		<calao-virtual-scroll [items]="(sectors.changes$ | async)" [itemSize]="88" transparent margeLastItem autosize
			class="virtual-scroll">
			<ng-template let-item="$implicit">

				<ion-item-sliding osappAutoCloseSlideItem class="ion-no-padding">
					<ion-item class="ion-no-padding" lines="none" (click)="navigateToSectorVisuModeAsync(item)">
						<calao-list-item>
							<calao-list-item-start>
								<ion-icon name="extension-puzzle" class="start-item"></ion-icon>
							</calao-list-item-start>
							<calao-list-item-content detail detailIcon="ellipsis-vertical" class="end-item">
								<calao-list-item-content-title>
									<ion-label> {{ item.title}} </ion-label>
								</calao-list-item-content-title>
								<span> {{ item.getNamePointOfContact() }} </span>
							</calao-list-item-content>
						</calao-list-item>
					</ion-item>
					<ion-item-options>
						<ion-item-option calaoSlidingItemOptionsToggle color="primary"
							(click)="navigateToSectorEditModeAsync(item)">
							<ion-icon name="create"></ion-icon>
							<ion-label>Éditer</ion-label>
						</ion-item-option>

						<ion-item-option [disabled]="!(isOnline$ | async) || !item.isMappable()" calaoSlidingItemOptionsToggle
							color="primary" (click)="navigateToSectorMapModeAsync(item)">
							<ion-icon name="map"></ion-icon>
							<ion-label>Carte</ion-label>
						</ion-item-option>

						<ion-item-option calaoSlidingItemOptionsToggle color="danger" (click)="onDeleteItemClickedAsync(item)">
							<ion-icon name="trash"></ion-icon>
							Supprimer
						</ion-item-option>
					</ion-item-options>
				</ion-item-sliding>
			</ng-template>
		</calao-virtual-scroll>
	</ng-container>

</ng-container>
<!-- Afficher s'il y a un chargement -->
<ng-template #loadingSpinner>
	<div class="full-width">
		<osapp-spinner>
		</osapp-spinner>
	</div>
</ng-template>


<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
<ng-template #emptyScreen>
	<div class="empty-screen flex-col">
		<span class="txt-message">
			<ion-note>Aucun résultat.</ion-note>
		</span>
	</div>
</ng-template>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin fab-list-text">
	<ion-fab-button class="fab-shadow" (click)="navigateToSectorCreateModeAsync()">
		<ion-icon name="add"></ion-icon>
	</ion-fab-button>
</ion-fab>