import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Directory } from '@capacitor/filesystem';
import { IonApp } from '@ionic/angular';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { DateHelper } from '../../helpers/dateHelper';
import { ObjectHelper } from '../../helpers/objectHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { EType } from '../../model/EType';
import { PageInfo } from '../../model/PageInfo';
import { EDateTimePickerMode } from '../../model/date/EDateTimePickerMode';
import { ETimetablePattern } from '../../model/date/ETimetablePattern';
import { IDateTimePickerParams } from '../../model/date/IDateTimePickerParams';
import { ELinkAction } from '../../model/link/ELinkAction';
import { LinkInfo } from '../../model/link/LinkInfo';
import { IMailOptions } from '../../model/mail/IMailOptions';
import { FilesystemService } from '../../modules/filesystem/services/filesystem.service';
import { ModalService } from '../../modules/modal/services/modal.service';
import { PageManagerService } from '../../modules/routing/services/pageManager.service';
import { Queue } from '../../modules/utils/queue/decorators/queue.decorator';
import { FieldsBindingPipe } from '../../pipes/fieldsBinding.pipe';
import { GlobalDataService } from '../../services/global-data.service';
import { MailService } from '../../services/mail.service';
import { DateTimePickerComponent } from '../date/dateTimePicker.component';
import { ISendMailParams } from './models/isendmail-params';

/** Les liens `link` permettent de passer d'une page à l'autre. */
@Component({
	selector: "calao-link",
	templateUrl: 'link.component.html',
	styleUrls: ['link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "LNK.C::";
	private static readonly C_SELECT_DATE_ACTION_ID = "select-date";

	//#endregion

	//#region PROPERTIES

	/** Informations pour le lien. */
	@Input() public linkInfo: LinkInfo;

	public ioAppCtrl: IonApp;
	public marker: string | number;

	public datePickerParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(ETimetablePattern.dd_MM_yyyy_slash, EDateTimePickerMode.date);
	public date: Date;

	//#endregion

	//#region METHODS

	constructor(
		public isvcPageManager: PageManagerService,
		private ioFileTransfer: FileTransfer,
		private ioFileOpener: FileOpener,
		private ioBindingPipe: FieldsBindingPipe,
		private ioRouter: Router,
		private isvcGlobalData: GlobalDataService,
		private readonly isvcFilesystem: FilesystemService,
		private readonly isvcMail: MailService,
		private readonly isvcModal: ModalService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		if (!this.linkInfo || !this.linkInfo.label)
			console.warn(`${LinkComponent.C_LOG_ID}Attention : No label for this link.`);

		if (!StringHelper.isBlank(this.linkInfo.marker)) {
			this.isvcGlobalData.getData<string | number>(this.linkInfo.marker)
				.pipe(
					takeUntil(this.destroyed$),
					tap((poMarker: string | number) => {
						this.marker = poMarker;
						this.detectChanges();
					})
				)
				.subscribe();
		}
	}

	/** Effectue l'action du lien. */
	public action(poDatePicker?: DateTimePickerComponent): void {

		switch (this.linkInfo.action) {
			case ELinkAction.route:
				this.changePage();
				break;

			case ELinkAction.download:
				this.downloadAsync();
				break;

			case ELinkAction.callback:
				if (ObjectHelper.checkType(this.linkInfo.actionParams.function, EType.function))
					this.linkInfo.actionParams.function();
				break;

			case ELinkAction.sendMail:
				this.sendMail$(this.linkInfo.sendMailParams).subscribe();
				break;

			case ELinkAction.navigate:
				const loNavigationExtras: NavigationExtras = {};

				if (this.linkInfo.params) { // S'il y a des paramètres de navigation, il faut les prendre en compte.
					if (this.linkInfo.params.stateParams)
						loNavigationExtras.state = this.linkInfo.params.stateParams;
					if (this.linkInfo.params.queryParams)
						loNavigationExtras.queryParams = this.linkInfo.params.queryParams;
				}

				this.ioRouter.navigate(this.linkInfo.actionParams, loNavigationExtras);
				break;

			case ELinkAction.action:
				if (this.linkInfo.actionParams.actionId === LinkComponent.C_SELECT_DATE_ACTION_ID)
					poDatePicker.pickDate();
				break;

			case ELinkAction.openModal:
				if (this.linkInfo.modalOptions)
					this.isvcModal.open(this.linkInfo.modalOptions).subscribe();
				else
					console.error(`${LinkComponent.C_LOG_ID}No options to open a modal !`);
				break;

			default:
				console.error(`${LinkComponent.C_LOG_ID}Action '${this.linkInfo.action}' unknown.`);
				break;
		}
	}

	/** La fonction push la page "actionParams" sur la pile des pages. */
	private changePage(): void {
		//TODO 1626 : Voir pour modifier les entrées en base de données ?
		this.isvcPageManager.routePageFromInfo(new PageInfo(this.linkInfo.actionParams)); // Récupère les paramètres par défaut.
	}

	/** Permet de télécharger un fichier et de l'ouvrir. */
	private async downloadAsync(): Promise<void> {
		// Reconstitution de l'URL grâce au tableau de clé/valeur bindingData.
		const lsUrl: string = this.ioBindingPipe.transform(this.linkInfo.actionParams.url, this.linkInfo.actionParams.bindingData);
		let lsTargetPath: string;
		const loFileTransferObject: FileTransferObject = this.ioFileTransfer.create(); // Initialisation du filetransfer.
		const lbTrustHosts = true;
		const loOptions: any = {};

		// Téléchargement.
		loFileTransferObject.download(
			lsUrl,
			lsTargetPath = await this.isvcFilesystem.getFileUriAsync(this.targetPathReconstructionToDownload(), Directory.External),
			lbTrustHosts,
			loOptions
		)
			.then(_ => {
				// Ouverture du fichier.
				this.ioFileOpener.open(lsTargetPath, "application/pdf")
					.then(() => console.debug(`${LinkComponent.C_LOG_ID}File '${lsTargetPath}' opened`))
					.catch(poError => console.error(`${LinkComponent.C_LOG_ID}Opening file '${lsTargetPath}' error :`, poError));
			})
			.catch(poError => console.error(`${LinkComponent.C_LOG_ID}Download file '${lsTargetPath}' error :`, poError));
	}

	@Queue<LinkComponent, Parameters<LinkComponent["sendMail$"]>, ReturnType<LinkComponent["sendMail$"]>>({ excludePendings: true })
	private sendMail$(poSendmailParams: ISendMailParams) {
		const lsBody: string = this.isvcMail.generateBody(poSendmailParams.body);
		const loOptions: IMailOptions = { to: poSendmailParams.to, needScreenshot: poSendmailParams.needScreenshot };
		return this.isvcMail.sendMail(poSendmailParams.subject, lsBody, loOptions);
	}

	/** Reconstruit l'url grâce au tableau de clé/valeur bindingData et le retourne. */
	private targetPathReconstructionToDownload(): string {
		return `download.${this.linkInfo.actionParams.mime.split('/')[1]}`;
	}

	public onDateSelected(pdNewDate: Date): void {
		this.ioRouter.navigate([this.linkInfo.actionParams.route], { queryParams: { date: pdNewDate.toISOString() } });
	}

	//#endregion
}