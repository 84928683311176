import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { EPrefix } from "@calaosoft/osapp/model/EPrefix";
import { StoreDocument } from "@calaosoft/osapp/modules/store/model/store-document";
import { ResolveModel } from "@calaosoft/osapp/modules/utils/models/decorators/resolve-model.decorator";
import { Exclude } from "class-transformer";
import { IInterventionStatement } from "./iintervention-statement";

export class InterventionStatement extends StoreDocument implements IInterventionStatement {
	@ResolveModel(Date)
	public date: Date;
	public userId: string;
	public entityId: string;
	public skipReason?: string;
	@ResolveModel(Date)
	public originalStartDate?: Date;

	@Exclude()
	public get userContactId(): string {
		return IdHelper.replacePrefixId(this.userId, EPrefix.contact, EPrefix.user);
	}
}