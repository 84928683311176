import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule, PipeTransform, Provider, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContactsModule } from '@calaosoft/osapp/components/contacts/contacts.module';
import { ConversationModule } from '@calaosoft/osapp/components/conversation/conversation.module';
import { DateModule } from '@calaosoft/osapp/components/date/date.module';
import { GalleryModule } from '@calaosoft/osapp/components/gallery/gallery.module';
import { SharedComponentsModule } from '@calaosoft/osapp/components/sharedComponents.module';
import { CalaoAvatarModule } from '@calaosoft/osapp/modules/avatar/calao-avatar.module';
import { BlankInformationModule } from '@calaosoft/osapp/modules/blank-information/blank-information.module';
import { ConflictsModule } from '@calaosoft/osapp/modules/conflicts/conflicts.module';
import { FilterModule } from '@calaosoft/osapp/modules/filter/filter.module';
import { HeaderModule } from '@calaosoft/osapp/modules/header';
import { HooksModule } from '@calaosoft/osapp/modules/hooks/hooks.module';
import { ListsModule } from '@calaosoft/osapp/modules/lists/lists.module';
import { ModalModule } from '@calaosoft/osapp/modules/modal';
import { PermissionsModule } from '@calaosoft/osapp/modules/permissions/permissions.module';
import { FavoritesModule } from '@calaosoft/osapp/modules/preferences/favorites/favorites.module';
import { SelectorModule } from '@calaosoft/osapp/modules/selector/selector.module';
import { SliderModule } from '@calaosoft/osapp/modules/slider/slider.module';
import { SlidingListModule } from '@calaosoft/osapp/modules/sliding-list/sliding-list.module';
import { SortModule } from '@calaosoft/osapp/modules/sort/sort.module';
import { TabsModule } from '@calaosoft/osapp/modules/tabs/tabs.module';
import { VirtualScrollModule } from '@calaosoft/osapp/modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '@calaosoft/osapp/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { PriorityModule } from '../priority/priority.module';
import { TradeEventsModule } from '../trade-events/trade-events.module';
import { BusinessActivitiesSlideComponent } from './components/business-activities-slide/business-activities-slide.component';
import { BusinessItemComponent } from './components/business-item/business-item.component';
import { BusinessesListComponent } from './components/businesses/businesses-list.component';
import { TradeConversationTabComponent } from './components/conversation-tab/conversation-tab.component';
import { TradeConversationsListComponent } from './components/conversationsList/tradeConversationsList.component';
import { CustomerNamePipe } from './pipes/customer-name.pipe';
import { TradeActionButtonService } from './services/trade-action-button.service';

const modules: Type<any>[] = [
	IonicModule,
	CommonModule,
	PipeModule,
	ConversationModule,
	SharedComponentsModule,
	GalleryModule,
	MatFormFieldModule,
	MatInputModule,
	MatTooltipModule,
	FormsModule,
	HeaderModule,
	ModalModule,
	TabsModule,
	FilterModule,
	SelectorModule,
	FavoritesModule,
	ScrollingModule,
	MatRadioModule,
	ConflictsModule,
	DateModule,
	SlidingListModule,
	VirtualScrollModule,
	PermissionsModule,
	MatAutocompleteModule,
	MatSelectModule,
	ContactsModule,
	CalaoAvatarModule,
	TradeEventsModule,
	BlankInformationModule,
	TabsModule,
	HooksModule,
	FilterModule,
	SlidingListModule,
	ListsModule,
	SelectorModule,
	SortModule,
	PriorityModule,
	SliderModule,
	VirtualScrollModule
];
const components: Type<any>[] = [
	TradeConversationsListComponent,
	BusinessesListComponent,
	TradeConversationTabComponent,
	BusinessActivitiesSlideComponent,
	BusinessItemComponent
];
const providers: Provider[] = [
	TradeActionButtonService,
	TitleCasePipe
];
const pipes: Type<PipeTransform>[] = [
	CustomerNamePipe,
];

@NgModule({
	imports: modules,
	exports: [...components, ...pipes],
	declarations: [...components, ...pipes],
	providers: [...providers, ...pipes]
})
export class BusinessesModule { }