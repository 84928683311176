import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../observable/models/observable-property';

@Component({
	selector: 'calao-list-item-start-content',
	templateUrl: './list-item-start-content.component.html',
	styleUrls: ['./list-item-start-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemStartContentComponent {

	//#region PROPERTIES

	/** Label. */
	@Input() public label?: string | null;
	@ObserveProperty<ListItemStartContentComponent>({ sourcePropertyKey: "label" })
	public readonly observableLabel = new ObservableProperty<string>();

	/** Icône. */
	@Input() public icon?: string | null;
	@ObserveProperty<ListItemStartContentComponent>({ sourcePropertyKey: "icon" })
	public readonly observableIcon = new ObservableProperty<string>();

	/** Texte. */
	@Input() public text?: string | null;
	@ObserveProperty<ListItemStartContentComponent>({ sourcePropertyKey: "text" })
	public readonly observableText = new ObservableProperty<string>();

	/** Couleur. */
	@Input() public color?: string | null;
	@ObserveProperty<ListItemStartContentComponent>({ sourcePropertyKey: "color" })
	public readonly observableColor = new ObservableProperty<string>();

	/** Couleur de l'icône. */
	@Input() public iconColor?: string | null;
	@ObserveProperty<ListItemStartContentComponent>({ sourcePropertyKey: "iconColor" })
	public readonly observableIconColor = new ObservableProperty<string>();

	//#endregion PROPERTIES

}
