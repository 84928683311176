import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, mergeMapTo, take, tap } from 'rxjs/operators';
import { ObjectHelper } from '../../../../../osapp/src/helpers/objectHelper';
import { EApplicationEventType } from '../../../../../osapp/src/model/application/EApplicationEventType';
import { IApplicationEvent } from '../../../../../osapp/src/model/application/IApplicationEvent';
import { Version } from '../../../../../osapp/src/model/application/Version';
import { ConfigData } from '../../../../../osapp/src/model/config/ConfigData';
import { EStoreEventStatus } from '../../../../../osapp/src/model/store/EStoreEventStatus';
import { EStoreEventType } from '../../../../../osapp/src/model/store/EStoreEventType';
import { IStoreEvent } from '../../../../../osapp/src/model/store/IStoreEvent';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IGdprPopupComponentDescriptor } from '../../../model/IGdprPopupComponentDescriptor';
import { Database } from '../../../model/store/Database';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { EStoreFlag } from '../../../model/store/EStoreFlag';
import { IDataSource } from '../../../model/store/IDataSource';
import { ApplicationService } from '../../../services/application.service';
import { Store } from '../../../services/store.service';
import { IFavorites } from '../../preferences/favorites/model/IFavorites';
import { FavoritesService } from '../../preferences/favorites/services/favorites.service';

@Component({
	selector: 'calao-gdpr-popup',
	templateUrl: './gdpr-popup.component.html',
	styleUrls: ['./gdpr-popup.component.scss'],
})
export class GdprPopupComponent implements OnInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "GDPR.P.C::"
	private static readonly C_GDPR_POPUP_KEY: string = "gdprPopup";
	private moPopupDescriptor: IGdprPopupComponentDescriptor;

	//#endregion

	//#region METHODS

	constructor(
		private isvcApplication: ApplicationService,
		private isvcStore: Store,
		private ioAlertCtrl: AlertController,
		private isvcFavorites: FavoritesService,
	) { }

	public ngOnInit(): void {
		this.init().subscribe();
	}

	private init(): Observable<boolean> {
		return this.isvcApplication.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(
				mergeMap(() => this.fillPopupFromDatabase()),
				mergeMap(() => this.isvcFavorites.get(EPrefix.gdpr, true)),
				tap((poFavorites: IFavorites) => {
					if (ObjectHelper.isNullOrEmpty(poFavorites) || Version.fromDescriptorId(poFavorites.gdprPopupVersion).compareTo(Version.fromDescriptorId(this.moPopupDescriptor._id)) < 0)
						this.presentPopup();
				}),
				mapTo(true)
			);
	}

	/** Initialise le contenu de la popup via la bdd. */
	private fillPopupFromDatabase(): Observable<boolean> {
		return this.getGdprPopup(GdprPopupComponent.C_GDPR_POPUP_KEY) // Récupération du contenu.
			.pipe(
				catchError(poError => { console.error(`${GdprPopupComponent.C_LOG_ID} Erreur création et récupèration du contenu de la popup : `, poError); return throwError(() => poError); }),
				map((poPopup: IGdprPopupComponentDescriptor) => {
					this.moPopupDescriptor = poPopup;
					return true;
				})
			);
	}

	private getGdprPopup(psPopupKey: string): Observable<IGdprPopupComponentDescriptor> {
		const loDatabase: Database = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.components)));

		if (loDatabase && loDatabase.id && loDatabase.isReady)
			return this.getComponentDescriptor(psPopupKey, loDatabase.id);
		else {
			return this.isvcApplication.appEvent$
				.pipe(
					filter((poEvent: IApplicationEvent) => this.filterComponentsDatabaseInitApplicationEvent(poEvent, loDatabase)),
					take(1),
					mergeMapTo(this.getComponentDescriptor(psPopupKey, loDatabase.id))
				);
		}
	}

	/** Filtre les événements d'application pour ne garder que celui qui vérifie que la base de données des composants a été initialisée.
	 * @param poEvent Événement d'application reçu.
	 * @param poWantedDatabase Base de données permettant de vérifier si l'identifiant de la base de données traitées est celui souhaité.
	 */
	private filterComponentsDatabaseInitApplicationEvent(poEvent: IApplicationEvent, poWantedDatabase: Database): boolean {
		let lbIsFilterOkay = false;

		if (poEvent.type === EApplicationEventType.StoreEvent && (poEvent as IStoreEvent).data.status === EStoreEventStatus.successed &&
			(poEvent as IStoreEvent).data.storeEventType === EStoreEventType.Init) {

			poWantedDatabase = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.components)));

			lbIsFilterOkay = poWantedDatabase ? (poEvent as IStoreEvent).data.databaseId === poWantedDatabase.id : false;
		}

		return lbIsFilterOkay;
	}

	/** Récupère le descripteur de la popup dans la base de données ayant la version la plus récente, renvoie une erreur si aucune popup rgpd n'est trouvé.
	 * @param psPopupKey Clé de la popup que l'on veut initialiser.
	 * @param psDatabaseId Id de la base de données à récupérer.
	 */
	private getComponentDescriptor(psPopupKey: string, psDatabaseId: string): Observable<IGdprPopupComponentDescriptor> {
		const loParams: IDataSource = {
			databaseId: psDatabaseId,
			viewParams: { include_docs: true }
		};

		return this.isvcStore.get(loParams)
			.pipe(
				map((paResults: IGdprPopupComponentDescriptor[]) => paResults.filter((poCompDesc: IGdprPopupComponentDescriptor) => poCompDesc.type === psPopupKey && Version.fromDescriptorId(poCompDesc._id).compareTo(Version.fromString(ConfigData.appInfo.appVersion)) <= 0)),
				catchError(poError => {
					console.error(`${GdprPopupComponent.C_LOG_ID} Erreur récupération base de données ${psDatabaseId} : `, poError);
					return throwError(() => poError);
				}),
				mergeMap((paPopups: IGdprPopupComponentDescriptor[]) => {
					const loPopup: IGdprPopupComponentDescriptor = ArrayHelper.getLastElement(paPopups);

					if (loPopup) {
						console.debug(`${GdprPopupComponent.C_LOG_ID} Gdpr popup component ${loPopup._id} is selected for application version ${ConfigData.appInfo.appVersion}.`);
						return of(loPopup);
					}
					else {
						const lsMessage = `No gdpr popup component descriptor matching ID ${psPopupKey}.`;
						console.error(`${GdprPopupComponent.C_LOG_ID} ${lsMessage}`);
						return throwError(() => lsMessage);
					}
				})
			);
	}

	private async presentPopup(): Promise<void> {
		const loPopup: HTMLIonAlertElement = await this.ioAlertCtrl.create({
			message: this.moPopupDescriptor.body,
			backdropDismiss: false,
			inputs: [
				{
					type: "checkbox",
					label: this.moPopupDescriptor.checkText,
					handler: () => {
						this.isvcFavorites.setGdprPopupVersion(EPrefix.gdpr, this.moPopupDescriptor._id)
							.pipe(
								tap(() => this.ioAlertCtrl.dismiss())
							).subscribe();
					}
				}
			]
		});
		await loPopup.present();
		document.getElementById("link").setAttribute("target", "_blank");

		return;
	}

	//#endregion
}