<ion-item class="ion-no-padding" lines="none" (click)="navigateToLateListPageAsync()">
	<calao-list-item>
		<calao-list-item-start [backgroundColor]="observableItemStartBackgroundColor.value$ | async">
			<ion-icon name="stopwatch" slot="start" [style.color]="observableItemStartIconColor.value$ | async"></ion-icon>
			<span class="late-label">Retard</span>
			<ng-container *ngIf="observableTotalCount.value$ |async as totalCount">
				<span class="late">{{ totalCount }}</span>
			</ng-container>
		</calao-list-item-start>
		<calao-list-item-content [detail]="true" (onDetailClicked)="navigateToLateListPageAsync()">
			<div>
				<ng-container *ngFor="let event of observableEvents.changes$ | async">
					<ion-label> - {{ event.title }}</ion-label>
				</ng-container>
			</div>
			<calao-list-item-content-suffix *ngIf="observableDiffCountLabel.value$ | async as label" class="dark-bold">{{
				label }}
			</calao-list-item-content-suffix>
		</calao-list-item-content>
	</calao-list-item>
</ion-item>