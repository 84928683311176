import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DmsModule } from '@calaosoft/osapp/modules/dms/dms.module';
import { CONV_META_CONFIG } from '@calaosoft/osapp/modules/dms/model/MetaConversationBuilder';
import { MetaTourneeBuilder } from './model/MetaTourneeBuilder';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		DmsModule.forRoot([
			MetaTourneeBuilder
		])
	],
	providers: [
		MetaTourneeBuilder,
		{
			provide: CONV_META_CONFIG,
			useValue: [
				MetaTourneeBuilder
			]
		}
	]
})
export class TradeDmsModule { }
