<div class="location-bloc-area">
	<ion-input #address (ionInput)="inputChanged()" (ionBlur)="onBlurEvent()" mode="md" debounce="1000" type="text"
		placeholder="Adresse complète">
		<ion-icon *ngIf="hasPlaceCoords.value$ | async " name="location"></ion-icon>
	</ion-input>
	<ion-note *ngIf="hasPlaceCoords.value$ | async "> {{this.moParams.geocodingSuccessLabel}}
	</ion-note>

	<ion-list [hidden]="!(observableItems.length$ | async)">
		<ion-item *ngFor="let item of observableItems" (click)="selected(item)">
			<ion-label>
				{{item.street}} {{item.zipCode ?? ""}} {{item.city}}
			</ion-label>
			<ion-note color="medium" slot="end">{{item.distance}} km</ion-note>
		</ion-item>
	</ion-list>
</div>