<calao-tabs #tabs>
	<calao-tabs-segments>
		<calao-tabs-segment>
			<ion-label>Actives</ion-label>
		</calao-tabs-segment>
		<calao-tabs-segment>
			<ion-label>Archivées</ion-label>
		</calao-tabs-segment>
	</calao-tabs-segments>

	<calao-tabs-tab-container>
		<!-- Première slide (actives). -->
		<ng-template #tabTemplate>
			<calao-tabs-tab #tab>
				<trade-conversations-list *ngIf="tab.isActive$ | async" [params]="observableParams.value$ | async"
					[conversationsStatusFilter]="statusFilters.active">
				</trade-conversations-list>
			</calao-tabs-tab>
		</ng-template>

		<!-- Seconde slide (archivées). -->
		<ng-template #tabTemplate>
			<calao-tabs-tab #tab>
				<trade-conversations-list *ngIf="tab.isActive$ | async" hideFabButton [params]="observableParams.value$ | async"
					[conversationsStatusFilter]="statusFilters.archived">
				</trade-conversations-list>
			</calao-tabs-tab>
		</ng-template>
	</calao-tabs-tab-container>
</calao-tabs>