import { DateHelper } from "@calaosoft/osapp/helpers/dateHelper";
import { UserHelper } from "@calaosoft/osapp/helpers/user.helper";
import { ETimetablePattern } from "@calaosoft/osapp/model/date/ETimetablePattern";
import { BaseEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence";
import { IEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/ievent-occurrence";
import { ObserveProperty } from "@calaosoft/osapp/modules/observable/decorators/observe-property.decorator";
import { ObservableProperty } from "@calaosoft/osapp/modules/observable/models/observable-property";
import { ModelMatch } from "@calaosoft/osapp/modules/utils/models/decorators/model-match.decorator";
import { Exclude } from "class-transformer";
import { EMPTY, Observable, combineLatest, defer, of } from "rxjs";
import { distinctUntilChanged, map, startWith, switchMap } from "rxjs/operators";
import { ETaskStatus } from "./etask-status";
import { ETradeEventType } from "./etrade-event-type";
import { Task } from "./task";

@ModelMatch((poOccurrence: IEventOccurrence) => poOccurrence.eventType === ETradeEventType.task, BaseEventOccurrence)
export class TaskOccurrence extends BaseEventOccurrence {

	//#region PROPERTIES

	public priority?: number;
	@ObserveProperty<TaskOccurrence>({ sourcePropertyKey: "priority" })
	public readonly observablePriority = new ObservableProperty<number>();

	public achievement: number;
	@ObserveProperty<TaskOccurrence>({ sourcePropertyKey: "achievement" })
	public readonly observableAchievement = new ObservableProperty<number>(0);

	public override event: Task;

	public get done(): boolean {
		return this.status === ETaskStatus.done;
	}
	@Exclude()
	public done$: Observable<boolean> = this.observableStatus.value$.pipe(map(() => this.done));

	public override get sortDate(): Date | undefined {
		if (this.done && this.statusChangeDate)
			return this.statusChangeDate;

		return this.startDate;
	}

	public override status?: ETaskStatus;

	@Exclude()
	public override readonly dateLabel$: Observable<string> = this.getEndDateLabel$();

	@Exclude()
	public readonly canBeEnded$: Observable<boolean> = this.getCanBeEnded$();

	@Exclude()
	public override readonly authorLabel$: Observable<string> = this.getAuthorLabel$();

	/** Echéance de la tâche. */
	public deadline?: string | Date;

	//#endregion

	//#region METHODS

	constructor(poTask?: Task, pdStartDate?: Date) {
		super(poTask, pdStartDate);
		this.priority = poTask?.priority;
		this.achievement = poTask?.achievement ?? 0;
		this.observableStatus.value = this.status = this.status ?? ETaskStatus.active;
		this.deadline = poTask?.deadline;
	}

	private getEndDateLabel$(): Observable<string> {
		return combineLatest([this.done$.pipe(startWith(false)), this.observableStatusChangeDate.value$.pipe(startWith(undefined))]).pipe(
			switchMap(([pbDone, pdStatusChangeDate]: [boolean, Date]) => {
				if (pbDone && pdStatusChangeDate)
					return of(`le ${DateHelper.transform(pdStatusChangeDate, ETimetablePattern.dd_MM_yyyy_slash)} à ${DateHelper.transform(pdStatusChangeDate, ETimetablePattern.HH_mm)}`);
				else
					return this.getDateLabel$();
			})
		);
	}

	private getCanBeEnded$(): Observable<boolean> {
		return this.observableParticipantIds.changes$.pipe(
			map(() => this.hasSomeParticipants([UserHelper.getUserContactId()])),
			distinctUntilChanged()
		);
	}

	protected override getCanBeEdited$(): Observable<boolean> {
		return defer(() => combineLatest([this.done$, super.getCanBeEdited$()])).pipe(
			map(([pbIsDone, pbCanBeEdited]: [boolean, boolean]) => !pbIsDone && pbCanBeEdited)
		);
	}

	public getStatusChangeDateLabel$(): Observable<string> {
		return this.observableStatusChangeDate.value$.pipe(
			startWith(undefined),
			map((pdDate?: Date) => {
				const ldDate: Date | undefined = pdDate ?? this.eventOccurrenceDifferential?.createDate;
				if (ldDate)
					return `depuis le ${DateHelper.transform(ldDate, ETimetablePattern.dd_MM_yyyy_HH_mm_slash)}`;
				return "";
			})
		);
	}

	protected override getAuthorLabel$(): Observable<string> {
		return EMPTY;
	}

	public getDeadlineLabel(): string | undefined {
		return this.deadline ? `${DateHelper.transform(this.deadline, ETimetablePattern.dd_MM_yyyy_comma_HH_mm_slash)}` : undefined;
	}

	protected override getLateStatus$(): Observable<boolean> {
		return super.getLateStatus$().pipe(
			switchMap((pbIsLate: boolean) => {
				if (pbIsLate)
					return of(true);
				else if (this.deadline) {
					return DateHelper.getMinutes$().pipe(
						map((pdDate: Date) => DateHelper.compareTwoDates(pdDate, this.deadline) >= 0 && !this.done),
						distinctUntilChanged()
					);
				}
				else
					return of(false);
			})
		);
	};

	//#endregion
}