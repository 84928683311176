import { Injectable } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { IDataSource } from '@calaosoft/osapp/model/store/IDataSource';
import { IStoreDataResponse } from '@calaosoft/osapp/model/store/IStoreDataResponse';
import { IRange } from '@calaosoft/osapp/modules/utils/models/models/irange';
import { Store } from '@calaosoft/osapp/services/store.service';
import { Observable } from 'rxjs';
import { C_PREFIX_INTER_STATE } from '../../../app/app.constants';
import { IInterventionStatement } from '../models/iintervention-statement';
import { InterventionStatement } from '../models/intervention-statement';

@Injectable()
export class InterventionStatementsService {

	//#region METHODS

	constructor(private readonly isvcStore: Store) { }

	/** Collecte en base les relevés d'interventions effectuées sur une plage donnée
	* @param poRange Optionnel, permet de spécifier un intervalle de Dates pour restreindre la requête
	* @returns Un Observable des relevés d'intervention
	*/
	public getInterventionStatements(poRange?: IRange<Date>): Observable<InterventionStatement[]> {
		return this.isvcStore.get(this.buildStatementsDataSource(true, poRange, true));
	}

	private buildStatementsDataSource(pbIncludeDocs: boolean, poRange?: IRange<Date>, pbLive?: boolean): IDataSource<InterventionStatement> {
		return {
			baseClass: InterventionStatement,
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			// TODO : Remplacer ce filtrage par l'utilisation de l'indexeur
			filter: ((poStatement: IInterventionStatement) => {
				if (poRange)
					return (DateHelper.isDateInRange(poStatement.date, poRange) || !!(poStatement.originalStartDate && DateHelper.isDateInRange(poStatement.originalStartDate, poRange)));
				else
					return true;
			}),
			viewParams: {
				startkey: C_PREFIX_INTER_STATE,
				endkey: `${C_PREFIX_INTER_STATE}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: pbIncludeDocs
			},
			live: pbLive
		};
	}

	/** Enregistre un relevé d'intervention en base de données.
	 * @param poInterventionStatement Relevé d'intervention à enregistrer en base.
	 */
	public async createInterventionStatementAsync(poInterventionStatement: IInterventionStatement): Promise<IStoreDataResponse> {
		return this.isvcStore.put(poInterventionStatement, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))).toPromise();
	}

	//#endregion
}
