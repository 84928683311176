<div *ngIf="observableTaskOccurrence.value$ | async as item" class="item">
	<ng-template *ngTemplateOutlet="item.event.private ? private: task; context : { item : item }"></ng-template>
</div>

<ng-template #task let-item="item">
	<ion-item class="ion-no-padding" lines="none" (click)="onTaskClicked()">
		<calao-list-item>
			<calao-list-item-start>
				<trade-list-item-start-priority
					[priority]="item.observablePriority.value$ | async"></trade-list-item-start-priority>
			</calao-list-item-start>
			<calao-list-item-content [backgroundColor]="taskItemColor" [detail]="!(item.done$ | async)"
				[detailIcon]="!item.done ? 'ellipsis-vertical' : ''" (onDetailClicked)="presentPopOverAsync($event)">
				<calao-list-item-content-prefix>
					<ng-container *ngIf="item.done; else lateIcon">
						<div class="task">
							<ion-icon class="done-icon" name="checkmark-circle"></ion-icon>
							<ion-note class="done">TERMINÉE</ion-note>
						</div>
					</ng-container>
					<ng-template #lateIcon>
						<ng-container *ngIf="item.isLate$ | async">
							<div class="task">
								<ion-icon class="late-icon" name="stopwatch"></ion-icon>
								<ion-note class="late">RETARD</ion-note>
							</div>
						</ng-container>
					</ng-template>
				</calao-list-item-content-prefix>
				<calao-list-item-content-prefix class="date-label"
					*ngIf="!(item.dateLabel$ | async) && item.getDeadlineLabel() as deadlineLabel">
					<ion-note class="prefix-note ion-text-wrap deadline">
						Échéance le {{ deadlineLabel }}
					</ion-note>
				</calao-list-item-content-prefix>
				<calao-list-item-content-prefix class="date-label" *ngIf="item.dateLabel$ | async as value">
					<ng-container
						*ngTemplateOutlet="(item.isLate$ | async) && item.getDeadlineLabel() && !item.done ? deadlineLabel : dateLabel"></ng-container>
					<ng-template #deadlineLabel>
						<ion-note class="prefix-note ion-text-wrap deadline">
							échéance le {{ item.getDeadlineLabel() }}
						</ion-note>
					</ng-template>
					<ng-template #dateLabel>
						<ion-note class="prefix-note ion-text-wrap">
							{{ value }}
						</ion-note>
					</ng-template>
				</calao-list-item-content-prefix>
				<calao-list-item-content-title *ngIf="item.observableTitle.value$ | async as value">
					<ion-icon *ngIf="item.event.private" name="lock-closed"></ion-icon>
					<ion-label>{{ value }}</ion-label>
				</calao-list-item-content-title>
				<ng-container *ngIf="item.observablePlace.value$ | async as place">
					<div class="place-label">
						<ion-icon name="location" color="warning"></ion-icon> <span>{{ place }}</span>
					</div>
				</ng-container>
				<span>{{ item.participantsLabel$ | async }}</span>
				<calao-list-item-content-suffix *ngIf="item.observableComment.value$ | async as value">{{value}}
				</calao-list-item-content-suffix>
			</calao-list-item-content>
		</calao-list-item>
	</ion-item>
</ng-template>

<ng-template #private let-item="item">
	<ng-container
		*ngTemplateOutlet="item.isMinePrivateEvent ? task: privateTask; context : { item : item }"></ng-container>
</ng-template>

<ng-template #privateTask let-item="item">
	<trade-private-activity-item [item]="item"></trade-private-activity-item>
</ng-template>