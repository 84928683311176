import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../../helpers/arrayHelper';
import { ContactHelper } from '../../../../../../helpers/contactHelper';
import { UserHelper } from '../../../../../../helpers/user.helper';
import { IContact } from '../../../../../../model/contacts/IContact';
import { IContactsSelectorParams } from '../../../../../../model/contacts/IContactsSelectorParams';
import { IGroupMember } from '../../../../../../model/contacts/IGroupMember';
import { ContactsService } from '../../../../../../services/contacts.service';
import { Contact } from '../../../../../contacts/models/contact';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { Queue } from '../../../../../utils/queue/decorators/queue.decorator';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IAssignmentFieldparams } from './models/iassignment-field-params';

@Component({
	selector: 'calao-assignment-field',
	templateUrl: './assignment-field.component.html',
	styleUrls: ['./assignment-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentFieldComponent extends FieldBase<Array<IContact> | Array<string>> implements OnInit {

	//#region PROPERTIES

	/** Paramètres du composant. */
	public readonly observableParams = new ObservableProperty<IAssignmentFieldparams>();

	/** Nom du contact. */
	public readonly observableContactName = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcContacts: ContactsService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.observableParams.value = this.props as IAssignmentFieldparams;

		if (this.fieldValue) {
			const loValue: IContact | string | undefined = ArrayHelper.getFirstElement(this.fieldValue as IContact[]);
			const loContact: Contact = await this.isvcContacts.getContact(typeof loValue === "string" ? loValue : loValue?._id).toPromise();
			this.observableContactName.value = this.getContactName(loContact);
		}
	}

	public openContactSelector(): void {
		this.openContactSelector$().pipe(
			tap((paContacts: IGroupMember[]) => {
				this.fieldValue = paContacts.map((poContact: IGroupMember) => poContact._id);
				this.observableContactName.value = this.getContactName(ArrayHelper.getFirstElement(paContacts));
			})
		).subscribe();
	}

	private getContactName(poContact: IGroupMember): string {
		return (poContact._id === UserHelper.getUserContactId()) ? "Moi" : ContactHelper.getCompleteFormattedName(poContact);
	}

	@Queue<AssignmentFieldComponent, Parameters<AssignmentFieldComponent["openContactSelector$"]>, ReturnType<AssignmentFieldComponent["openContactSelector$"]>>({
		excludePendings: true
	})
	private openContactSelector$(): Observable<IGroupMember[]> {
		const loContactsSelectorParams: IContactsSelectorParams = {
			...this.observableParams.value.contactsSelectorParams,
		};
		return this.isvcContacts.openContactsSelectorAsModalWithWorkspacePreSelection(this.model, loContactsSelectorParams, loContactsSelectorParams.title);
	}

	//#endregion METHODS

}
