import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListsModule } from '@calaosoft/osapp/modules/lists/lists.module';
import { IonicModule } from '@ionic/angular';
import { ListItemStartPriorityComponent } from './components/list-item-start-priority/list-item-start-priority.component';

@NgModule({
	declarations: [ListItemStartPriorityComponent],
	exports: [ListItemStartPriorityComponent],
	imports: [
		CommonModule,
		IonicModule,
		ListsModule
	]
})
export class PriorityModule { }
