import { Injectable } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { IdHelper } from '@calaosoft/osapp/helpers/idHelper';
import { UserHelper } from '@calaosoft/osapp/helpers/user.helper';
import { EPrefix } from '@calaosoft/osapp/model/EPrefix';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { IDataSource } from '@calaosoft/osapp/model/store/IDataSource';
import { IStoreDocument } from '@calaosoft/osapp/model/store/IStoreDocument';
import { EUTCAccuracy } from '@calaosoft/osapp/modules/date/model/eutcaccuracy.enum';
import { IRange } from '@calaosoft/osapp/modules/utils/models/models/irange';
import { Store } from '@calaosoft/osapp/services/store.service';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { C_PREFIX_BUSINESS, C_PREFIX_INTER_STATE } from '../../app/app.constants';
import { Event } from '../tournee-events/model/event';
import { IOldInterventionStatement } from './models/iintervention-statement';
import { OldInterventionStatement } from './models/intervention-statement';

@Injectable()
export class InterventionStatementService {

	//#region PROPERTIES

	public static C_DEFAULT_SKIP_REASON = "Séance annulée";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store
	) { }

	/** Créé un objet InterventionStatement.
	 * @param psEventId Id du event.
	 * @param paEvents Liste des actes.
	 * @param psIntervenantId Id de l'intervenant en charge des actes.
	 */
	public static createInterventionStatement(pdDate: Date, psEventId: string, psSkipReason: string = InterventionStatementService.C_DEFAULT_SKIP_REASON): OldInterventionStatement {
		const lsSiteGuid: string = IdHelper.getGuidFromId(UserHelper.getCurrentSiteId(), EPrefix.site);
		return plainToClass(OldInterventionStatement, {
			_id: IdHelper.buildChildId(C_PREFIX_INTER_STATE, psEventId.replace(lsSiteGuid, `${lsSiteGuid}${IdHelper.C_VIRTUAL_NODE_SEPARATOR}${DateHelper.toUTCString(pdDate, EUTCAccuracy.minutes)}`), DateHelper.toUTCString(new Date(), EUTCAccuracy.milliseconds)),
			skipReason: psSkipReason
		});
	}

	/** Tri les relevés d'intervention.
	 * @param paInterStates Liste des relevés d'intervention.
	 */
	public static sortInterStateByDate(paInterStates: OldInterventionStatement[]): OldInterventionStatement[] {
		return paInterStates.sort((poInterStateA: OldInterventionStatement, poInterStateB: OldInterventionStatement) => DateHelper.compareTwoDates(poInterStateA.interventionDate, poInterStateB.interventionDate));
	}

	/** Récupère tous les relevés d'intervention. */
	public getInterventionStatements(): Observable<OldInterventionStatement[]> {
		const lsStartKey: string = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
			IdHelper.buildChildId(EPrefix.event, IdHelper.buildVirtualNode([UserHelper.getCurrentSiteId(), C_PREFIX_BUSINESS]), ""),
			""
		);
		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
		};
		return this.isvcStore.get(loIdsDataSource)
			.pipe(
				map((paInterStates: OldInterventionStatement[]) => paInterStates.map((poInterState: OldInterventionStatement) => this.toClass(poInterState)))
			);
	}

	/** Récupère les relevés d'intervention d'un event.
	 * @param psEventId
	 */
	public getEventInterventionStatements(psEventId: string): Observable<OldInterventionStatement[]> {
		const lsStartKey: string = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
			IdHelper.buildChildId(EPrefix.event, psEventId, ""), // TODO : intervention statement, ligne à contrôler
			""
		);
		const lsEventGuid: string = Event.extractGuid(psEventId);

		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`
			}
		};

		return this.isvcStore.get(loIdsDataSource)
			.pipe(
				mergeMap((paDocs: IStoreDocument[]) => {
					const laInterventionStatementKeys: string[] = [];

					paDocs.forEach((poDoc: IStoreDocument) => {
						if (poDoc._id.includes(lsEventGuid))
							laInterventionStatementKeys.push(poDoc._id);
					});

					return this.isvcStore.get({ role: EDatabaseRole.workspace, viewParams: { include_docs: true, keys: laInterventionStatementKeys } });
				}),
				map((paInterStates: IOldInterventionStatement[]) => paInterStates.map((poInterState: OldInterventionStatement) => this.toClass(poInterState)))
			);
	}

	/** Récupère les derniers relevés d'interventions d'un event pour chaque date/heure/minute.
	 * @param psEventId
	 * @param pbLive
	 * @returns
	 */
	public getEventLastInterventionStatements(psEventId: string, pbLive?: boolean): Observable<OldInterventionStatement[]> {
		const lsStartKey: string = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
			IdHelper.buildChildId(EPrefix.event, psEventId, ""), // TODO : intervention statement, ligne à contrôler
			""
		);
		const lsEventGuid: string = Event.extractGuid(psEventId);

		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`
			},
			live: pbLive
		};

		return this.isvcStore.get(loIdsDataSource)
			.pipe(
				switchMap((paDocs: IStoreDocument[]) => {
					const laInterventionStatementKeys: string[] = [];
					const loInterventionStatementsByTime = new Map<string, string[]>();

					paDocs.forEach((poDoc: IStoreDocument) => {
						if (poDoc._id.includes(lsEventGuid)) {
							const lsKey: string = OldInterventionStatement.extractInterventionDateString(poDoc._id);
							const laInterventionStatementIds: string[] = loInterventionStatementsByTime.get(lsKey) ?? [];
							laInterventionStatementIds.push(poDoc._id);
							loInterventionStatementsByTime.set(lsKey, laInterventionStatementIds);
						}
					});

					loInterventionStatementsByTime.forEach((paIds: string[]) => laInterventionStatementKeys.push(ArrayHelper.getLastElement(paIds)));

					return this.isvcStore.get({ role: EDatabaseRole.workspace, viewParams: { include_docs: true, keys: laInterventionStatementKeys }, live: pbLive });
				}),
				map((paInterStates: IOldInterventionStatement[]) => paInterStates.map((poInterState: OldInterventionStatement) => this.toClass(poInterState)))
			);
	}

	/** Récupère les derniers relevés d'interventions pour chaque date/heure/minute.
	 * @param paEventIds
	 * @param pbLive
	 * @returns
	 */
	public getEventsLastInterventionStatements(paEventIds: string[], poRange?: IRange<Date>, pbLive?: boolean): Observable<Map<string, OldInterventionStatement[]>> {
		const lsStartKey: string = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
			IdHelper.buildChildId(EPrefix.event, IdHelper.buildVirtualNode([UserHelper.getCurrentSiteId(), poRange?.from ? DateHelper.toUTCString(poRange.from, EUTCAccuracy.minutes) : "", C_PREFIX_BUSINESS]), ""),
			""
		);
		let lsEndKey: string;
		if (poRange?.to) {
			lsEndKey = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
				IdHelper.buildChildId(EPrefix.event, IdHelper.buildVirtualNode([UserHelper.getCurrentSiteId(), DateHelper.toUTCString(poRange.to, EUTCAccuracy.minutes), C_PREFIX_BUSINESS]), ""),
				""
			);
		}
		else
			lsEndKey = lsStartKey;

		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsEndKey}${Store.C_ANYTHING_CODE_ASCII}`
			},
			live: pbLive
		};

		return this.isvcStore.get(loIdsDataSource)
			.pipe(
				switchMap((paDocs: IStoreDocument[]) => {
					const laInterventionStatementKeys: string[] = [];
					const loInterventionStatementsByTimeAndEventId = new Map<string, string[]>();

					paDocs.forEach((poDoc: IStoreDocument) => {
						const lsEventId: string = OldInterventionStatement.extractEventId(poDoc._id);
						if (paEventIds.includes(lsEventId)) {
							const lsKey: string = OldInterventionStatement.extractInterventionDateString(poDoc._id) + OldInterventionStatement.extractEventId(poDoc._id);
							const laInterventionStatementIds: string[] = loInterventionStatementsByTimeAndEventId.get(lsKey) ?? [];
							laInterventionStatementIds.push(poDoc._id);
							loInterventionStatementsByTimeAndEventId.set(lsKey, laInterventionStatementIds);
						}
					});

					loInterventionStatementsByTimeAndEventId.forEach((paIds: string[]) => laInterventionStatementKeys.push(ArrayHelper.getLastElement(paIds)));

					return this.isvcStore.get({ role: EDatabaseRole.workspace, viewParams: { include_docs: true, keys: laInterventionStatementKeys } });
				}),
				map((paInterStates: IOldInterventionStatement[]) => {
					const loInterventionStatementsByEventId = new Map<string, OldInterventionStatement[]>();

					paInterStates.forEach((poInterState: OldInterventionStatement) => {
						const loInterventionStatement: OldInterventionStatement = this.toClass(poInterState);
						const laEventInterventionStatements: OldInterventionStatement[] = loInterventionStatementsByEventId.get(loInterventionStatement.eventId) ?? [];

						laEventInterventionStatements.push(loInterventionStatement);

						loInterventionStatementsByEventId.set(loInterventionStatement.eventId, laEventInterventionStatements);
					});

					return loInterventionStatementsByEventId;
				})
			);
	}

	/** Sauvegarde un relevé d'intervention.
	 * @param poInterState
	 */
	public saveInterventionStatement(poInterState: OldInterventionStatement): Observable<OldInterventionStatement> {
		const loInterState: OldInterventionStatement = poInterState instanceof OldInterventionStatement ? poInterState : this.toClass(poInterState);
		return this.isvcStore.put(loInterState).pipe(mapTo(loInterState));
	}

	protected toClass(poInterState: IOldInterventionStatement): OldInterventionStatement {
		return plainToClass(OldInterventionStatement, poInterState);
	}

	//#endregion
}
