import { Observable, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ENetworkFlag } from '../../../model/application/ENetworkFlag';
import { IDmsTaskParams } from '../../../model/backgroundTask/taskParams/IDmsTaskParams';
import { TaskDescriptor } from '../../../services/backgroundTask/TaskDescriptor';
import { DmsTaskBase } from './DmsTaskBase';

export class DownloadDmsFileTask<T extends IDmsTaskParams> extends DmsTaskBase<T> {

	//#region FIELDS

	/** Indique le nombre d'essai maximum pour une tâche avant qu'elle ne soit supprimée en abandonnant. */
	private static readonly C_TRY_LIMIT = 10;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
	}

	protected override execTask$(): Observable<Array<string>> {
		return this.msvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(mergeMap(_ => this.msvcSyncDms.execPendingDownload(this.descriptor.params.fileGuid)));
	}

	public override retryStrategy(poErrors$: Observable<any>): Observable<any> {
		return super.retryStrategy(poErrors$.pipe( // Observable qui fournit chaque erreur de la tâche.
			mergeMap((poError: any, pnIndex: number) => {
				if (pnIndex === DownloadDmsFileTask.C_TRY_LIMIT)
					return throwError(() => poError);
				return of(poError);
			})
		));
	}

	//#endregion
}