<calao-entity-entries-list-base [hasDetail]="observableHasDetail.value$ | async"
	[params]="observableParams.value$ | async" [sortKey]="observableSortKey.value$ | async"
	(onDetailClicked)="onDetailClickedAsync($event.item, $event.event)" (onItemClicked)="onItemClickedAsync($event)">
</calao-entity-entries-list-base>

<ion-fab *ngIf="observableCanCreate.value$ | async" vertical="bottom" horizontal="end" slot="fixed"
	class="fab-margin fab-list-text">
	<ion-fab-button class="fab-shadow" (click)="onCreateClickedAsync()">
		<ion-icon name="add"></ion-icon>
	</ion-fab-button>
</ion-fab>