import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Zip } from '@awesome-cordova-plugins/zip/ngx';
import { UnzipService } from './services/unzip.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [UnzipService, Zip]
})
export class UnzipModule { }