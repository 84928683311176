import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateModule } from '@calaosoft/osapp/components/date/date.module';
import { CalaoAvatarModule } from '@calaosoft/osapp/modules/avatar/calao-avatar.module';
import { ModalModule } from '@calaosoft/osapp/modules/modal/modal.module';
import { FavoritesModule } from '@calaosoft/osapp/modules/preferences/favorites/favorites.module';
import { SelectorModule } from '@calaosoft/osapp/modules/selector/selector.module';
import { PipeModule } from '@calaosoft/osapp/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { PlanificationModalComponent } from './components/planification-modal/planification-modal.component';
import { SlotEditModalComponent } from './components/slot-edit-modal/slot-edit-modal.component';
import { PlanningRHComponent } from './planning-rh/planning-rh.component';
import { PlanningRHService } from './services/planning-rh.service';

@NgModule({
	declarations: [
		PlanningRHComponent,
		PlanificationModalComponent,
		SlotEditModalComponent,
	],
	exports: [PlanningRHComponent],
	imports: [
		CommonModule,
		IonicModule,
		MatTableModule,
		MatTooltipModule,
		DateModule,
		PipeModule,
		CalaoAvatarModule,
		ModalModule,
		DragDropModule,
		FormsModule,
		SelectorModule,
		FavoritesModule
	],
	providers: [PlanningRHService]
})
export class PlanningRHModule { }