<!-- Mode edit. -->
<ng-container *ngIf="!params.readOnly">
	<ion-item *ngIf="!params.displayStyle || params.displayStyle === 'list'" lines="none"
		class="ion-no-padding select-item">
		<ion-icon *ngIf="fieldValue && (fieldValue.icon || fieldValue.url)" [name]="fieldValue.icon ? fieldValue.icon : ''"
			[src]="(!fieldValue.icon && fieldValue.url) ? fieldValue.url : ''" [class]="params.iconCss"></ion-icon>
		<ion-select [ngModel]="values" [multiple]="!!params.multiple" (ngModelChange)="change($event)"
			[ngClass]="params.selectCss" [cancelText]="cancelText" class="ion-no-padding">
			<ion-select-option *ngFor=" let option of params.options" [value]="option.value">
				{{option[labelFieldName]}}
			</ion-select-option>
		</ion-select>
	</ion-item>

	<div class="chips-ctn">
		<mat-chip-listbox *ngIf="params.displayStyle === 'tags'" [multiple]="!!params.multiple">
			<mat-chip-option #chip *ngFor="let option of params.options"
				(selectionChange)="onTagSelectionChange($event, option[labelFieldName], option.value)"
				(click)="option.disabled ? {} : chip.toggleSelected(true)" color="primary" [value]="option.value"
				[selected]="selectLabels.includes(option[labelFieldName])" [disabled]="option.disabled">
				<ion-label>{{option[labelFieldName]}}</ion-label>
				<ion-icon *ngIf="chip.selected" name="checkmark"></ion-icon>
			</mat-chip-option>
		</mat-chip-listbox>
	</div>

	<osapp-selector *ngIf="params.displayStyle === 'tags-unique'" [multiple]="false"
		[displayMode]="selectorDisplayMode.tags" [options]="params.options" [preselectedValues]="values"
		(selectionChanged)="change($event)" [scrollWrapper]="false" [min]="params.min">
	</osapp-selector>

	<ng-container *ngIf="params.displayStyle === 'checkbox'">
		<ng-container *ngFor="let option of params.options">
			<div class="checkbox">
				<ion-checkbox labelPlacement="end" [checked]="values.includes(option.value)"
					(ionChange)="onCheckedChange($event, option.value)">
					{{option.label}}
				</ion-checkbox>
			</div>
		</ng-container>
	</ng-container>
</ng-container>

<!-- Mode visu. -->
<div *ngIf="params.readOnly && hasValue()" class="padd-btm-affection pad-top-report">
	<ion-item lines="none" class="ion-no-padding pad-end">
		<div class="text-align-report">
			<ion-label *ngIf='params.label && (label || iconName)' position="stacked"
				class="margin-b label-group txt10px min-w">
				{{params.label}}</ion-label>
			<ion-icon *ngIf="to.icon || (iconName && !params.multiple)" [name]="to.icon || iconName" [class]="params.iconCss">
			</ion-icon>
			<ion-label *ngIf="selectLabels.length > 0 && !!params.multiple" class="ion-text-wrap">
				<ng-container *ngFor="let selectLabel of selectLabels">
					<ion-chip color="primary">
						<ion-label>{{selectLabel}}</ion-label>
					</ion-chip>
				</ng-container>
			</ion-label>
			<div lines="none" class="just-flex marg-infos pad-type">
				<span *ngIf="params.readOnly && label && !params.multiple" [class]="params.className">
					{{label}}
				</span>
			</div>
		</div>
	</ion-item>
</div>