<ion-list>
	<ion-list-header>
		<ion-label>Trier par</ion-label>
	</ion-list-header>
	<ion-radio-group #byGroup [value]="params.by">
		<ion-item lines="none" *ngFor="let option of params.options">
			<ion-radio [value]="option.value">
				{{option.label}}
			</ion-radio>
		</ion-item>
	</ion-radio-group>
</ion-list>

<ion-list>
	<ion-list-header>
		<ion-label>par ordre</ion-label>
	</ion-list-header>
	<ion-radio-group #orderGroup [value]="params.order">
		<ion-item lines="none">
			<ion-radio [value]="sortOrders.ascending">
				Croissant
			</ion-radio>
		</ion-item>
		<ion-item lines="none">
			<ion-radio [value]="sortOrders.descending">
				Décroissant
			</ion-radio>
		</ion-item>
	</ion-radio-group>
</ion-list>

<ion-toolbar>
	<ion-button slot="end" class="fab-label" (click)="validate(byGroup.value, orderGroup.value)">Valider</ion-button>
</ion-toolbar>