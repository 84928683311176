<div *ngIf="observableItem.value$ | async as item" class="container">
	<div>
		<h2>Reporter "{{ item.title }}"</h2>
		<h4 *ngIf="item.startDate as startDate">Prévu le {{ startDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy, HH:mm' }}
		</h4>

		<p>Sélectionnez une nouvelle date pour votre rappel:</p>
		<h3>Date et heure</h3>
		<div *ngIf="observableNewDate.value$ | async as newDate" class="date-wrapper rounded-corners-form">
			<osapp-date-time [params]="observableDatePickerParams.value$ | async" [model]="newDate"
				(modelChange)="onDateChanged($event)" class="picker date"></osapp-date-time>

			<osapp-date-time [params]="observableTimePickerParams.value$ | async" [model]="newDate"
				(modelChange)="onDateChanged($event)" class="picker time"></osapp-date-time>
		</div>
	</div>

	<div class="buttons">
		<ion-button fill="clear" color="medium" shape="round" (click)="close()">
			Annuler
		</ion-button>
		<ion-button class="reschedule-btn" color="primary" shape="round" (click)="close(observableNewDate.value)">
			Reporter
		</ion-button>
	</div>
</div>