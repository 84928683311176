import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IonicModule } from "@ionic/angular";
import { MapComponent } from "./components/map.component";

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		LeafletModule
	],
	declarations: [
		MapComponent
	],
	exports: [
		MapComponent
	]
})
export class CalaoMapModule { }
