import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { IListDefinitionsField } from '../../../forms/models/IListDefinitionsField';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { IEntityEntriesListListItemParams } from '../../models/ientity-entries-list-list-item-params';
import { EntitiesService } from '../../services/entities.service';
import { EntityEntriesListItemBaseComponent } from '../entity-entries-list-item-base/entity-entries-list-item-base.component';

@Component({
	selector: 'calao-entity-entries-list-list-item',
	templateUrl: './entity-entries-list-list-item.component.html',
	styleUrls: ['./entity-entries-list-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityEntriesListListItemComponent<T extends IStoreDocument> extends EntityEntriesListItemBaseComponent<T> {

	//#region PROPERTIES

	/** Doc propriété. */
	@Input() public params?: IEntityEntriesListListItemParams;
	@ObserveProperty<EntityEntriesListListItemComponent<T>>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<IEntityEntriesListListItemParams>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(private readonly isvcEntities: EntitiesService) {
		super();
	}

	public getText(poListField: IListDefinitionsField): string | undefined {
		return this.isvcEntities.getEntityListFieldText(this.item, poListField);
	}

	//#endregion METHODS

}
