<div class="container">

	<ng-container
		*ngTemplateOutlet="(observableDisplayCustomReminder.value$ | async) ? customReminders : defaultReminders"></ng-container>

	<div class="buttons">
		<ion-button (click)="cancelAsync()" class="cancel-button">Annuler</ion-button>
		<ion-button [disabled]="observableDisableSubmit.value$ | async" (click)="validateAsync()"
			class="submit-button">Enregistrer</ion-button>
	</div>
</div>

<ng-template #defaultReminders>
	<ion-radio-group value="15" (ionChange)="onChange($event)">
		<ion-list-header>
			<ion-label>Ajouter un rappel ...</ion-label>
		</ion-list-header>

		<ion-item lines="none">
			<ion-radio value="15" labelPlacement="end">
				15 minutes avant
			</ion-radio>
		</ion-item>

		<ion-item lines="none">
			<ion-radio value="60" labelPlacement="end">
				1 heure avant
			</ion-radio>
		</ion-item>

		<ion-item lines="none">
			<ion-radio value="120" labelPlacement="end">
				2 heures avant
			</ion-radio>
		</ion-item>

		<ion-item lines="none">
			<ion-radio value="1440" labelPlacement="end">
				1 jour avant
			</ion-radio>
		</ion-item>

		<ion-item lines="none">
			<ion-radio value="custom" labelPlacement="end">
				Personnalisé
			</ion-radio>
		</ion-item>

	</ion-radio-group>
</ng-template>

<ng-template #customReminders>
	<ion-radio-group value="minutes" (ionChange)="onChange($event)">
		<ion-list-header>
			<ion-label>Rappel personnalisé</ion-label>
		</ion-list-header>

		<ion-input [value]="observableCustomValue.value$ | async" type="number" min="1" step="1" class="duration"
			(ionInput)="onCustomValueChanged($event)"></ion-input>

		<ion-item lines="none">
			<ion-radio value="minutes" labelPlacement="end">
				minutes avant
			</ion-radio>
		</ion-item>

		<ion-item lines="none">
			<ion-radio value="hours" labelPlacement="end">
				heures avant
			</ion-radio>
		</ion-item>

		<ion-item lines="none">
			<ion-radio value="days" labelPlacement="end">
				jours avant
			</ion-radio>
		</ion-item>

		<ion-item lines="none">
			<ion-radio value="weeks" labelPlacement="end">
				semaines avant
			</ion-radio>
		</ion-item>

	</ion-radio-group>
</ng-template>