import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertButton, ModalController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { PathHelper } from '../../../../helpers/path-helper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { GalleryFile } from '../../../../model/gallery/gallery-file';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '../../../../services/platform.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { DmsService } from '../../../dms/services/dms.service';
import { Entity } from '../../../entities/models/entity';
import { EntitiesService } from '../../../entities/services/entities.service';
import { ModalComponentBase } from '../../../modal';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { DocExplorerConfig } from '../../models/doc-explorer-config';
import { Document } from '../../models/document';
import { EAddDocumentModaleDocumentType } from '../../models/eadd-document-modale-document-type';
import { EAddDocumentModaleStep } from '../../models/eadd-document-modale-step';
import { Folder } from '../../models/folder';
import { FolderContent } from '../../models/folder-content';
import { IAddDocumentParams } from '../../models/iadd-document-modal-params';
import { IDocumentType } from '../../models/idocument-type';
import { DocExplorerDocumentsService } from '../../services/doc-explorer-documents.service';

@Component({
	selector: 'calao-add-document-modal',
	templateUrl: './add-document-modal.component.html',
	styleUrls: ['./add-document-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDocumentModalComponent extends ModalComponentBase<Document | undefined> implements IAddDocumentParams, OnInit {

	//#region FIELDS

	/** Configuration du docExplorer. */
	private readonly moObservableDocExplorerConfig = new ObservableProperty<DocExplorerConfig>();

	/** Titre personnalisé du document. */
	private readonly moObservableDocumentTitle = new ObservableProperty<string>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** @implements */
	@Input() public path?: string;
	@ObserveProperty<AddDocumentModalComponent>({ sourcePropertyKey: "path" })
	public readonly observablePath = new ObservableProperty<string | undefined>();

	/** @implements */
	@Input() public parentEntity?: Entity;
	@ObserveProperty<AddDocumentModalComponent>({ sourcePropertyKey: "parentEntity" })
	public readonly observableParentEntity = new ObservableProperty<Entity | undefined>();

	/** @implements */
	@Input() public documentTypes?: IDocumentType;
	@ObserveProperty<AddDocumentModalComponent>({ sourcePropertyKey: "documentTypes" })
	public readonly observableDocumentTypes = new ObservableProperty<IDocumentType | undefined>();

	/** @implements */
	@Input() public step?: EAddDocumentModaleStep;
	@ObserveProperty<AddDocumentModalComponent>({ sourcePropertyKey: "step" })
	public readonly observableCurrentStep = new ObservableProperty<EAddDocumentModaleStep>(EAddDocumentModaleStep.entitySelection);

	@Input() public activatedRoute: ActivatedRoute;

	/** Catégorie de l'entité parent. */
	public readonly observableParentEntityCategory = new ObservableProperty<string | undefined>();

	/** Liste des options. */
	public readonly observableFoldersOptions = new ObservableArray<ISelectOption<Folder>>([]);

	/** Dossier courant. */
	public readonly observableCurrentFolder = new ObservableProperty<Folder | undefined>();

	/** Dossier sélectionné. */
	public readonly observableSelectedFolder = new ObservableProperty<Folder | undefined>();

	/** Indique si on a la possibilité . */
	public readonly observableHasForm = new ObservableProperty<boolean>(false);

	/** Etape suivante sélectionnée. */
	public readonly observableSelectedNextStep = new ObservableProperty<EAddDocumentModaleStep>(EAddDocumentModaleStep.entitySelection);

	/** Mode de sélection. */
	public readonly selectorDisplayMode = ESelectorDisplayMode;

	/** Libellé du bouton submit de la modale. */
	public readonly observableSubmitButtonLabel = new ObservableProperty<string>("Créer");

	public readonly ECurrentStep = EAddDocumentModaleStep;
	public readonly EDocumentType = EAddDocumentModaleDocumentType;

	/** Type de document sélectionné. */
	public readonly observableSelectedDocumentType = new ObservableProperty<EAddDocumentModaleDocumentType | undefined>();

	/** Fichier/image venant du composant Gallery à uploader. */
	public readonly observableGalleryFile = new ObservableProperty<GalleryFile>();

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		private readonly isvcDms: DmsService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableParentEntity.value$.pipe(
			filter((poEntity?: Entity) => !!poEntity),
			tap((poEntity: Entity) => this.observableParentEntityCategory.value = this.isvcEntities.getEntityName(poEntity)),
			secure(this)
		).subscribe();

		this.observablePath.value$.pipe(
			filter((psPath?: string) => !!psPath),
			map((psPath: string) => PathHelper.parsePath(psPath)),
			mergeMap((psPath: string) => this.isvcDocExplorerDocuments.getFolderContent$(psPath)),
			map((poFolderContent: FolderContent) => {
				const laOptions: ISelectOption<Folder>[] = [];
				poFolderContent.folders.forEach((poSubFolder: FolderContent) => {
					if (this.isvcDocExplorerDocuments.checkFolderPermissions(poSubFolder.current.path, "create", false))
						laOptions.push({ label: poSubFolder.current.name, value: poSubFolder.current });
				});

				this.observableFoldersOptions.resetArray(laOptions);

				if (!this.observableFoldersOptions.length)
					this.observableSelectedFolder.value = poFolderContent.current;
			}),
			secure(this)
		).subscribe();

		this.observableSelectedFolder.value$.pipe(
			tap((poFolder?: Folder) => {
				const pbHasForm: boolean = ArrayHelper.hasElements(poFolder?.documentTypes.forms);
				this.observableHasForm.value = pbHasForm;
			}),
			secure(this)
		).subscribe();

		this.isvcDocExplorerDocuments.getConfig$().pipe(
			tap((poDocExplorerConfig: DocExplorerConfig) => this.moObservableDocExplorerConfig.value = poDocExplorerConfig),
			secure(this)
		).subscribe();

		this.observableHasForm.value$.pipe(
			tap((pbHasForm: boolean) => {
				this.observableSelectedDocumentType.value = pbHasForm ? EAddDocumentModaleDocumentType.form : EAddDocumentModaleDocumentType.file;
			})
		).subscribe();

		this.observableSelectedDocumentType.value$.pipe(
			tap((psSelectedDocumentType: EAddDocumentModaleDocumentType) => {
				if (this.observableCurrentStep.value === EAddDocumentModaleStep.typeSelection)
					this.observableSubmitButtonLabel.value = (psSelectedDocumentType === EAddDocumentModaleDocumentType.form) ? "Suivant" : "Créer"
			}),
			secure(this)
		).subscribe();
	}

	public onFolderChanged(paSelectedFolders: Folder[]): void {
		this.observableSelectedFolder.value = ArrayHelper.getFirstElement(paSelectedFolders);
	}

	public onNextStepChanged(paSelectedNextStep: EAddDocumentModaleStep[]): void {
		this.observableSelectedNextStep.value = ArrayHelper.getFirstElement(paSelectedNextStep);
	}

	public onSubmitAsync(): Promise<boolean> {
		return this.submit$().toPromise();
	}

	@Queue<AddDocumentModalComponent, Parameters<AddDocumentModalComponent["submit$"]>, ReturnType<AddDocumentModalComponent["submit$"]>>({
		excludePendings: true
	})
	private submit$(): Observable<any> {
		return this.observableSelectedFolder.value$.pipe(
			take(1),
			mergeMap((poFolder: Folder) => {

				this.observableDocumentTypes.value = this.isvcDocExplorerDocuments.getMatchingPathFolderConfig(
					poFolder.path,
					this.moObservableDocExplorerConfig.value
				)?.documentTypes;

				if (this.observableCurrentStep.value === EAddDocumentModaleStep.typeSelection) {
					if (this.observableSelectedDocumentType.value === EAddDocumentModaleDocumentType.form)
						return this.addFromForm$(poFolder);
					else if (this.observableSelectedDocumentType.value === EAddDocumentModaleDocumentType.file)
						return this.saveFileAsync(poFolder);
				}
				else if (this.observableCurrentStep.value === EAddDocumentModaleStep.entitySelection) {
					this.observableSelectedFolder.value = poFolder;

					if (this.observableDocumentTypes.value?.forms)
						this.observableSubmitButtonLabel.value = "Suivant";

					if (ArrayHelper.hasElements(this.observableDocumentTypes.value?.forms) && !this.observableDocumentTypes.value?.files)
						return this.addFromForm$(poFolder);
					else
						this.observableCurrentStep.value = EAddDocumentModaleStep.typeSelection;
				}

				return of(true);
			})
		);
	}

	private addFromForm$(poFolder: Folder): Observable<boolean> {
		return this.isvcDocExplorerDocuments.addFromForm$(poFolder, this.activatedRoute).pipe(
			mergeMap((poDocument: Document | undefined) => {
				return this.close(poDocument);
			})
		);
	}

	private async saveFileAsync(poFolder: Folder): Promise<boolean> {
		const loFile: GalleryFile | undefined = this.observableGalleryFile.value;
		if (loFile && loFile.file) {
			if (this.moObservableDocumentTitle.value)
				loFile.file.Name = `${this.moObservableDocumentTitle.value}.${loFile.file.getExtension()}`;
			try {
				await this.isvcDms.save(loFile.file, loFile.file.createDmsMeta(loFile.guid, undefined, poFolder.lastPathPart, [poFolder.path])).toPromise();
			} catch (error) {
				this.isvcUiMessage.showMessage(
					new ShowMessageParamsPopup({
						message: "Une erreur s'est produite lors de l'enregistrement.",
						header: "Erreur",
						buttons: [
							{ text: "OK", handler: () => UiMessageService.getTruthyResponse() } as AlertButton,
						]
					})
				);
			}
		}
		return this.close();
	}

	public getName(poEntity: Entity): string {
		return this.isvcEntities.getEntityName(poEntity);
	}

	public onDocumentTypeChanged(poEvent: Event) {
		const loEvent: CustomEvent = poEvent as CustomEvent;
		this.observableSelectedDocumentType.value = loEvent.detail.value;
	}

	public onClick(poEvent: Event): void {
		poEvent.stopPropagation();
	}

	public onFilesChanged(paGalleryFiles: GalleryFile[]): void {
		this.observableGalleryFile.value = ArrayHelper.getFirstElement(paGalleryFiles);
	}

	public updateTitle(poEvent: Event): void {
		poEvent.stopPropagation();
		const lsTitle: string = (poEvent as CustomEvent).detail.value;
		if (!StringHelper.isBlank(lsTitle))
			this.moObservableDocumentTitle.value = lsTitle;
	}

	//#endregion

}
