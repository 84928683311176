import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { Observable } from 'rxjs';
import { C_COLOR_EVENT_DEFAULT, C_COLOR_EVENT_ITEM_START } from '../../../../app/app.constants';
import { TradeEventsService } from '../../services/trade-events.service';

@Component({
	selector: 'trade-event-item',
	templateUrl: './event-item.component.html',
	styleUrls: ['./event-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventItemComponent {

	//#region PROPERTIES

	public readonly eventItemStartColor = C_COLOR_EVENT_ITEM_START;
	public readonly eventItemColor = C_COLOR_EVENT_DEFAULT;

	/** Occurrence de l'évènement. */
	@Input() public item?: BaseEventOccurrence;
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "item" })
	public readonly observableItem = new ObservableProperty<BaseEventOccurrence>();

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcTradeEvents: TradeEventsService,
		private readonly ioActivatedRoute: ActivatedRoute
	) { }

	public getEventIcon$(poEvent: BaseEventOccurrence): Observable<string> {
		return this.isvcTradeEvents.getIconNameFromSubtype$(poEvent);
	}

	public getEventTypeLabel$(poEvent: BaseEventOccurrence): Observable<string> {
		return this.isvcTradeEvents.getShortTypeLabelFromSubtype$(poEvent);
	}

	/** Navigue vers la page de visualisation d'un évènement. */
	public routeToViewEvent(poEventOccurrence: BaseEventOccurrence): void {
		this.isvcTradeEvents.routeToViewEventAsync(poEventOccurrence, this.ioActivatedRoute);
	}

	/** Navigue vers la page d'édition d'un évènement. */
	public routeToEditEvent(poEventOccurrence: BaseEventOccurrence): void {
		this.isvcTradeEvents.routeToEditEventAsync(poEventOccurrence);
	}

	/** Retourne le nom de l'icône à afficher avant le titre.
	 * @param poEventOccurrence Evènement à afficher dans l'item.
	 */
	public getTitleIcon(poEventOccurrence: BaseEventOccurrence): string | undefined {
		return poEventOccurrence.event.private ? "lock-closed" : undefined;
	}

	//#endregion

}
