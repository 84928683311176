import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { combineLatest, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { IDataSource } from '../../../../model/store/IDataSource';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { PatternResolverService } from '../../../../services/pattern-resolver.service';
import { PlatformService } from '../../../../services/platform.service';
import { IDocExlorerFilterValues } from '../../../doc-explorer/models/idoc-explorer-filter-values';
import { ModalComponentBase } from '../../../modal';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { secure } from '../../../utils/rxjs/operators/secure';
import { IEntityDescriptor } from '../../models/ientity-descriptor';
import { EntitiesService } from '../../services/entities.service';
import { IEntityPickerFilterValues } from './components/entity-picker-filter-bar-component/entity-picker-filter-bar-component.component';

@Component({
	selector: 'calao-entity-picker-modal',
	templateUrl: './entity-picker-modal.component.html',
	styleUrls: ['./entity-picker-modal.component.scss'],
})
export class EntityPickerModalComponent<T extends IStoreDocument> extends ModalComponentBase<string> implements OnInit {

	//#region FIELDS

	private msNamePattern?: string;
	private moEntityNameById = new Map<string, string>();

	/** Filtres. */
	private readonly moObservableFilterValues = new ObservableProperty<IEntityPickerFilterValues>({});

	//#endregion FIELDS

	//#region PROPERTIES

	@Input() public entityDescId?: string;
	@ObserveProperty<EntityPickerModalComponent<T>>({ sourcePropertyKey: "entityDescId" })
	public readonly observableEntityDescId = new ObservableProperty<string>();

	@Input() public dataSource?: IDataSource<T>;
	@ObserveProperty<EntityPickerModalComponent<T>>({ sourcePropertyKey: "dataSource" })
	public readonly observableDataSource = new ObservableProperty<IDataSource<T>>();

	public readonly observableEntries = new ObservableArray<T>();
	public readonly observableFilteredEntries = new ObservableArray<T>();

	public readonly observablePickerDescription = new ObservableProperty<string>("Sélection");
	public readonly observableSearchboxPlaceholder = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntities: EntitiesService,
		private readonly isvcPatternResolver: PatternResolverService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableEntityDescId.value$.pipe(
			filter((psEntityDescId?: string) => !StringHelper.isBlank(psEntityDescId)),
			switchMap((psEntityDescId: string) => this.isvcEntities.getDescriptor$(psEntityDescId)),
			switchMap((poDesc: IEntityDescriptor) => {
				this.msNamePattern = poDesc.namePattern;
				if (poDesc.defaultsLabels?.pickerDescription)
					this.observablePickerDescription.value = poDesc.defaultsLabels.pickerDescription;
				if (poDesc.defaultsLabels?.searchboxPlaceholder)
					this.observableSearchboxPlaceholder.value = poDesc.defaultsLabels.searchboxPlaceholder;
				if (ArrayHelper.hasElements(this.observableEntries))
					return of(this.observableEntries);
				else {
					return this.isvcEntities.getEntries$(this.observableDataSource.value ?? this.isvcEntities.getDataSource(poDesc)).pipe(
						tap((paEntities: T[]) => this.observableEntries.resetArray(paEntities))
					);
				}
			}),
			secure(this)
		).subscribe();

		combineLatest([this.observableEntries.changes$, this.moObservableFilterValues.value$]).pipe(
			tap(([paEntities, poFilters]: [T[], IDocExlorerFilterValues]) => {
				if (!StringHelper.isBlank(poFilters.name)) {
					this.observableFilteredEntries.resetArray(paEntities.filter((poEntity: T) => {
						return this.getEntityName(poEntity).toLowerCase().includes(poFilters.name!.toLowerCase());
					}));
				}
				else
					this.observableFilteredEntries.resetArray(paEntities);
			}),
			secure(this)
		).subscribe();
	}

	public getEntityName(poEntity: T): string {
		let lsEntityName: string | undefined = this.moEntityNameById.get(poEntity._id);

		if (!StringHelper.isBlank(lsEntityName))
			return lsEntityName;

		this.moEntityNameById.set(poEntity._id, lsEntityName = this.isvcPatternResolver.resovleStringPattern(this.msNamePattern, { entry: poEntity }));

		return lsEntityName;
	}

	public onFilterValuesChange(poFilterValues: IDocExlorerFilterValues): void {
		this.moObservableFilterValues.value = poFilterValues;
	}

	//#endregion METHODS

}
