import { ComponentRef, ModalOptions } from '@ionic/core';
import { ISendMailParams } from '../../components/link/models/isendmail-params';
import { IPermission } from '../../modules/permissions/models/ipermission';
import { ELinkAction } from './ELinkAction';
import { ELinkTemplate } from './ELinkTemplate';
import { ILinkInfo } from './ILinkInfo';

export class LinkInfo implements ILinkInfo {

	//#region PROPERTIES

	/** Format du json utilisé. */
	public meta?: { schemaVersion: string };
	/** Identifiant du lien. */
	public id?: string;
	/** Label affiché du lien. */
	public label: string;
	/** Identifiant du template html utilisé. */
	public templateId: ELinkTemplate = ELinkTemplate.default;
	/** Nom du template utilisé. */
	public templateName = "default template name";
	/** Paramètres du lien (en fonction du type de lien utilisé). */
	public params?: any;
	/** Action à réaliser par le lien. */
	public action?: ELinkAction;
	/** Informations sur la page de destination. */
	public actionParams?: any;
	/** Permet de cacher le lien. */
	public hidden?: boolean;
	/** Couleur du bouton du lien. */
	public color?: string;
	/** Classe Css de l'item. */
	public cssClass?: string;
	/** @implements */
	public marker?: string;
	/** @implements */
	public permissions?: IPermission[];
	/** @implements */
	public icon?: string;
	/** @implements */
	public sendMailParams?: ISendMailParams;
	/** @implements */
	public readonly modalOptions?: ModalOptions<ComponentRef>;

	//#endregion

	//#region METHODS

	constructor(poLinkInfo: ILinkInfo) {
		for (const lsKey in poLinkInfo) {
			if (lsKey !== "destination")
				this[lsKey] = poLinkInfo[lsKey];
		}
	}

	//#endregion
}