import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { EnumHelper } from '@calaosoft/osapp/helpers/enumHelper';
import { NumberHelper } from '@calaosoft/osapp/helpers/numberHelper';
import { ObjectHelper } from '@calaosoft/osapp/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { ESortOrder } from '@calaosoft/osapp/model/ESortOrder';
import { IGeolocData } from '@calaosoft/osapp/model/navigation/IGeolocData';
import { GeolocationHelper } from '@calaosoft/osapp/modules/geolocation/helpers/geolocation.helper';
import { ObserveArray } from '@calaosoft/osapp/modules/observable/decorators/observe-array.decorator';
import { ObservableArray } from '@calaosoft/osapp/modules/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { HasPermissions } from '@calaosoft/osapp/modules/permissions/decorators/has-permissions.decorator';
import { EPermission } from '@calaosoft/osapp/modules/permissions/models/EPermission';
import { ESelectorDisplayMode } from '@calaosoft/osapp/modules/selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '@calaosoft/osapp/modules/selector/selector/ISelectOption';
import { ESortSelectorSize } from '@calaosoft/osapp/modules/sort/models/esort-selector-size';
import { ISortOption } from '@calaosoft/osapp/modules/sort/models/isort-option';
import { ISortSelectorResult } from '@calaosoft/osapp/modules/sort/models/isort-selector-result';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { secure } from '@calaosoft/osapp/modules/utils/rxjs/operators/secure';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { C_BUSINESS_PERMISSION_ID } from '../../../../app/app.constants';
import { Business } from '../../model/business';
import { ETranslatedProspectState } from '../../model/etranslated-prospect-state';

interface IFilterValues {
	text?: string;
	priority?: number;
	types?: string[];
	legalIdentity?: boolean[];
	position?: IGeolocData;
	locationRange?: number;
	sort?: ISortSelectorResult<keyof Business>;
	prospectStates?: string[];
}

@Component({
	selector: 'trade-businesses-list',
	templateUrl: './businesses-list.component.html',
	styleUrls: ['./businesses-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessesListComponent extends DestroyableComponentBase {

	//#region FIELDS

	/** Événement lors du click sur la suppression d'un business. */
	@Output("onDeleteItemClicked") private readonly moDeleteItemClickedEvent = new EventEmitter<Business>();
	@Output("onItemClicked") private readonly moItemClickedEvent = new EventEmitter<Business>();
	@Output("onItemEditClicked") private readonly moItemEditClickedEvent = new EventEmitter<Business>();

	private static readonly C_DEFAULT_GEO_VALUE = 10;
	private static readonly C_DEFAULT_GEO_MIN = 5;
	private static readonly C_DEFAULT_GEO_MAX = 100;
	private static readonly C_DEFAULT_PRIO = 5;

	//#endregion

	//#region PROPERTIES

	public readonly geoDefault: number = BusinessesListComponent.C_DEFAULT_GEO_VALUE;
	public readonly geoMin: number = BusinessesListComponent.C_DEFAULT_GEO_MIN;
	public readonly geoMax: number = BusinessesListComponent.C_DEFAULT_GEO_MAX;
	public readonly defaultPrio: number = BusinessesListComponent.C_DEFAULT_PRIO;

	public readonly sortOptions: ISortOption<keyof Business>[] = [
		{ label: "Nom", value: "name" },
		{ label: "Priorité", value: "priority" },
		{ label: "Ville", value: "city" },
		{ label: "Enseigne", value: "tradingName" },
	];

	public readonly defaultSortBy: keyof Business = "name";
	public readonly defaultSortOrder = ESortOrder.ascending;

	/** Doc propriété. */
	@Input() public businesses?: Business[] | null;
	@ObserveArray<BusinessesListComponent>("businesses")
	public readonly observableBusinesses = new ObservableArray<Business>();

	public readonly observableFilterValues = new ObservableProperty<IFilterValues>(this.getDefaultFilterValues());
	public readonly observableNbTmpResults = new ObservableProperty<number>(0);
	public readonly observableHasLocation = new ObservableProperty<boolean>();
	public readonly observableGeoRangeLabel = new ObservableProperty<string>();

	public nbMoreFiltersLabel$: Observable<string | undefined> = this.getNbMoreFiltersLabel$().pipe(secure(this));
	public nbFiltersLabel$: Observable<string | undefined> = this.getNbFiltersLabel$().pipe(secure(this));

	public readonly filteredBusinesses$: Observable<Business[]> = this.getFilteredBusinesses$().pipe(secure(this));

	public readonly typeSelectOptions: ISelectOption[] = this.getTypeSelectOptions();
	public readonly legalIdentitySelectOptions: ISelectOption[] = this.getLegalIdentitySelectOptions();
	public readonly prospectStateSelectOptions: ISelectOption[] = this.getProspectStateSelectOptions();

	public readonly selectorDisplayMode: ESelectorDisplayMode = ESelectorDisplayMode.alert;
	public readonly sortSize = ESortSelectorSize.small;

	public readonly permissionScope?: EPermission = C_BUSINESS_PERMISSION_ID;

	@HasPermissions({ permission: "edit" })
	public get canEditBusiness(): boolean { return true; }

	@HasPermissions({ permission: "delete" })
	public get canDeleteBusiness(): boolean { return true; }

	/** Définition des couleurs par valeur. */
	public readonly observableColors = new ObservableProperty<Record<string, string>>(this.getColors());

	//#endregion

	//#region METHODS

	constructor() {
		super();

		this.observableGeoRangeLabel.bind(
			this.observableFilterValues.value$.pipe(
				map((poValues: IFilterValues) => `${poValues.locationRange ?? BusinessesListComponent.C_DEFAULT_GEO_VALUE} km`)
			),
			this
		);

		this.observableHasLocation.bind(
			this.observableFilterValues.value$.pipe(
				map((poValues: IFilterValues) => !!poValues.position)
			),
			this
		);
	}

	private getDefaultFilterValues(): IFilterValues {
		return { priority: this.defaultPrio };
	}

	public onFilterValuesChange(poFilterValues: IFilterValues): void {
		this.observableNbTmpResults.value = 0;
		this.observableFilterValues.value = ObjectHelper.isEmpty(poFilterValues) ? this.getDefaultFilterValues() : poFilterValues;
	}

	public onTmpFilterValuesChange(poFilterValues: IFilterValues): void {
		this.observableNbTmpResults.value = this.filterDocuments(this.observableBusinesses, poFilterValues).length;
	}

	private filterDocuments(
		paDocuments: Business[],
		poFilterValues: IFilterValues
	): Business[] {
		// Filtrage par texte
		if (!StringHelper.isBlank(poFilterValues.text)) {
			paDocuments = paDocuments.filter((poBusiness: Business) => {
				const laWords: string[] = poFilterValues.text!.toLocaleLowerCase().trim().split(" ").filter((psWord: string) => StringHelper.isValid(psWord));

				return laWords.every((psText: string) =>
					poBusiness.name?.toLocaleLowerCase().includes(psText) ||
					poBusiness.businessCode?.includes(psText) ||
					poBusiness.city?.toLocaleLowerCase().includes(psText) ||
					poBusiness.tradingName?.toLocaleLowerCase().includes(psText)
				);
			});
		}

		if (NumberHelper.isValid(poFilterValues.priority)) {
			paDocuments = paDocuments.filter((poBusiness: Business) =>
				(poBusiness.priority ?? 0) <= (poFilterValues.priority ?? NaN)
			);
		}

		if (ArrayHelper.hasElements(poFilterValues.types)) {
			paDocuments = paDocuments.filter((poBusiness: Business) =>
				poFilterValues.types?.some((psLabel: string) => poBusiness.typesLabels.includes(psLabel))
			);
		}

		if (ArrayHelper.hasElements(poFilterValues.prospectStates)) {
			paDocuments = paDocuments.filter((poBusiness: Business) =>
				poFilterValues.prospectStates?.some((psLabel: string) => ETranslatedProspectState[poBusiness.prospectState] === psLabel)
			);
		}

		if (ArrayHelper.hasElements(poFilterValues.legalIdentity)) {
			paDocuments = paDocuments.filter((poBusiness: Business) =>
				!ObjectHelper.isDefined(poBusiness.isPro) ||
				poFilterValues.legalIdentity?.includes(poBusiness.isPro)
			);
		}

		if (poFilterValues.position) {
			const loPosition: IGeolocData = poFilterValues.position;
			paDocuments = paDocuments.filter((poBusiness: Business) => {
				let lnDistance: number | undefined;
				if (poBusiness.latitude && poBusiness.longitude && loPosition.latitude && loPosition.longitude) {
					lnDistance = GeolocationHelper.calculateDistanceBetweenCoordinatesKm(
						+loPosition.latitude,
						+loPosition.longitude,
						poBusiness.latitude,
						poBusiness.longitude
					);
				}

				return NumberHelper.isValid(lnDistance) && lnDistance < (poFilterValues.locationRange ?? 0);
			});
		}

		return this.sortDocuments(paDocuments, poFilterValues);
	}

	private sortDocuments(paDocuments: Business[], poFilterValues: IFilterValues): Business[] {
		return ArrayHelper.dynamicSort(
			paDocuments,
			poFilterValues.sort?.by ?? this.defaultSortBy,
			poFilterValues.sort?.order ?? this.defaultSortOrder
		).sort((poBusinessA: Business, poBusinessB: Business) =>
			ArrayHelper.compareByExistingProperty(poBusinessB, poBusinessA, "_conflicts")
		);
	}

	private getFilteredBusinesses$(): Observable<Business[]> {
		return combineLatest([
			this.observableBusinesses.changes$,
			this.observableFilterValues.value$
		]).pipe(
			map(([paBusinesses, poFilterValues]: [Business[], IFilterValues]) =>
				this.filterDocuments(
					paBusinesses,
					poFilterValues
				)
			)
		);
	}

	private getTypeSelectOptions(): ISelectOption[] {
		return Business.types.map((psType: string) => ({ label: psType, value: psType }));
	}

	private getProspectStateSelectOptions(): ISelectOption[] {
		return EnumHelper.getValues(ETranslatedProspectState).map((psState: string) => ({ label: psState, value: psState }));
	}

	private getLegalIdentitySelectOptions(): ISelectOption[] {
		return [
			{
				label: "Société",
				value: true
			},
			{
				label: "Individu",
				value: false
			}
		];
	}

	public onDeleteItemClicked(poBusiness: Business): void {
		this.moDeleteItemClickedEvent.emit(poBusiness);
	}

	public onItemClicked(poBusiness: Business): void {
		this.moItemClickedEvent.emit(poBusiness);
	}

	public onItemEditClicked(poBusiness: Business): void {
		this.moItemEditClickedEvent.emit(poBusiness);
	}

	public getNbMoreFiltersLabel$(): Observable<string | undefined> {
		return this.observableFilterValues.value$.pipe(
			map((poValues: IFilterValues) => {
				const lnFilterNumber: number = Object.keys(
					ObjectHelper.omit(poValues, ["sort", "locationRange", "position"])
				).filter((psKey: keyof IFilterValues) => this.checkIfFilterIsSet(poValues, psKey)).length;

				return lnFilterNumber > 0 ? `+${lnFilterNumber}` : undefined;
			})
		);
	}

	public getNbFiltersLabel$(): Observable<string | undefined> {
		return this.observableFilterValues.value$.pipe(
			map((poValues: IFilterValues) => {
				const lnFilterNumber: number = Object.keys(
					ObjectHelper.omit(poValues, ["sort", "locationRange", "text"])
				).filter((psKey: keyof IFilterValues) => this.checkIfFilterIsSet(poValues, psKey)).length;

				return lnFilterNumber > 0 ? `${lnFilterNumber}` : undefined;
			})
		);
	}

	private checkIfFilterIsSet(poValues: IFilterValues, psKey: keyof IFilterValues): boolean {
		if (psKey === "priority")
			return poValues[psKey] !== BusinessesListComponent.C_DEFAULT_PRIO;

		const loValue: any = poValues[psKey];

		if (loValue instanceof Array)
			return ArrayHelper.hasElements(loValue);

		return ObjectHelper.isDefined(poValues[psKey]);
	}

	private getColors(): Record<string, string> {
		return {
			"1": "--ion-color-danger",
			"2": "--ion-color-danger",
			"3": "--ion-color-task",
			"4": "--ion-color-task",
			"5": "--ion-color-success"
		};
	}

	//#endregion

}