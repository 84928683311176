import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormModule } from '../../components/forms/form/form.module';
import { SearchModule } from '../../components/search/search.module';
import { SharedComponentsModule } from '../../components/sharedComponents.module';
import { PipeModule } from '../../pipes/pipes.module';
import { ModalModule } from '../modal/modal.module';
import { SectorsService } from '../sectors/services/sectors.service';
import { SiteDetailsComponent } from './components/site-details/site-details.component';
import { SiteSelectorModalComponent } from './components/site-selector-modal/site-selector-modal.component';
import { SitesListComponent } from './components/sites-list/sites-list.component';
import { SitesService } from './services/sites.service';

const components: Type<any>[] = [
	SitesListComponent,
	SiteDetailsComponent,
	SiteSelectorModalComponent
];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		IonicModule,
		SearchModule,
		SharedComponentsModule,
		PipeModule,
		ModalModule,
		FormModule
	],
	exports: components,
	providers: [SitesService, SectorsService]
})
export class SitesModule { }