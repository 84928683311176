import { Injectable } from '@angular/core';
import { Message } from '../modules/conversations/model/message';
import { TextGenerationResponse } from '../modules/forms/models/text-generation-response';
import { DestroyableServiceBase } from '../modules/services/models/destroyable-service-base';
import { AiGenerationService } from './ai-generation.service';
import { ITextGenerationRequest } from './interfaces/itext-generation-request';
import { TokenizerService } from './tokenizer.service';

@Injectable({ providedIn: "root" })
export class AiConversationService extends DestroyableServiceBase {

	//#region PROPERTIES

	private readonly CN_MAX_TOKEN_FOR_PROMPT = 14000;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcAiGeneration: AiGenerationService,
		private readonly isvcTokenizer: TokenizerService,
	) {
		super();
	}



	public generateConversationSummary(paMessages: string[], psTitle: string): Promise<TextGenerationResponse> {
		const loBody: ITextGenerationRequest = {
			prompt: {
				user: paMessages.join("")
			}
		};

		// On sélectionne un prompt système selon le nombre de jetons de la conversation.
		if (this.isvcTokenizer.countTokenTextGenerationRequest(loBody) > 500) {
			loBody.prompt.system = `Tu vas recevoir une conversation par message sous la forme [user] : \`\`[message]\`\`\nTu dois fournir un résumé sous forme de liste non ordonnée en suivant ces consignes :\n- Reste concis et clair\n- N'oublie pas les points essentiels\n- Repose strictement sur les informations fournies\n- N'essaie pas de transformer les abréviations\n- Met en avant des grandes catégories avec des sous points dans la liste\n`;
		}
		else {
			loBody.prompt.system = `Tu vas recevoir une conversation au sein d'une messagerie d'entreprise sous la forme [user] : \`\`[message]\`\`Tu dois fournir un résumé synthétique sous forme de liste. Ecris seulement les sujets abordés. Reste très concis. ${psTitle ? `Titre de la conversation : ${psTitle}.` : ""} Commence le résumé par : "Sujets abordés :"`;
		}

		// Si on dépasse le nombre de jetons limite, on supprime le message le plus ancien et on reteste.
		while (paMessages.length !== 0 && this.isvcTokenizer.countTokenTextGenerationRequest(loBody) > this.CN_MAX_TOKEN_FOR_PROMPT) {
			paMessages.shift();
			loBody.prompt.user = paMessages.join("");
		}

		return this.isvcAiGeneration.generateText(loBody);
	}

	public isSummarizable(poMessage: Message): boolean {
		return poMessage.body !== '' && !poMessage.deleted && !poMessage.body.endsWith("a rejoint la visio.") && !poMessage.body.endsWith("a quitté la visio.");
	}

	public hasMessagesToSummarize(paMessages : Message[]): boolean {
		return paMessages.some((poMessage: Message) => this.isSummarizable(poMessage));
	}

	//#endregion

}