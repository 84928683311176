import { Exclude } from "class-transformer";
import { Observable } from "rxjs";
import { ObjectObserver } from "../../utils/changes/ObjectObserver";

export abstract class ObservableObjectBase {

	//#region FIELDS

	@Exclude()
	/** Objet observable permettant de notifier des changements dans l'instance. */
	protected readonly moObserver = new ObjectObserver(this);

	//#endregion

	//#region PROPERTIES

	@Exclude()
	/** Flux continu de récupération des mises à jour de l'instance. */
	public get changes$(): Observable<this> { return this.moObserver.changes$; }

	//#endregion

	//#region METHODS

	public emit(): void {
		this.moObserver.emit(this);
	}

	//#endregion

}