<div class="list-item-ctn" *ngFor="let file of files; let i = index">
	<div class="list-item">
		<div class="left">
			<div class="checkbox-ctn" (click)="toggleSelection(file)">
				<ion-checkbox aria-label="Fichier" class="disable-clicks" fill="outline" slot="start"
					[checked]="selectedFilesGuids.includes(file.guid)"></ion-checkbox>
			</div>
			<div [ngClass]="!file.description ? 'only-name-ctn' : ''" class="list-item-detail"
				(click)="toggleSelection(file)">
				<div class="list-item-description" *ngIf="file.description as description" class="list-item-description">
					{{ description }}
				</div>
				<div [ngClass]="!file.description ? 'only-name' : ''" class="list-item-file-name">
					{{ file.name }}
				</div>
			</div>
		</div>
		<div class="list-item-icon" (click)="openFile(file)">
			<ion-icon slot="icon-only" name="see-document"></ion-icon>
		</div>
	</div>
</div>