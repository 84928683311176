import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { ModalController } from '@ionic/angular';
import { defer, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { StringHelper } from '../../../../helpers/stringHelper';
import { ShowMessageParamsToast } from '../../../../services/interfaces/ShowMessageParamsToast';
import { PlatformService } from '../../../../services/platform.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { ModalComponentBase } from '../../../modal';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { CalendarShareService } from '../../services/calendar-share.service';

enum EShareStep {
	generate,
	consult,
	revoke
};

@Component({
	selector: 'calao-share-calendar-modal',
	templateUrl: './share-calendar-modal.component.html',
	styleUrls: ['./share-calendar-modal.component.scss'],
})
export class ShareCalendarModalComponent extends ModalComponentBase<any> implements OnInit {

	//#region PROPERTIES

	public readonly observableIsLoading = new ObservableProperty<boolean>(true);
	public readonly observableHasError = new ObservableProperty<boolean>(false);

	/** Flux de l'étape d'avancement. */
	public readonly observableStep = new ObservableProperty<EShareStep>(EShareStep.consult);
	/** Flux de l'url de l'agenda. */
	public readonly observableCalendarUrl = new ObservableProperty<string>("");

	public readonly hasBackButton$: Observable<boolean> = this.observableStep.value$.pipe(map((peStep: EShareStep) => [EShareStep.generate, EShareStep.revoke].includes(peStep)));
	public readonly message$: Observable<string> = this.observableStep.value$.pipe(
		map((peStep: EShareStep) => {
			switch (peStep) {
				case EShareStep.generate:
					return "Voulez-vous générer un lien d'abonnement à votre agenda CalaoTrade ? Vous pourrez l'utiliser pour afficher votre agenda dans une application tierce.";
				case EShareStep.consult:
					return "Copiez le lien ci-dessous dans votre application de calendrier (Outlook, Google Agenda...) pour lui permettre d'afficher votre agenda CalaoTrade.";
				case EShareStep.revoke:
					return "Voulez-vous révoquer le lien d'abonnement à votre agenda CalaoTrade ? Plus aucun tiers ne pourra consulter votre agenda.";
			}
		})
	);
	public readonly mainButtonText$ = this.observableStep.value$.pipe(
		map((peStep: EShareStep) => {
			switch (peStep) {
				case EShareStep.generate:
					return "Générer";
				case EShareStep.consult:
					return "Fermer";
				case EShareStep.revoke:
					return "Révoquer";
			}
		})
	);
	public readonly secondaryButtonText$ = this.observableStep.value$.pipe(
		map((peStep: EShareStep) => {
			switch (peStep) {
				case EShareStep.generate:
				case EShareStep.revoke:
					return "Retour";
				case EShareStep.consult:
					return "Supprimer";
			}
		})
	);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		private readonly isvcCalendarShare: CalendarShareService,
		private readonly isvcUiMessage: UiMessageService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		try {
			this.isvcCalendarShare.getCalendarSharedTokenAsync()
				.then((psToken?: string) => {
					if (StringHelper.isBlank(psToken))
						this.observableStep.value = EShareStep.generate;
					else {
						this.observableStep.value = EShareStep.consult;
						this.observableCalendarUrl.value = this.isvcCalendarShare.getCalendarUrl(psToken);
					}

					this.observableIsLoading.value = false;
				});
		}
		catch (_) {
			this.observableIsLoading.value = false;
			this.observableHasError.value = true;
		}
	}

	public onMainButtonClick(): Promise<any> {
		switch (this.observableStep.value) {
			case EShareStep.generate:
				return this.generateToken$().toPromise();
			case EShareStep.consult:
				return this.close();
			case EShareStep.revoke:
				return this.deleteToken$().toPromise();
		}
	}

	public onSecondaryButtonClick(): Promise<any> {
		switch (this.observableStep.value) {
			case EShareStep.generate:
			case EShareStep.revoke:
				return this.close();
			case EShareStep.consult:
				this.observableStep.value = EShareStep.revoke;
				this.observableCalendarUrl.value = "";
				return Promise.resolve();
		}
	}

	@Queue<ShareCalendarModalComponent, Parameters<ShareCalendarModalComponent["generateToken$"]>, ReturnType<ShareCalendarModalComponent["generateToken$"]>>({
		excludePendings: true
	})
	private generateToken$(): Observable<string> {
		this.observableIsLoading.value = true;
		return defer(() => this.isvcCalendarShare.shareCalendarAsync()).pipe(
			tap((psToken?: string) => {
				if (StringHelper.isBlank(psToken))
					this.observableStep.value = EShareStep.generate;
				else {
					this.observableStep.value = EShareStep.consult;
					this.observableCalendarUrl.value = this.isvcCalendarShare.getCalendarUrl(psToken);
				}

				this.observableIsLoading.value = false;
			}, () => {
				this.observableIsLoading.value = false;
				this.observableHasError.value = true;
			})
		);
	}

	@Queue<ShareCalendarModalComponent, Parameters<ShareCalendarModalComponent["deleteToken$"]>, ReturnType<ShareCalendarModalComponent["deleteToken$"]>>({
		excludePendings: true
	})
	private deleteToken$() {
		this.observableIsLoading.value = true;
		return defer(() => this.isvcCalendarShare.deleteCalendarSharedTokenAsync()).pipe(
			tap(() => {
				this.close();
				this.isvcUiMessage.showToastMessage(new ShowMessageParamsToast({ message: `Lien de partage révoqué.`, color: "dark" }));
			}, () => {
				this.observableIsLoading.value = false;
				this.observableHasError.value = true;
				this.isvcUiMessage.showToastMessage(new ShowMessageParamsToast({ header: "Erreur", message: `Une erreur est survenue, réessayez ultérieurement.`, color: "dark" }));
			})
		);
	}

	public copyUrl(): void {
		Clipboard.write({ string: this.observableCalendarUrl.value });
	}

	//#endregion METHODS

}
