import { Observable, Timestamp } from 'rxjs';
import { tap, timestamp } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { INotificationTaskParams } from '../../model/backgroundTask/taskParams/INotificationTaskParams';
import { IConversation } from '../../model/conversation/IConversation';
import { InjectorService } from '../injector.service';
import { ShowMessageParamsPopup } from '../interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../uiMessage.service';
import { NotificationTaskBase } from './NotificationTaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export class NotificationOpenConversationTask<T extends INotificationTaskParams> extends NotificationTaskBase<T> {

	//#region FIELDS

	private readonly msvcUiMessage: UiMessageService;

	//#endregion FIELDS

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msvcUiMessage = InjectorService.instance.get(UiMessageService);
	}

	protected override execTask$(): Observable<Timestamp<IConversation>> {
		const lnStartTime: number = Date.now();
		const loParams: T = this.descriptor.params;
		const lsConvId: string = ArrayHelper.hasElements(loParams.notification.actionButtons) ?
			(loParams.notification.actionButtons[0] as any).id : loParams.result.actionId;

		return this.msvcConversation.downloadConversation(lsConvId) // TODO Modif workflow pour passer l'id de la conversation dans les additionnalDatas.
			.pipe(
				tap((poConversation?: IConversation) => {
					if (poConversation)
						this.msvcConversation.routeToConversation(poConversation);
					else {
						this.msvcUiMessage.showPopupMessage(
							new ShowMessageParamsPopup({
								header: "Ouverture impossible",
								message: "La conversation n'a pu être récupérée."
							})
						);
					}
				}),
				timestamp(),
				tap((poResult: Timestamp<IConversation>) => console.debug(`NOTIFOP.T:: Temps de redirection (ms) : ${poResult.timestamp - lnStartTime}`))
			);
	}

	//#endregion

}