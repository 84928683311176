import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { TabsSegmentsComponent } from '../tabs-segments/tabs-segments.component';
import { TabsTabContainerComponent } from '../tabs-tab-container/tabs-tab-container.component';

@Component({
	selector: 'calao-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent extends DestroyableComponentBase implements AfterContentInit {

	//#region FIELDS

	@ContentChild(TabsSegmentsComponent) private moSegments: TabsSegmentsComponent;
	@ContentChild(TabsTabContainerComponent) private moContainer: TabsTabContainerComponent;

	/** Événement lors du changement d'onglet. */
	@Output("onTabChanged") private readonly moTabChangedEvent = new EventEmitter<number>();

	//#endregion

	//#region PROPERTIES

	/** Doc propriété. */
	@Input() public activeTabIndex?: number;
	@ObserveProperty<TabsComponent>({ sourcePropertyKey: "activeTabIndex" })
	public readonly observableActiveTabIndex = new ObservableProperty<number>(0);


	//#endregion

	//#region METHODS

	constructor() {
		super();

		this.observableActiveTabIndex.value$.pipe(
			tap((pnIndex: number) => this.moTabChangedEvent.emit(pnIndex)),
			secure(this)
		).subscribe();
	}

	public ngAfterContentInit(): void {
		if (this.moContainer) {
			this.moContainer.observableActiveTabIndex.bind(this.observableActiveTabIndex.value$, this);
			this.observableActiveTabIndex.bind(this.moContainer.observableActiveTabIndex.value$, this);
		}

		if (this.moSegments) {
			this.moSegments.observableActiveTabIndex.bind(this.observableActiveTabIndex.value$, this);
			this.observableActiveTabIndex.bind(this.moSegments.observableActiveTabIndex.value$, this);
		}

	}

	//#endregion

}
