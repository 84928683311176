import { IAuthentication } from '@calaosoft/osapp/model/IAuthentication';
import { IAppInfo } from '@calaosoft/osapp/model/application/IAppInfo';
import { EAuthenticationAction } from '@calaosoft/osapp/model/authenticator/EAuthenticationAction';
import { IBackgroundTask } from '@calaosoft/osapp/model/backgroundTask/IBackgroundTask';
import { IConfigOneSignal } from '@calaosoft/osapp/model/config/IConfigOneSignal';
import { IContactsConfig } from '@calaosoft/osapp/model/config/icontacts-config';
import { IDocumentsConfiguration } from '@calaosoft/osapp/model/config/idocument-configuration';
import { EFormEngine } from '@calaosoft/osapp/model/forms/EFormEngine';
import { Credentials } from '@calaosoft/osapp/model/security/Credentials';
import { EUpdateProvider } from '@calaosoft/osapp/model/update/EUpdateProvider';
import { IUpdateConfig } from '@calaosoft/osapp/model/update/IUpdateConfig';
import { ERedirectionAfterAuthMode } from '@calaosoft/osapp/modules/authentication/models/eredirection-after-auth-mode';
import { ApplicationService } from '@calaosoft/osapp/services/application.service';
import { DomainService } from '@calaosoft/osapp/services/domain.service';
import { APP_CORE_DATABASE_PREFIX, APP_ID, environment } from './environments/environment';
import { TradeApplicationService } from './services/tradeApplicationService.service';
import { TradeDomainService } from './services/tradeDomain.service';

const defaultHearbeatIntervalMilliseconds = 60000;

export const appInfo: IAppInfo = {
	appId: APP_ID,
	formEngine: EFormEngine.formly,
	deviceId: "123",
	appName: "TRADE",
	supportEmail: "support@calaosoft.fr",
	firstName: "",
	lastName: "",
	login: "",
	applicationDatabaseId: `${APP_CORE_DATABASE_PREFIX}local_application_storage`,
	applicationServiceProvider: { provide: ApplicationService, useClass: TradeApplicationService },
	domainServiceProvider: { provide: DomainService, useClass: TradeDomainService },
	useLinks: true,
	unlockRequired: environment.unlockRequired,
	coreDatabasePrefix: APP_CORE_DATABASE_PREFIX,
	useReminders: true,
	useDocExplorer: true,
	useSite: false,
	CguUrl: "https://www.calaotrade.fr/conditions-generales-utilisation/"
};

export const contacts: IContactsConfig = {
	afterMyContactValidationRoute: "contacts"
};

export * from './environments/environment';

export const authentication: IAuthentication = {
	type: "disable",
	hearbeatIntervalMilliseconds: defaultHearbeatIntervalMilliseconds,
	appCredentials: environment.appCredentials!,
	credentials: new Credentials(),
	authenticatorParams: {
		_id: "",
		loginEditEnabled: true,
		newAccountEnabled: false,
		authenticationAction: EAuthenticationAction.route,
		imgEnabled: true,
		backgroundStyles: "auth",
		login: environment.credentials ? environment.credentials.login : '',
		password: environment.credentials ? environment.credentials.password : '',
		thumbnailPicture: 'img/calaotrade-logo-large.png',
		thumbnailIcon: 'img/trade-icon.png',
		selectSite: true,
		demoEnabled: true,
		infosEnabled: true,
		redirectionAfterAuthMode: ERedirectionAfterAuthMode.tab,
		initUserContactAfterAuth: false,
		destinationPathAfterAuth: "home",
		pinEnabled: environment.pinEnabled
	}
};

export const backgroundTask: IBackgroundTask = {
	execTasksIntervalMs: 30000,
	maxRepeatTask: 10
};

export const update: IUpdateConfig = {
	android: {
		updateCheckIntervalMinutes: 30,
		updateProvider: EUpdateProvider.calaoStore,
		updatesApiUrl: `${environment.cloud_url}${environment.cloud_api_apps_suffix}updates`
	},
	ios: {
		updateCheckIntervalMinutes: 30,
		updateProvider: EUpdateProvider.calaoStore,
		updatesApiUrl: `${environment.cloud_url}${environment.cloud_api_apps_suffix}updates`,
	}
};

export const oneSignal: IConfigOneSignal = {
	appId: environment.onesignalId!,
	googleProjectNumber: environment.googleProjectNumber!
};

export const builtInEntityDescIds: string[] = [
	"business",
	"communicationMedium",
	"contacts",
	"eventOccurrence",
	"events",
	"taskOccurrence",
	"tasks",
	"expense",
	"groups",
	"memo",
	"leaveRequest",
	"users",
	"conversations",
	"reminderOccurrence",
	"reminders",
	"sectors",
	"reports",
	"internalReports",
	"opportunity"
];

export const builtInConfigDescIds: string[] = [
	"docExplorer"
];

export const documentsConfiguration: IDocumentsConfiguration = {
	defaultPathPrefix: "internal"
};