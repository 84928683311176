import { Type } from '@angular/core';
import { isEqual, omit, pick } from 'lodash';
import { ModelResolver } from '../modules/utils/models/model-resolver';
import { ArrayHelper } from './arrayHelper';
import { DateHelper, IDateTypes } from './dateHelper';
import { MapHelper } from './mapHelper';

export abstract class ObjectHelper {

	//#region FIELDS

	private static readonly C_MAX_LOOP_COUNT = 20;

	//#endregion FIELDS

	//#region METHODS

	/** Copie les données d'un objet (propriétés et méthodes) vers un nouvel objet (classe ou interface).
	 * Dans le cas d'une interface, seule la donnée source est nécessaire.
	 * Dans le cas d'une copie de classe, renseigner une instance du type de la classe afin de correctement l'instancier.
	 * ### ATTENTION : Ne gère pas les listes d'objets complexes. Constructeur vide nécessaire pour les classes.
	 * @param poSource Données à copier vers le nouvel objet.
	 * @param pbDataOnly Permet de ne copier que les données, 'true' par défaut.
	 */
	public static clone<T>(poSource: T, pbDataOnly: boolean = true): T {
		if (pbDataOnly) {
			const loNewData: T = ModelResolver.toPlain(poSource);
			if (loNewData === poSource) // On vérifie si le toPlain a déjà créé une nouvelle instance.
				return JSON.parse(JSON.stringify(loNewData));
			else
				return loNewData;
		}
		else
			return ModelResolver.toClass(Object.getPrototypeOf(poSource), ModelResolver.toPlain(poSource));
	}

	/** Vérifie si un objet est bien du type passé en paramètre ou non.
	 * @param poObject Objet dont on veut vérifier le type.
	 * @param peType Valeur d'énumération correspondant au type à vérifier.
	 */
	public static checkType<T>(poObject: T, peType: string): boolean {
		return typeof poObject === peType;
	}

	/** Retourne un booléen qui indique si la valeur est de type primitif ou non.
	 * @param poValue Valeur à déterminer si elle est primitive ou non.
	 */
	public static isPrimitive(poValue: any): poValue is string | boolean | number | Function | undefined | symbol {
		return typeof poValue === "string" || typeof poValue === "boolean" || typeof poValue === "number" ||
			typeof poValue === "function" || typeof poValue === "symbol" || typeof poValue === "undefined";
	}

	/** Retourne `true` si le paramètre est un objet vide. */
	public static isEmpty<T>(poValue: T | {}): poValue is {} {
		return poValue && Object.values(poValue).every((poVal: any) => !this.isDefined(poVal));
	}

	/** Initialise la possibilité d'utiliser le mot-clé `instanceof` pour une classe.
	 * @param poThis `this` du constructeur de la classe.
	 * @param poClass Type de la classe qui veut activer l'utilisation du `instanceof`.
	 */
	public static initInstanceOf(poThis: any, poClass: Type<any>): void {
		Object.setPrototypeOf(poThis, poClass.prototype); // Permet d'utiliser `instanceof MaClasse`.
	}

	public static isNullOrEmpty(poValue: any): poValue is null | undefined {
		return !poValue || this.isEmpty(poValue);
	}

	/** Indique si le paramètre a une valeur (non undefined et non null).
	 * @param poValue
	 * @returns
	 */
	public static isDefined<T>(poValue: T): poValue is NonNullable<T> {
		return poValue !== undefined && poValue !== null;
	}

	/** Vérifie si une chaîne de caratères peut être transformée en JSON.
	 * @param psString Chaîne de caractères à vérifier.
	 */
	public static isJson(psString: string): boolean {
		try {
			JSON.parse(psString);
			return true;
		}
		catch (poException) {
			return false;
		}
	}

	/** Indique si une propriété est modifiable.
	 * @param poObject
	 * @param psKey
	 */
	public static isWritable<T extends Object>(poObject: T, psKey: keyof T): boolean {
		let loProto: any = poObject;
		let loDescriptor: PropertyDescriptor;

		do {
			loDescriptor = Object.getOwnPropertyDescriptor(loProto, psKey);
			loProto = Object.getPrototypeOf(loProto);
		} while (!!loProto && !loDescriptor)

		if (!loDescriptor)
			loDescriptor = { writable: true };

		return !!loDescriptor.writable || !!loDescriptor.set;
	}

	/** Copie toutes les propriétés de `poObjectB` dans `poObjectA` si elles sont éditables ou non définies.
	 * @param poObjectA
	 * @param poObjectB
	 * @returns `poObjectA`
	 */
	public static assign<T extends Object, V extends Object>(poObjectA: T, poObjectB: V): T & V {
		if (!poObjectA)
			poObjectA = {} as T;

		let loObject: T & V = poObjectA as T & V;

		if (typeof poObjectB === "object") {
			for (const lsKey in poObjectB) {
				const loAttribute: any = poObjectB[lsKey];

				if (ObjectHelper.isDefined(loAttribute) && ObjectHelper.isWritable(poObjectA, lsKey as keyof T)) {
					if (typeof loAttribute === "object") {
						if (loAttribute instanceof Array) {
							const laOldArray: any[] = loObject[lsKey] as any ?? [];
							loObject[lsKey] = loAttribute.map((poValue: any, pnIndex: number) => this.assign(laOldArray[pnIndex], poValue)) as any;
						}
						else if (loAttribute instanceof Date)
							loObject[lsKey] = new Date(loAttribute) as any;
						else
							loObject[lsKey] = Object.setPrototypeOf(Object.assign(loObject[lsKey] ?? {}, loAttribute ?? {}), Object.getPrototypeOf(loAttribute));
					}
					else
						loObject[lsKey] = loAttribute;
				}
			}
		}
		else if (poObjectB)
			loObject = poObjectB;

		return loObject;
	}

	/** Teste l'égalité entre 2 objets.
	 * @param poObjectA
	 * @param poObjectB
	 * @deprecated Utiliser `areEqual` à la place.
	 */
	public static areEquals(poObjectA: any, poObjectB: any): boolean {
		return isEqual(poObjectA instanceof Object ? JSON.parse(JSON.stringify(poObjectA)) : poObjectA, poObjectB instanceof Object ? JSON.parse(JSON.stringify(poObjectB)) : poObjectB);
	}

	/** Retourne `true` si deux éléments sont identiques (parcours en profondeur), `false` sinon.\
	 * La méthode considère `null` et `undefined` comme deux valeurs identiques (non définies).
	 * @param poItemA Élément A à comparer avec l'élément B.
	 * @param poItemB Élément B à comparer avec l'élément A.
	 * @link https://developer.mozilla.org/fr/docs/Web/JavaScript/Data_structures#les_objets
	 */
	public static areEqual(poItemA: any, poItemB: any): boolean {
		// Si les deux éléments ont la même instance ou ne sont pas définis, ils sont égaux.
		if (poItemA === poItemB || (!ObjectHelper.isDefined(poItemA) && !ObjectHelper.isDefined(poItemB)))
			return true;

		// Si l'un des deux éléments est défini mais pas l'autre, ils sont différents.
		else if ((ObjectHelper.isDefined(poItemA) && !ObjectHelper.isDefined(poItemB)) || (!ObjectHelper.isDefined(poItemA) && ObjectHelper.isDefined(poItemB)))
			return false;

		const loPlainItemA: any = ModelResolver.toPlain(poItemA);
		const loPlainItemB: any = ModelResolver.toPlain(poItemB);

		if (loPlainItemA instanceof Array)
			return ObjectHelper.areEqual_array(loPlainItemA, loPlainItemB);

		else if (loPlainItemA instanceof Map)
			return ObjectHelper.areEqual_map(loPlainItemA, loPlainItemB);

		else if (loPlainItemA instanceof Set)
			return ObjectHelper.areEqual_set(loPlainItemA, loPlainItemB);

		//! On n'utilise jamais ce genre d'objets ; à implémenter plus tard si on finit par en utiliser.
		// else if (loPlainItemA instanceof WeakMap || loPlainItemA instanceof WeakSet)

		else if (DateHelper.isDate(loPlainItemA))
			return ObjectHelper.areEqual_date(loPlainItemA, loPlainItemB);

		else if (typeof loPlainItemA === "object")
			return ObjectHelper.areEqual_object(loPlainItemA, loPlainItemB);

		else // Type primitif pour `loPlainItemA` et n'a pas abouti au premier test d'égalité donc éléments différents.
			return false;
	}

	private static areEqual_array(paObjectA: any[], poObjectB: any): boolean {
		if (!(poObjectB instanceof Array)) // Si l'objet B n'est pas un tableau, pas d'égalité de possible.
			return false;

		else if (paObjectA.length !== poObjectB.length) // Si les tableaux n'ont pas la même longueur, ils ne sont pas égaux.
			return false;

		else // On compare chaque élément d'un tableau pour trouver une correspondance dans le second, si c'est ok pour tous les éléments alors égalité.
			return paObjectA.every((poItemA: any) => poObjectB.some((poItemB: any) => ObjectHelper.areEqual(poItemA, poItemB)));
	}

	private static areEqual_map(poMap: Map<any, any>, poItemB: any): boolean {
		if (!(poItemB instanceof Map)) // Si l'élément B n'est pas une map, pas d'égalité.
			return false;

		else {
			const laMapKeysA: any[] = MapHelper.keysToArray(poMap);
			const laMapKeysB: any[] = MapHelper.keysToArray(poItemB);

			if (ObjectHelper.areEqual_map_haveSameUndefinedKeys(poMap, poItemB, laMapKeysA, laMapKeysB)) {
				// Comparaison de toutes les entrées en commun des deux maps.
				return ArrayHelper.intersection(laMapKeysA, laMapKeysB).every((poCommonKey: any) => ObjectHelper.areEqual(poMap.get(poCommonKey), poItemB.get(poCommonKey)));
			}
			else
				return false;
		}
	}

	private static areEqual_map_haveSameUndefinedKeys(poMapA: Map<any, any>, poMapB: Map<any, any>, paMapKeysA: any[], paMapKeysB: any[]): boolean {
		const laNotCommonKeys: any[] =
			ArrayHelper.getDifferences(paMapKeysA, paMapKeysB).concat(ArrayHelper.getDifferences(paMapKeysB, paMapKeysA));

		// Si tous les champs qui ne sont pas en commun dans les deux maps ne sont pas définis.
		return laNotCommonKeys.every((psKey: string) => !ObjectHelper.isDefined(poMapA.get(psKey)) && !ObjectHelper.isDefined(poMapB.get(psKey)));
	}

	private static areEqual_set(poSet: Set<any>, poItemB: any): boolean {
		return poItemB instanceof Set ? ObjectHelper.areEqual_array(Array.from(poSet), Array.from(poItemB)) : false;
	}

	private static areEqual_date(pdDateA: IDateTypes, poItemB: any): boolean {
		return DateHelper.isDate(poItemB) ? DateHelper.areEqual(pdDateA, poItemB) : false;
	}

	private static areEqual_object(poObjectA: any, poItemB: any): boolean {
		if (poItemB instanceof Array || typeof poItemB !== "object") // Si l'élément B est un tableau ou n'est pas un objet complexe, pas d'égalité.
			return false;

		else { // Les deux éléments sont des objets complexes, on peut tester l'égalité entre eux.
			const laObjectKeysA: string[] = Object.keys(poObjectA);
			const laObjectKeysB: string[] = Object.keys(poItemB);

			if (ObjectHelper.areEqual_object_haveSameUndefinedKeys(poObjectA, poItemB, laObjectKeysA, laObjectKeysB)) {
				// Comparaison de tous les champs en commun des deux objets.
				return ArrayHelper.intersection(laObjectKeysA, laObjectKeysB)
					.every((psCommonKey: string) => ObjectHelper.areEqual(poObjectA[psCommonKey], poItemB[psCommonKey]));
			}
			else
				return false;
		}
	}

	private static areEqual_object_haveSameUndefinedKeys(poObjectA: any, poItemB: any, paObjectKeysA: string[], paObjectKeysB: string[]): boolean {
		const laNotCommonKeys: string[] =
			ArrayHelper.getDifferences(paObjectKeysA, paObjectKeysB).concat(ArrayHelper.getDifferences(paObjectKeysB, paObjectKeysA));

		// Si tous les champs qui ne sont pas en commun dans les deux objets ne sont pas définis.
		return laNotCommonKeys.every((psKey: string) => !ObjectHelper.isDefined(poObjectA[psKey]) && !ObjectHelper.isDefined(poItemB[psKey]));
	}

	public static pick<T extends object, U extends keyof T>(poObject: T, paKeys: U[]): Pick<T, U> {
		return pick(poObject, ...paKeys);
	}

	public static omit<T extends object, U extends keyof T>(poObject: T, paKeys: U[]): Omit<T, U> {
		return omit(poObject, ...paKeys);
	}

	/** Retourne la propriété d'un objet sous la forme d'une chaîne de caractères.
	 * @param poObject L'objet.
	 * @param poProperty La propriété.
	 */
	public static getPropertyName<T extends object>(poObject: T, poProperty: any): string {
		for (const psKey in poObject) {
			if (poObject[psKey] === poProperty) {
				return psKey;
			}
		}
		return "";
	}

	/** Retourne `true` si l'objet passé en paramètre est un objet JS pur, `false` si c'est un type primitif ou une instance de classe.
	 * @param poObject Objet qu'il faut vérifier.
	 */
	public static isPlainObject(poObject?: Object): boolean {
		return poObject?.constructor === Object;
	}

	/** Retourne `true` si l'objet peut être sérialisé, `false` sinon.
	 * @param poObject Objet qu'il faut vérifier.
	 */
	public static isSerializable(poObject?: Object): boolean {
		// Si l'objet en paramètre a un constructeur 'Date' ou si c'est la valeur 'null', pas de problème.
		if (poObject?.constructor === Date || poObject === null)
			return true;

		// Si l'objet en paramètre a un constructeur 'Object' ou 'Array', il faut vérifier que tous ses champs ne sont pas des instances (hors tableau et 'Date').
		else if (poObject?.constructor === Object || poObject?.constructor === Array)
			return this.areSerializableSubObjects(poObject, 0);

		else if (typeof poObject === "undefined" || typeof poObject === "function") // Objet de type 'undefined' ou 'function' -> non sérialiables.
			return false;

		else if (this.isPrimitive(poObject)) // Autres types primitifs ok.
			return true;

		else // Instances.
			return false;
	}

	/** Retourne `true` si les objets peuvent être sérialisés, `false` sinon.
	 * @param poObject Objet dont il faut vérifier que tous ses champs complexes sont sérialisables.
	 */
	private static areSerializableSubObjects(poObject: any, pnLoopCount: number): boolean {
		if (pnLoopCount === this.C_MAX_LOOP_COUNT) // On considère qu'au bout de x tours de boucles on est dans un cas de récursivité infinie.
			return false;
		else {
			const laKeys: string[] = Object.keys(poObject);
			let lbIsPlainObject = true;

			for (let lnIndex = 0; lnIndex < laKeys.length; ++lnIndex) {
				const lsKey: string = laKeys[lnIndex];
				const loValue: any = poObject[lsKey];

				// Dans le cas d'un tableau ou d'un objet JS, il faut vérifier ses champs.
				// Avec une instance de classe (hors 'Array') on s'arrête, l'objet parent n'est pas un objet JS pur.
				if (this.isDefined(loValue) && !this.isPrimitive(loValue) && !(loValue instanceof Date))
					lbIsPlainObject = loValue.constructor === Array || loValue.constructor === Object ? this.areSerializableSubObjects(loValue, ++pnLoopCount) : false;

				if (!lbIsPlainObject)
					break;
			}

			return lbIsPlainObject;
		}
	}

	//#endregion

}