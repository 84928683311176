import { Injectable } from '@angular/core';
import { ConfigData } from '@calaosoft/osapp/model/config/ConfigData';
import { IWorkspace } from '@calaosoft/osapp/model/workspaces/IWorkspace';
import { ApplicationService } from '@calaosoft/osapp/services/application.service';
import { DomainService } from '@calaosoft/osapp/services/domain.service';
import { Store } from '@calaosoft/osapp/services/store.service';
import { C_PREFIX_BUSINESS } from '../app/app.constants';

@Injectable({ providedIn: "root" })
export class TradeDomainService extends DomainService {

	//#region METHODS

	constructor(poStore: Store, psvcApplication: ApplicationService) {
		super(poStore, psvcApplication);
	}

	/** Crée le workspace de l'utilisateur */
	public override createUserWorkspace(psUserId: string, psName: string, psWorkspaceId: string): IWorkspace {
		const loWorkspace: IWorkspace = super.createUserWorkspace(psUserId, psName, psWorkspaceId);

		loWorkspace.databases.push(this.createWorkspaceDatabaseName(psUserId, "soins"));
		return loWorkspace;
	}

	public override createWorkspaceDatabaseName(psWorkspaceId: string, psDataBaseRole: string): string {
		return `${ConfigData.appInfo.appId}_custom_ws${psWorkspaceId}${C_PREFIX_BUSINESS}${psDataBaseRole}`;
	}

	//#endregion

}