import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IdHelper } from '@calaosoft/osapp/helpers/idHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp/model/EPrefix';
import { BaseEvent } from '@calaosoft/osapp/modules/calendar-events/models/base-event';
import { CalendarEventsService } from '@calaosoft/osapp/modules/calendar-events/services/calendar-events.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { take } from 'rxjs/operators';

@Injectable()
export class CanEditEventGuard  {

	//#region METHODS

	constructor(private readonly isvcEvent: CalendarEventsService, private readonly isvcUiMessage: UiMessageService) { }

	public async canActivate(poRoute: ActivatedRouteSnapshot): Promise<boolean> {
		const lsEventGuid: string | undefined = poRoute.params.taskGuid || poRoute.params.eventGuid;


		if (!StringHelper.isBlank(lsEventGuid)) {
			const loEvent: BaseEvent | undefined = await this.isvcEvent.getEventAsync(IdHelper.buildId(EPrefix.event, lsEventGuid));
			if (loEvent && !(await loEvent?.canBeEdited$.pipe(take(1)).toPromise())) {
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: "Vous devez être participant de la série ou son auteur pour l'éditer." }));
				return false;
			}
		}

		return true;
	}

	//#endregion

}
