import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { ArrayHelper } from "@calaosoft/osapp/helpers/arrayHelper";
import { GuidHelper } from "@calaosoft/osapp/helpers/guidHelper";
import { ICoordinates } from "@calaosoft/osapp/model/navigation/ICoordinates";
import { GeolocationHelper } from "@calaosoft/osapp/modules/geolocation/helpers/geolocation.helper";
import { IMapOptions } from "@calaosoft/osapp/modules/maps/models/imap-options";
import { IPolygonOptions } from "@calaosoft/osapp/modules/maps/models/ipolygon-options";
import { IStartMap } from "@calaosoft/osapp/modules/maps/models/istart-map";
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { secure } from "@calaosoft/osapp/modules/utils/rxjs/operators/secure";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ESectors } from "../esectors";
import { EMapUrlPart } from "../models/emap-url-part";
import { Sector } from "../models/sector";
import { SectorsService } from "../services/sectors.service";

@Component({
	selector: 'sectors-map',
	templateUrl: './sectors-map.page.html',
	styleUrls: ['./sectors-map.page.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorsMapPage extends DestroyableComponentBase {

	//#region FIELDS

	private static readonly C_POPUP_BUTTON_CLASS = "button-class";

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly mapOptions$: Observable<IMapOptions>;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		psvcSectors: SectorsService,
		private readonly ioRouter: Router
	) {
		super();
		// Utilisé dans le HTML.
		this.mapOptions$ = psvcSectors.getSectors$()
			.pipe(
				map((paSectors: Sector[]): IMapOptions => this.loadMapOptions(paSectors)),
				secure(this)
			);
	}

	/** Charge les options de la carte en utilisant les données des secteurs fournies.
	 * @param paSectors Tableau des secteurs.
	 * @returns Les options de la carte pour le rendu.
	 */
	private loadMapOptions(paSectors: Sector[]): IMapOptions {
		const loStartMap: IStartMap = this.getStartMapOptions(paSectors);
		return {
			start: loStartMap,
			polygons: this.createPolygon(paSectors),
			buttons: [],
			pois: []
		};
	}

	/** Calcule les options de départ pour la carte en fonction des secteurs.
	 * @param paSectors Tableau des secteurs.
	 * @returns Options de départ de la carte incluant le centre et le zoom.
	 */
	private getStartMapOptions(paSectors: Sector[]): IStartMap {
		const laPois: ICoordinates[] = paSectors
			.map((poSector: Sector) => poSector.getSectorZoneCoordinates())
			.flat();

		let lnMaxDst = 0;
		let lnSumLon = 0;
		let lnSumLat = 0;
		laPois.forEach((poStep1: ICoordinates) => {
			lnSumLat += poStep1.latitude;
			lnSumLon += poStep1.longitude;
			laPois.forEach((poStep2: ICoordinates) => {
				const lnDist: number = GeolocationHelper.calculateDistanceUsingCoordinatesKm(poStep1, poStep2);
				lnMaxDst < lnDist ? (lnMaxDst = lnDist) : undefined;
			});
		});

		return {
			center: GeolocationHelper.getCenter(laPois.length, lnSumLat, lnSumLon),
			zoom: GeolocationHelper.getZoom(lnMaxDst)
		};
	}

	/** Crée les options de polygone pour les secteurs.
	 * @param paSectors Tableau des secteurs.
	 * @returns Tableau des options de polygones.
	 */
	private createPolygon(paSectors: Sector[]): IPolygonOptions[] {
		const laPolyOptions: IPolygonOptions[] = [];

		paSectors.forEach((poSector: Sector) => {
			const laSectorZone: ICoordinates[] = poSector.getSectorZoneCoordinates();
			const lsSectorId: string | undefined = poSector._id.split("_")[1];
			if (ArrayHelper.hasElements(laSectorZone) && lsSectorId) {
				const lsClassCss = `btn-${GuidHelper.newGuid()}`;
				const lsHtmlPopup = `<h1>${poSector.title}</h1><br><ion-button class='${lsClassCss}'>voir</ion-button>`;
				laPolyOptions.push(
					{
						coordinates: laSectorZone,
						options: { borderColor: '#c0c4ff', fillColor: '#c0c4ff', fillOpacity: 0.5, },
						popup: {
							htmlText: lsHtmlPopup,
							buttons: {
								[`.${lsClassCss}`]:
									{ onClick: () => this.ioRouter.navigate([`/businesses/${ESectors.sectors}/${lsSectorId}/${EMapUrlPart.map}`]) }
							}
						}
					});
			}
		});
		return laPolyOptions;
	}

	//#endregion METHODS
}