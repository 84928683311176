import { NgModule, Type } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { BadgeModule } from '../../modules/badge/badge.module';
import { CalaoCheckboxModule } from '../../modules/checkbox/checkbox.module';
import { ConflictsModule } from '../../modules/conflicts/conflicts.module';
import { UserContactModule } from '../../modules/contacts/userContact/user-contact.module';
import { FavoritesModule } from '../../modules/preferences/favorites/favorites.module';
import { SelectorModule } from '../../modules/selector/selector.module';
import { TabsModule } from '../../modules/tabs/tabs.module';
import { VirtualScrollModule } from '../../modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '../../pipes/pipes.module';
import { SharedComponentsModule } from '../sharedComponents.module';
import { ContactSelectorItemComponent } from './contact-selector-item/contact-selector-item.component';
import { ContactSelectorComponent } from './contactSelector/contactSelector.component';
import { ContactsListComponent } from './contactsList/contactsList.component';

const modules: Array<Type<any>> = [
	SharedComponentsModule,
	FormlyModule,
	PipeModule,
	TabsModule,
	CalaoCheckboxModule,
	SelectorModule,
	FavoritesModule,
	MatBadgeModule,
	VirtualScrollModule,
	MatRadioModule,
	RouterModule,
	BadgeModule,
	ConflictsModule,
	UserContactModule
];
const exportComponents: Array<Type<any>> = [
	ContactsListComponent,
	ContactSelectorComponent,
	ContactSelectorItemComponent
];


/** Module de gestion des contacts. */
@NgModule({
	imports: modules,
	exports: exportComponents,
	declarations: [...exportComponents],
	providers: [{ provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: "primary" } }]
})
export class ContactsModule { }