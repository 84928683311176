import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from '@calaosoft/osapp/modules/events/events.service';
import { LoggerService } from '@calaosoft/osapp/modules/logger/services/logger.service';
import { ApplicationService } from '@calaosoft/osapp/services/application.service';
import { FlagService } from '@calaosoft/osapp/services/flag.service';
import { GlobalDataService } from '@calaosoft/osapp/services/global-data.service';
import { PlatformService } from '@calaosoft/osapp/services/platform.service';

@Injectable({ providedIn: "root" })
export class TradeApplicationService extends ApplicationService {

	//#region FIELDS

	/** Identifiant du descripteur de formulaire pour un customer. */
	public static readonly C_CUSTOMERS_BOOK_FORMDESC_ID = `formDesc_customersBook`;
	/** Identifiant de la définition de formulaire pour l'édition d'un customer. */
	public static readonly C_CUSTOMERS_BOOK_FORMDEF_ID_EDIT = "customer_edit";
	/** Identifiant de la définition de formulaire pour le visu d'un customer. */
	public static readonly C_CUSTOMERS_BOOK_FORMDEF_ID_VISU = "customer_visu";
	/** Tableau des lettre-clés des actes qui sont éligibles à l'IFI. */
	public static readonly C_IFI_ELIGIBLE_KEY_LETTERS = ["BSA", "BSB", "BSC"];

	//#endregion

	//#region PROPERTIES

	/** Zone géographique de l'utilisateur, mise en dur pour l'instant. */
	public geoZone?: number = 1;

	//#endregion

	//#region METHODS

	constructor(
		psvcFlag: FlagService,
		psvcEvents: EventsService,
		psvcPlatform: PlatformService,
		psvcLogger: LoggerService,
		psvcGlobalData: GlobalDataService,
		poRouter: Router) {
		super(psvcFlag, psvcEvents, psvcPlatform, psvcLogger, psvcGlobalData, poRouter);
		this.waitInitDatabasesToCreateDatabaseDocuments();
	}

	/** Attend la fin de l'initialisation des bases de données pour demander la création des design documents. */
	protected override waitInitDatabasesToCreateDatabaseDocuments(): void { }

	//#endregion
}
