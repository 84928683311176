import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { IListDefinitionsField } from '../../../forms/models/IListDefinitionsField';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { EntityEntriesListItemBaseComponent } from '../entity-entries-list-item-base/entity-entries-list-item-base.component';

@Component({
	selector: 'calao-entity-entries-list-tab-item',
	templateUrl: './entity-entries-list-tab-item.component.html',
	styleUrls: ['./entity-entries-list-tab-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityEntriesListTabItemComponent<T extends IStoreDocument>
	extends EntityEntriesListItemBaseComponent<T> implements OnInit {

	//#region PROPERTIES

	/** Doc propriété. */
	@Input() public displayFields?: IListDefinitionsField<T>[];
	@ObserveProperty<EntityEntriesListTabItemComponent<T>>({ sourcePropertyKey: "displayFields" })
	public readonly observableDisplayFields = new ObservableProperty<IListDefinitionsField<T>[]>();

	//#endregion PROPERTIES

	//#region METHODS

	public ngOnInit(): void {
		this.detectChanges(); // Correction bug maj taille colonnes.
	}

	//#endregion METHODS

}
