<calao-filter-bar #filterBar [filterValues]="observableFilterValues.value$ | async"
	[nbTmpResults]="observableNbTmpResults.value$ | async" (onFilterValuesChange)="onFilterValuesChange($any($event))"
	(onTmpFilterValuesChange)="onTmpFilterValuesChange($any($event))">
	<calao-filter-bar-main-content>
		<calao-filter-bar-item key="title">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher une tâche ou un évènement"
					[value]="observableValue.value$ | async" (onValueChange)="observableValue.value = $event"
					[filterBadgeLabel]="nbFiltersLabel$ | async">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-tags>
			<ng-container *ngIf="observableSortOptions.value$ | async as sortOptionsValue">
				<calao-filter-bar-item key="sort">
					<ng-template let-observableValue="observableValue">
						<calao-sort-selector [options]="sortOptionsValue"
							[by]="$any(observableValue.value$ | async)?.by ?? $any(observableDefaultBy.value$ | async)"
							[order]="$any(observableValue.value$ | async)?.order ?? defaultSort"
							(onSortChanged)="observableValue.value = $event" [size]="sortSize"></calao-sort-selector>
					</ng-template>
				</calao-filter-bar-item>
			</ng-container>
			<calao-filter-bar-tags-tag *ngIf="!(observableHideDateSelector.value$ | async)" [label]="dateRangeLabel$ | async">
			</calao-filter-bar-tags-tag>
			<calao-filter-bar-tags-tag *ngIf="!(observableHideMoreFiltersTag.value$ | async)"
				[label]="nbMoreFiltersLabel$ | async">
			</calao-filter-bar-tags-tag>
		</calao-filter-bar-tags>
	</calao-filter-bar-main-content>
	<calao-filter-bar-modal-content [canValidate]="observableCanValidate.value$ | async">
		<calao-filter-bar-item key="title">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher une tâche ou un évènement"
					[value]="observableValue.value$ | async" [hasModal]="false" (onValueChange)="observableValue.value = $event">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
		<ng-container *ngIf="observableTypeSelectorParams.value$ | async as selectorParams">
			<calao-filter-bar-item key="eventType">
				<ng-template let-observableValue="observableValue">
					<calao-filter-bar-item-title>Type</calao-filter-bar-item-title>
					<osapp-selector multiple [preselectedValues]="selectorParams.preselectedValues"
						[displayMode]="selectorParams.displayMode" [options]="selectorParams.options" [showItemBorder]="false"
						listDirection="row" (selectionChanged)="onSelectionChanged($event, observableValue)"></osapp-selector>
				</ng-template>
			</calao-filter-bar-item>
		</ng-container>
		<ng-container *ngIf="!(observableHideDateSelector.value$ | async)">
			<calao-filter-bar-item key="dateRange"
				*ngIf="(observableMinDate.value$ | async) || (observableMaxDate.value$ | async)" class="border">
				<ng-template let-observableValue="observableValue">
					<calao-filter-bar-item-title>Plage de dates</calao-filter-bar-item-title>
					<calao-date-range-picker [min]="observableMinDate.value$ | async" [max]="observableMaxDate.value$ | async"
						[model]="observableValue.value$ | async"
						(dateRangeChanged)="observableValue.value = $event; updateCanValidate($event)">
					</calao-date-range-picker>
				</ng-template>
			</calao-filter-bar-item>
		</ng-container>
		<ng-container *ngIf="!(observableHideDeadlineSelector.value$ | async)">
			<calao-filter-bar-item key="deadlineDateRange" class="border">
				<ng-template let-observableValue="observableValue">
					<calao-filter-bar-item-title>Échéance
						<calao-filter-bar-item-reset>
							<ion-label class="label" (click)="observableValue.value = undefined">Effacer</ion-label>
						</calao-filter-bar-item-reset>
					</calao-filter-bar-item-title>
					<calao-date-range-picker [model]="observableValue.value$ | async"
						(dateRangeChanged)="observableValue.value = $event; updateCanValidate($event)">
					</calao-date-range-picker>
				</ng-template>
			</calao-filter-bar-item>
		</ng-container>
		<ng-container *ngIf="!(observableHidePlaceSelector.value$ | async)">
			<calao-filter-bar-item key="place" class="border">
				<ng-template let-observableValue="observableValue">
					<calao-filter-bar-item-title>Lieu</calao-filter-bar-item-title>
					<calao-filter-bar-search-input placeholder="Emplacement" [value]="observableValue.value$ | async"
						[hasModal]="false" (onValueChange)="observableValue.value = $event">
					</calao-filter-bar-search-input>
				</ng-template>
			</calao-filter-bar-item>
		</ng-container>
		<calao-filter-bar-item key="priority" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Priorité maxi</calao-filter-bar-item-title>
				<calao-slider [minValue]="1" [maxValue]="5" [step]="1" [pin]="true" [ticks]="true" [snaps]="true"
					[value]="observableValue.value$ | async" [showMinValue]="true" [showMaxValue]="true"
					[colors]="observableColors.value$ | async" [defaultValue]="(observableFilterValues.value$ | async).priority"
					minLabel="Forte" maxLabel="Faible" (onValueChange)="observableValue.value = $event"></calao-slider>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-item *ngIf="!(observableHideCustomerSelector.value$ | async)" key="businessIds" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Business</calao-filter-bar-item-title>
				<contacts-list [params]="customersSelectorParams" [model]="$any(observableValue.value$ | async)"
					(modelChange)="observableValue.value = $event"></contacts-list>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-item key="participantIds" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Participant(s)</calao-filter-bar-item-title>
				<contacts-list [params]="contactsSelectorParams" [model]="$any(observableValue.value$ | async)"
					(modelChange)="observableValue.value = $event"></contacts-list>
			</ng-template>
		</calao-filter-bar-item>
	</calao-filter-bar-modal-content>
</calao-filter-bar>