import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ContactPickerContactItemBase } from '../../models/contacts-picker/contacts-picker-contact-item-base';

@Component({
	selector: "osapp-contacts-picker-contact-item",
	templateUrl: './contacts-picker-contact-item.component.html',
	styleUrls: ['./contacts-picker-contact-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsPickerContactItemComponent extends ContactPickerContactItemBase {

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	//#endregion

}