import { Component, OnInit } from '@angular/core';
import { EConversationStatus } from '../../../model/conversation/EConversationStatus';
import { TabsComponent } from '../../../modules/tabs/components/tabs/tabs.component';

@Component({
	selector: 'calao-conversation-tab',
	templateUrl: './conversation-tab.component.html',
	styleUrls: ['./conversation-tab.component.scss'],
})
export class ConversationTabComponent implements OnInit {

	//#region FIELDS

	private mnArchiveTabIndex: number = 1;

	//#endregion

	//#region PROPERTIES

	public defaultTabIndex: number = 0;

	//#endregion

	constructor() { }

	ngOnInit() {
	}

	/** Retourn le type de filtre à appliquer sur la liste des conversations. */
	public getStatusFilter(poTabs: TabsComponent): EConversationStatus | undefined {
		if (poTabs.activeTabIndex === this.defaultTabIndex)
			return EConversationStatus.active;
		else if (poTabs.activeTabIndex === this.mnArchiveTabIndex)
			return EConversationStatus.archived;
		else {
			console.warn("CONV.TAB.C:: Unknow tab index.");
			return undefined;
		}
	}
}
