<ng-container *ngIf="observableItem.value$ | async as observeditem">
	<calao-column-list-row [index]="index" (click)="onItemClicked()" (onDetailClicked)="onDetailClicked($event)"
		[detail]="observableDetail.value$ | async">
		<calao-column-list-row-column *ngFor="let col of observableDisplayFields.value$ | async" [ngClass]="col.cssClass"
			[size]="col.size" class="colonne">
			<ng-container *ngIf="!col.hidden">

				<!-- Affichage d'avatar. -->
				<ng-container *ngIf="getPictureCol(col, observeditem) as pictureCol">
					<avatar *ngIf="!col.isArray && observeditem[col.key] && (pictureCol.url ||
											pictureCol.base64 || pictureCol.guid)" [src]="getAvatar(pictureCol)">
					</avatar>
					<ion-icon *ngIf="!pictureCol.base64 && !pictureCol.url" class="img-thumbnail" name="person"></ion-icon>
				</ng-container>

				<!-- Affichage d'une icône. -->
				<div *ngIf="getIconCol(col, observeditem) as iconCol" class="grid-icon">
					<ion-icon *ngIf="iconCol.icon" [name]="iconCol.icon"></ion-icon>
					<ion-icon *ngIf="!iconCol.icon && iconCol.url" [src]="iconCol.url"></ion-icon>
					<ion-icon *ngIf="iconCol.iconName" [name]="col.iconName"></ion-icon>
				</div>

				<!-- Affichage d'une image. -->
				<div *ngIf="getImageCol(col, observeditem) as imageCol" class="margin-auto">
					<ion-avatar
						[ngStyle]="col.size? {'max-height': avatarSize[col.size]+'px', 'max-width': avatarSize[col.size]+'px'} : {}">
						<ion-img #img [src]="imageCol.url" [alt]="imageCol.alt"></ion-img>
					</ion-avatar>
				</div>

				<!-- Affichage de texte. -->
				<ng-container *ngIf="!col.isPicture && !col.isIcon && !col.isImage">
					<p>{{ observeditem[col.key] | pattern: $any(col.key) : observeditem | isDate: 'dd/MM/yyyy HH:mm' }}
					</p>
				</ng-container>
			</ng-container>
		</calao-column-list-row-column>
	</calao-column-list-row>
</ng-container>