<calao-tabs #tabs [activeTabIndex]="defaultTabIndex">
	<calao-tabs-segments slot="top">
		<calao-tabs-segment>
			<ion-label>Actives</ion-label>
		</calao-tabs-segment>
		<calao-tabs-segment>
			<ion-label>Archivées</ion-label>
		</calao-tabs-segment>
	</calao-tabs-segments>

	<calao-tabs-tab-container>
		<ng-template #tabTemplate>
			<!-- Première slide (actives). -->
			<calao-tabs-tab>
				<ion-content>
					<calao-conversation-list *ngIf="tabs.activeTabIndex === 0"
						[conversationsStatusFilter]="getStatusFilter(tabs)">
					</calao-conversation-list>
				</ion-content>
			</calao-tabs-tab>
		</ng-template>
		<ng-template #tabTemplate>
			<!-- Seconde slide (archivées). -->
			<calao-tabs-tab>
				<ion-content>
					<calao-conversation-list *ngIf="tabs.activeTabIndex === 1" hideFabButton
						[conversationsStatusFilter]="getStatusFilter(tabs)"></calao-conversation-list>
				</ion-content>
			</calao-tabs-tab>
		</ng-template>
	</calao-tabs-tab-container>
</calao-tabs>