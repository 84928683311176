import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CalendarModule as AngularCalendar, CalendarDateFormatter, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { DateModule } from '../../components/date/date.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { CalendarEventComponent } from './components/calendar-event/calendar-event.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { EventOccurencesCounterComponent } from './components/event-occurences-counter/event-occurences-counter.component';
import { ViewSelectorComponent } from './components/view-selector/view-selector.component';
import { CustomCalendarDateFormatterService } from './services/custom-calendar-date-formatter.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		FlatpickrModule.forRoot(),
		AngularCalendar.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		IonicModule,
		VirtualScrollModule,
		DateModule
	],
	declarations: [CalendarComponent, CalendarEventComponent, EventOccurencesCounterComponent, ViewSelectorComponent],
	exports: [CalendarComponent, CalendarEventComponent, EventOccurencesCounterComponent],
	providers: [
		{
			provide: CalendarDateFormatter, useClass: CustomCalendarDateFormatterService
		}
	]
})
export class CalendarModule { }
