<ion-item #item lines="none">
	<ion-icon slot="start" name="search" class="search-icon"></ion-icon>
	<ion-input #input class="filter-search-input" clearInput [placeholder]="observablePlaceholder.value$ | async"
		[value]="observableValue.value$ | async" [debounce]="observableDebounce.value$ | async"
		(ionInput)="onValueChange($event)"></ion-input>
	<ng-content></ng-content>
	<ion-button *ngIf="observableHasModal.value$ | async" color="primary" fill="outline" slot="end"
		(click)="presentFilterModal()" class="filter-button">
		<ion-icon slot="icon-only" name="filter"></ion-icon>
		<ion-badge *ngIf="observableFilterBadgeLabel.value$ | async as label" color="warning">{{label}}</ion-badge>
	</ion-button>
</ion-item>