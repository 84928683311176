import { IRolePermission } from "./irole-permission";

export class Role {

	public label: string;
	public permissions: IRolePermission[];

	constructor(psLabel: string, paPermission: IRolePermission[]) {
		this.label = psLabel;
		this.permissions = paPermission;
	}
}