import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContactsModule } from '@calaosoft/osapp/components/contacts/contacts.module';
import { DateModule } from '@calaosoft/osapp/components/date/date.module';
import { BlankInformationModule } from '@calaosoft/osapp/modules/blank-information/blank-information.module';
import { CalendarEventsModule } from '@calaosoft/osapp/modules/calendar-events/calendar-events.module';
import { CalendarModule } from '@calaosoft/osapp/modules/calendar/calendar.module';
import { FilterModule } from '@calaosoft/osapp/modules/filter/filter.module';
import { ListsModule } from '@calaosoft/osapp/modules/lists/lists.module';
import { SelectorModule } from '@calaosoft/osapp/modules/selector/selector.module';
import { SliderModule } from '@calaosoft/osapp/modules/slider/slider.module';
import { SlidingListModule } from '@calaosoft/osapp/modules/sliding-list/sliding-list.module';
import { SortModule } from '@calaosoft/osapp/modules/sort/sort.module';
import { VirtualScrollModule } from '@calaosoft/osapp/modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '@calaosoft/osapp/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { PriorityModule } from '../priority/priority.module';
import { ActivitiesListComponent } from './components/activities-list/activities-list.component';
import { DayListViewComponent } from './components/day-list-view/day-list-view.component';
import { DelegatedTaskItemComponent } from './components/delegated-task-item/delegated-task-item.component';
import { EventItemComponent } from './components/event-item/event-item.component';
import { EventsFilterBarComponent } from './components/events-filter-bar/events-filter-bar.component';
import { LateItemComponent } from './components/late-item/late-item.component';
import { PrivateActivityItemComponent } from './components/private-activity-item/private-activity-item.component';
import { ReminderItemComponent } from './components/reminder-item/reminder-item.component';
import { RescheduleModalComponent } from './components/reschedule-modal/reschedule-modal.component';
import { TaskItemComponent } from './components/task-item/task-item.component';
import { TodoItemInlineComponent } from './components/todo-item-inline/todo-item-inline.component';
import { TodoItemComponent } from './components/todo-item/todo-item.component';
import { CanEditEventOccurenceGuard } from './guards/can-edit-event-occurence.guard';
import { CanEditEventGuard } from './guards/can-edit-event.guard';
import { CanShowEventOccurenceGuard } from './guards/can-show-event-occurence.guard';
import { CanShowEventGuard } from './guards/can-show-event.guard';
import { Event } from './models/event';
import { Reminder } from './models/reminder';
import { Task } from './models/task';
import { InterventionStatementsService } from './services/intervention-statements.service';
import { TradeActivitiesService } from './services/trade-activities.service';

// Ne pas supprimer. Nécessaire au fonctionnement du ModelResolver pour transformer les Event génériques de la lib en Task
const eventTypes = [Event, Task, Reminder];
@NgModule({
	declarations: [
		ActivitiesListComponent,
		TaskItemComponent,
		DayListViewComponent,
		EventItemComponent,
		EventsFilterBarComponent,
		TodoItemComponent,
		TodoItemInlineComponent,
		DelegatedTaskItemComponent,
		PrivateActivityItemComponent,
		ReminderItemComponent,
		LateItemComponent,
		RescheduleModalComponent
	],

	exports: [
		ActivitiesListComponent,
		TaskItemComponent,
		CalendarEventsModule,
		DayListViewComponent,
		EventItemComponent,
		EventsFilterBarComponent,
		TodoItemComponent,
		TodoItemInlineComponent,
		DelegatedTaskItemComponent,
		PrivateActivityItemComponent,
		ReminderItemComponent,
		LateItemComponent,
		RescheduleModalComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		VirtualScrollModule,
		CalendarEventsModule,
		CalendarModule,
		ListsModule,
		PipeModule,
		FilterModule,
		SortModule,
		DateModule,
		ContactsModule,
		BlankInformationModule,
		PriorityModule,
		SlidingListModule,
		SliderModule,
		SelectorModule
	],
	providers: [
		InterventionStatementsService,
		CanEditEventGuard,
		CanEditEventOccurenceGuard,
		TradeActivitiesService,
		CanShowEventOccurenceGuard,
		CanShowEventGuard
	]
})
export class TradeEventsModule { }