import { Exclude } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';

@Exclude()
export class ObjectObserver<T> {

	//#region FIELDS

	private readonly moChangeSubject: BehaviorSubject<T>;

	//#endregion

	//#region PROPERTIES

	/** Permet d'écouter les changements. */
	public get changes$(): Observable<T> {
		return this.moChangeSubject.asObservable();
	}

	//#endregion

	//#region METHODS

	constructor(poInitialValue: T) {
		this.moChangeSubject = new BehaviorSubject(poInitialValue);
	}

	/** Envoie un changement dans le canal.
	 * @param poNewValue 
	 */
	public emit(poNewValue: T): void {
		this.moChangeSubject.next(poNewValue);
	}

	//#endregion

}
