<ng-container *ngIf="observableIsLoading.value$ | async; else liste">
	<osapp-spinner></osapp-spinner>
</ng-container>

<ng-template #liste>
	<ng-container *ngIf="observableListItems.changes$ | async as items">
		<ng-container *ngIf="items.length > 0; else empty">
			<div class="content">
				<calao-virtual-scroll [itemSizeCalculator]="itemSizeCalculator" [items]="items" [itemSize]="98" autosize
					transparent margeLastItem>
					<ng-template let-item="$implicit">
						<ng-container [ngSwitch]="item.type">
							<ng-container *ngSwitchCase="'month'">
								<div class="month-title">
									{{item.value}}
								</div>
							</ng-container>

							<ng-container *ngSwitchCase="'day'">
								<div class="day-title">
									{{item.value}}
								</div>
							</ng-container>

							<ng-container *ngSwitchCase="'doc'">
								<calao-document-item [document]="item.value"
									[displayNavigationTree]="observableDisplayNavigationTree.value$ | async"
									[options]="(observableItemOptionsById.value$ | async)?.get(item.value._id)"
									(onOpenClicked)="onOpenClicked($event)" (onDetailClicked)="onDetailClicked($event, item.value)">
								</calao-document-item>
							</ng-container>
						</ng-container>
					</ng-template>
				</calao-virtual-scroll>
			</div>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #empty>
	<div *ngIf="observableDisplayEmptyText.value$ | async" class="empty">
		<ion-label>Aucun document dans ce dossier</ion-label>
	</div>
</ng-template>