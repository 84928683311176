<ng-container *ngIf="observableEvent.value$ | async as item">
	<div [style.backgroundColor]="item.color.primary" [style.borderColor]="item.color.primary"
		[style.color]="observableFontColor.value$ | async" [ngClass]="observableClass.value$ | async" class="cal-event">
		<ng-container *ngIf="!item.eventOccurrence.event.private || item.eventOccurrence.isMinePrivateEvent">
			<ion-icon class="event-icon" *ngIf="observableEventIcon.value$ | async as name" [name]="name"
				slot="start"></ion-icon>
			<ion-icon *ngIf="observableStatusIcon.value$ | async as name"
				[ngClass]="name == 'checkmark-circle' ? 'done-icon' : 'late-icon' " [name]="name" slot="start"></ion-icon>
		</ng-container>
		<ion-icon *ngIf="observableTitleIcon.value$ | async as titleIcon" [name]="titleIcon" class="title-icon"></ion-icon>
		<ion-label [ngClass]="(observableIsStrike.value$ | async) ? 'strike' : ''" class="title">
			{{ item.title }}</ion-label>
		<ng-container *ngIf="item.eventOccurrence.periodLabel$ | async as periodLabel">
			<ion-label [ngClass]="item.eventOccurrence?.fullDay ? '' : 'block'">{{ periodLabel }}</ion-label>
		</ng-container>
		<ng-container *ngIf="!item.eventOccurrence?.fullDay && displayParticipants">
			<div class="participants">
				<ion-label *ngIf="item.eventOccurrence.authorLabel$ | async as author" class="author">
					{{author}}{{ (author && item.eventOccurrence?.participantsLabel$ | async) ? "," : "" }}</ion-label>
				<ion-label *ngIf="item.eventOccurrence?.participantsLabel$ | async as participantsLabel">{{ participantsLabel
					}}</ion-label>
			</div>
		</ng-container>
		<ng-container *ngIf="!item.eventOccurrence?.fullDay && observableValidator.value$ | async as validator">
			<ion-label>{{ validator.lastName }} {{ validator.firstName }}</ion-label>
		</ng-container>
	</div>
</ng-container>