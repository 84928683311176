import { Injectable } from '@angular/core';
import { CanGoBackService as OsappCanGoBackService } from '@calaosoft/osapp/modules/routing/services/can-go-back.service';

@Injectable({
	providedIn: 'root'
})
export class CanGoBackService extends OsappCanGoBackService {

	//#region METHODS

	protected override testRoute(psDefaultHref: string, psRouterUrl: string): boolean {
		return psRouterUrl.split("/").length > 2; // /contacts = 2
	}

	//#endregion

}
