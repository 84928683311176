import { RouteComponent } from "../model/RouteComponent";
import { DocExplorerSlideComponent } from "../modules/doc-explorer/components/doc-explorer-slide/doc-explorer-slide.component";
import { DocumentHeaderComponent } from "../modules/doc-explorer/components/document-header/document-header.component";
import { EntityEntriesListComponent } from "../modules/entities/components/entity-entries-list/entity-entries-list.component";
import { EntityFormComponent } from "../modules/entities/components/entity-form/entity-form.component";
import { EntityHeaderComponent } from "../modules/entities/components/entity-header/entity-header.component";
import { SiteDetailsComponent } from "../modules/sites/components/site-details/site-details.component";
import { ConversationComponent } from "./conversation/conversation.component";
import { ConversationEditComponent } from "./conversation/edit/conversationEdit.component";
import { ConversationsListComponent } from "./conversation/list/conversations-list.component";
import { ConversationTabComponent } from "./conversation/tab/conversation-tab.component";
import { DynamicPageComponent } from "./dynamicPage/dynamicPage.component";
import { FormComponent } from "./forms/form/form.component";
import { FormListComponent } from "./forms/formList/formList.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { GoogleMapComponent } from "./geolocation/googleMap.component";
import { LinkedItemsListComponent } from "./linkedItemsList/linkedItemsList.component";
import { MenuComponent } from "./menu/menu.component";
import { SearchComponent } from "./search/search.component";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { WebViewComponent } from './webview/webview.component';

/** Liste de tous les composants d'OSApp */
export const RouteComponentRegistries: RouteComponent[] = [
	{ id: 'dynamicPage', componentType: DynamicPageComponent },
	{ id: 'conversation', componentType: ConversationComponent },
	{ id: 'conversationEdit', componentType: ConversationEditComponent },
	{ id: 'conversationList', componentType: ConversationsListComponent },
	{ id: 'conversationTab', componentType: ConversationTabComponent },
	{ id: 'site-details', componentType: SiteDetailsComponent },
	{ id: 'form', componentType: FormComponent },
	{ id: 'formList', componentType: FormListComponent },
	{ id: 'gallery', componentType: GalleryComponent },
	{ id: 'googleMap', componentType: GoogleMapComponent },
	{ id: 'home', componentType: MenuComponent },
	{ id: 'linkedItemsList', componentType: LinkedItemsListComponent },
	{ id: 'search', componentType: SearchComponent },
	{ id: 'webview', componentType: WebViewComponent },
	{ id: 'toolbar', componentType: ToolbarComponent },
	{ id: "doc-explorer", componentType: DocExplorerSlideComponent },
	{ id: "entity-form", componentType: EntityFormComponent },
	{ id: "entity-header", componentType: EntityHeaderComponent },
	{ id: "document-header", componentType: DocumentHeaderComponent },
	{ id: "entity-entries-list", componentType: EntityEntriesListComponent }
];
