import { DatePipe } from '@angular/common';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { Contacts } from '@awesome-cordova-plugins/contacts/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { Screenshot } from '@awesome-cordova-plugins/screenshot/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Config } from '@ionic/angular';
import { ArchivingService } from './archiving.service';
import { GalleryService } from './gallery.service';
import { PlatformService } from './platform.service';
import { PopoverService } from './popover.service';

function initializeAppFactory(psvcPlatform: PlatformService): () => Promise<void> {
	return () => psvcPlatform.initPlatformInfo();
}

const providers: Provider[] = [
	SocialSharing,
	Screenshot,
	OpenNativeSettings,
	InAppBrowser,
	DatePipe,
	Network,
	OneSignal,
	Contacts,
	Config,
	PopoverService,
	GalleryService,
	ArchivingService,
	{ provide: APP_INITIALIZER, deps: [PlatformService], useFactory: initializeAppFactory, multi: true }
];

/** Ce module regroupe les services généraux de la bibliothèque et les instancie. */
@NgModule({
	imports: [],
	exports: [],
	declarations: [],
	providers: providers
})
export class ServiceModule { }
