<ng-container *ngIf="items$ | async as listItems">
	<ng-container *ngIf="listItems.length === 0 && !(observableHideBlankInformation.value$ | async)">
		<div class="blank-information">
			<calao-blank-information icon="task" [title]="observableBlankInformationTitle.value$ | async"
				[subtitle]="observableBlankInformationSubtitle.value$ | async"></calao-blank-information>
		</div>
	</ng-container>
	<calao-virtual-scroll [items]="listItems" [itemSizeCalculator]="itemSizeCalculator" [minBufferPx]="400"
		[maxBufferPx]="500" [startTemplate]="virtualScrollStartTemplate" autosize margeLastItem hasLoadMoreButton
		transparent (onLoadMoreClicked)="onLoadMoreClicked()">
		<ng-template let-item="$implicit" let-index="index">
			<ion-label *ngIf="item.dateLabel" color="primary"
				[class]="isToday(item.date) ? 'now-label' : 'date-label'">{{item.dateLabel}}</ion-label>
			<ng-container *ngIf="item.occurrence">
				<ng-container [ngSwitch]="item.occurrence.eventType">
					<ng-container *ngSwitchCase="tradeEventTypes.task">
						<ng-container
							*ngTemplateOutlet="(observableUseDelegatedItem.value$ | async) ?  delegatedtaskItem : taskItem; context: { item : item.occurrence }"></ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="tradeEventTypes.reminder">
						<trade-reminder-item [item]="item.occurrence"></trade-reminder-item>
					</ng-container>
					<trade-event-item *ngSwitchDefault [item]="item.occurrence"></trade-event-item>
				</ng-container>
			</ng-container>
		</ng-template>
	</calao-virtual-scroll>
</ng-container>

<ng-template #taskItem let-item="item">
	<trade-task-item [taskOccurrence]="item"></trade-task-item>
</ng-template>

<ng-template #delegatedtaskItem let-item="item">
	<trade-delegated-task-item [taskOccurrence]="item"></trade-delegated-task-item>
</ng-template>