<osapp-header-modal title="Résumer la conversation via IA"></osapp-header-modal>

<ion-content class="ion-padding" *ngIf="(observableConsent.value$ | async)">
	<ion-text>Vous pouvez obtenir un résumé de cette conversation grâce à l'IA générative !</ion-text>

	<ion-button color="ai-button" expand="block" (click)="summarize()">Résumer</ion-button>
	<ion-text class="label-grey">Quota restant : {{(observableUserUsage.value$ | async)?.percentageLeft}}%</ion-text>
</ion-content>

<ion-content class="ion-padding" *ngIf="!(observableConsent.value$ | async)">
	<ion-text>Vous pouvez obtenir un résumé de cette conversation via une IA générative.</ion-text>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-text class="ion-text-wrap txt14px">Avis d'utilisation de service externe.
					<br />
					En utilisant ce service, vous accédez à une intelligence artificielle générative externe. Vous êtes
					responsable du contenu que vous soumettez et de l'utilisation que vous faites des résultats générés. Veuillez
					utiliser ce service de manière responsable et conforme à nos conditions d'utilisation.
					<br />
					Pour en savoir plus, veuillez consulter nos CGU. <a *ngIf="cguUrl"
						[href]="cguUrl"
						target="_blank">{{cguUrl}}</a>
				</ion-text>

			</ion-col>
			<ion-col size="auto">
				<ion-button (click)="consent()">Je consens</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>