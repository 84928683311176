import { ArrayHelper } from "../../../helpers/arrayHelper";
import { DateHelper } from "../../../helpers/dateHelper";
import { StringHelper } from "../../../helpers/stringHelper";
import { Document } from "./document";
import { Folder } from "./folder";
import { IDocExlorerFilterValues } from "./idoc-explorer-filter-values";

export class FolderContent {

	//#region PROPERTIES

	public current: Folder;
	/** Liste des dossiers enfants. */
	public folders: FolderContent[] = [];
	/** Liste des fichiers du dossier courant. */
	public documents: Document[] = [];

	//#endregion

	//#region METHODS

	public getDocumentsRecursively(pbExcludeTrash?: boolean): Document[] {
		return [...this.documents, ...(this.folders.map((poFolder: FolderContent) => pbExcludeTrash && poFolder.current.isInTrash ? [] : poFolder.getDocumentsRecursively())).flat()];
	}

	public searchDocuments(poFilters: IDocExlorerFilterValues, pbRecursively?: boolean, pbExcludeTrash?: boolean): Document[] {
		return (pbRecursively ? this.getDocumentsRecursively(pbExcludeTrash) : this.documents).filter((poDocument: Document) => {
			if (!StringHelper.isBlank(poFilters.name) && !poDocument.name.toLowerCase().includes(poFilters.name.toLowerCase()))
				return false;
			if (poFilters.dateRange && !DateHelper.isBetweenTwoDates(poDocument.displayDate ?? new Date, poFilters.dateRange.from, poFilters.dateRange.to))
				return false;
			if (poFilters.notRead && poDocument.observableIsRead.value)
				return false;
			if (ArrayHelper.hasElements(poFilters.authorIds) && !poFilters.authorIds.includes(poDocument.authorId))
				return false;

			return true;
		});
	}

	public searchFolders(poFilters: IDocExlorerFilterValues): FolderContent[] {
		return this.folders.filter((poFolderContent: FolderContent) => {
			if (!StringHelper.isBlank(poFilters.name) && !poFolderContent.current.name.toLowerCase().includes(poFilters.name.toLowerCase()))
				return false;
			if (poFilters.notRead && poFolderContent.current.documentsCounters.notRead === 0)
				return false;
			return true;
		});
	}

	//#endregion METHODS

}
