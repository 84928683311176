import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ContactPickerContactItemBase } from '../../../modules/contacts/models/contacts-picker/contacts-picker-contact-item-base';

/** @deprecated */
@Component({
	selector: "osapp-contact-selector-item",
	templateUrl: './contact-selector-item.component.html',
	styleUrls: ['./contact-selector-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSelectorItemComponent extends ContactPickerContactItemBase {

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	//#endregion

}