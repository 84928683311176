<ng-container *ngIf="observableItem.value$ | async as observedItem">
	<ion-item *ngIf="observableParams.value$ | async as observedParams" class="ion-no-padding" lines="none"
		(click)="onItemClicked()">
		<calao-list-item>
			<calao-list-item-start
				*ngIf="observedParams.start && !observedParams.start.hidden && getText(observedParams.start) as text">
				<calao-list-item-start-content [icon]="observedParams.start.iconName" [text]="text"
					[label]="observedParams.start.label">
				</calao-list-item-start-content>
			</calao-list-item-start>
			<calao-list-item-content [detail]="observableDetail.value$ | async" (onDetailClicked)="onDetailClicked($event)"
				detailIcon="ellipsis-vertical">
				<calao-list-item-content-title
					*ngIf="observedParams.title && !observedParams.title.hidden && getText(observedParams.title) as title">
					{{ title }}
				</calao-list-item-content-title>
				<span *ngIf="getText(observedParams.description) as description">{{ description }}</span>
			</calao-list-item-content>
		</calao-list-item>
	</ion-item>
</ng-container>