<calao-filter-bar [filterValues]="observableFilterValues.value$ | async"
	(onFilterValuesChange)="onFilterValuesChange($any($event))"
	(onTmpFilterValuesChange)="onTmpFilterValuesChange($any($event))"
	[nbTmpResults]="observableNbTmpResults.value$ | async" #filterBar>
	<calao-filter-bar-main-content>
		<calao-filter-bar-item #positionItem key="position" class="hidden">
		</calao-filter-bar-item>
		<calao-filter-bar-item #locationRangeItem key="locationRange" class="hidden">
		</calao-filter-bar-item>
		<calao-filter-bar-item key="text">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher parmi les prospects et clients"
					[value]="observableValue.value$ | async" (onValueChange)="observableValue.value = $event"
					[filterBadgeLabel]="nbFiltersLabel$ | async">
					<calao-filter-bar-geographical-search-input #geoInput class="hidden"
						[currentPosition]="positionItem.observableValue.value$| async"
						(onLocationChanged)="positionItem.observableValue.value = $event; locationRangeItem.observableValue.value = geoDefault">
					</calao-filter-bar-geographical-search-input>
					<ion-button color="primary" fill="outline" slot="end" class="filter-button" (click)="geoInput.locate()">
						<ion-icon slot="icon-only" name="locate"></ion-icon>
					</ion-button>
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-tags>
			<calao-filter-bar-item key="sort">
				<ng-template let-observableValue="observableValue">
					<calao-sort-selector [options]="sortOptions" [by]="$any(observableValue.value$ | async)?.by ?? defaultSortBy"
						[order]="$any(observableValue.value$ | async)?.order ?? defaultSortOrder" [size]="sortSize"
						(onSortChanged)="observableValue.value = $event"></calao-sort-selector>
				</ng-template>
			</calao-filter-bar-item>
			<calao-filter-bar-tags-tag *ngIf="observableHasLocation.value$ | async" key="position" icon="locate"
				[label]="observableGeoRangeLabel.value$ | async">
			</calao-filter-bar-tags-tag>
			<calao-filter-bar-tags-tag [label]="nbMoreFiltersLabel$ | async">
			</calao-filter-bar-tags-tag>
		</calao-filter-bar-tags>
	</calao-filter-bar-main-content>
	<calao-filter-bar-modal-content #modal canValidate>
		<calao-filter-bar-item key="text">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher parmi les prospects et client"
					[value]="observableValue.value$ | async" (onValueChange)="observableValue.value = $event" [hasModal]="false">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
		<div class="border">
			<calao-filter-bar-item key="position">
				<ng-template let-observableValue="observableValue">
					<calao-filter-bar-item-title>Autour de moi</calao-filter-bar-item-title>
					<calao-filter-bar-geographical-search-input [currentPosition]="observableValue.value$ | async"
						(onLocationChanged)="observableValue.value = $event"></calao-filter-bar-geographical-search-input>
				</ng-template>
			</calao-filter-bar-item>
			<calao-filter-bar-item key="locationRange" [defaultValue]="geoDefault">
				<ng-template let-observableValue="observableValue">
					<ng-container *ngIf="(modal.observableFilterValues.value$ | async)?.position">
						<div class="location-range-label">
							<ion-note>Dans un rayon autour de</ion-note>
							<ion-note class="location-range-value-label">{{observableValue.value$ | async}} km</ion-note>
						</div>
						<ion-range #range [min]="geoMin" [max]="geoMax" [step]="5" [pin]="true"
							[value]="observableValue.value$ | async" (ionChange)="observableValue.value = range.value" [ticks]="true"
							[snaps]="true">
							<ion-label slot="start">{{geoMin}} km</ion-label>
							<ion-label slot="end">{{geoMax}} km</ion-label>
						</ion-range>
					</ng-container>
				</ng-template>
			</calao-filter-bar-item>
		</div>
		<calao-filter-bar-item key="legalIdentity" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Identité juridique</calao-filter-bar-item-title>
				<calao-filter-bar-item-selector-field [options]="legalIdentitySelectOptions"
					[preselectedValues]="observableValue.value$ | async" multiple
					(onSelectionChanged)="observableValue.value = $event" placeholder="Sélectionner une identité ..."
					[displayMode]="selectorDisplayMode"></calao-filter-bar-item-selector-field>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-item key="types" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Type</calao-filter-bar-item-title>
				<calao-filter-bar-item-selector-field [options]="typeSelectOptions"
					[preselectedValues]="observableValue.value$ | async" multiple
					(onSelectionChanged)="observableValue.value = $event" placeholder="Sélectionner un type ..."
					[displayMode]="selectorDisplayMode"></calao-filter-bar-item-selector-field>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-item key="priority" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Priorité maxi</calao-filter-bar-item-title>
				<calao-slider [minValue]="1" [maxValue]="5" [step]="1" [pin]="true" [ticks]="true" [snaps]="true"
					[value]="observableValue.value$ | async" [showMinValue]="true" [showMaxValue]="true"
					[colors]="observableColors.value$ | async" [defaultValue]="(observableFilterValues.value$ | async).priority"
					minLabel="Forte" maxLabel="Faible" (onValueChange)="observableValue.value = $event"></calao-slider>
			</ng-template>
		</calao-filter-bar-item>
		<calao-filter-bar-item key="prospectStates" class="border">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-item-title>Étape de prospection</calao-filter-bar-item-title>
				<calao-filter-bar-item-selector-field [options]="prospectStateSelectOptions"
					[preselectedValues]="observableValue.value$ | async" multiple
					(onSelectionChanged)="observableValue.value = $event" placeholder="Sélectionner une étape ..."
					[displayMode]="selectorDisplayMode"></calao-filter-bar-item-selector-field>
			</ng-template>
		</calao-filter-bar-item>
	</calao-filter-bar-modal-content>
</calao-filter-bar>

<ng-content></ng-content>

<ng-container *ngTemplateOutlet="databaseContent"></ng-container>

<!-- Template pour le contenu issu de la base de données. -->
<ng-template #databaseContent>
	<ng-container *ngIf="filteredBusinesses$ | async as filteredDocuments; else loadingSpinner;">
		<!-- Affichage de la liste. -->
		<ng-container *ngIf="filteredDocuments.length !== 0; else emptyScreen;">
			<calao-virtual-scroll [items]="filteredDocuments" [itemSize]="100" autosize transparent margeLastItem>
				<ng-template let-item="$implicit">
					<trade-business-item [item]="item" (onDeleteItemClicked)="onDeleteItemClicked($event)"
						(onItemClicked)="onItemClicked($event)" (onItemEditClicked)="onItemEditClicked($event)">
					</trade-business-item>
				</ng-template>
			</calao-virtual-scroll>
		</ng-container>
	</ng-container>

	<!-- Afficher s'il y a un chargement -->
	<ng-template #loadingSpinner>
		<div class="full-width">
			<osapp-spinner>
			</osapp-spinner>
		</div>
	</ng-template>

	<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
	<ng-template #emptyScreen>
		<div class="empty-screen flex-col">
			<span class="txt-message">
				<ion-note>Aucun résultat.</ion-note>
			</span>
		</div>
	</ng-template>
</ng-template>