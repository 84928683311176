<div *ngIf="leafletMapOptions.value$| async" leaflet (leafletMapReady)="onMapReady($event);"
	[leafletOptions]="(leafletMapOptions.value$| async)" [leafletLayers]="(leafletLayerOptions.changes$| async)"
	class="map">

	<div *ngFor="let button of options.buttons;index as i" class="list-button">

		<ion-button [ngStyle]="{'top': + (i+2) * 40 + 'px'}" (click)="navigateAsync(button)" class="back-button"
			color="white">
			<ion-icon [name]="button.icon"></ion-icon>
		</ion-button>
	</div>
</div>