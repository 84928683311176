/** Sert à stocker un consentement */
export class ConsentObject {

	//#region PROPERTIES

	/** Date du consentement */
	public date: Date;

	/** Version du consentement */
	public version: number;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(pdDate: Date, pnVersion: number) {
		this.date = pdDate;
		this.version = pnVersion;
	}

	//#endregion METHODS

}