import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { UserHelper } from '@calaosoft/osapp/helpers/user.helper';
import { ResolveModel } from '@calaosoft/osapp/modules/utils/models/decorators/resolve-model.decorator';
import { Exclude, Expose } from 'class-transformer';
import { EConstraintType } from './EConstraintType';
import { IConstraint } from './iconstraint';
import { IConstraintLabelParams } from './iconstraint-label-params';
import { IEventOccurrence } from './itournee-event-occurrence';
import { EventOccurrenceComparator } from './tournee-event-occurrence-comparator';

export abstract class Constraint implements IConstraint {

	//#region PROPERTIES

	/** @implements */
	@Exclude()
	@ResolveModel(EventOccurrenceComparator)
	private maOccurrenceComparators: EventOccurrenceComparator[];
	@Expose()
	public get occurrenceComparators(): EventOccurrenceComparator[] {
		if (!this.maOccurrenceComparators)
			this.maOccurrenceComparators = [];

		return this.maOccurrenceComparators;
	}
	public set occurrenceComparators(paOccurrenceComparators: EventOccurrenceComparator[]) {
		if (paOccurrenceComparators !== this.maOccurrenceComparators)
			this.maOccurrenceComparators = paOccurrenceComparators;
	}
	/** @implements */
	public abstract readonly type: EConstraintType;

	/** @implements */
	public decription?: string;
	@Exclude()
	private msCreateUserContactId: string = UserHelper.getUserContactId();
	/** @implements */
	@Expose()
	public get createUserContactId(): string { return this.msCreateUserContactId; }
	public set createUserContactId(psCreateUserContactId: string) {
		if (psCreateUserContactId !== this.msCreateUserContactId)
			this.msCreateUserContactId = psCreateUserContactId;
	}

	@Exclude()
	private mdCreateDate: Date;
	/** @implements */
	@Expose()
	public get createDate(): Date { return this.mdCreateDate; }
	public set createDate(pdCreateDate: Date) {
		if (DateHelper.isDate(pdCreateDate) && pdCreateDate !== this.mdCreateDate)
			this.mdCreateDate = new Date(pdCreateDate);
	}

	//#endregion

	//#region METHODS

	constructor() {
		this.mdCreateDate = new Date;
	}

	public getLabel(poParams: IConstraintLabelParams): string {
		return `Séance(s) ${this.getOccurrencesLabel(poParams)}: ${this.buildlabel(poParams)}`;
	};

	/** Indique si on peut appliquer la contrainte à l'occurrence.
	 * @param poEventOccurrence
	 */
	public match(poEventOccurrence: IEventOccurrence): boolean {
		if (!ArrayHelper.hasElements(this.occurrenceComparators))
			return true;
		return this.occurrenceComparators.every((poOccurenceComparator: EventOccurrenceComparator) => poOccurenceComparator.match(poEventOccurrence));
	}

	/** Action à effectuer sur l'occurrence.
	 * @param poEventOccurrence
	 */
	public abstract action(poEventOccurrence: IEventOccurrence): void;

	/** Construit la description de la contrainte. */
	public abstract buildlabel(poParams?: IConstraintLabelParams): string;

	public getIntervenantsIds(): string[] {
		return [];
	}

	/** Récupère la description des occurrences. */
	private getOccurrencesLabel(poParams: IConstraintLabelParams): string {
		return `${this.occurrenceComparators.map((poItem: EventOccurrenceComparator) => poItem.getLabel(poParams)).join(" - ")} `;
	}

	//#endregion

}
