import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { IonicModule } from '@ionic/angular';
import { ContactsModule as LegacyContactsModule } from '../../components/contacts/contacts.module';
import { SearchModule } from '../../components/search/search.module';
import { PipeModule } from '../../pipes/pipes.module';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { BadgeModule } from '../badge/badge.module';
import { ConflictsModule } from '../conflicts/conflicts.module';
import { FilterModule } from '../filter/filter.module';
import { GroupsModule } from '../groups/groups.module';
import { ModalModule } from "../modal/modal.module";
import { SelectorModule } from '../selector/selector.module';
import { TabsModule } from '../tabs/tabs.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { ContactItemComponent } from './components/contact-item/contact-item.component';
import { ContactsFilterBarComponent } from './components/contacts-filter-bar/contacts-filter-bar.component';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';
import { ContactsPickerContactItemComponent } from './components/contacts-picker-contact-item/contacts-picker-contact-item.component';
import { ContactsPickerModalComponent } from './components/contacts-picker-modal/contacts-picker-modal.component';

const components: Type<any>[] = [
	ContactItemComponent,
	ContactsPickerContactItemComponent,
	ContactsListComponent,
	ContactsFilterBarComponent,
	ContactsPickerContactItemComponent,
	ContactsPickerModalComponent
];

@NgModule({
	declarations: components,
	imports: [
		IonicModule,
		CommonModule,
		CalaoAvatarModule,
		ConflictsModule,
		BadgeModule,
		VirtualScrollModule,
		FilterModule,
		GroupsModule,
		LegacyContactsModule,
		ModalModule,
		PipeModule,
		MatRadioModule,
		TabsModule,
		SearchModule,
		SelectorModule,
		MatBadgeModule
	],
	exports: components
})
export class ContactsModule { }