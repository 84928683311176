<osapp-header-modal title="Planification"></osapp-header-modal>

<ion-content>
	<ion-grid>
		<ion-row>
			<ion-col size="3">Contact</ion-col>
			<ion-col size="9">
				<p class="ion-padding txt-right">{{ contact | contactName }}</p>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col size="3">Tranche</ion-col>
			<ion-col size="9">
				<ion-select #slotSelector placeholder="Selectionnez ici" cancelText="Annuler" interface="popover"
					(ionChange)="onSlotSelectionChange(slotSelector.value)" class="txt-right">
					<ion-select-option *ngFor="let slot of slots" [value]="slot">{{ slot.label }}</ion-select-option>
				</ion-select>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col size="3">Dates</ion-col>
			<ion-col size="9">
				<calao-date-range-picker [model]="dateRange" (dateRangeChanged)="onDateRangeChanged($event)" class="txt-right">
				</calao-date-range-picker>
			</ion-col>
		</ion-row>
	</ion-grid>

	<div class="buttons">
		<ion-button (click)="close()" fill="clear" color="danger">
			Annuler
		</ion-button>
		<ion-button (click)="validatePlanification()" color="primary" fill="clear">
			Valider
		</ion-button>
	</div>
</ion-content>