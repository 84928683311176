import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { InAppBrowser, InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Directory } from '@capacitor/filesystem';
import { EMPTY, Observable, defer, from, of, throwError } from 'rxjs';
import { catchError, finalize, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { StringHelper } from '../helpers/stringHelper';
import { ENetworkFlag } from '../model/application/ENetworkFlag';
import { EPlatform } from '../model/application/EPlatform';
import { ConfigData } from '../model/config/ConfigData';
import { IUiResponse } from '../model/uiMessage/IUiResponse';
import { IUpdate } from '../model/update/IUpdate';
import { IUpdatePlatformConfig } from '../model/update/IUpdatePlatformConfig';
import { AuthenticatedRequestOptionBuilder } from '../modules/api/models/authenticated-request-option-builder';
import { FilesystemService } from '../modules/filesystem/services/filesystem.service';
import { Loader } from '../modules/loading/Loader';
import { ELogActionId } from '../modules/logger/models/ELogActionId';
import { LoggerService } from '../modules/logger/services/logger.service';
import { FlagService } from './flag.service';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { LoadingService } from './loading.service';
import { PlatformService } from './platform.service';
import { UiMessageService } from './uiMessage.service';

interface IUpdateAvailableData {
	/** Version courante de l'application. */
	currentVersion: string;
	/** Version proposée par la mise à jour. */
	availableVersion: string;
}

interface IUserDeclinedUpdateData extends IUpdateAvailableData {
	/** Identifiant de l'utilisateur. */
	userId: string;
}

interface IUpdateInstallState {
	/** Indique si l'installation a échoué, `false` par défaut. */
	hasFailed?: boolean;
	/** Indique si l'installation a échoué pendant le téléchargement, `false` par défaut. */
	errorEncounteredDuringDownload?: boolean;
}

/** Gestion des mises à jour de l'application. */
@Injectable({ providedIn: "root" })
export class UpdateService {

	//#region FIELDS

	private static readonly C_LOG_ID = "UPD.S::";
	private static readonly C_ANDROID_APP_MIME_TYPE = "application/vnd.android.package-archive";
	private static readonly C_MAJ_TITLE = "Mise à jour";
	private static readonly C_MAJ_MESSAGE = 'Une mise à jour est disponible';
	private static readonly C_INSTALL_TEXT = "Installer";
	private static readonly C_CANCEL_TEXT = "Plus tard";
	private static readonly C_INSTALL_BUTTON_CSS_CLASS = "button-positive";

	/** Lorsque le téléchargement de la mise à jour est en cours, contient le dernier pourcentage de progression. */
	private mnLastNotifiedProgressPercent: number;

	//#endregion

	//#region METHODS

	constructor(
		private ioFileTransfer: FileTransfer,
		private ioFileOpener: FileOpener,
		private ioHttpClient: HttpClient,
		private isvcUiMessage: UiMessageService,
		private ioInAppBrowser: InAppBrowser,
		private isvcLoading: LoadingService,
		private isvcPlatform: PlatformService,
		private isvcFlag: FlagService,
		private readonly isvcLogger: LoggerService,
		private readonly isvcFilesystem: FilesystemService
	) { }

	/** Retourne la configuration de mise à jour souhaitée, en fonction de la plateforme de l'appareil. */
	public getPlatformUpdateConfig(): IUpdatePlatformConfig {
		return ConfigData.update[ConfigData.appInfo.platform] as IUpdatePlatformConfig;
	}

	/** Télécharge un apk à partir d'un serveur de dépôt et lance une popup d'installation.
	 * @param poUpdate Mise à jour disponible.
	 * @param psInstallFileName Nom de l'application utilisé pour l'enregistrement de l'APK.
	 * @param pfProgress Fonction notifiée à chaque progression du téléchargement.
	 */
	private downloadAndInstallApk(poUpdate: IUpdate, psInstallFileName: string, pfProgress?: (pnProgressPercent: number) => void): Observable<boolean> {
		let lsTargetPath: string;
		const loFileTransfer: FileTransferObject = this.ioFileTransfer.create();

		console.debug(`${UpdateService.C_LOG_ID}Downloading APK from ${poUpdate.downloadUrl}...`);

		loFileTransfer.onProgress((poProgressEvent: ProgressEvent) => this.onFileTransferProgress(poProgressEvent, pfProgress));

		return defer(async () => await this.isvcFilesystem.getFileUriAsync(psInstallFileName, Directory.External))
			.pipe(
				mergeMap((psTargetPath: string) => loFileTransfer.download(poUpdate.downloadUrl, lsTargetPath = psTargetPath, true, {})),
				catchError(poError => {
					console.error(`${UpdateService.C_LOG_ID}Update download failed: `, poError);
					this.emitUpdateInstallationState(poUpdate, { hasFailed: true, errorEncounteredDuringDownload: true } as IUpdateInstallState);
					return throwError(() => poError);
				}),
				mergeMap(poResult => {
					console.debug(`${UpdateService.C_LOG_ID}Download complete, opening file ...`, poResult);
					return from(this.ioFileOpener.open(lsTargetPath, UpdateService.C_ANDROID_APP_MIME_TYPE))
						.pipe(
							catchError(poError => {
								console.error(`${UpdateService.C_LOG_ID}Update install failed: `, poError);
								this.emitUpdateInstallationState(poUpdate, { hasFailed: true } as IUpdateInstallState);
								return throwError(() => poError);
							}));
				}),
				tap(poResult => console.debug(`${UpdateService.C_LOG_ID}Android package installation launched: ${lsTargetPath}.`, poResult)),
				mapTo(true)
			);
	}

	private onFileTransferProgress(poProgressEvent: ProgressEvent, pfProgress?: (pnProgressPercent: number) => void): void {
		const lnPercent: number = Math.round((poProgressEvent.loaded / poProgressEvent.total) * 100);

		if (lnPercent !== this.mnLastNotifiedProgressPercent) {	// Notifie l'événement uniquement si la valeur entière du pourcentage de progression est différente.
			this.mnLastNotifiedProgressPercent = lnPercent;
			if (pfProgress)
				pfProgress(lnPercent);
		}
	}

	/** Recherche si une mise à jour est disponible, puis, affiche une popup à l'utilisateur si c'est le cas.
	 * @returns `null` | `IUpdate`. `null` si pas de mise à jour par rapport à la version actuelle.
	 */
	public popupUpdate(): Observable<IUpdate> {
		return this.getAvailableUpdate()
			.pipe(
				mergeMap((poUpdate?: IUpdate) => {
					if (poUpdate) { // On ne peut faire des mises à jours que sur un device android.
						console.info(`${UpdateService.C_LOG_ID}Update available at "${poUpdate.downloadUrl}".`);
						this.emitUpdateAvailableAction(poUpdate);

						return defer(() => ConfigData.appInfo.platform === EPlatform.android ? this.onAndroidUpdateAvailable(poUpdate) : this.onOtherPlatformUpdateAvailable(poUpdate))
							.pipe(
								mapTo(poUpdate) // On retourne l'url de téléchargement du package en cas de mise à jour disponible.
							);
					}
					else {
						console.debug(`${UpdateService.C_LOG_ID}No update available.`);
						return of(null);
					}
				})
			);
	}

	/** Affiche une popup. Télécharge la mise à jour si l'utilisateur clique sur le bouton.
	 * @param poUpdate Informations sur la nouvelle version.
	 */
	private onAndroidUpdateAvailable(poUpdate: IUpdate): Observable<IUiResponse<void>> {
		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				header: UpdateService.C_MAJ_TITLE,
				message: `${UpdateService.C_MAJ_MESSAGE} : ${poUpdate.name} ${poUpdate.version}.${poUpdate.changeLog ? `<br />${poUpdate.changeLog}` : ""}`,
				buttons: [
					{ text: UpdateService.C_CANCEL_TEXT, handler: () => this.emitDeclineUpdateAction(poUpdate) },
					{ text: UpdateService.C_INSTALL_TEXT, cssClass: UpdateService.C_INSTALL_BUTTON_CSS_CLASS, handler: () => this.install(poUpdate) }
				]
			})
		);
	}

	private install(poUpdate: IUpdate): Promise<boolean> {
		let loLoader: Loader;

		return from(this.isvcLoading.create("0%"))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.downloadAndInstallApk( // On appelle le service de mise à jour.
					poUpdate,
					`${ConfigData.appInfo.appId}_${poUpdate.version}.apk`,
					(pnProgressPercent: number) => this.onApkDownloading(pnProgressPercent, loLoader)
				)),
				tap(
					_ => console.log(`${UpdateService.C_LOG_ID}Install complete.`),
					_ => this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "L'installation de la mise à jour a échoué.", header: "Erreur" }))
				),
				finalize(() => this.isvcLoading.dismiss())
			)
			.toPromise();
	}

	private onApkDownloading(pnProgressPercent: number, poLoader: Loader): void {
		console.debug(`${UpdateService.C_LOG_ID}Downloading... ${pnProgressPercent}%`);
		poLoader.text = `${pnProgressPercent}%`;
	}

	/** Ouverture de la page de téléchargement de la nouvelle version, dans le navigateur.
	 * @param poUpdate Informations sur la nouvelle version.
	 */
	private onOtherPlatformUpdateAvailable(poUpdate: IUpdate): Observable<IUiResponse<void>> {
		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				message: UpdateService.C_MAJ_MESSAGE,
				header: UpdateService.C_MAJ_TITLE,
				buttons: [
					{
						text: UpdateService.C_CANCEL_TEXT,
						handler: () => this.emitDeclineUpdateAction(poUpdate)
					},
					{
						text: UpdateService.C_INSTALL_TEXT,
						cssClass: UpdateService.C_INSTALL_BUTTON_CSS_CLASS,
						handler: () => {
							const loInAppBrowserInstance: InAppBrowserObject = this.ioInAppBrowser.create(poUpdate.downloadUrl, "_system", "location=yes");

							loInAppBrowserInstance.on("loaderror")
								.pipe(
									take(1),
									tap(_ => this.emitUpdateInstallationState(poUpdate, { hasFailed: true } as IUpdateInstallState))
								)
								.toPromise();

							loInAppBrowserInstance.show();
						}
					}
				]
			})
		);
	}

	/** Retourne la mise à jour disponible ou `null` si aucune mise à jour.
	 * @returns `null` | `IUpdate`. `null` si pas de mise à jour par rapport à la version actuelle.
	 */
	public getAvailableUpdate(): Observable<IUpdate> {
		if (ConfigData.environment.storeRelease)
			return EMPTY;

		return this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(
				mergeMap(_ => ConfigData.appInfo.platform === EPlatform.browser ?
					throwError(() => "Les mises à jour ne sont pas disponibles sur navigateur.") : of(this.getPlatformUpdateConfig())
				),
				mergeMap((poConfig?: IUpdatePlatformConfig) => {
					if (!poConfig)
						return throwError(() => `Aucune configuration de mise à jour n'est disponible pour la plateforme '${ConfigData.appInfo.platform}'.`);
					// On vérifie si on peut contacter le serveur pour savoir si l'application doit se mettre à jour.
					else if (StringHelper.isBlank(poConfig.updatesApiUrl))
						return throwError(() => "Aucune URL n'a été fournie pour la recherche de mises à jour.");
					else {
						return this.sendUpdateRequest(poConfig)
							.pipe(
								catchError(poError => {
									const lsErrorMessage = "Échec de la requête de vérification des mises à jour.";
									console.error(`${UpdateService.C_LOG_ID}${lsErrorMessage} Url : `, poConfig.updatesApiUrl, "Erreur: ", poError);
									return throwError(() => lsErrorMessage);
								}),
								mergeMap((poResponse: HttpResponse<IUpdate>) => this.getUpdateFromRequestResponse(poResponse))
							);
					}
				})
			);
	}

	/** Envoie la requête HTTP vers le serveur afin de récupérer la mise à jour.
	 * @param poConfig Configuration de mise à jour de la plateforme.
	 */
	private sendUpdateRequest(poConfig: IUpdatePlatformConfig): Observable<HttpResponse<IUpdate>> {
		console.debug(`${UpdateService.C_LOG_ID}Checking available update for version ${ConfigData.appInfo.appVersion} on ${poConfig.updateProvider} at ${poConfig.updatesApiUrl}...`);

		ConfigData.appInfo.updateProvider = poConfig.updateProvider;

		const loHeaders = new HttpHeaders({
			appInfo: (AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions().headers as HttpHeaders).get("appInfo"),
			applicationId: this.isvcPlatform.widgetId,
			"api-key": ConfigData.environment.API_KEY
		});

		return this.ioHttpClient.get<IUpdate>(poConfig.updatesApiUrl, { headers: loHeaders, observe: "response" });
	}

	/** Récupère l'objet de mise à jour depuis la requête HTTP effectuée.
	 * @param poResult Objet résultat de la requête de récupération de la mise à jour.
	 */
	private getUpdateFromRequestResponse(poResult: HttpResponse<IUpdate>): Observable<IUpdate> {
		let loUpdate$: Observable<IUpdate | never>;

		if (poResult.status === 200) { // Récupération d'une mise à jour.
			// Ce cas a l'air de se présenté uniquement si 'body' est 'undefined' donc on ne devrait pas obtenir une réponse 200.
			if (StringHelper.isBlank(JSON.stringify(poResult.body))) {
				console.error(`${UpdateService.C_LOG_ID}Résultat requête mise à jour illisible (body) :`, poResult);
				loUpdate$ = throwError(() => "Impossible de lire la nouvelle version de mise à jour.");
			}
			else
				loUpdate$ = of(poResult.body as IUpdate);
		}

		else if (poResult.status === 204)	// Aucune mise à jour disponible.
			loUpdate$ = of(undefined);

		else { // Résultat non attendu.
			console.error(`${UpdateService.C_LOG_ID}Status HTTP attendu lors de la récupération de la mise à jour: 200 ou 204. Obtenu: ${poResult.status}. ${poResult}`);
			loUpdate$ = throwError(() => "Erreur inattendue lors de la récupération de la mise à jour.");
		}

		return loUpdate$;
	}

	/** Génère un log d'action indiquant qu'une mise à jour est disponible.
	 * @param poUpdate Mise à jour disponible.
	 */
	private emitUpdateAvailableAction(poUpdate: IUpdate): void {
		this.isvcLogger.action(
			UpdateService.C_LOG_ID,
			"Application update available.",
			ELogActionId.updateAvailable,
			{
				currentVersion: ConfigData.appInfo.appVersion,
				availableVersion: poUpdate.version
			} as IUpdateAvailableData
		);
	}

	/** Génère un log d'action indiquant qu'un utilisateur a refusé la mise à jour d'application proposée.
	 * @param poUpdate Mise à jour disponible.
	 */
	private emitDeclineUpdateAction(poUpdate: IUpdate): void {
		this.isvcLogger.action(
			UpdateService.C_LOG_ID,
			"User declined available update",
			ELogActionId.updateCanceled,
			{
				currentVersion: ConfigData.appInfo.appVersion,
				availableVersion: poUpdate.version
			} as IUserDeclinedUpdateData
		);
	}

	/** Génère un log d'action indiquant si la mise à jour a été correctement installée ou si une erreur s'est produite lors de l'installation ou le téléchargement.
	 * @param poUpdate Mise à jour disponible.
	 * @param poOptions Précise s'il s'agit d'une erreur et si elle s'est produite durant le téléchargement.
	 */
	private emitUpdateInstallationState(poUpdate: IUpdate, poOptions: IUpdateInstallState = {}): void {
		this.isvcLogger.action(
			UpdateService.C_LOG_ID,
			poOptions.hasFailed ? `An error occured while ${poOptions.errorEncounteredDuringDownload ? 'downloading' : 'installing'} application update` : "Application update successfully installed",
			poOptions.hasFailed ? ELogActionId.updateFailed : ELogActionId.updateSuccess,
			{
				currentVersion: ConfigData.appInfo.appVersion,
				availableVersion: poUpdate.version,
			} as IUpdateAvailableData
		);
	}

	//#endregion
}
