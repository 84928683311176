import { NgModule, Provider, Type } from "@angular/core";
import { SharedComponentsModule } from "@calaosoft/osapp/components/sharedComponents.module";
import { ListsModule } from "@calaosoft/osapp/modules/lists/lists.module";
import { CalaoMapModule } from "@calaosoft/osapp/modules/maps/map.module";
import { SectorMapPage } from "./sector-map/sector-map.page";
import { SectorsListComponent } from "./sectors-list/sectors-list.component";
import { SectorsMapPage } from "./sectors-map/sectors-map.page";
import { SectorsService } from "./services/sectors.service";

const modules: Type<any>[] = [
	SharedComponentsModule,
	ListsModule,
	CalaoMapModule
];
const components: Type<any>[] = [
	SectorsListComponent,
	SectorMapPage,
	SectorsMapPage
];
const providers: Provider[] = [
	SectorsService
];

@NgModule({
	exports: components,
	declarations: components,
	providers: providers,
	imports: modules
})
export class SectorsModule { }