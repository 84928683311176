import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { UserHelper } from '@calaosoft/osapp/helpers/user.helper';
import { FieldWrapper } from '@ngx-formly/core';
import { C_INTERVENANTS_ROLE_ID } from '../../../../app/app.constants';

@Component({
	selector: 'trade-signature-wrapper',
	templateUrl: './signature-wrapper.component.html',
	styleUrls: ['./signature-wrapper.component.scss'],
})
export class SignatureWrapperComponent extends FieldWrapper implements OnInit {

	//#region PROPERTIES

	/** Conteneur pour le champs de formulaire. */
	@ViewChild("fieldComponent", { read: ViewContainerRef, static: true }) public override fieldComponent: ViewContainerRef;

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioRouter: Router
	) {
		super();
	}

	public ngOnInit(): void {
		if (this.field)
			this.field.hideExpression = () => this.hasToHideWrapper();
	}

	private hasToHideWrapper(): boolean {
		if (StringHelper.isBlank(this.model._rev))
			return true;
		if (!(this.ioRouter.url.includes(UserHelper.getUserContactId()) || this.ioRouter.url.includes("contacts/me/edit")))
			return true;
		return !this.model.roles?.some((psRole: string) => [C_INTERVENANTS_ROLE_ID].includes(psRole));
	}

	//#endregion

}
