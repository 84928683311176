import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { defer, EMPTY, Observable, of } from 'rxjs';
import { filter, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { IPopoverItemParams } from '../../../../model/popover/IPopoverItemParams';
import { IStoreDataResponse } from '../../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { PopoverService } from '../../../../services/popover.service';
import { ModalService } from '../../../modal/services/modal.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { EEntityEntriesListItemAction } from '../../models/eentity-entries-list-item-action';
import { IEntityDescriptor } from '../../models/ientity-descriptor';
import { IEntityEntriesListDefinition } from '../../models/ientity-entries-list-definition';
import { IEntityEntriesListParams } from '../../models/ientity-entries-list-params';
import { IEntityEntriesListParamsCreateParams } from '../../models/ientity-entries-list-params-create-params';
import { EntitiesUpdateService } from '../../services/entities-update.service';
import { EntitiesService } from '../../services/entities.service';
import { EntityPickerModalComponent } from '../entity-picker-modal/entity-picker-modal.component';

@Component({
	selector: 'calao-entity-entries-list',
	templateUrl: './entity-entries-list.component.html',
	styleUrls: ['./entity-entries-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityEntriesListComponent<T extends IStoreDocument> extends DestroyableComponentBase {

	//#region FIELDS

	private moDescriptor?: IEntityDescriptor;
	private moListDefinition?: IEntityEntriesListDefinition;

	//#endregion FIELDS

	//#region PROPERTIES

	/** Paramètres du formList. */
	@Input() public params?: IEntityEntriesListParams<T>;
	@ObserveProperty<EntityEntriesListComponent<T>>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<IEntityEntriesListParams<T>>();

	/** Clé de tri. */
	@Input() public sortKey?: keyof T;
	@ObserveProperty<EntityEntriesListComponent<T>>({ sourcePropertyKey: "sortKey" })
	public readonly observableSortKey = new ObservableProperty<keyof T>();

	public readonly observableHasDetail = new ObservableProperty<boolean>();

	public readonly observableCanCreate = new ObservableProperty<boolean>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntities: EntitiesService,
		private readonly isvcEntitiesUpdate: EntitiesUpdateService,
		private readonly isvcPopover: PopoverService,
		private readonly isvcModal: ModalService,
		private readonly ioRoute: ActivatedRoute
	) {
		super();

		this.observableParams.value$.pipe(
			switchMap((poParams?: IEntityEntriesListParams<T>) =>
				defer(() =>
					poParams?.entityDescriptor ?
						of(poParams?.entityDescriptor) :
						this.isvcEntities.getDescriptor$(poParams?.entityDescId)
				).pipe(
					tap((poDesc: IEntityDescriptor) => {
						this.moListDefinition = this.params?.listDefinition ??
							this.isvcEntities.getListDefinition(poDesc, this.params?.listId) as IEntityEntriesListDefinition;

						this.observableHasDetail.value = this.hasDetail(poDesc, this.moListDefinition);
						this.observableCanCreate.value =
							this.moListDefinition.actions?.includes(EEntityEntriesListItemAction.create) &&
							this.isvcEntities.hasPermission(poDesc, "create");
						this.moDescriptor = poDesc;
						if (poParams)
							poParams.entityDescriptor = poDesc;
					})
				)
			),
			secure(this)
		).subscribe();
	}

	public async onDetailClickedAsync(poItem: T, poEvent: MouseEvent): Promise<void> {
		await this.openPopover$(poItem, poEvent).toPromise();
	}

	@Queue<
		EntityEntriesListComponent<T>,
		Parameters<EntityEntriesListComponent<T>["openPopover$"]>,
		ReturnType<EntityEntriesListComponent<T>["openPopover$"]>
	>({
		excludePendings: true
	})
	private openPopover$(poItem: T, poEvent: MouseEvent): Observable<boolean> {
		return defer(() => {
			const laPopoverItemParams: IPopoverItemParams[] | undefined = this.getPopoverItemParams(poItem);
			if (ArrayHelper.hasElements(laPopoverItemParams)) {
				return this.isvcPopover.showPopoverAsync(
					laPopoverItemParams,
					poEvent
				);
			}
			return EMPTY;
		}).pipe(mapTo(true));
	}

	private hasDetail(poDesc: IEntityDescriptor, poListDef: IEntityEntriesListDefinition): boolean {
		return poListDef.actions?.some((peAction: EEntityEntriesListItemAction) =>
			this.isvcEntities.hasPermission(poDesc, peAction)
		);
	}

	private getPopoverItemParams(poItem: T): IPopoverItemParams[] | undefined {
		const laPopoverItemParams: IPopoverItemParams[] = [];
		if (this.params && this.moDescriptor) {
			const loDescriptor: IEntityDescriptor = this.moDescriptor;
			this.moListDefinition?.actions?.forEach((peAction: EEntityEntriesListItemAction) => {
				if (
					peAction !== EEntityEntriesListItemAction.create &&
					peAction !== EEntityEntriesListItemAction.read &&
					this.isvcEntities.hasPermission(loDescriptor, peAction)
				) {
					laPopoverItemParams.push({
						title: this.getPopoverItemTitle(peAction),
						icon: this.getPopoverItemIcon(peAction),
						color: this.getPopoverItemColor(peAction),
						action: () => this.onPopoverItemClicked(poItem, loDescriptor, peAction)
					});
				}
			});
		}

		return laPopoverItemParams;
	}

	private getPopoverItemIcon(peAction: EEntityEntriesListItemAction): string | undefined {
		switch (peAction) {
			case EEntityEntriesListItemAction.edit:
				return "create";
			case EEntityEntriesListItemAction.delete:
				return "trash";
		}

		return undefined;
	}

	private getPopoverItemTitle(peAction: EEntityEntriesListItemAction): string | undefined {
		switch (peAction) {
			case EEntityEntriesListItemAction.edit:
				return "Éditer";
			case EEntityEntriesListItemAction.delete:
				return "Supprimer définitivement";
		}

		return undefined;
	}

	private getPopoverItemColor(peAction: EEntityEntriesListItemAction): string | undefined {
		switch (peAction) {
			case EEntityEntriesListItemAction.edit:
				return "primary";
			case EEntityEntriesListItemAction.delete:
				return "danger";
		}

		return undefined;
	}

	private onPopoverItemClicked(
		poItem: T,
		poDescriptor: IEntityDescriptor,
		peAction: EEntityEntriesListItemAction
	): Observable<boolean> {
		switch (peAction) {
			case EEntityEntriesListItemAction.edit:
				return defer(() => this.isvcEntities.navigateToEntityEditAsync(poItem, this.ioRoute));
			case EEntityEntriesListItemAction.delete:
				return this.isvcEntitiesUpdate.deleteEntity(poItem, poDescriptor).pipe(
					map((poResponse: IStoreDataResponse) => poResponse.ok)
				);
		}

		return EMPTY;
	}

	public async onItemClickedAsync(poItem: T): Promise<void> {
		await this.onItemClicked$(poItem).toPromise();
	}

	@Queue<
		EntityEntriesListComponent<T>,
		Parameters<EntityEntriesListComponent<T>["onItemClicked$"]>,
		ReturnType<EntityEntriesListComponent<T>["onItemClicked$"]>
	>({
		excludePendings: true
	})
	private onItemClicked$(poItem: T): Observable<boolean> {
		if (
			this.moDescriptor &&
			this.moListDefinition?.actions?.includes(EEntityEntriesListItemAction.read) &&
			this.isvcEntities.hasPermission(this.moDescriptor, "read")
		)
			return defer(() => this.isvcEntities.navigateToEntityViewAsync(poItem, this.ioRoute));

		return EMPTY;
	}

	public async onCreateClickedAsync(): Promise<void> {
		await this.onCreateClicked$().toPromise();
	}

	@Queue<
		EntityEntriesListComponent<T>,
		Parameters<EntityEntriesListComponent<T>["onItemClicked$"]>,
		ReturnType<EntityEntriesListComponent<T>["onItemClicked$"]>
	>({
		excludePendings: true
	})
	private onCreateClicked$(): Observable<boolean> {
		if (this.observableCanCreate.value && this.moDescriptor) {
			const loDesc: IEntityDescriptor = this.moDescriptor;

			return defer(() => {
				if (
					this.observableParams.value?.parentEntityDescId &&
					StringHelper.isBlank(this.params?.createParams?.parentId)
				) {
					return this.getParentEntityId(this.observableParams.value.parentEntityDescId);
				}
				return of(undefined);
			}).pipe(
				mergeMap((psParentEntityId?: string) => {
					let loCreateParams: IEntityEntriesListParamsCreateParams | undefined = this.params?.createParams;
					if (!StringHelper.isBlank(psParentEntityId))
						loCreateParams = { ...(loCreateParams ?? {}), parentId: psParentEntityId };

					return this.isvcEntities.navigateToEntityCreationAsync(
						loDesc,
						this.ioRoute,
						loCreateParams
					);
				})
			);
		}

		return EMPTY;
	}

	private getParentEntityId(psParentEntityDescId: string): Observable<string> {
		return this.isvcModal.open<string>({
			component: EntityPickerModalComponent,
			componentProps: {
				entityDescId: psParentEntityDescId
			}
		}).pipe(
			filter((psParentId?: string) => !StringHelper.isBlank(psParentId)),
		) as Observable<string>;
	}

	//#endregion

}
