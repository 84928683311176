<ion-radio-group [ngModel]="observableSelectedView.value$ | async" (ionChange)="onChange($event)"
	(click)="onClick($event)">
	<ion-item lines="none">
		<ion-icon name="calendar-clear"></ion-icon>
		<ion-label>Mois</ion-label>
		<ion-radio mode="md" [value]="ECalendarView.Month"></ion-radio>
	</ion-item>
	<ion-item lines="none">
		<ion-icon name="calendar"></ion-icon>
		<ion-label>Semaine</ion-label>
		<ion-radio mode="md" [value]="ECalendarView.Week"></ion-radio>
	</ion-item>
	<ion-item lines="none">
		<ion-icon name="calendar-number"></ion-icon>
		<ion-label>Jour</ion-label>
		<ion-radio mode="md" [value]="ECalendarView.Day"></ion-radio>
	</ion-item>
	<ion-item lines="none">
		<ion-icon name="list"></ion-icon>
		<ion-label>Liste</ion-label>
		<ion-radio mode="md" [value]="ECalendarView.List"></ion-radio>
	</ion-item>
</ion-radio-group>