import { DateHelper } from "@calaosoft/osapp/helpers/dateHelper";
import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { IRecurrence } from "@calaosoft/osapp/modules/event-markers/models/irecurrence";
import { Recurrence } from "@calaosoft/osapp/modules/event-markers/models/recurrence";
import { ModelResolver } from "@calaosoft/osapp/modules/utils/models/model-resolver";
import { Exclude } from "class-transformer";
import { EPlace } from "../../../model/EPlace";
import { EventOccurrence } from "../../../model/EventOccurrence";
import { Constraint } from "./constraint";
import { IConstraint } from "./iconstraint";
import { ITourneeEvent } from "./itournee-event";
import { WeekRepetition } from "./week-repetition";

export class Event implements ITourneeEvent {

	//#region PROPERTIES

	/** @implements */
	public latitude?: number;
	/** @implements */
	public longitude?: number;
	/** @implements */
	public city?: string;
	/** @implements */
	public zipCode?: string;

	/** @implements */
	public _id: string;
	/** @implements */
	public name?: string;
	/** @implements */
	public _rev: string;
	/** @implements */
	public authorId?: string;
	/** @implements */
	public startDate: Date;
	/** @implements */
	public timeWindow: [Date, Date];
	/** @implements */
	public endDate: Date;
	/** @implements */
	public recurrences: Recurrence[] = [];
	/** @implements */
	public place: EPlace;
	/** @implements */
	public duration: number;

	/** @implements */
	public startOffset: number;
	/** @implements */
	public endOffset: number;
	/** @implements */
	public constraints: Constraint[] = [];
	/** @implements */
	public weekRepetition: WeekRepetition;
	public canceled: boolean;

	/** @implements */
	public customerId: string;
	/** @implements */
	public siteId: string;

	@Exclude()
	private msGuid: string;
	public get guid(): string {
		if (!this.msGuid)
			this.msGuid = Event.extractGuid(this._id);

		return this.msGuid;
	}

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: ITourneeEvent) {
		// Construction de l'évènement à partir d'une interface, qui peut être un document issu du store
		if (poData) {
			this._id = poData._id;
			if (poData._rev)
				this._rev = poData._rev;

			this.startDate = new Date(poData.startDate);
			this.endDate = new Date(poData.endDate);
			this.timeWindow = [new Date(poData.startDate), new Date(poData.endDate)]; // TODO : ne pas initialiser les time window à partir des bornes de la période de réccurrence
			this.duration = poData.duration ?? 0;
			this.authorId = poData.authorId;
			this.name = poData.name;
			this.longitude = poData.longitude;
			this.latitude = poData.latitude;
			this.city = poData.city;
			this.zipCode = poData.zipCode;


			this.customerId = poData.customerId;
			this.siteId = poData.siteId;

			// TODO : Les récurrences sont détectées car tous les évènements du site sont lus en base, cette gestion devra être améliorée car elle ne tiendra pas la charge
			this.recurrences = poData.recurrences?.map((poRecurrence: IRecurrence) => ModelResolver.toClass(Recurrence, poRecurrence)) ?? [];
			this.constraints = poData.constraints?.map((poConstraint: IConstraint) => ModelResolver.toClass(Constraint, poConstraint)) ?? [];
		}
	}

	/** Vérifie si un item est le dernier de l'évènement
	 * @param poItem Item à tester
	 * @returns Vrai si l'item est le dernier de l'évènement, faux sinon
	 */
	public isLastItem(poItem: EventOccurrence): boolean {
		return DateHelper.diffMinutes(poItem.endDate, this.endDate) === 0;
	}

	public static extractGuid(psEventId: string): string {
		return IdHelper.getLastGuidFromId(psEventId);
	}

	//#endregion METHODS

}