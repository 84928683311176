import { IEntityLinkEntity } from "./ientity-link-entity";

export class EntityLinkEntity implements IEntityLinkEntity {

	//#region PROPERTIES

	/** @implements */
	public readonly id: string;
	/** @implements */
	public readonly type?: string;
	/** @implements */
	public readonly databaseId: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: IEntityLinkEntity) {
		this.id = poData?.id ?? "";
		this.databaseId = poData?.databaseId ?? "";
		this.type = poData?.type;
	}

	//#endregion METHODS

}
