import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { ECalendarView } from '../../models/ecalendar-view';

@Component({
	selector: 'calao-view-selector',
	templateUrl: './view-selector.component.html',
	styleUrls: ['./view-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewSelectorComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	/** Vue actuellement affichée. */
	@Input() public selectedView?: ECalendarView;
	@ObserveProperty<ViewSelectorComponent>({ sourcePropertyKey: "selectedView" })
	public readonly observableSelectedView = new ObservableProperty<ECalendarView>();

	/** Enum des vue possibles. */
	public ECalendarView = ECalendarView;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(private readonly ioPopoverController: PopoverController, poChangeDetectoRef: ChangeDetectorRef) {
		super(poChangeDetectoRef);
	}

	public ngOnInit(): void {
		this.detectChanges();
	}

	public async onChange(poEvent: Event): Promise<boolean> {
		const poPopover = await this.ioPopoverController.getTop();
		return poPopover ? this.ioPopoverController.dismiss((poEvent as CustomEvent).detail.value) : false;
	}

	/** Permet de revenir à la date courante si l'utilisateur re-sélectionne la vue actuellement affichée. */
	public onClick(poEvent: Event): void {
		const leSelectedView: ECalendarView = (poEvent.target as HTMLIonRadioGroupElement).value;
		if (leSelectedView === this.observableSelectedView.value)
			this.onChange({ detail: { value: leSelectedView } } as CustomEvent);
	}

	//#endregion METHODS

}
