import { EOccurrenceType } from './EOccurrenceType';
import { IConstraintLabelParams } from './iconstraint-label-params';
import { IEventOccurrence } from './itournee-event-occurrence';
import { IEventOccurrenceComparator } from './itournee-event-occurrence-comparator';
import { IEventOccurrenceComparatorParams } from './itournee-event-occurrence-comparator-params';

export abstract class EventOccurrenceComparator<T extends IEventOccurrenceComparatorParams = IEventOccurrenceComparatorParams> implements IEventOccurrenceComparator<T> {

	//#region PROPERTIES	

	/** @implements */
	public params?: T;
	/** @implements */
	public abstract type: EOccurrenceType;

	//#endregion

	//#region METHODS

	constructor(poParams?: T) {
		this.params = poParams;
	}

	public abstract getLabel(poParams: IConstraintLabelParams): string;

	/** Indique si l'occurrence correspond au comparateur.
	 * @param poEventOccurrence 
	 */
	public abstract match(poEventOccurrence: IEventOccurrence): boolean;

	public getIntervenantsIds(): string[] {
		return [];
	}

	//#endregion

}
