import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { SearchModule } from '../../components/search/search.module';
import { ModalModule } from '../modal/modal.module';
import { SelectorModalComponent } from './selector-modal/selector-modal.component';
import { SelectorComponent } from './selector/selector.component';

@NgModule({
	declarations: [SelectorComponent, SelectorModalComponent],
	exports: [SelectorComponent, SelectorModalComponent],
	imports: [
		CommonModule,
		MatChipsModule,
		IonicModule,
		FormsModule,
		SearchModule,
		ModalModule,
		MatTooltipModule,
		DragScrollComponent,
		DragScrollItemDirective
	]
})
export class SelectorModule { }