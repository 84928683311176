import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { AlertButton } from '@ionic/core';
import { EMPTY, Observable, ReplaySubject, Subject, from, fromEvent, of, throwError } from 'rxjs';
import { catchError, map, mapTo, mergeMap, mergeMapTo, retryWhen, take, tap } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { ContactHelper } from '../helpers/contactHelper';
import { IdHelper } from '../helpers/idHelper';
import { ObjectHelper } from '../helpers/objectHelper';
import { StoreDocumentHelper } from '../helpers/storeDocumentHelper';
import { StringHelper } from '../helpers/stringHelper';
import { EPrefix } from '../model/EPrefix';
import { ESuffix } from '../model/ESuffix';
import { IHttpOptions } from '../model/IHttpOptions';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { IUser } from '../model/application/IUser';
import { UserData } from '../model/application/UserData';
import { IAuthenticatorParams } from '../model/authenticator/IAuthenticatorParams';
import { ConfigData } from '../model/config/ConfigData';
import { EConfigFlag } from '../model/config/EConfigFlag';
import { Credentials } from '../model/security/Credentials';
import { IAuthStatus } from '../model/security/IAuthStatus';
import { ICredentials } from '../model/security/ICredentials';
import { ICredentialsDocument } from '../model/security/ICredentialsDocument';
import { ISecurityEvent } from '../model/security/ISecurityEvent';
import { ISecuritySettings } from '../model/security/ISecuritySettings';
import { ISecuritySettingsDocument } from '../model/security/ISecuritySettingsDocument';
import { ISession } from '../model/security/ISession';
import { EUnlockSecurityMode } from '../model/security/eunlock-secutity-mode';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { EStoreType } from '../model/store/EStoreType';
import { ESyncType } from '../model/store/ESyncType';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IWorkspaceInfo } from '../model/workspaces/IWorkspaceInfo';
import { AuthenticatedRequestOptionBuilder } from '../modules/api/models/authenticated-request-option-builder';
import { IAuthenticationNavigationParams } from '../modules/authentication/models/iauthentication-navigation-params';
import { OsappError } from '../modules/errors/model/OsappError';
import { Loader } from '../modules/loading/Loader';
import { ELogActionId } from '../modules/logger/models/ELogActionId';
import { LoggerService } from '../modules/logger/services/logger.service';
import { ObservableProperty } from '../modules/observable/models/observable-property';
import { OsappApiHelper } from '../modules/osapp-api/helpers/osapp-api.helper';
import { IRedirectToAuthenticationParams } from '../modules/security/models/iredirect-to-authentication-params';
import { ESignupErrorCode } from '../modules/signup/model/ESignupErrorCode';
import { ApplicationService } from './application.service';
import { FlagService } from './flag.service';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { LoadingService } from './loading.service';
import { NetworkService } from './network.service';
import { PlatformService } from './platform.service';
import { Store } from './store.service';
import { UiMessageService } from './uiMessage.service';
import { WorkspaceService } from './workspace.service';

@Injectable({ providedIn: "root" })
export class SecurityService {

	//#region FIELDS

	private static readonly C_APPLICATION_CREDENTIALS_STORAGE_DOC_ID = "app_storage_credentials";
	private static readonly C_APPLICATION_SECURITY_SETTINGS_STORAGE_DOC_ID = "app_storage_security_settings";
	private static readonly C_LOG_ID = "SEC.S::";
	private static readonly C_AUTH_ERROR_HEADER = "Erreur d'authentification";
	private static readonly C_BAD_LOGIN_OR_PASSWORD_MESSAGE = "Identifiant ou mot de passe invalide.";

	private static C_DATABASE_ID_APP_STORAGE: string;

	private readonly moAuthenticatedSubject = new ReplaySubject<boolean>();
	/** Subject pour l'envoi d'événement. */
	private readonly moEventSubject = new Subject<ISecurityEvent>();
	/** Sujet de notification du statut de l'authentification. */
	private readonly moAuthStatusSubject = new ReplaySubject<IAuthStatus>(1);

	private get touchScreenEventName(): string { return this.isvcPlatform.isMobile ? "touchstart" : "mousemove"; }

	//#endregion

	//#region PROPERTIES

	public isDomListening: boolean;

	public get authenticationStatus$(): Observable<IAuthStatus> {
		return this.isvcFlag.waitForFlag(EConfigFlag.ConfigReady, true)
			.pipe(
				mergeMap(() => this.moAuthStatusSubject.asObservable()),
				take(1)
			);
	}

	private get credentialsDataSource(): IDataSource {
		return {
			databaseId: SecurityService.C_DATABASE_ID_APP_STORAGE,
			viewParams: {
				key: SecurityService.C_APPLICATION_CREDENTIALS_STORAGE_DOC_ID,
				include_docs: true
			}
		} as IDataSource;
	}

	private get securitySettingsDataSource(): IDataSource {
		return {
			databaseId: SecurityService.C_DATABASE_ID_APP_STORAGE,
			viewParams: {
				key: SecurityService.C_APPLICATION_SECURITY_SETTINGS_STORAGE_DOC_ID,
				include_docs: true
			}
		} as IDataSource;
	}

	private get securityApiPath(): string {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_suffix}apps/${ConfigData.appInfo.appId}/security`;
	}

	private msUserId: string;
	public get userId(): string { return this.msUserId; }

	/** `true` si le déverrouillage de l'application est possible, sinon `false`. */
	public readonly observableCanUnlockApp = new ObservableProperty<boolean>(false);

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private readonly isvcStore: Store,
		private readonly isvcNetwork: NetworkService,
		public readonly ioHttpClient: HttpClient,
		/** Service de gestion des popups et toasts. */
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcLoading: LoadingService,
		private readonly ioNavController: NavController,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcLogger: LoggerService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcFlag: FlagService,
	) {

		isvcFlag.waitForFlag(EStoreFlag.DBLocalInitialized, true)
			.pipe(tap(_ => SecurityService.C_DATABASE_ID_APP_STORAGE = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage))))
			.subscribe();

		isvcFlag.waitForFlag(EConfigFlag.ConfigReady, true)
			.pipe(tap(_ => this.raiseStartHeartbeatEvent()))
			.subscribe();

		this.moAuthenticatedSubject.asObservable()
			.pipe(
				mergeMap((pbResult: boolean) => pbResult ? this.isvcWorkspace.selectCurrentWorkspace().pipe(mapTo(pbResult)) : of(pbResult)),
				tap(
					(pbResult: boolean) => {
						this.raiseSecurityEvent({
							type: EApplicationEventType.SecurityEvent,
							createDate: new Date(),
							data: { isAuthenticated: pbResult }
						} as ISecurityEvent);
					},
					poError => console.error(`${SecurityService.C_LOG_ID}'moAuthenticatedSubject' a levé une erreur :`, poError)
				)
			)
			.subscribe();
	}

	/** Retourne le login du compte invité. */
	public getLoginInvite(): string {
		return `invite.${ConfigData.appInfo.appId}@calaosoft.fr`;
	}

	/** Initialise le statut d'authentification de l'utilisateur. */
	public initUserAuthStatus(): Observable<boolean> {
		return this.getUserCredentials$()
			.pipe(
				mergeMap((poCredentials: Credentials) => {
					const lbCanUnlock: boolean = (poCredentials.token && poCredentials.isValidToken()) || (!poCredentials.token && !ConfigData.appInfo.unlockRequired)
					this.observableCanUnlockApp.value = lbCanUnlock;

					return poCredentials.isValidToken() && !ConfigData.appInfo.unlockRequired ?
						this.unlockAppAsync(poCredentials) : of(this.setAuthenticationStatus(false)).pipe(mapTo(false));
				}),
				retryWhen((poError$: Observable<any>) => poError$.pipe(mergeMap(poError => poError.status === 401 ? EMPTY : throwError(() => poError))))
			);
	}

	/** Initialise l'application pour un accès invité.
	 * @param paUrlParams Tableau des paramètres passés par l'url.
	 * @param poConfigData Tableau des données de configuration de l'application.
	 */
	public initGuest(psWorkspaceId: string): Observable<boolean> {
		const lsWorkSpace = `${ConfigData.appInfo.appId}_core_ws_${psWorkspaceId}${ESuffix.workspace}`;
		return this.authenticateUser(this.getLoginInvite(), ConfigData.authentication.appCredentials.password)
			.pipe(
				tap(() => ConfigData.databases.push({ id: lsWorkSpace, syncType: ESyncType.remote, roles: [EDatabaseRole.workspace, EDatabaseRole.contacts] })),
				mergeMap(_ => this.isvcStore.initDynamicDatabases()),
				mapTo(true)
			);
	}

	/** Vérifie si la date d'expiration est dépassée.
	 * @return `true` si la date est expirée, sinon `false`.
	 */
	public checkTokenExpiration(): Observable<boolean> {
		return this.getUserCredentials$()
			.pipe(map((poCredentials?: Credentials) => !poCredentials?.isValidToken()));
	}

	/** Récupère les Credentials sauvegardées dans la base de données d'application. */
	public getUserCredentials$(): Observable<Credentials> {
		return this.isvcStore.getLocal(SecurityService.C_APPLICATION_CREDENTIALS_STORAGE_DOC_ID, SecurityService.C_DATABASE_ID_APP_STORAGE)
			.pipe(
				// TODO TB: RETROCOMPAT com 3.01.292
				mergeMap((poResult: ICredentialsDocument) => {
					if (!poResult) {
						return this.isvcStore.getOne(this.credentialsDataSource, false)
							.pipe(
								tap((poOldResult: ICredentialsDocument) => {
									if (poOldResult)
										poOldResult._rev = undefined;
								})
							);
					}
					else
						return of(poResult);
				}),
				tap(
					_ => { },
					poError => console.error(`${SecurityService.C_LOG_ID}Erreur récupération credentials utilisateur :`, poError)
				),
				map((poResult: ICredentialsDocument) => poResult ? Object.assign(new Credentials(), poResult) : new Credentials())
			);
	}

	/** Récupère les paramètres de sécurité. */
	public getSavedSecuritySettings$(): Observable<ISecuritySettingsDocument> {
		return this.isvcStore.getOne<ISecuritySettingsDocument>(this.securitySettingsDataSource, false)
			.pipe(tap(_ => { }, poError => console.error(`${SecurityService.C_LOG_ID}Erreur récupération paramètres de sécurité :`, poError)));
	}

	private raiseSecurityEvent(poEvent: ISecurityEvent): void {
		this.moEventSubject.next(poEvent);
	}

	/** Sauvegarde les Credentials de l'utilisateur dans la base de données qui a le rôle "applicationStorage".
	 * @param poNewCredentials Objet Credentials à sauvegarder.
	 * @returns Observable retournant l'objet Credentials enregistré.
	 */
	public persistCredentials(poNewCredentials: ICredentials): Observable<ICredentialsDocument> {
		return this.getUserCredentials$()
			.pipe(
				mergeMap((poOldCredentials?: ICredentialsDocument) => {
					const loNewCredentialsDoc: ICredentialsDocument = poNewCredentials as ICredentialsDocument;
					loNewCredentialsDoc._id = SecurityService.C_APPLICATION_CREDENTIALS_STORAGE_DOC_ID;

					if (poOldCredentials) // On met à jour les champs que si c'est un doc qui existait déjà.
						loNewCredentialsDoc._rev = poOldCredentials._rev;

					if (this.areCredentialsDifferent(loNewCredentialsDoc, poOldCredentials)) {
						ConfigData.authentication.token = loNewCredentialsDoc.token;

						return this.isvcStore.putLocal(loNewCredentialsDoc, SecurityService.C_DATABASE_ID_APP_STORAGE)
							.pipe(
								mapTo(loNewCredentialsDoc),
								tap(
									_ => console.log(`${SecurityService.C_LOG_ID}${new Date().toLocaleString("fr-FR")} - Token: ${loNewCredentialsDoc.token} - Expiration: ${loNewCredentialsDoc.tokenExpirationDate}`),
									poError => console.error(`${SecurityService.C_LOG_ID}Erreur enregistrement nouveaux credentials :`, poError)
								)
							);
					}
					else {
						console.log(`${SecurityService.C_LOG_ID}Identifiants déjà à jour.`);
						return of(loNewCredentialsDoc);
					}
				})
			);
	}

	/** Permet de comparer les deux documents contenant les informations de connexion.
	 * @param poNewCredentials
	 * @param poOldCredentials
	 */
	private areCredentialsDifferent(poNewCredentials: ICredentialsDocument, poOldCredentials: ICredentialsDocument): boolean {
		return !poOldCredentials || poNewCredentials.login !== poOldCredentials.login || poNewCredentials.password !== poOldCredentials.password ||
			poNewCredentials.tokenExpirationDate !== poOldCredentials.tokenExpirationDate || poNewCredentials.token !== poOldCredentials.token;
	}

	/** Sauvegarde les paramètres de sécurité.
	 * @param poSecuritySettings Paramètres de sécurité à sauvegarder.
	 */
	private saveSecuritySettings$(poSecuritySettings: ISecuritySettings): Observable<ISecuritySettingsDocument> {
		return this.getSavedSecuritySettings$()
			.pipe(
				mergeMap((poOldSecuritySettingsDoc?: ISecuritySettingsDocument) => {
					const loNewSecuritySettingsDoc: ISecuritySettingsDocument = poSecuritySettings as ISecuritySettingsDocument;
					loNewSecuritySettingsDoc._id = SecurityService.C_APPLICATION_SECURITY_SETTINGS_STORAGE_DOC_ID;

					if (poOldSecuritySettingsDoc) // On met à jour la rev que si c'est un doc qui existait déjà.
						loNewSecuritySettingsDoc._rev = poOldSecuritySettingsDoc._rev;

					if (loNewSecuritySettingsDoc.unlockMode === undefined && poOldSecuritySettingsDoc)
						loNewSecuritySettingsDoc.unlockMode = poOldSecuritySettingsDoc.unlockMode;

					const loPersist$: Observable<IStoreDataResponse> = this.areSecuritySettingsDifferent(loNewSecuritySettingsDoc, poOldSecuritySettingsDoc) ?
						this.isvcStore.put(loNewSecuritySettingsDoc, SecurityService.C_DATABASE_ID_APP_STORAGE) : of(null);

					return loPersist$.pipe(mapTo(loNewSecuritySettingsDoc));
				})
			);
	}

	/** Permet de comparer les deux documents contenant les informations de connexion.
	 * @param poNewSecuritySettings
	 * @param poOldSecuritySettings
	 */
	private areSecuritySettingsDifferent(poNewSecuritySettings: ISecuritySettingsDocument, poOldSecuritySettings: ISecuritySettingsDocument): boolean {
		return !poOldSecuritySettings || poNewSecuritySettings.unlockMode !== poOldSecuritySettings.unlockMode;
	}

	/** Redirige vers la page d'authentification. */
	public redirectToAuthenticationAsync(poParams?: IRedirectToAuthenticationParams): Promise<boolean> {
		const loRequestParams: IRedirectToAuthenticationParams = poParams ?? {};
		const loNavigationOptions: NavigationOptions = { queryParams: {} };

		if (!StringHelper.isBlank(loRequestParams.redirectUrl))
			loNavigationOptions.queryParams.redirectUrl = loRequestParams.redirectUrl;

		ObjectHelper.assign(loNavigationOptions.queryParams, loRequestParams.queryParams ?? {});

		if (loRequestParams.isLoggingOut)
			loNavigationOptions.state = { loggedOut: true } as IAuthenticationNavigationParams;

		return this.ioNavController.navigateRoot(ApplicationService.C_AUTHENTICATION_ROUTE_URL, loNavigationOptions);
	}

	/** Défini la méthode à appeler lorsque l'on touche l'écran. */
	public defineTouchHandler(): Observable<ICredentialsDocument> {
		this.isDomListening = true;

		return fromEvent(document.body, this.touchScreenEventName)
			.pipe(
				take(1),
				tap(_ => this.isDomListening = false),
				mergeMap(_ => {
					// Appel ver l'API permettant de prolonger la durée du token.
					return this.ioHttpClient.get<ISession>(this.securityApiPath, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions())
						.pipe(mergeMap((poResult: ISession) => this.persistSession(poResult, ConfigData.authentication.credentials.login)));
				})
			);
	}

	/** Sauvergarde le token afin qu'il puisse être utilisé.
	 * @param psToken
	 */
	public persistSession(poSession: ISession, psLogin: string): Observable<ICredentialsDocument> {
		let loPersist$: Observable<ICredentialsDocument>;

		if (StringHelper.isBlank(psLogin))
			psLogin = ConfigData.authentication.credentials.login;

		if (UserData.current) {
			const loCredentials: ICredentials = {
				login: UserData.current.isGuest ? "" : psLogin,
				token: poSession.token,
				tokenExpirationDate: new Date(poSession.expireTimestamp),
				tokenCreationDate: new Date(poSession.createTimestamp)
			};

			loPersist$ = this.persistCredentials(loCredentials);
		}
		else
			loPersist$ = EMPTY;

		return loPersist$;
	}

	/** Permet de souscrire aux événements du securityService.
	 * @param pfNext Fonction appelée lors du next => function(poResult: ISecurityEvent).
	 * @param pfError Fonction appelée lors du error => function(poError: Any).
	 * @param pfComplete Fonction appelée lors du complete.
	 */
	public subscribe(pfNext: Function, pfError?: Function, pfComplete?: Function): void {
		this.moEventSubject.asObservable()
			.subscribe(
				(poResult: ISecurityEvent) => pfNext(poResult),
				poError => pfError(poError),
				() => pfComplete()
			);
	}

	/** Vérifie si l'utilisateur existe sur le serveur.
	 * @param psUserId
	 */
	public remoteUserExists(psUserId: string): Observable<boolean> {
		return this.ioHttpClient.get(`${this.securityApiPath}/users/${psUserId.toLowerCase()}/verify`, AuthenticatedRequestOptionBuilder.buildNonAuthenticatedRequestOptions())
			.pipe(
				catchError(poError => {
					console.warn(`${SecurityService.C_LOG_ID}Erreur vérification existence utilisateur sur le serveur :`, poError);
					return of(false);
				})
			) as Observable<boolean>;
	}

	/** Récupère le document utilisateur en local, peut être `undefined`.
	 * @param poUser Utilisateur dont il faut récupérer le document.
	 */
	private getLocalUserDocument(poUser: IUser): Observable<IUser>;
	/** Récupère le document utilisateur en local, peut être `undefined`.
	 * @param psLogin Login de l'utilisateur pour déterminer quel document utilisateur récupérer.
	 */
	private getLocalUserDocument(psLogin: string): Observable<IUser>;
	private getLocalUserDocument(poUserOrLogin: IUser | string): Observable<IUser> {
		const loDataSource: IDataSource = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage)),
			type: EStoreType.PouchDb,
			viewParams: { include_docs: true }
		};

		// Si on a un login en paramètre, il faut filtrer les possibles multi-comptes en filtrant le document de l'utilisateur qui tente de se connecter.
		if (typeof poUserOrLogin === "string") {
			// Le filtre permet de récupérer uniquement le(s) document(s) utilisateur(s) (y compris le document de contact utilisateur).
			loDataSource.filter = (poUser: IUser) => poUser.email === poUserOrLogin || poUser.name === poUserOrLogin;
		}
		else // Sinon, il suffit de récupérer le document de l'utilisateur via son identifiant.
			loDataSource.viewParams.key = poUserOrLogin._id;

		return this.isvcStore.get<IUser>(loDataSource)
			.pipe( // On retourne le premier élément des résultats qui comporte le préfixe `usr_` dans son identifiant.
				map((paResults: IUser[]) =>
					paResults.filter((poDocument: IUser) => IdHelper.hasPrefixId(poDocument._id) ? poDocument._id.includes(EPrefix.user) : true)
				),
				map((paFilteredResults: IUser[]) => {
					if (paFilteredResults.length > 1)
						console.warn(`${SecurityService.C_LOG_ID}Plusieurs documents éligibles pour le document utilisateur, conservation du premier qui n'a pas de préfixe couchbd par défaut`, paFilteredResults);

					const loUserDocWithoutCouchdbPrefix: IUser = paFilteredResults.find((poUser: IUser) => !poUser._id.includes(StoreDocumentHelper.C_COUCHDB_USER_PREFIX));

					return loUserDocWithoutCouchdbPrefix ? loUserDocWithoutCouchdbPrefix : ArrayHelper.getFirstElement(paFilteredResults) as IUser;
				})
			);
	}

	/** Ajoute une tâche au BTS pour vérifier le mode online et lancer l'envoi d'un heartbeat pour prolonger la session. */
	private raiseStartHeartbeatEvent(): void {
		this.raiseSecurityEvent({ type: EApplicationEventType.SecurityEvent, createDate: new Date(), data: { startHeartbeat: true } });
	}

	/** Permet de récupérer la config de l'authenticator en base.
	 * @param psAuthenticatorKey Clé de l'authenticator afin de le retrouver en base.
	 */
	public getAuthenticatorConfig$(psAuthenticatorKey: string): Observable<IAuthenticatorParams> {
		const loParams: IDataSource = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.components)),
			viewName: "component/by_type",
			viewParams: {
				key: psAuthenticatorKey
			}
		};

		return this.isvcStore.get<IAuthenticatorParams>(loParams)
			.pipe(map((paResults: IAuthenticatorParams[]) => ArrayHelper.hasElements(paResults) ? ArrayHelper.getLastElement(paResults) : undefined));
	}

	/** Affichage d'une popup lorsqu'une erreur est survenue lors de l'authentification de l'utilisateur (login ou mdp incorrect).
	 * @param poError Erreur http ou texte qui indique l'erreur survenue.
	 */
	private authenticatePopupError(poError: HttpErrorResponse, psEmail: string): void {
		const loError: HttpErrorResponse | string = poError || "bad login or password";
		const loPopupParams = new ShowMessageParamsPopup({ header: "Erreur" });

		switch (poError.status) {
			case 500:
				loPopupParams.message = `Une erreur inattendue est survenue sur le serveur. Veuillez contacter le support à l'adresse <b>${ConfigData.appInfo.supportEmail}</b>.`;
				break;

			case 0:
				loPopupParams.message = "Veuillez vérifier votre connexion internet.";
				break;

			case 401:
			case 404:
				let lsMessage: string;

				if ((poError.error?.message as string ?? "").includes("Le compte est bloqué"))
					lsMessage = ConfigData.environment.accountBlockedMessage ?? "Compte bloqué.";
				else
					lsMessage = SecurityService.C_BAD_LOGIN_OR_PASSWORD_MESSAGE;

				loPopupParams.header = SecurityService.C_AUTH_ERROR_HEADER;
				loPopupParams.message = lsMessage;
				break;

			case 403:
				if (poError.error.code === ESignupErrorCode.EMAIL_NOT_VERIFIED) {
					loPopupParams.message = `Votre adresse email n'a pas encore été vérifiée. Si vous n'avez pas reçu le mail de vérification, `;
					loPopupParams.buttons.push({
						text: "Renvoyer le mail",
						handler: () => { this.ioNavController.navigateForward(["signup", "send-validation-email"], { queryParams: { email: psEmail } }); }
					} as AlertButton);
				}
				break;

			default:
				loPopupParams.header = `Erreur ${poError.status}`;
				loPopupParams.message = `Une erreur inattendue est survenue sur le serveur. Veuillez contacter le support à l'adresse <b>${ConfigData.appInfo.supportEmail}</b>.`;
				break;
		}

		this.isvcUiMessage.showMessage(loPopupParams);
		console.error(`${SecurityService.C_LOG_ID}Erreur lors de l'authentification de l'utilisateur :`, loError);
	}

	public createBadLoginOrPasswordShowMessageParamsPopup(): ShowMessageParamsPopup {
		return new ShowMessageParamsPopup({ header: SecurityService.C_AUTH_ERROR_HEADER, message: SecurityService.C_BAD_LOGIN_OR_PASSWORD_MESSAGE });
	}

	public createInternetRequiredShowMessageParamsPopup(): ShowMessageParamsPopup {
		return new ShowMessageParamsPopup({ message: "Une connexion internet est requise pour l'authentification.", header: "Erreur" });
	}

	/** Affichage d'une popup lorsque une erreur est survenue lors de l'initialisation de l'application après authentification.
	 * @param poError Erreur survenue.
	 * @param psErrorType Indique le type d'erreur survenue.
	 */
	private innerAuthenticateAndSaveUserPopupError(poError: any, psErrorType: string): void {
		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: `Une erreur est survenue lors de ${psErrorType} de votre document utilisateur. Veuillez réessayer ultérieurement.`,
				header: "Erreur"
			})
		);

		console.error(`${SecurityService.C_LOG_ID}Échec de ${psErrorType} du document utilisateur :`, poError);
	}

	/** Retourne une erreur survenue lors de l'authentification de l'utilisateur (mauvaise réponse serveur) et affiche une popup. */
	private raiseInnerAuthenticateAndSaveUserPopupError(): Observable<never> {
		const lsErrorMessage = "Le token n'a pas été trouvé dans la session.";

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({ header: "Erreur serveur", message: "Le serveur n'a pas renvoyé de réponse. Veuillez réessayer ultérieurement." })
		);

		console.error(`${SecurityService.C_LOG_ID}Erreur lors de la tentative d'initialisation lors de l'authentification :`, lsErrorMessage);
		return throwError(() => lsErrorMessage);
	}

	/** Un utilisateur correspond au login entré, on tente de se connecter avec le mot de passe fourni.
	 * @param poNewUser Document utilisateur de son compte.
	 * @param psLogin Login de l'utilisateur.
	 * @param psPassword Mot de passe de l'utilisateur.
	 */
	private authenticateAndSaveUser(psLogin: string, psPassword: string): Observable<IUser> {
		let loLoader: Loader;

		return this.authenticate(psLogin, psPassword)
			.pipe(
				tap(_ => { }, poError => this.authenticatePopupError(poError, psLogin)),
				mergeMap((poResult?: IUser) => !poResult ? throwError(() => null) : of(poResult)),
				mergeMap((poResult: IUser) => {
					return from(this.isvcLoading.create("Initialisation ..."))
						.pipe(
							tap((poLoader: Loader) => loLoader = poLoader),
							mergeMap((poLoader: Loader) => poLoader.present()),
							mapTo(poResult)
						);
				}),
				tap((poResult: IUser) => {
					poResult._id = StoreDocumentHelper.removeUserCouchDBPrefix(poResult);

					ConfigData.appInfo.firstName = StringHelper.isBlank(poResult.firstName) ? "" : ContactHelper.getFormattedFirstName(poResult.firstName);
					ConfigData.appInfo.lastName = StringHelper.isBlank(poResult.lastName) ? "" : ContactHelper.getFormattedLastName(poResult.lastName);
					ConfigData.appInfo.login = psLogin;
					ConfigData.authentication.credentials.login = ConfigData.appInfo.login;
				}),
				mergeMap((poResult: IUser) => this.innerAuthenticateAndSaveUser(poResult)),
				mergeMap((poResult: IUser) => this.persistSession(poResult.session, psLogin).pipe(mapTo(poResult))),
				tap(
					_ => loLoader?.dismiss(),
					_ => loLoader?.dismiss()
				)
			);
	}

	/** Récupère un nouveau token pour l'utilisateur sur le serveur.
	 * @param psLogin
	 * @param psPassword
	 */
	private authenticate(psLogin: string, psPassword: string): Observable<IUser> {
		const loRequestOptions: IHttpOptions<"json"> = {
			responseType: "json",
			headers: new HttpHeaders({ appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo), Authorization: btoa(`${psLogin}:${psPassword}`) })
		};

		return this.ioHttpClient.get(`${this.securityApiPath}/users/authenticate`, loRequestOptions) as Observable<IUser>;
	}

	private innerAuthenticateAndSaveUser(poNewUser: IUser): Observable<IUser> {
		if (poNewUser.session && !StringHelper.isBlank(poNewUser.session.token)) {
			return this.getLocalUserDocument(poNewUser)
				.pipe(
					tap(_ => { }, poError => this.innerAuthenticateAndSaveUserPopupError(poError, "la récupération")),
					mergeMap((poOldUser: IUser) => {
						UserData.current = poNewUser;

						if (poNewUser.isGuest)
							return of(poNewUser);
						else {
							return this.saveUserDocument(poNewUser, poOldUser)
								.pipe(tap(_ => { }, poError => this.innerAuthenticateAndSaveUserPopupError(poError, "l'enregistrement")));
						}
					}),
					mapTo(poNewUser)
				);
		}
		else
			return this.raiseInnerAuthenticateAndSaveUserPopupError();
	}

	/** Recherche le token avec les login/mdp.
	 * @param psLogin Login de l'utilisateur.
	 * @param psPassword Mot de passe de l'utilisateur.
	 * @return Retourne le token de session en mode Online, ou null en mode Offline
	 */
	public authenticateUser(psLogin: string, psPassword: string): Observable<IUser> {
		return this.isvcNetwork.asyncIsNetworkReliable()
			.pipe(
				mergeMap((pbHasNetwork: boolean) => {
					let loAuthentication$: Observable<IUser>;

					if (pbHasNetwork)
						loAuthentication$ = this.authenticateAndSaveUser(psLogin, psPassword);
					else {
						this.isvcUiMessage.showMessage(this.createInternetRequiredShowMessageParamsPopup());
						loAuthentication$ = throwError(() => "Internet connection is needed for login/password authentication.");
					}

					return loAuthentication$.pipe(
						mergeMap((poUser: IUser) => this.getUserCredentials$().pipe(tap((poCredentials: Credentials) => this.setUserConfigs(poUser, poCredentials)))),
						mergeMap((poCredentials: Credentials) => {
							if (ConfigData.authentication.token !== poCredentials.token) {
								console.log(`${SecurityService.C_LOG_ID}Votre nouveau token : ${poCredentials.token}`);
								ConfigData.authentication.token = poCredentials.token;
							}

							return poCredentials.isValidToken() ? of(null) : this.raiseSessionExpiredPopupError();
						}),
						tap(
							_ => {
								this.setAuthenticationStatus(true);
								const loSafeUserData: IUser = { ...UserData.current };
								delete loSafeUserData.session.token;
								this.isvcLogger.action(SecurityService.C_LOG_ID, "User logged in successfully with login/password.", ELogActionId.login, { user: loSafeUserData }, undefined, true);
							},
							poError => this.logAuthenticationActionFailed("User authentication failed", poError)
						),
						mergeMap(_ => this.updateUnlockMode(EUnlockSecurityMode.loginPassword)),
						mapTo(UserData.current),
						tap(_ => { }, _ => this.setAuthenticationStatus(false))
					);
				})
			);
	}

	private setAuthenticationStatus(pbIsAuthenticated: boolean): void {
		if (!pbIsAuthenticated)
			UserData.current = undefined;

		this.moAuthStatusSubject.next({ isAuthenticated: pbIsAuthenticated, isGuest: UserData.current && UserData.current.isGuest } as IAuthStatus);
		this.moAuthenticatedSubject.next(pbIsAuthenticated);
	}

	private raiseSessionExpiredPopupError(): Observable<never> {
		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({ message: "Veuillez vous connecter à internet pour recharger votre session.", header: "Session expirée" })
		);

		return throwError(() => new OsappError("La session de l'utilisateur a expirée, connexion avec internet requise."));
	}

	/** Enregistre le document utilisateur de l'utilisateur.
	 * @param poNewUser Document utilisateur de l'utilisateur.
	 * @param poOldUser Utilisateur stocké en base locale.
	 */
	private saveUserDocument(poNewUser: IUser, poOldUser: IUser): Observable<IStoreDataResponse> {
		if (poOldUser)
			poNewUser._rev = poOldUser._rev;

		return this.isvcStore.put(poNewUser, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage)));
	}

	/** Défini les données de configuration liées à l'utilisateur.
	 * @param poUser Document utilisateur.
	 * @param poCredentials Données de connexion de l'utilisateur.
	 */
	private setUserConfigs(poUser: IUser, poCredentials: ICredentialsDocument): void {
		poUser.workspaceInfos = ArrayHelper.hasElements(poUser.workspaceInfos) ?
			poUser.workspaceInfos.filter((poWorkspaceInfo: IWorkspaceInfo) => poWorkspaceInfo.appId === ConfigData.appInfo.appId) : [];

		UserData.current = poUser;
		ConfigData.authentication.userId = this.msUserId = poUser.name;
		ConfigData.authentication.credentials.login = poCredentials.login;
	}

	/** Déverrouille l'application.
	 * ### ATTENTION : Le token doit être défini !
	 * @param poCredentials Document contenant les informations d'authentification de l'utilisateur.
	 */
	public unlockAppAsync(poCredentials: ICredentialsDocument): Promise<boolean> {
		return this.getLocalUserDocument(poCredentials.login)
			.pipe(
				tap((poUser?: IUser) => this.innerUnlockApp(poCredentials, poUser)),
				tap(
					() => {
						this.setAuthenticationStatus(true);
						this.isvcLogger.action(SecurityService.C_LOG_ID, "User unlocked the application.", ELogActionId.login, UserData.current);
					},
					poError => this.logAuthenticationActionFailed("User unlock app failed", poError)
				),
				mapTo(true),
				catchError(poError => {
					this.setAuthenticationStatus(false);
					console.error(`${SecurityService.C_LOG_ID}Erreur déverrouillage de l'app :`, poError);
					poCredentials.tokenExpirationDate = undefined;
					return this.persistCredentials(poCredentials).pipe(mergeMapTo(throwError(() => poError)));
				})
			)
			.toPromise();
	}

	private innerUnlockApp(poCredentials: ICredentialsDocument, poUser?: IUser): void {
		if (poUser) {
			this.setUserConfigs(poUser, poCredentials);
			ConfigData.authentication.token = poCredentials.token;
			ConfigData.appInfo.login = poCredentials.login;
		}
		else {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({
					message: "Une erreur est survenue lors de la récupération de votre profil.\nVeuillez vous reconnecter par mot de passe.",
					header: "Erreur"
				})
			);

			throw `Erreur critique : pas de document utilisateur pour '${poCredentials.login}'.`;
		}
	}

	/** Permet de mettre à jour le mode de déverrouillage.
	 * @param peLockMode Mode de déverrouillage de l'application.
	 */
	public updateUnlockMode(peLockMode: EUnlockSecurityMode): Observable<ISecuritySettingsDocument> {
		return this.saveSecuritySettings$({ unlockMode: peLockMode });
	}

	/** Déconnecte l'utilisateur courant et navigue vers la page de connexion. */
	public logOutAsync(): Promise<boolean> {
		this.setAuthenticationStatus(false);
		this.observableCanUnlockApp.value = false;
		// Log qui sera téléverser sur la base des logs lors de la prochaine connexion car plus les droits pour écrire sur la base.
		this.isvcLogger.action(SecurityService.C_LOG_ID, "User logged out.", ELogActionId.logout, undefined, true);

		return this.redirectToAuthenticationAsync({ isLoggingOut: true } as IRedirectToAuthenticationParams);
	}

	/** Supprime le token sauvegardé en local. */
	public clearTokenAsync(): Promise<ICredentialsDocument> {
		return this.getUserCredentials$()
			.pipe(mergeMap((poUserCredentials: ICredentialsDocument) => this.persistCredentials({ login: poUserCredentials.login })))
			.toPromise();
	}

	public logAuthenticationActionFailed(psMessage: string, poError?: any): void {
		this.isvcLogger.action(SecurityService.C_LOG_ID, psMessage, ELogActionId.authenticationFailed, poError);
	}

	//#endregion

}