import { Observable, Subject } from 'rxjs';
import { IDestroyable } from "../../lifecycle/models/IDestroyable";
import { IQueuerParams } from '../models/iqueuer-params';
import { Queuer } from '../models/queuer';

/** Paramétrage de la file. */
interface IQueueParams<T extends IDestroyable, U extends any[], V> extends Omit<IQueuerParams<U, V>, "thingToQueue"> {
	/** Condition de fermeture de la file d'attente. */
	shouldFinishQueue?: (poThis: T, ...paArgs: U) => boolean;
	/** Callback appelée lors de la fermeture de la file d'attente. */
	onQueueFinished?: (poThis: T) => void;
	/** Indique si la file doit faire abstraction de l'instance d'une même classe. */
	crossInstance?: boolean;
}

/** Permet de chaîner les appels à une méthode observable. Utiliser le snippet `queue`.
 * @param poParams
 */
export function Queue<S extends IDestroyable, T extends any[], U extends Observable<any>>(poParams?: IQueueParams<S, T, U>):
	(poTarget: S,
		psMethodName: string | symbol,
		poDescriptor: TypedPropertyDescriptor<(...paArgs: any[]) => U>
	) => TypedPropertyDescriptor<(...paArgs: any[]) => U> {

	return function (poTarget: S,
		psMethodName: string | symbol,
		poDescriptor: TypedPropertyDescriptor<(...paArgs: any[]) => U>
	): TypedPropertyDescriptor<(...paArgs: any[]) => U> {
		const lfOriginalMethod: (...paArgs: any[]) => U = poDescriptor.value; // On sauvegarde l'ancienne implémentation de la méthode.
		const loQueuersByTarget = new Map<S, Queuer<any, any>>();
		const loFinishQueueSubjectsByTarget = new Map<S, Subject<void>>();

		poDescriptor.value = function (): U {
			const loArgument: T = arguments as any as T;
			const loTarget: S = this;
			const loIndexTarget: S = poParams?.crossInstance ? poTarget : this;
			let loQueuer: Queuer<any, any>;
			let loShouldFinishQueueSubject: Subject<void>;

			if (loFinishQueueSubjectsByTarget.has(loIndexTarget))
				loShouldFinishQueueSubject = loFinishQueueSubjectsByTarget.get(loIndexTarget);
			else {
				loFinishQueueSubjectsByTarget.set(loIndexTarget, loShouldFinishQueueSubject = new Subject);
				loTarget.destroyed$.subscribe(() => { // On envoie un événement avant de compléter pour notifier les abonnés de la fin.
					loShouldFinishQueueSubject.next(undefined);
					loShouldFinishQueueSubject.complete();
				});
			}

			if (loQueuersByTarget.has(loIndexTarget)) {
				loQueuer = loQueuersByTarget.get(loIndexTarget);
				// Permet de redéfinir la méthode dans le cas d'un crossInstance avec des classes filles
				// afin d'exécuter la méthode de la classe fille qui a déclenché le décorateur.
				loQueuer.replaceActionQueue((paParams: any[]) => lfOriginalMethod.apply(loTarget, paParams));
			}
			else {
				loQueuersByTarget.set(loIndexTarget, loQueuer = new Queuer<any, any>({
					thingToQueue: (paParams: any[]) => lfOriginalMethod.apply(loTarget, paParams),
					excludePendings: poParams?.excludePendings,
					paramsReducer: poParams?.paramsReducer,
					keepOnlyLastPending: poParams?.keepOnlyLastPending,
					minimumGapMs: poParams?.minimumGapMs,
					idBuilder: (paArgs: T) => poParams?.idBuilder ? poParams.idBuilder(...paArgs) : psMethodName
				}));

				loQueuer.start().subscribe();

				loShouldFinishQueueSubject.asObservable().subscribe(() => { // On termine la file puis on supprime des maps des files la cible afin de réinitialiser.
					loQueuer.end();
					loQueuersByTarget.delete(loIndexTarget);
					loFinishQueueSubjectsByTarget.delete(loIndexTarget);
				});
			}

			if (poParams?.shouldFinishQueue && poParams.shouldFinishQueue(loTarget, ...loArgument)) {
				loShouldFinishQueueSubject.next(undefined);
				if (poParams.onQueueFinished)
					poParams.onQueueFinished(loTarget);
			}

			return loQueuer.exec$(loArgument) as U;
		};

		return poDescriptor;
	};
}