import { Injectable } from '@angular/core';

import { ShowMessageParamsToast } from '../../services/interfaces/ShowMessageParamsToast';
import { PlatformService } from '../../services/platform.service';
import { UiMessageService } from '../../services/uiMessage.service';

@Injectable()
export class CanRecordAudioGuard  {

	//#region FIELDS

	private static readonly C_ACCEPT_MIC_MESSAGE = "Veuillez accepter l'utilisation du microphone pour accéder à la consultation.";
	private static readonly C_NO_MIC_MESSAGE = "Aucun microphone n'a été détecté.";

	//#endregion

	//#region METHODS

	constructor(
		private isvcPlatform: PlatformService,
		private isvcUIMessage: UiMessageService
	) { }

	public async canActivate(): Promise<boolean> {
		try {
			try {
				await navigator.mediaDevices.getUserMedia({ audio: true });
			} catch (poError) {
				if (poError?.message === "Requested device not found")
					throw new Error(CanRecordAudioGuard.C_NO_MIC_MESSAGE);
				else
					throw new Error(CanRecordAudioGuard.C_ACCEPT_MIC_MESSAGE);
			}

			return true;
		}
		catch (poError) {
			console.warn("CAN.CALL.G:: ", poError);

			this.isvcUIMessage.showMessage(new ShowMessageParamsToast({ message: poError?.message, color: "dark" }));

			return false;
		}
	}

	//#endregion
}