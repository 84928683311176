import { IConstructor } from "../../utils/models/models/iconstructor";

/** Registre des composants de modales pouvant être ouverts depuis le menu latéral. */
export abstract class ModalComponentsRegistry {

	//#region PROPERTIES

	private static readonly C_MODAL_COMPONENT_BY_ID = new Map<string, IConstructor>();

	//#endregion PROPERTIES

	//#region METHODS

	private constructor() { }

	/** `true` si l'ajout s'est bien passé, `false` si la clé existe déjà.
	 * @param psKey Clé du composant modale.
	 * @param poConstructor Constructeur du composant modale.
	 */
	public static add(psKey: string, poConstructor: IConstructor): boolean {
		if (this.C_MODAL_COMPONENT_BY_ID.has(psKey))
			return false;
		else {
			this.C_MODAL_COMPONENT_BY_ID.set(psKey, poConstructor);
			return true;
		}
	}

	/** `true` si la suppression s'est bien passée, `false` si la clé n'existe pas.
	 * @param psKey Clé qu'il faut supprimer.
	 */
	public static remove(psKey: string): boolean {
		return this.C_MODAL_COMPONENT_BY_ID.delete(psKey);
	}

	/** `true` si la clé du composant modale existe, `false` sinon.
	 * @param psKey Clé du composant modale à vérifier.
	 */
	public static has(psKey: string): boolean {
		return this.C_MODAL_COMPONENT_BY_ID.has(psKey);
	}

	/** Récupère le constructeur du composant modale correspondant à la clé en paramètre, `undefined` si non trouvé.
	 * @param psKey Clé du composant modal à récupérer.
	 */
	public static get(psKey: string): IConstructor | undefined {
		return this.C_MODAL_COMPONENT_BY_ID.get(psKey);
	}

	//#endregion METHODS

}