import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { ICalendarEvent } from '@calaosoft/osapp/modules/calendar/models/icalendar-event';
import { ObserveArray } from '@calaosoft/osapp/modules/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp/modules/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { Observable, of } from 'rxjs';
import { C_COLOR_EVENT_DEFAULT, C_COLOR_EVENT_ITEM_START, C_ICON_TASK_DEFAULT } from '../../../../app/app.constants';
import { ETradeEventType } from '../../models/etrade-event-type';
import { TradeEventsService } from '../../services/trade-events.service';

@Component({
	selector: 'trade-day-list-view',
	templateUrl: './day-list-view.component.html',
	styleUrls: ['./day-list-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.width]": "'90%'",
		"[style.margin]": "'0px auto'"
	}
})
export class DayListViewComponent {

	//#region PROPERTIES

	public readonly eventItemStartColor = C_COLOR_EVENT_ITEM_START;
	public readonly eventItemColor = C_COLOR_EVENT_DEFAULT;

	/** Evènements à afficher dans la liste. */
	@Input() public events?: ICalendarEvent[];
	@ObserveArray<DayListViewComponent>("events")
	public readonly observableEvents = new ObservableArray<ICalendarEvent>();

	/** Date de la journée. */
	@Input() public date?: Date;
	@ObserveProperty<DayListViewComponent>({ sourcePropertyKey: "date" })
	public readonly observableDate = new ObservableProperty<Date>();

	public readonly eventType = ETradeEventType;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcTradeEvents: TradeEventsService
	) {
	}

	public getEventIcon$(poEvent: BaseEventOccurrence): Observable<string> {
		if (poEvent.eventType === "task")
			return of(C_ICON_TASK_DEFAULT);
		else
			return this.isvcTradeEvents.getIconNameFromSubtype$(poEvent);
	}

	public getEventTypeLabel$(poEvent: BaseEventOccurrence): Observable<string> {
		return this.isvcTradeEvents.getShortTypeLabelFromSubtype$(poEvent);
	}

	/** Retourne la classe css à appliquer à l'item. Permet de changer la couleur de l'icone.
	* @param poItem Occurence où regarder le type pour en déduire la classe à appliquer.
	*/
	public getEventCssClass(poOccurence: BaseEventOccurrence): string {
		return poOccurence.eventType === "task" ? "task" : "event";
	}

	//#endregion

}
