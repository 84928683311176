import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, AppLaunchUrl, URLOpenListenerEvent } from '@capacitor/app';
import { PlatformService } from '../../services/platform.service';

@Injectable()
export class DeeplinkService {

	//#region FIELDS

	private static readonly C_LOG_ID = "DEEPLINK.S::";

	//#endregion

	//#region METHODS

	constructor(
		private ioRouter: Router,
		private ioNgZone: NgZone,
		private isvcPlatform: PlatformService
	) { }

	public init(): void {
		if (this.isvcPlatform.isMobileApp) {
			App.getLaunchUrl().then((poAppLaunchUrl?: AppLaunchUrl) => {
				if (poAppLaunchUrl)
					this.navigateTo(poAppLaunchUrl.url);
			});
			App.addListener("appUrlOpen", (poEvent: URLOpenListenerEvent) => {
				this.ioNgZone.run(() => // Indispensable car on est dans une callback executée en dehors d'angular.
					this.navigateTo(poEvent.url)
				);
			});
		}
	}

	private navigateTo(psUrl: string): Promise<boolean> {
		const lsPreparedUrl: string = psUrl.split(".fr").pop();
		console.debug(`${DeeplinkService.C_LOG_ID}Navigating to ${lsPreparedUrl}.`);
		return this.ioRouter.navigateByUrl(lsPreparedUrl);
	}

	//#endregion

}
