import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { IUpdateTaskParams } from '../../model/backgroundTask/taskParams/IUpdateTaskParams';
import { ESecurityFlag } from '../../model/security/ESecurityFlag';
import { IUpdate } from '../../model/update/IUpdate';
import { FlagService } from '../flag.service';
import { InjectorService } from '../injector.service';
import { UpdateService } from '../update.service';
import { TaskBase } from './TaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export class UpdateTask<T extends IUpdateTaskParams = IUpdateTaskParams> extends TaskBase<T> {

	//#region FIELDS

	protected msvcUpdate: UpdateService;
	protected msvcFlags: FlagService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);

		this.msvcUpdate = InjectorService.instance.get(UpdateService);
		this.msvcFlags = InjectorService.instance.get(FlagService);
	}

	protected override execTask$(): Observable<IUpdate> {
		return this.msvcFlags.waitForFlag(ESecurityFlag.authenticated, true).pipe(
			mergeMap(() => this.msvcUpdate.popupUpdate()
				.pipe(
					catchError((poError: any) => {
						console.error(`UPD.T:: Erreur récupération de la mise à jour: ${poError}.`);
						return throwError(() => "Erreur récupération de la mise à jour.");
					})
				)
			));
	}

	//#endregion

}
