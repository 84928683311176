import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo, mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { EntityLinkService } from '../../../services/entityLink.service';
import { Store } from '../../../services/store.service';
import { IEntity } from '../../entities/models/ientity';
import { IDmsDocument } from '../model/IDmsDocument';
import { IDmsDocumentData } from '../model/IDmsDocumentData';
import { DmsDocument } from '../model/dms-document';
import { DmsMetaService } from './dms-meta.service';

@Injectable()
export class DmsExternalLinkerService {

	//#region FIELDS

	private static readonly C_LOG_ID = "DMS.EXT.LINK.S::";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcStore: Store,
		private readonly isvcMeta: DmsMetaService
	) { }

	/** Lie des documents à une entité. Il faut donner tous les fichiers liés car ceux qui ne sont pas indiqués seront supprimés.
	 * @param poEntity
	 * @param paFiles
	 */
	public linkFilesToEntity(poEntity: IStoreDocument, paFiles: IDmsDocumentData[]): Observable<boolean> {
		const laDmsDocuments: DmsDocument[] = paFiles.map((poFile: IDmsDocumentData) =>
			this.isvcMeta.generateDmsDocumentFromData(poFile)
		);

		return this.getEntityLinkedDocuments(poEntity).pipe(
			mergeMap((paOldDmsDocuments: IDmsDocument[]) => {
				const laDmsDocumentsToRemove: IDmsDocument[] = ArrayHelper.getDifferences(paOldDmsDocuments, laDmsDocuments, (poDocA: IDmsDocument, poDocB: DmsDocument) => poDocA._id === poDocB._id);
				const laDmsDocumentsToAdd: DmsDocument[] = ArrayHelper.getDifferences(laDmsDocuments, paOldDmsDocuments, (poDocA: DmsDocument, poDocB: IDmsDocument) => poDocA._id === poDocB._id);
				const laEntitiesToAdd: IEntity[] = this.getEntitiesToAdd(laDmsDocumentsToAdd, poEntity);
				const laEntitiesToRemove: IEntity[] = this.getEntitiesToRemove(laDmsDocumentsToRemove);

				this.isvcEntityLink.cacheLinkToAdd(poEntity, laEntitiesToAdd);
				this.isvcEntityLink.cacheLinkToRemove(poEntity, laEntitiesToRemove);

				const laDmsDocumentsToUpdate: DmsDocument[] = ArrayHelper.intersection(laDmsDocuments, paOldDmsDocuments, (poDocA: DmsDocument, poDocB: IDmsDocument) => {
					if (poDocA._id === poDocB._id) {
						poDocA._rev = poDocB._rev;
						StoreHelper.updateDocumentCacheData(poDocA, StoreHelper.getDocumentCacheData(poDocB));
						return !ObjectHelper.areEquals(poDocA, poDocB);
					}
					return false;
				});

				return this.isvcEntityLink.saveEntityLinks(poEntity).pipe(
					mergeMap(() =>
						this.isvcStore.putMultipleDocuments([...laDmsDocumentsToAdd, ...laDmsDocumentsToUpdate, ...laDmsDocumentsToRemove], ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)))
					),
					mapTo(true)
				);
			})
		);
	}

	private getEntitiesToAdd(paDmsDocumentsToAdd: DmsDocument[], poEntity: IStoreDocument): IEntity[] {
		const laEntitiesToAdd: IEntity[] = [];
		paDmsDocumentsToAdd.map((poDoc: DmsDocument) => {
			try {
				StoreHelper.updateDocumentCacheData(poDoc, { databaseId: StoreHelper.getDatabaseIdFromCacheData(poEntity) });
				laEntitiesToAdd.push(poDoc);
			}
			catch (poError) {
				console.error(`${DmsExternalLinkerService.C_LOG_ID}`, poError);
			}
		});
		return laEntitiesToAdd;
	}

	private getEntitiesToRemove(paDmsDocumentsToRemove: IDmsDocument[]): IEntity[] {
		const laEntitiesToRemove: IEntity[] = [];
		paDmsDocumentsToRemove.map((poDoc: DmsDocument) => {
			try {
				poDoc._deleted = true;
				laEntitiesToRemove.push(poDoc);
			}
			catch (poError) {
				console.error(`${DmsExternalLinkerService.C_LOG_ID}`, poError);
			}
		});
		return laEntitiesToRemove;
	}

	/** Récupère les documents liés à une entité.
	 * @param poEntity
	 * @param psType Type de document `optional`
	 * @returns Un observable qui retourne les documents liés.
	 */
	public getEntityLinkedDocuments(poEntity?: IStoreDocument | string, psType?: string): Observable<DmsDocument[]> {
		return this.isvcEntityLink.getLinkedEntities(poEntity, this.isvcMeta.generatePrefix(psType)).pipe(
			map(((paDocs: IDmsDocument[]) => (paDocs ?? []).map((poDmsDocument: IDmsDocument) => DmsDocument.FromIDmsDocument(poDmsDocument))))
		);
	}

	/** Récupère les documents liés à un ensemble d'entités.
	 * @param paEntities
	 * @param psType Type de document `optional`
	 * @returns Un observable qui retourne les documents liés.
	 */
	public getEntitiesLinkedDocuments(paEntities?: IStoreDocument[] | string[], psType?: string): Observable<Map<string, DmsDocument[]>> {
		return this.isvcEntityLink.getLinkedEntities<IDmsDocument>(paEntities, this.isvcMeta.generatePrefix(psType)).pipe(
			map(((poDocsByIds: Map<string, IDmsDocument[]>) => MapHelper.map(poDocsByIds, (paDocs: IDmsDocument[]) => (paDocs ?? []).map((poDmsDocument: IDmsDocument) => DmsDocument.FromIDmsDocument(poDmsDocument)))))
		);
	}

	//#endregion

}
