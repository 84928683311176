<ng-container *ngIf="observableTaskOccurrence.value$ | async as item">
	<ion-item class="ion-no-padding" lines="none" (click)="onTaskClicked()">
		<calao-list-item>
			<calao-list-item-start>
				<trade-list-item-start-priority
					[priority]="item.observablePriority.value$ | async"></trade-list-item-start-priority>
			</calao-list-item-start>
			<calao-list-item-content [backgroundColor]="taskItemColor" [detail]="!(item.done$ | async)"
				[detailIcon]="!item.done ? 'ellipsis-vertical' : ''" (onDetailClicked)="presentPopOverAsync($event)">
				<calao-list-item-content-prefix>
					<ng-container *ngIf="!item.done && (item.isLate$ | async)">
						<div class="task">
							<ion-icon class="late-icon" name="stopwatch"></ion-icon>
							<ion-note class="late">RETARD</ion-note>
						</div>
					</ng-container>
					<ng-container class="date-label"
						*ngIf="!item.done && (item.getDeadlineLabel() || (item.dateLabel$ | async)) as value">
						<ng-container
							*ngTemplateOutlet="(observableDisplayDeadlineLabel.value$ | async) ? deadlineLabel : dateLabel"></ng-container>
						<ng-template #deadlineLabel>
							<ion-note class="prefix-note ion-text-wrap deadline">
								{{ (item.isLate$ | async )? 'échéance' : 'Échéance' }} {{ value }}
							</ion-note>
						</ng-template>
						<ng-template #dateLabel>
							<ion-note class="prefix-note ion-text-wrap">
								{{ (item.isLate$ | async )? 'prévue' : 'Prévue' }} {{ value }}
							</ion-note>
						</ng-template>
					</ng-container>
				</calao-list-item-content-prefix>
				<calao-list-item-content-title *ngIf="item.observableTitle.value$ | async as value">
					{{value}}
				</calao-list-item-content-title>
				<ng-container>
					<div [ngClass]="item.done ? 'achievement-done' : 'achievement'">
						<b>{{ item.getTranslatedStatus() }}</b> <span> {{ item.getStatusChangeDateLabel$() | async
							}}, </span> <span class="achievement-percentage">{{ item.observableAchievement.value$ | async }}%</span>
					</div>
				</ng-container>
				<span>{{item.participantsLabel$ | async}}</span>
				<calao-list-item-content-suffix *ngIf="item.observableComment.value$ | async as value">{{value}}
				</calao-list-item-content-suffix>
			</calao-list-item-content>
		</calao-list-item>
	</ion-item>
</ng-container>