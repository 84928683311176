import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { ObserveArray } from '@calaosoft/osapp/modules/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp/modules/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { secure } from '@calaosoft/osapp/modules/utils/rxjs/operators/secure';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'trade-late-item',
	templateUrl: './late-item.component.html',
	styleUrls: ['./late-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LateItemComponent extends DestroyableComponentBase implements OnInit {

	//#region PROPERTIES

	/** Couleur de fond de la partie gauche de l'item. */
	@Input() public itemStartBackgroundColor?: string;
	@ObserveProperty<LateItemComponent>({ sourcePropertyKey: "itemStartBackgroundColor" })
	public readonly observableItemStartBackgroundColor = new ObservableProperty<string>("var(--ion-color-danger-tint)");

	/** Couleur de l'icône dans la partie gauche de l'item. */
	@Input() public itemStartIconColor?: string;
	@ObserveProperty<LateItemComponent>({ sourcePropertyKey: "itemStartIconColor" })
	public readonly observableItemStartIconColor = new ObservableProperty<string>("var(--ion-color-danger)");

	/** Couleur de fond de l'item. */
	@Input() public itemBackgroundColor?: string;
	@ObserveProperty<LateItemComponent>({ sourcePropertyKey: "itemBackgroundColor" })
	public readonly observableItemBackgroundColor = new ObservableProperty<string>("var(--ion-color-primary-contrast)");

	/** Nombre total de tâches/rappels en retard. */
	@Input() public totalCount?: number;
	@ObserveProperty<LateItemComponent>({ sourcePropertyKey: "totalCount" })
	public readonly observableTotalCount = new ObservableProperty<number>();

	/** Nombres de tâches/rappels restants en plus de ceux affichées. */
	@Input() public diffCount?: number;
	@ObserveProperty<LateItemComponent>({ sourcePropertyKey: "diffCount" })
	public readonly observableDiffCount = new ObservableProperty<number>();

	public readonly observableDiffCountLabel = new ObservableProperty<string>();

	/** Tableau des tâches/rappels à lister dans l'item late. */
	@Input() public events: BaseEventOccurrence[];
	@ObserveArray<LateItemComponent>("events")
	public readonly observableEvents = new ObservableArray<BaseEventOccurrence>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcRouter: Router,
		private readonly poActivatedRoute: ActivatedRoute
	) {
		super();
	}

	public ngOnInit() {
		this.observableDiffCount.value$.pipe(
			tap((pnDiff: number) => {
				this.observableDiffCountLabel.value = (pnDiff > 0) ? `+${pnDiff} autre${pnDiff > 1 ? "s" : ""}` : "";
			}),
			secure(this)
		).subscribe();
	}

	public navigateToLateListPageAsync(): Promise<boolean> {
		return this.isvcRouter.navigate(["late"], { relativeTo: this.poActivatedRoute });
	}

	//#endregion METHODS

}
