import { CommonModule } from '@angular/common';
import { NgModule, Provider, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { IonicModule } from '@ionic/angular';
import { ROUTE_COMPONENTS } from '../constants';
import { DocExplorerModule } from '../modules/doc-explorer/doc-explorer.module';
import { SitesModule } from '../modules/sites/sites.module';
import { PipeModule } from '../pipes/pipes.module';
import { RouteComponentRegistries } from './ComponentRegistry';
import { ContactsModule } from './contacts/contacts.module';
import { ConversationModule } from './conversation/conversation.module';
import { CurveModule } from './curve/curve.module';
import { DateModule } from './date/date.module';
import { DiagnosticsModule } from './diagnostics/diagnostics.module';
import { DynamicPageModule } from './dynamicPage/dynamicPage.module';
import { FilePickerModule } from './filePicker/filePicker.module';
import { FormModule } from './forms/form/form.module';
import { GalleryModule } from './gallery/gallery.module';
import { GeolocationModule } from './geolocation/geolocation.module';
import { LinkModule } from './link/link.module';
import { LinkedItemsListModule } from './linkedItemsList/linkedItemsList.module';
import { MenuModule } from './menu/menu.module';
import { PopoverModule } from './popover/popover.module';
import { SearchModule } from './search/search.module';
import { SecurityModule } from './security/security.module';
import { SliderModule } from './slider/slider.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { WebViewModule } from './webview/webview.module';

const modules: Type<any>[] = [
	PipeModule,
	IonicModule,
	FormsModule,
	CommonModule
];
const osappModules: Type<any>[] = [
	CurveModule,
	ContactsModule,
	ConversationModule,
	DateModule,
	DiagnosticsModule,
	DynamicPageModule,
	FilePickerModule,
	FormModule,
	GalleryModule,
	GeolocationModule,
	LinkedItemsListModule,
	LinkModule,
	MenuModule,
	PopoverModule,
	SearchModule,
	SliderModule,
	ToolbarModule,
	WebViewModule,
	SecurityModule,
	SitesModule,
	DocExplorerModule
];
const providers: Provider[] = [
	SocialSharing,
	Network,
	FingerprintAIO,
	{ provide: ROUTE_COMPONENTS, useValue: RouteComponentRegistries }
];

/** Ce module regroupe tous les composants généraux de la bibliothèque. */
@NgModule({
	imports: [modules, ...osappModules],
	exports: [...osappModules],
	providers: providers
})
export class ComponentsModule { }