import { Component } from '@angular/core';
import { FieldBase } from '../../../models/FieldBase';
import { FormsService } from '../../../services/forms.service';

@Component({
	templateUrl: './toggleField.component.html'
})
export class ToggleFieldComponent extends FieldBase { //todo : typer + params.

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	public onModelChanged(): void {
		this.fieldValue = !this.fieldValue;
		this.markAsDirty();
	}

	//#endregion
}