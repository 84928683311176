import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonImg } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { EXTENSIONS_AND_MIME_TYPES, FileHelper } from '../../../../helpers/fileHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { EAvatarSize } from '../../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { EDmsImageQuality } from '../../../dms/EDmsImageQuality';
import { IDmsData } from '../../../dms/model/IDmsData';
import { DmsService } from '../../../dms/services/dms.service';

type InitialsAndColorIndex = {
	colorIndex: number;
	initials: string;
};

@Component({
	selector: "avatar",
	templateUrl: './avatar.component.html',
	styleUrls: ['avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Regex permettant d'extraire la première lettre croisée. */
	private static readonly C_AVATAR_REGEX: RegExp = /[a-zA-Z]/;
	/** Tableau de mappage des couleurs : dark = couleur sombre texte clair ; light = couleur claire texte sombre */
	private static readonly C_COLORS: Array<{ id: number, darkColor: string, lightColor: string }> = [
		{ id: 1, darkColor: "49B790", lightColor: "A4DBC8" },
		{ id: 2, darkColor: "22780F", lightColor: "C3F4C8" },
		{ id: 3, darkColor: "16B84E", lightColor: "8BDCA7" },
		{ id: 4, darkColor: "A33030", lightColor: "80FF80" },
		{ id: 5, darkColor: "4ABBB0", lightColor: "82D5DD" },
		{ id: 6, darkColor: "3A8EBA", lightColor: "9DC7DD" },
		{ id: 7, darkColor: "5472AE", lightColor: "AAB9D7" },
		{ id: 8, darkColor: "245CA0", lightColor: "81A2E5" },
		{ id: 9, darkColor: "6803F9", lightColor: "B481FC" },
		{ id: 10, darkColor: "660099", lightColor: "B380CC" },
		{ id: 11, darkColor: "884DA7", lightColor: "C4A6D3" },
		{ id: 12, darkColor: "C165C1", lightColor: "E0B2E0" },
		{ id: 13, darkColor: "DF73FF", lightColor: "EFB9FF" },
		{ id: 14, darkColor: "FF00FF", lightColor: "FF80FF" },
		{ id: 15, darkColor: "EF4BB0", lightColor: "F7A5D8" },
		{ id: 16, darkColor: "C71585", lightColor: "E38AC2" },
		{ id: 17, darkColor: "FE436E", lightColor: "FFA1B7" },
		{ id: 18, darkColor: "FF6F7D", lightColor: "FFB7BE" },
		{ id: 19, darkColor: "E67E30", lightColor: "F3BF98" },
		{ id: 20, darkColor: "FFCB60", lightColor: "FFE5B0" },
		{ id: 21, darkColor: "DFAE2C", lightColor: "EFD796" },
		{ id: 22, darkColor: "F0C300", lightColor: "F8E180" },
		{ id: 23, darkColor: "FCDC12", lightColor: "FEEE89" },
		{ id: 24, darkColor: "E4EC7C", lightColor: "F2F6BE" },
		{ id: 25, darkColor: "E1CE9A", lightColor: "F0E7CD" },
		{ id: 26, darkColor: "B074C0", lightColor: "7B6474" }
	];

	//#endregion

	//#region PROPERTIES

	private moSource: IAvatar;
	public get source(): IAvatar { return this.moSource; }
	@Input("src") public set source(poValue: IAvatar) {
		if (poValue) { // Si la nouvelle valeur est valide.
			// Si la source est renseignée et son texte ou sa data est différente de celui de la nouvelle valeur OU si la source n'est pas renseignée.
			if (!this.moSource ||
				(this.moSource && (this.moSource.text !== poValue.text || this.moSource.data !== poValue.data || this.moSource.guid !== poValue.guid))) {
				this.moSource = poValue;
				this.onSourceChanged();
			}
		}
	}

	@Input() public cssClass = "round";

	/** Événement pour indiquer que la source devant afficher un avatar est tombée en erreur. */
	@Output("srcError") public readonly srcErrorEvent: EventEmitter<IonImg & { el: any }> = new EventEmitter();
	/** Balise `ion-image` du template html. */
	@ViewChild("img") public ionImg: IonImg;

	/** Url, base64 ou blob vers l'image de l'avatar à afficher. */
	public data?: string | Blob;
	public inlineStyle: { width: string, "min-width": string, height: string, color?: string, background?: string };

	//#endregion

	//#region METHODS

	constructor(private isvcDms: DmsService, poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		if (!this.source) { // Cas de bug (source non renseignée).
			this.moSource = { text: '?', size: EAvatarSize.medium }; // On affiche un point d'interrogation sur fond rouge.
			this.onSourceChanged();
		}
	}

	/** Crée le rendu graphique de l'avatar et le retourne.
	 * @param psInitials Initiales de la personne qui figureront sur l'avatar.
	 * @param psBackgroundColor Couleur de fond de l'avatar en hex.
	 * @param psTextColor Couleur du texte de l'avatar.
	 * @returns Rendu graphique de l'avatar.
	 */
	private renderer(psInitials: string, psBackgroundColor: string, psTextColor: string): Blob {
		const lnSize: number = EAvatarSize.big;
		const loCanvas: HTMLCanvasElement = document.createElement("canvas");
		const loContext: CanvasRenderingContext2D | null = loCanvas.getContext("2d");

		loCanvas.width = lnSize;
		loCanvas.height = lnSize;

		if (loContext) {
			loContext.fillStyle = `#${psBackgroundColor}`;
			loContext.fillRect(0, 0, loCanvas.width, loCanvas.height);
			loContext.font = `bold ${Math.round(loCanvas.width / 2)}px Arial`;
			loContext.textAlign = "center";
			loContext.fillStyle = `#${psTextColor}`;
			loContext.fillText(psInitials, lnSize / 2, lnSize / 1.5);
		}
		else
			console.error(`AVTR.C::Avatar graphics rendering failed : canvas context is missing.`);

		return FileHelper.base64toBlob(loCanvas.toDataURL(), EXTENSIONS_AND_MIME_TYPES.png.mimeType);
	}

	/** Crée une image sous forme de canvas contenant un fond de couleur avec les initiales d'une personne.
	 * @param psName Nom et prénom de la personne séparés par un espace.
	 */
	private create(psName: string = ""): Blob {
		const lnColorsLength: number = AvatarComponent.C_COLORS.length;
		const loInitialsAndColorIndex: InitialsAndColorIndex = this.getInitialsAndColorIndex(psName);
		let lsBackgroundColor: string;

		// 26 lettres dans l'alphabet : mode 'dark' pour les index 0-25, mode 'light pour les index 26-51.
		if (loInitialsAndColorIndex.colorIndex >= lnColorsLength) { // Si l'index est pour le mode 'light'.
			// On parle des index ici, pas du nombre d'éléments.
			lsBackgroundColor = AvatarComponent.C_COLORS[loInitialsAndColorIndex.colorIndex - (lnColorsLength - 1)].lightColor;
		}
		else if (loInitialsAndColorIndex.colorIndex >= 0) // Si l'index est pour le mode 'dark'.
			lsBackgroundColor = AvatarComponent.C_COLORS[loInitialsAndColorIndex.colorIndex].darkColor;
		else // Si l'index n'est pas valide, avatar '?', fond rouge.
			lsBackgroundColor = "ff0000";

		const lsTextColor = loInitialsAndColorIndex.colorIndex >= lnColorsLength ? "000" : "FFF";

		return this.renderer(loInitialsAndColorIndex.initials, lsBackgroundColor, lsTextColor);
	}

	/** Récupère les initiales et l'index de la couleur du texte pour construire l'avatar.
	 * @param psName Nom et prénom de la personne séparés par un espace.
	 * @see https://github.com/uttesh/ngletteravatar (modifié)
	 * @returns Objet comprenant les initiales pour l'avatar ainsi que l'index de couleur dans le tableau, -1 si l'avatar est inconnu.
	 */
	private getInitialsAndColorIndex(psName: string): InitialsAndColorIndex {
		// On met en majuscules et on normalise les lettres accentuées par décomposition (on sépare le caractère 'è' en caractère 'e' + caractère 'accent grave').
		const laSplittedNames: Array<string> = psName.toUpperCase().normalize("NFD")
			.split(" ");

		// L'index de couleur sera compris entre 'A' et 'Z' (code ascii = 65 à 90) ou '?' (code ascii = 63).
		return laSplittedNames.length === 1 ?
			this.innerGetInitialsAndColorIndex_oneName(laSplittedNames) : this.innerGetInitialsAndColorIndex_twoNames(laSplittedNames);
	}

	private innerGetInitialsAndColorIndex_oneName(paSplittedNames: string[]): InitialsAndColorIndex {
		const lsName: string = ArrayHelper.getFirstElement(paSplittedNames);
		let lsInitial: string;

		if (StringHelper.isBlank(lsName))
			lsInitial = '?';
		else {
			const laMatches: RegExpMatchArray | null = lsName.match(AvatarComponent.C_AVATAR_REGEX);
			lsInitial = laMatches ? ArrayHelper.getFirstElement(laMatches) : lsName.charAt(0);
		}

		return { initials: lsInitial, colorIndex: lsInitial.charCodeAt(0) - 65 } as InitialsAndColorIndex;
	}

	private innerGetInitialsAndColorIndex_twoNames(paSplittedNames: string[]): InitialsAndColorIndex {
		const lsFirstPartName: string = paSplittedNames[0];
		const lsSecondPartName: string = paSplittedNames[1];
		const laFirstPartNameRegexResults: RegExpMatchArray | undefined | null = !StringHelper.isBlank(lsFirstPartName) ?
			lsFirstPartName.match(AvatarComponent.C_AVATAR_REGEX) : undefined;
		const laSecondPartNameRegexResults: RegExpMatchArray | undefined | null = !StringHelper.isBlank(lsSecondPartName) ?
			lsSecondPartName.match(AvatarComponent.C_AVATAR_REGEX) : undefined;
		let lsInitials: string;
		let lnColorIndex: number;

		if (laFirstPartNameRegexResults && laSecondPartNameRegexResults) {
			lsInitials = ArrayHelper.getFirstElement(laFirstPartNameRegexResults) + ArrayHelper.getFirstElement(laSecondPartNameRegexResults);
			lnColorIndex = lsInitials.charCodeAt(0) + lsInitials.charCodeAt(1) - 130; // Le code ascii de 'A' est la base ; 'A' == 65, on a deux initiales donc 65*2=130.
		}
		else {
			if (laFirstPartNameRegexResults)
				lsInitials = ArrayHelper.getFirstElement(laFirstPartNameRegexResults);
			else if (laSecondPartNameRegexResults)
				lsInitials = ArrayHelper.getFirstElement(laSecondPartNameRegexResults);
			else
				lsInitials = '?';

			lnColorIndex = lsInitials.charCodeAt(0) - 65; // On a une seule initiale donc on soustrait la base qui est 'A'=65. '?'=63 donc négatif si pas d'initiale.
		}

		return { initials: lsInitials, colorIndex: lnColorIndex } as InitialsAndColorIndex;
	}

	/** Vérifie les changements de propriétés de l'avatar et le recrée si besoin.
	 * @param poNewAvatar Objet correspondant aux nouvelles valeurs de l'avatar.
	 * @param poOldAvatar Objet correspondant aux anciennes valeurs de l'avatar.
	 */
	private onSourceChanged(): void {
		this.inlineStyle = { width: `${this.moSource.size || EAvatarSize.big}px`, 'min-width': `${this.moSource.size || EAvatarSize.big}px`, height: `${this.moSource.size || EAvatarSize.big}px` };
		if (!StringHelper.isBlank(this.source.color)) {
			this.inlineStyle.background = `var(--ion-color-${this.source.color})`;
			this.inlineStyle.color = `var(--ion-color-${this.source.color}-contrast)`;
		}

		if (!StringHelper.isBlank(this.moSource.guid)) {
			this.isvcDms.get(this.moSource.guid, EDmsImageQuality.high)
				.pipe(
					tap((poDmsData: IDmsData) => {
						this.data = poDmsData.file.File;
						this.source.icon = undefined;
						this.detectChanges();
					}),
					catchError((poError) => {
						console.error(`AVTR.C::Erreur récupération ${this.moSource.guid}, levé d'événement d'erreur associé.`, poError);
						this.data = undefined;
						this.moSource.guid = undefined;
						this.onSourceChanged();
						return throwError(() => poError);
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
		else if (!StringHelper.isBlank(this.moSource.text) && !(this.moSource.useIconAsDefaultFallback && !StringHelper.isBlank(this.moSource.icon))) { // Avatar avec texte/initiales.
			this.data = this.create(this.moSource.text);
			this.detectChanges();
		}
		else { // Avatar d'une photo/image.
			this.data = this.moSource.data;
			this.moSource.mimeType = this.moSource.data instanceof Blob ? this.moSource.data.type : EXTENSIONS_AND_MIME_TYPES.jpeg.mimeType;
			this.detectChanges();
		}
	}

	/** Gère l'erreur d'une balise `ion-img` en levant un événement contenant la balise en erreur. */
	public onImgError(): void {
		console.error(`AVTR.C::Erreur lors de l'affichage d'une image.`);
		this.srcErrorEvent.emit(this.ionImg as IonImg & { el: any });
	}

	public getAvatarClass(): string {
		switch (this.source?.size) {

			case 15:
				return "avatar-icon-15px";

			case 20:
				return "avatar-icon-20px";

			default:
				return "avatar-icon";
		}
	}

	//#endregion
}