<ng-container *ngIf="observableItem.value$ | async as eventItem">
	<ng-template
		*ngTemplateOutlet="eventItem.event.private ? private: event; context : { eventItem : eventItem }"></ng-template>

</ng-container>

<ng-template #private let-eventItem="eventItem">
	<ng-container
		*ngTemplateOutlet="eventItem.isMinePrivateEvent ? event: privateEvent; context : { eventItem : eventItem }"></ng-container>
</ng-template>

<ng-template #event let-eventItem="eventItem">
	<calao-event-item [event]="eventItem" [contentColor]="eventItemColor" [startColor]="eventItemStartColor"
		[icon$]="getEventIcon$(eventItem)" [typeLabel$]="getEventTypeLabel$(eventItem)"
		[titleIcon]="getTitleIcon(eventItem)" (click)="routeToViewEvent(eventItem)"
		(onEditClicked)="routeToEditEvent(eventItem)"></calao-event-item>
</ng-template>

<ng-template #privateEvent let-eventItem="eventItem">
	<trade-private-activity-item [item]="eventItem"></trade-private-activity-item>
</ng-template>