import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { ETimetablePattern } from 'libs/osapp/src/model/date/ETimetablePattern';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { DateHelper } from '../../../helpers/dateHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { ObserveProperty } from '../../../modules/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../modules/observable/models/observable-property';
import { IDateRange } from './model/IDateRange';
@Component({
	selector: 'calao-date-range-picker',
	templateUrl: './date-range-picker.component.html',
	styleUrls: ['./date-range-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent extends ComponentBase {

	//#region FIELDS

	@Output("dateRangeChanged") private readonly moDateRangeChangedEventEmitter = new EventEmitter<IDateRange>();

	//#endregion

	//#region PROPERTIES

	/** Modèle sur lequel se baser pour le fonctionnement du composant. */
	private moModel: IDateRange | null = null;
	public get model(): IDateRange | null {
		return this.moModel;
	}
	@Input()
	public set model(poModel: IDateRange | null) {
		if (poModel !== this.moModel) {
			if (poModel) {
				poModel.from = poModel.from ? DateHelper.resetDay(poModel.from) : poModel.from;
				poModel.to = poModel.to ? DateHelper.fillDay(poModel.to) : poModel.to;
			}

			this.moModel = poModel;
			this.detectChanges();
		}
	}

	/** Date min. */
	@Input() public min?: Date | string | null;
	@ObserveProperty<DateRangePickerComponent>({
		sourcePropertyKey: "min",
		transformer: (poValue: string | Date) => DateHelper.toDate(poValue)
	})
	public readonly observableMin = new ObservableProperty<Date>();

	/** Date max. */
	@Input() public max?: Date | string | null;
	@ObserveProperty<DateRangePickerComponent>({
		sourcePropertyKey: "max",
		transformer: (poValue: string | Date) => DateHelper.toDate(poValue)
	})
	public readonly observableMax = new ObservableProperty<Date>();

	@ViewChild("picker") public dateRangeToggle: MatDateRangePicker<Date>;

	//#endregion

	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	public pickRange(): void {
		this.dateRangeToggle.open();
	}

	public dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement): void {
		const loRange: IDateRange = {};

		if (!StringHelper.isBlank(dateRangeStart.value))
			loRange.from = DateHelper.resetDay(DateHelper.parseStringDate(dateRangeStart.value, ETimetablePattern.dd_MM_yyyy_slash));
		if (!StringHelper.isBlank(dateRangeStart.value))
			loRange.to = DateHelper.fillDay(DateHelper.parseStringDate(dateRangeEnd.value, ETimetablePattern.dd_MM_yyyy_slash));

		this.moDateRangeChangedEventEmitter.emit(this.model = loRange);
	}

	//#endregion

}