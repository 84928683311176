import { Injectable } from "@angular/core";
import { TiktokenModel, encodingForModel } from "js-tiktoken";
import { DestroyableServiceBase } from "../modules/services/models/destroyable-service-base";
import { ITextGenerationExample } from "./interfaces/itext-generation-example";
import { ITextGenerationRequest } from "./interfaces/itext-generation-request";

/** Représente le nombre de token ajouté par défaut à chaque type de message par l'API d'IA */
interface IDefaultTokenAdd {
	start: number;
	user: number;
	system: number;
	example: number;
};

@Injectable({ providedIn: "root" })
export class TokenizerService extends DestroyableServiceBase {
	//#region PROPERTIES

	/** Modèle par défaut pour le comptage de token */
	private msDefaultModel: TiktokenModel = "gpt-3.5-turbo-0125";

	private moDefaultTokenAdd : IDefaultTokenAdd = {
		start: 3,
		user: 4,
		system: 4,
		example: 8,
	}

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		super();
	}

	public countTokenTextGenerationRequest(
		poRequest: ITextGenerationRequest,
		psModel: TiktokenModel = this.msDefaultModel
	): number {
		const loEncoder = encodingForModel(psModel);
		let lnTokens = this.moDefaultTokenAdd.start;
		lnTokens += loEncoder.encode(poRequest.prompt.user).length + this.moDefaultTokenAdd.user;

		if (poRequest.prompt.system) {
			lnTokens += loEncoder.encode(poRequest.prompt.system).length + this.moDefaultTokenAdd.system;
		}

		if (poRequest.prompt.examples) {
			poRequest.prompt.examples.forEach((poExamples: ITextGenerationExample) => {
				lnTokens += loEncoder.encode(poExamples.user).length + loEncoder.encode(poExamples.assistant).length + this.moDefaultTokenAdd.example;
			});
		}

		return lnTokens;
	}

	//#endregion
}
