<ng-container *ngIf="observableEvents.changes$ | async as events">
	<ng-container *ngIf="observableDate.value$ | async as date">
		<ion-label class="date">{{ date | dateWithLocale: 'fr-FR' : 'dd MMMM yyyy' }}</ion-label>
	</ng-container>
	<calao-virtual-scroll autosize="true" [items]="events" [itemSize]="100" [minBufferPx]="400" [maxBufferPx]="500"
		margeLastItem transparent>
		<ng-template let-item="$implicit">
			<ng-container *ngIf="item.eventOccurrence.eventType === eventType.task">
				<trade-task-item [taskOccurrence]="item.eventOccurrence"></trade-task-item>
			</ng-container>
			<ng-container *ngIf="item.eventOccurrence.eventType === eventType.standard">
				<trade-event-item [item]="item.eventOccurrence"></trade-event-item>
			</ng-container>
			<ng-container *ngIf="item.eventOccurrence.eventType === eventType.reminder">
				<trade-reminder-item [item]="item.eventOccurrence"></trade-reminder-item>
			</ng-container>
		</ng-template>
	</calao-virtual-scroll>
</ng-container>