import { Component, Input } from '@angular/core';
import { EDateTimePickerMode } from '@calaosoft/osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@calaosoft/osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@calaosoft/osapp/model/date/IDateTimePickerParams';
import { ModalComponentBase } from '@calaosoft/osapp/modules/modal';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { map } from 'rxjs/operators';
import { ReminderOccurrence } from '../../models/reminder-occurrence';

@Component({
	selector: 'trade-reschedule-modal',
	templateUrl: './reschedule-modal.component.html',
	styleUrls: ['./reschedule-modal.component.scss'],
})
export class RescheduleModalComponent extends ModalComponentBase<Date> {

	//#region PROPERTIES

	/** Occurrence de l'évènement. */
	@Input() public item?: ReminderOccurrence;
	@ObserveProperty<RescheduleModalComponent>({ sourcePropertyKey: "item" })
	public readonly observableItem = new ObservableProperty<ReminderOccurrence>();

	/** Date du report. */
	public readonly observableNewDate = new ObservableProperty<Date>().bind(
		this.observableItem.value$.pipe(map((poReminderOccurrence: ReminderOccurrence) => poReminderOccurrence.startDate ?? new Date)),
		this
	);

	public readonly observableDatePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MMM_yyyy,
		hideIcon: true
	});

	public readonly observableTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.time,
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true
	});

	//#endregion PROPERTIES

	//#region METHODS

	public onDateChanged(pdStartDate: Date): void {
		this.observableNewDate.value = pdStartDate;
	}

	//#endregion METHODS

}
