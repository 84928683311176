import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { PathHelper } from '../../../../helpers/path-helper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { secure } from '../../../utils/rxjs/operators/secure';
import { DocExplorerComponent } from '../doc-explorer/doc-explorer.component';

@Component({
	selector: 'calao-doc-explorer-slide',
	templateUrl: './doc-explorer-slide.component.html',
	styleUrls: ['./doc-explorer-slide.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocExplorerSlideComponent extends DocExplorerComponent implements OnDestroy, OnInit {

	//#region PROPERTIES

	public readonly canDisplayFabButton$: Observable<boolean> = this.getCanDisplayFabButton$();

	//#endregion

	//#region METHODS

	public ngOnInit(): void {
		if (!this.observableRootPath.value) {
			const lsRootPath: string | undefined = ArrayHelper.getFirstElement(this.isvcEntities.getEntityDocumentPaths(this.isvcEntityLink.currentEntity));
			this.observableRootPath.value = lsRootPath ? PathHelper.parsePath(lsRootPath) : undefined;
		}

		if (!this.observableCurrentPath.value)
			this.observableCurrentPath.value = this.observableRootPath.value ?? "";
	}

	private getCanDisplayFabButton$(): Observable<boolean> {
		return combineLatest([
			this.observableCurrentPath.value$,
			this.isvcDocExplorerDocuments.observableIsInit.value$
		]).pipe(
			map(([psPath, _]) => {
				if (psPath)
					return this.isvcDocExplorerDocuments.checkFolderPermissions(psPath, "create", false);
				return false;
			}),
			secure(this)
		);
	}

	public override onPathChanged(psPath: string): void {
		super.onPathChanged(psPath);

		if (!StringHelper.isBlank(psPath))
			this.observableCurrentPath.value = psPath;
	}

	//#endregion METHODS

}
