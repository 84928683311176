<ng-container *ngIf="observableParams.value$ | async as params">
	<!-- Mode Edit (!RO) -->
	<div *ngIf="params && !params.readOnly">
		<calao-slider [minValue]="params.minValue" [maxValue]="params.maxValue" [step]="params.step" [pin]="params.pin"
			[ticks]="params.ticks" [snaps]="params.snaps" [value]="(observableFieldValue.value$ | async)"
			[showMinValue]="params.showMinValue" [showMaxValue]="params.showMaxValue" [colors]="params.colors"
			[defaultValue]="params.defaultValue" [minLabel]="params.minLabel" [maxLabel]="params.maxLabel"
			(onValueChange)="fieldValue = $event"></calao-slider>
	</div>

	<!-- Mode Visu (RO) -->
	<ng-container *ngIf="params && params.readOnly && fieldValue">
		<ng-container *ngTemplateOutlet="(!params.readonlyDisplayMode || params.readonlyDisplayMode === 'bar') ? bar : bubble;
			context : { fieldValue : fieldValue, params : params }"></ng-container>
	</ng-container>
</ng-container>

<ng-template #bar let-fieldValue="fieldValue" let-params="params">
	<div class="progress-bar-area">
		<ion-label [class]="'label-group txt14px margin-b'">
			{{ params.label }}
		</ion-label>
		<ion-progress-bar [value]="fieldValue/(params.maxValue-params.minValue)">
		</ion-progress-bar>
	</div>
</ng-template>

<ng-template #bubble let-fieldValue="fieldValue">
	<div [style]="observableBubbleColor.value$ | async" class="bubble">
		{{ fieldValue }}
	</div>
</ng-template>