/* eslint-disable max-lines */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Event as REvent, Router, RouterEvent } from '@angular/router';
import { Clipboard } from '@capacitor/clipboard';
import { Keyboard } from '@capacitor/keyboard';
import { IonContent, IonInfiniteScroll, ModalOptions, Platform, PopoverController } from '@ionic/angular';
import { BehaviorSubject, EMPTY, GroupedObservable, Observable, Subject, combineLatest, defer, from, fromEvent, merge, of, throwError } from 'rxjs';
import { auditTime, catchError, debounceTime, delay, distinctUntilChanged, filter, finalize, groupBy, map, mapTo, mergeMap, reduce, retryWhen, startWith, take, takeUntil, takeWhile, tap, toArray } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ConversationHelper } from '../../helpers/ConversationHelper';
import { LifeCycleObserverComponentBase } from '../../helpers/LifeCycleObserverComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ContactHelper } from '../../helpers/contactHelper';
import { DateHelper } from '../../helpers/dateHelper';
import { GuidHelper } from '../../helpers/guidHelper';
import { IdHelper } from '../../helpers/idHelper';
import { MapHelper } from '../../helpers/mapHelper';
import { StoreDocumentHelper } from '../../helpers/storeDocumentHelper';
import { StoreHelper } from '../../helpers/storeHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { UserHelper } from '../../helpers/user.helper';
import { EPrefix } from '../../model/EPrefix';
import { EApplicationEventType } from '../../model/application/EApplicationEventType';
import { ENetworkFlag } from '../../model/application/ENetworkFlag';
import { IApplicationEvent } from '../../model/application/IApplicationEvent';
import { UserData } from '../../model/application/UserData';
import { ConfigData } from '../../model/config/ConfigData';
import { EContactsType } from '../../model/contacts/EContactsType';
import { IContact } from '../../model/contacts/IContact';
import { IGroup } from '../../model/contacts/IGroup';
import { IGroupMember } from '../../model/contacts/IGroupMember';
import { EActivityStatus } from '../../model/conversation/EActivityStatus';
import { EConversationEvent } from '../../model/conversation/EConversationEvent';
import { EConversationType } from '../../model/conversation/EConversationType';
import { IConversation } from '../../model/conversation/IConversation';
import { IConversationActivity } from '../../model/conversation/IConversationActivity';
import { IConversationCacheData } from '../../model/conversation/IConversationCacheData';
import { IConversationEvent } from '../../model/conversation/IConversationEvent';
import { IConversationTask } from '../../model/conversation/IConversationTask';
import { IConversationUiEvent } from '../../model/conversation/IConversationUiEvent';
import { IParticipant } from '../../model/conversation/IParticipant';
import { IParticipantIndicator } from '../../model/conversation/IParticipantIndicator';
import { IReadIndicator } from '../../model/conversation/IReadIndicator';
import { InitComponentError } from '../../model/errors/InitComponentError';
import { IFlag } from '../../model/flag/IFlag';
import { IGalleryCommand } from '../../model/gallery/IGalleryCommand';
import { GalleryFile } from '../../model/gallery/gallery-file';
import { ELifeCycleEvent } from '../../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../../model/lifeCycle/ILifeCycleEvent';
import { EAvatarSize } from '../../model/picture/EAvatarSize';
import { IAvatar } from '../../model/picture/IAvatar';
import { IPopoverItemParams } from '../../model/popover/IPopoverItemParams';
import { ISelectorParams } from '../../model/selector/ISelectorParams';
import { IDataSource } from '../../model/store/IDataSource';
import { IStoreDocument } from '../../model/store/IStoreDocument';
import { IStoreReplicationResponse } from '../../model/store/IStoreReplicationResponse';
import { EContactsPickerSort } from '../../modules/contacts/components/contacts-picker-modal/econtacts-picker-sort';
import { IContactsPickerParams } from '../../modules/contacts/models/contacts-picker/icontacts-picker-params';
import { Message } from '../../modules/conversations/model/message';
import { MessageAiSummaryAttachment } from '../../modules/conversations/model/message-ai-summary-attachment';
import { MessageAttachment } from '../../modules/conversations/model/message-attachment';
import { MessageEntityAttachment } from '../../modules/conversations/model/message-entity-attachment';
import { MessageFileAttachment } from '../../modules/conversations/model/message-file-attachment';
import { IDmsDocumentData } from '../../modules/dms/model/IDmsDocumentData';
import { DmsDocument } from '../../modules/doc-explorer/models/dms-document';
import { Document } from '../../modules/doc-explorer/models/document';
import { FormDocument } from '../../modules/doc-explorer/models/form-document';
import { EntityModalComponent } from '../../modules/entities/components/entity-modal/entity-modal.component';
import { Entity } from '../../modules/entities/models/entity';
import { EntityLink } from '../../modules/entities/models/entity-link';
import { EntityLinkEntity } from '../../modules/entities/models/entity-link-entity';
import { IEntity } from '../../modules/entities/models/ientity';
import { IEntityCategory } from '../../modules/entities/models/ientity-category';
import { IEntityModalParams } from '../../modules/entities/models/ientity-modal-params';
import { EntitiesService } from '../../modules/entities/services/entities.service';
import { UserUsageQuota } from '../../modules/forms/models/user-usage-quota';
import { LiveService } from '../../modules/live/live.service';
import { EVisioType } from '../../modules/live/models/EVisio-type';
import { Loader } from '../../modules/loading/Loader';
import { EModalSize } from '../../modules/modal';
import { ModalService } from '../../modules/modal/services/modal.service';
import { ObservableProperty } from '../../modules/observable/models/observable-property';
import { CanExecute } from '../../modules/permissions/decorators/can-execute.decorator';
import { HasPermissions, HasPermissions$ } from '../../modules/permissions/decorators/has-permissions.decorator';
import { EPermission } from '../../modules/permissions/models/EPermission';
import { IPermissionContext } from '../../modules/permissions/models/ipermission-context';
import { IHasPermission, PermissionsService } from '../../modules/permissions/services/permissions.service';
import { PageManagerService } from '../../modules/routing/services/pageManager.service';
import { ModelResolver } from '../../modules/utils/models/model-resolver';
import { Queue } from '../../modules/utils/queue/decorators/queue.decorator';
import { secure } from '../../modules/utils/rxjs/operators/secure';
import { AiConversationService } from '../../services/ai-conversation.service';
import { AiGenerationService } from '../../services/ai-generation.service';
import { ApplicationService } from '../../services/application.service';
import { ContactsService } from '../../services/contacts.service';
import { ConversationService } from '../../services/conversation.service';
import { EntityLinkService } from '../../services/entityLink.service';
import { FlagService } from '../../services/flag.service';
import { GalleryService } from '../../services/gallery.service';
import { GroupsService } from '../../services/groups.service';
import { ShowMessageParamsPopup } from '../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '../../services/loading.service';
import { NetworkService } from '../../services/network.service';
import { PopoverService } from '../../services/popover.service';
import { Store } from '../../services/store.service';
import { UiMessageService } from '../../services/uiMessage.service';
import { DynamicPageComponent } from '../dynamicPage/dynamicPage.component';
import { EGalleryDisplayMode } from '../gallery/models/EGalleryDisplayMode';
import { PopoverComponent } from '../popover/popover.component';
import { AiConversationSummaryModalComponent } from './aiConversationSummaryModal/ai-conversation-summary-modal.component';
import { IAiConversationSummaryModalResponse } from './aiConversationSummaryModal/iai-conversation-summary-modal-response';
import { AttachmentsPopoverComponent } from './attachmentsPopover/attachmentsPopover.component';

interface IMessageModelItem {
	index: number;
	message?: Message;
}
interface IError {
	error: any,
	message: string;
}
interface FirstLinkedEntityAndAvatar {
	avatar: IAvatar;
	linkedEntity: IEntity;
	linkedEntityName: string;
}

@Component({
	selector: "calao-conversation",
	templateUrl: './conversation.component.html',
	styleUrls: ['./conversation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationComponent extends LifeCycleObserverComponentBase implements OnInit, IHasPermission, OnDestroy {

	//#region FIELDS

	private static readonly C_LOG_ID = "CONV.C::";
	private static readonly C_SCROLL_ANIMATION_MS = 200;
	private static readonly C_TAPPING_MS = 2000;
	private static readonly C_WAIT_DEQUEUE_MS = 150;
	private static readonly C_WAIT_NEW_ELEMENT_QUEUE_MS = 500;
	private static readonly C_CHECK_PARTICIPANTS_ACTIVITIES_INTERVAL_MS = 5000;
	private static readonly C_MESSAGES_LIMIT = 10;
	private static readonly C_ACTIVITY_TOUCHSTART_EVENT = "touchstart";
	private static readonly C_DEFAULT_GALLERY_FILES_TYPE = "application/pdf";
	private static readonly C_DEFAULT_USER_LABEL = "Un utilisateur";
	private static readonly C_REQUIRED_PERMISSION = "Permission requise";

	public static readonly C_DELETED_MESSAGE_BODY = "Ce message a été supprimé.";

	private readonly moGalleryCommandSubject = new Subject<IGalleryCommand>();
	private readonly moEntitiesUpdatedSubject = new Subject<void>();
	private readonly moObservableEntitiesCacheByPath = new Map<string, ObservableProperty<Entity>>()

	private msConvGuid: string;
	private moDataSource: IDataSource<Message>;
	/** Tableau des indicateurs de chaque participant. */
	private maParticipantIndicators: IParticipantIndicator[] = [];
	private maConversationTasksQueue: Array<IConversationTask> = [];
	private mbIsDequeueEventBusy = true;
	/** Indique si le défilement des tâches doit être stoppé ou non. */
	private mbStopDequeue = false;
	private mnTappingTimer?: number;
	private mnChekParticipantsActivitiesInterval: number;
	private mbIsViewDestroy = false;
	/** Indique si la page a été correctement initialisée une première fois ou non (pour éviter de la réinitialiser). */
	private mbInitialized = false;
	/** Indique si le téléchargement des données de conversation est terminé ou non. */
	private mbDownloadConversationDetailsFinished = false;
	private moIsActivePageSubject: Subject<boolean> = new BehaviorSubject(true);
	/** Liste des groupes membres de la conversations. */
	private maGroups: IGroup[];
	private msUrl: string;
	private mbIsDeletedConversation = false;
	/** Indique si la conversation à déjà été initialisée */
	private mbConversationInit: boolean;
	/** Tableau des messages en attente. */
	private maPendingMessages: IStoreDocument[] = [];
	/** Représente l'usage et le quota IA de l'utilisateur actuel */
	public readonly observableUserUsage = new ObservableProperty<UserUsageQuota>()
		.bind(
			this.isvcAiGeneration.getUserUsage()
				.pipe(
					map((paResult: UserUsageQuota) => ModelResolver.toClass(UserUsageQuota, paResult)),
					catchError((loError: Error) => {
						console.error(ConversationComponent.C_LOG_ID, "Erreur lors de la récupération de l'usage de l'utilisateur", loError);
						return of(new UserUsageQuota(0, 0, new Date()));
					})
				),
			this
		);
	/** Represente la présence de message résumable via IA ou non  */
	public readonly observableHasMessageForSummarize = new ObservableProperty<boolean>(true);

	//#endregion

	//#region PROPERTIES

	/** Indique si return envoie le message ou passe à la ligne. */
	@Input() public sendOnReturn: boolean;
	/** Indique les types de fichiers que la galerie doit prendre en charge, pdf par défaut. */
	@Input() public galleryAcceptFiles: string;
	/** Change le titre de la page parente. Par défaut à true, doit être à faux si intégré dans un autre composant. */
	@Input() public changeParentPageTitle: boolean;
	/** Identifiant du contact utilisateur courant. */
	@Input() public currentContactId: string;
	/** Conteneur de la liste de message. */
	@ViewChild("messagesContainer") public messagesContainer: IonContent;

	/** Corps d'un message supprimé. */
	public readonly deletedMessageBody: string = ConversationComponent.C_DELETED_MESSAGE_BODY;
	/** Nombre d'avatars maximum pour les indicateurs de lecture d'un message. */
	public readonly maxReadIndicatorsLength = 8;
	public readonly galleryCommand$: Observable<IGalleryCommand>;
	public readonly permissionScope: EPermission = EPermission.conversations;

	private mbIsCallButtonDisabled = false;
	public get isCallButtonDisabled(): boolean { return this.mbIsCallButtonDisabled; }

	/** Récupération des contacts participants à la conversation (pas les contacts des groupes), sans le contact utilisateur */
	public get contacts(): IContact[] { return ArrayHelper.getValidValues(this.otherParticipants.map((poParticipant: IParticipant<IContact>) => poParticipant.model)) as IContact[]; }
	/** Récupération des contacts participants à la conversation (contacts et contacts de groupes), sans le contact utilisateur . */
	public get otherParticipants(): IParticipant<IContact>[] { return MapHelper.valuesToArray(this.otherParticipantsMap); }
	/** Si true, l'utilisateur peut ajouter des entités liées à la conversation via un bouton. */
	public get canAddLink(): boolean { return ConfigData.appInfo.useLinks && !ConfigData.conversation?.disableManualsLinks; }

	/** Indique si l'utilisateur peut éditer la conversation. */
	@HasPermissions$({
		permission: "edit",
		context$: (poThis: ConversationComponent) => {
			return poThis.ioRoute.data.pipe(
				map((poData: { conversation: IConversation }): IPermissionContext =>
					({ ...poData.conversation, authorId: IdHelper.buildId(EPrefix.contact, poData.conversation.createUserId) })
				)
			);
		}
	})
	public get canEdit$(): Observable<boolean> { return of(!UserData.current?.isGuest); }

	@HasPermissions({ permission: "read" })
	public get canRead(): boolean { return true; }

	/** Indique si l'utilisateur peut appeler la conversation. */
	@HasPermissions({ permission: "call", permissionScopes: EPermission.conversations })
	public get canCall(): boolean { return true; }

	/** Indique si l'utilisateur peut utiliser une IA pour résumer la conversation. */
	@HasPermissions({ permission: "ai", permissionScopes: EPermission.conversations })
	public get canSummarize(): boolean { return true; }

	public readonly observableIsMessageSending = new ObservableProperty<boolean>();

	public readonly galleryDisplayMode = EGalleryDisplayMode.preview;

	/** Identificateur de la conversation. */
	public convId: string;
	public newMessage: Message;
	public conversation: IConversation;
	public messages: Array<Message> = [];
	/** Tableau des participants sans l'utilisateur. */
	public otherParticipantsMap: Map<string, IParticipant<IContact>> = new Map();
	public files: GalleryFile[] = [];
	/** Indique si on peut envoyer un message ou non. */
	public canSend = false;
	/** Indique si le chargement des anciens messages est possible. */
	public infiniteScrollEnabled = true;
	public isDownloading = false;
	public firstLinkedEntityAndAvatar$: Observable<FirstLinkedEntityAndAvatar | undefined>;

	private moUserParticipant?: IParticipant<IContact>;
	/** Participant utilisateur, peut-être `undefined`. */
	public get userParticipant(): IParticipant<IContact> | undefined { return this.moUserParticipant; }

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des visios. */
		private readonly isvcLive: LiveService,
		/** Service de gestion des conversations. */
		private readonly isvcConversation: ConversationService,
		/** Service de gestion des loaders. */
		private readonly isvcLoading: LoadingService,
		/** Service de gestion des requpetes en base de données. */
		private readonly isvcStore: Store,
		/** Service de la plateforme. */
		private readonly ioPlatform: Platform,
		/** Service de gestion des liens */
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcContacts: ContactsService,
		private readonly isvcGroups: GroupsService,
		private readonly isvcPageManager: PageManagerService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioPopoverCtrl: PopoverController,
		private readonly isvcFlag: FlagService,
		private readonly ioRoute: ActivatedRoute,
		private readonly isvcPopover: PopoverService,
		private readonly ioRouter: Router,
		private readonly isvcModal: ModalService,
		private readonly isvcAiConversation: AiConversationService,
		private readonly isvcAiGeneration: AiGenerationService,
		private readonly isvcNetwork: NetworkService,
		public readonly isvcPermissions: PermissionsService,
		public readonly isvcGallery: GalleryService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poParentPage, poChangeDetectorRef);
		this.galleryCommand$ = this.moGalleryCommandSubject.asObservable();
		this.convId = IdHelper.buildId(EPrefix.conversation, this.ioRoute.snapshot.params.conversationId);
		this.msConvGuid = IdHelper.getGuidFromId(this.convId, EPrefix.conversation);
		this.newMessage = this.createMessage("");
	}

	private getAttachmentsFromNavigationState(): MessageAttachment[] {
		const loState: { [psKey: string]: any; } | undefined = this.ioRouter.getCurrentNavigation()?.extras.state;
		return (loState?.sharedDocuments ? loState.sharedDocuments : []).map((poDocument: Document) => {
			if (!!(poDocument as FormDocument).$document)
				return new MessageEntityAttachment({ entityPath: Store.getDocumentPath(poDocument), entity: ModelResolver.toClass(FormDocument, poDocument) });
			else
				return new MessageFileAttachment({ dmsData: ModelResolver.toClass(DmsDocument, poDocument) });
		});
	}

	protected onLifeCycleEvent(poValue: IApplicationEvent): void {
		if (poValue.type === EApplicationEventType.LifeCycleEvent) {
			switch ((poValue as ILifeCycleEvent).data.value) {

				case ELifeCycleEvent.viewWillEnter:
					if (this.mbInitialized)
						this.isvcEntityLink.trySetCurrentEntity(this.conversation).pipe(takeUntil(this.destroyed$))
							.subscribe();
					break;

				case ELifeCycleEvent.viewWillLeave:
					this.onViewWillLeave();
					break;
			}
		}
	}

	public ngOnInit(): void {
		this.ioRoute.data
			.pipe(
				tap((poData: { conversation: IConversation }) => {
					this.conversation = poData.conversation;

					if (!this.conversation) { // Si la conversation n'est pas renseignée, alors c'est qu'elle a été supprimée.
						this.onConversationDeleted();
						return;
					}
					this.setPropertiesFromConvCacheData();
					this.newMessage.attachments = this.getAttachmentsFromNavigationState();
					this.detectChanges();
				}),
				filter(() => !this.mbConversationInit),
				tap(() => {
					this.moDataSource = {
						databaseId: this.isvcConversation.databaseId,
						viewParams: {
							include_docs: true,
							startkey: EPrefix.message + this.msConvGuid + Store.C_ANYTHING_CODE_ASCII,
							endkey: EPrefix.message + this.msConvGuid,
							descending: true,
							limit: ConversationComponent.C_MESSAGES_LIMIT
						},
						baseClass: Message
					};

					this.initEventsListeners();
					this.initProperties();
					this.init();

					// Valeur par défaut de changeParentPageTitle à true.
					this.changeParentPageTitle ? this.changeParentPageTitle : true;

					this.mbConversationInit = true;

					const openVisio: boolean = this.ioRoute.snapshot.queryParamMap.get('openVisio') === 'true';
					openVisio ? this.callAsync() : 0;
				}),
				mergeMap(() => this.firstLinkedEntityAndAvatar$ = this.moEntitiesUpdatedSubject.asObservable()
					.pipe(
						startWith(null),
						mergeMap(_ => this.getFirstLinkedEntityAndAvatar())
					)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.initMessagesStatus()
			.pipe(
				tap(_ => this.refresh()),
				catchError(poError => this.isvcConversation.onError(poError, "Erreur lors de l'envoi du message sur le serveur.")),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private activityJoinCall$(): Observable<IConversationActivity | undefined> {
		return this.updateUserActivity(EActivityStatus.online, true);
	}

	private activityLeaveCall$(): Observable<IConversationActivity | undefined> {
		//Accélère l'UI
		if (this.moUserParticipant?.activity)
			this.moUserParticipant.activity.isInVisio = false;
		return this.updateUserActivity(EActivityStatus.online, false);
	}

	public callAsync(): void {
		let loLoader: any;
		this.mbIsCallButtonDisabled = true;
		from(this.isvcLoading.create("Chargement de la visio ..."))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.activityJoinCall$()),
				mergeMap(_ => this.sendMessage(this.createMessage(`${this.userParticipant?.label ?? ConversationComponent.C_DEFAULT_USER_LABEL} a rejoint la visio.`))),
				mergeMap(_ => loLoader.dismiss()),
				mergeMap(_ => this.isvcLive.showVisioModalAsync(EVisioType.Jitsi, this.msConvGuid, this.userParticipant?.label)),
				mergeMap(_ => this.sendMessage(this.createMessage(`${this.userParticipant?.label ?? ConversationComponent.C_DEFAULT_USER_LABEL} a quitté la visio.`))),
				mergeMap(_ => this.activityLeaveCall$()),
				map(_ => this.detectChanges())
			)
			.subscribe(_ => this.mbIsCallButtonDisabled = false);
	}

	private setPropertiesFromConvCacheData(): void {
		const loCacheData: IConversationCacheData = StoreHelper.getDocumentCacheData(this.conversation) as IConversationCacheData;

		if (loCacheData) {
			loCacheData.participantIndicators.forEach((poParticipantIndicator: IParticipantIndicator) => poParticipantIndicator.indicator.avatar.size = EAvatarSize.small);
			this.maParticipantIndicators = loCacheData.participantIndicators;
			this.moUserParticipant = loCacheData.userParticipant;
			this.otherParticipantsMap = loCacheData.otherParticipantsMap;
		}
		else
			throw new InitComponentError("Pas de cacheData dans la conversation, mais nécessaire pour l'initialisation !");
	}

	private getFirstLinkedEntityAndAvatar(): Observable<FirstLinkedEntityAndAvatar | undefined> {
		return this.isvcEntityLink.getEntityLinks(this.convId)
			.pipe(
				distinctUntilChanged(ArrayHelper.areArraysFromDatabaseEqual),
				map((paLinkedEntities: EntityLink[]) => ArrayHelper.getFirstElement(
					paLinkedEntities.sort((poEntityA: EntityLink, poEntityB: EntityLink) =>
						DateHelper.compareTwoDates(poEntityA.creationDate, poEntityB.creationDate)
					)
				)),
				mergeMap((poLinkedEntity: EntityLink) => {
					if (poLinkedEntity) {
						// On ne gère qu'un seul élèment pour rétrocompat
						const loTargetEntity: EntityLinkEntity | undefined =
							ArrayHelper.getFirstElement(poLinkedEntity.getTargetEntitiesBySourceId(this.convId));

						if (loTargetEntity) {
							return this.isvcEntities.getModel$(loTargetEntity.id, loTargetEntity.databaseId)
								.pipe(
									map((poEntity: IEntity) => {
										return {
											avatar: this.isvcEntities.getEntityAvatar(poEntity),
											linkedEntity: poEntity,
											linkedEntityName: this.isvcEntities.getEntityName(poEntity)
										} as FirstLinkedEntityAndAvatar;
									})
								);
						}
					}

					return of(undefined);
				}),
				takeUntil(this.destroyed$)
			);
	}

	public override ngOnDestroy(): void {
		if (!this.mbIsDeletedConversation)
			this.updateUserActivity(EActivityStatus.quit, false).subscribe();

		this.moEntitiesUpdatedSubject.complete();
		this.moGalleryCommandSubject.complete();
		this.mbIsViewDestroy = true;
		super.ngOnDestroy();
	}

	/** Ajoute une tâche dans la file des tâches à exécuter de la conversation.
	 * @param pfFunction Fonction à exécuter.
	 * @param psId Identifiant de l'objet manipulé.
	 * @param peType Type d'objet de conversation, optionnel.
	 */
	private addTask(pfFunction: () => Observable<void | IConversationActivity>, psId: string, peType?: EConversationType): void {
		const loConvTask: IConversationTask = {
			function: pfFunction,
			params: {
				_id: psId,
				createDate: new Date(),
				type: peType
			}
		};
		this.maConversationTasksQueue.push(loConvTask);
	}

	/** Ouvre le popover de sélection des pièces jointes.
	 * @param poEvent Evénement de click de l'utilisateur.
	 */
	public async openAttachmentsPopover(poEvent: MouseEvent): Promise<void> {
		try {
			await Keyboard.hide();
		}
		finally {
			return this.innerOpenAttachmentsPopover(poEvent);
		}
	}

	private async innerOpenAttachmentsPopover(poEvent: MouseEvent): Promise<void> {
		const loPopover: HTMLIonPopoverElement = await this.ioPopoverCtrl.create({
			component: AttachmentsPopoverComponent,
			event: poEvent,
			componentProps: { galleryCommandSubject: this.moGalleryCommandSubject },
			keyboardClose: true,
			mode: "ios"
		});

		await loPopover.present();
		this.detectChanges();
	}

	/** Modifie les indicateurs de lecture des messages en fonction d'un participant.
	 * @param poParticipant Participant à la conversation dont il faut modifier l'indicateur du dernier message lu.
	 */
	private changeReadIndicator(poParticipant: IParticipant<IContact>): void {
		const loIndicator: IParticipantIndicator | undefined =
			this.maParticipantIndicators.find((poIndicator: IParticipantIndicator) => poIndicator.id === poParticipant.participantId);

		if (loIndicator) // Si un indicateur est déjà présent, il faut le mettre à jour.
			this.updateChangeReadIndicator(poParticipant, loIndicator);
		else // Sinon, il faut créer un nouvel indicateur pour ce participant.
			this.createChangeReadIndicator(poParticipant);

		this.refresh();
	}

	/** Crée un indicateur de message lu pour le participant.
	 * @param poParticipant Participant à la conversation.
	 */
	private createChangeReadIndicator(poParticipant: IParticipant<IContact>): void {
		const loIndicator: IReadIndicator | undefined = this.createReadIndicator(poParticipant);
		const lsLastMessageReadId: string | undefined = poParticipant.activity?.lastReadMessageId;

		if (!loIndicator || StringHelper.isBlank(lsLastMessageReadId)) {
			if (!loIndicator)
				if (StringHelper.isBlank(lsLastMessageReadId))
					console.error(`${ConversationComponent.C_LOG_ID}Conversation ${this.conversation._id} of the participant ${poParticipant.model?._id} : the ID of the last message read and the reading indicator are missing.`);
				else
					console.error(`${ConversationComponent.C_LOG_ID}Conversation ${this.conversation._id} of the participant ${poParticipant.model?._id} : reading indicator is missing.`);
			else
				console.error(`${ConversationComponent.C_LOG_ID}Conversation ${this.conversation._id} of the participant ${poParticipant.model?._id} : the ID of the last message read.`);
		}
		else {
			const loNewIndicator: IParticipantIndicator = {
				indicator: loIndicator!,
				id: poParticipant.participantId,
				lastReadMessageId: lsLastMessageReadId!
			};

			this.maParticipantIndicators.push(loNewIndicator);
		}
	}

	/** Met à jour l'indicateur de message lu du participant.
	 * @param poParticipant Participant à la conversation.
	 * @param poIndicator Indicateur du participant.
	 */
	private updateChangeReadIndicator(poParticipant: IParticipant<IContact>, poIndicator: IParticipantIndicator): void {
		const lsPreviousMessageReadId: string = poIndicator.lastReadMessageId;

		// Si le dernier et l'avant-dernier message lu sont différents alors on met à jour, sinon c'est le même donc pas besoin de mettre à jour.
		if (StringHelper.isValid(poParticipant.activity?.lastReadMessageId) && (poParticipant.activity?.lastReadMessageId !== lsPreviousMessageReadId))
			poIndicator.lastReadMessageId = poParticipant.activity!.lastReadMessageId!;
	}

	/** Retrouve les indicateurs de lecture pour un message.
	 * @param psMessageId Identifiant du message.
	 */
	public findReadIndicators(psMessageId: string): IParticipantIndicator[] {
		return this.maParticipantIndicators.filter((poParticipantIndicator: IParticipantIndicator) => poParticipantIndicator.lastReadMessageId === psMessageId);
	}

	/** Défilement : on exécute la fonction contenue dans le premier élément du tableau en bloquant les futures exécutions tant que celle-ci n'est pas terminée. */
	private dequeue(): void {
		const lfRecall: Function = () => {
			this.maConversationTasksQueue.shift();
			this.mbIsDequeueEventBusy = false;
			this.startDequeue();
			this.detectChanges();
		};
		this.mbIsDequeueEventBusy = true;

		this.isvcConversation.asyncHasNetworkConnection()
			.pipe(
				mergeMap((pbHasNetwork: boolean) => {
					if (!pbHasNetwork) 	// si une interruption réseau est survenue.
						this.reorganizeQueue(); // on trie la file dans l'ordre chronologique pour ne pas avoir de problème.

					const loTask: IConversationTask = ArrayHelper.getFirstElement(this.maConversationTasksQueue);
					loTask.params.lastActivityDate = new Date();

					return loTask.function(loTask.params);
				}),
				catchError(poError => this.isvcConversation.onError(poError)),
				tap(_ => lfRecall()),
				takeWhile(_ => !this.mbStopDequeue)
			)
			.subscribe();
	}

	/** Récupération de la conversation. */
	private loadConversation(): Observable<boolean> {
		return this.isvcConversation.getConversation(this.convId, true)
			.pipe(
				catchError(poError => throwError(() => ({ error: poError, message: "Une erreur est survenue lors de la récupération de la conversation sur la base de données." }) as IError)),
				mergeMap((poConversation?: IConversation) => {
					if (!poConversation) {
						return this.moIsActivePageSubject.asObservable()
							.pipe(
								filter((pbIsActivePage: boolean) => pbIsActivePage),
								take(1),
								tap(_ => this.onConversationDeleted()) //* Si première init, pas supprimée, juste pas en local !
							);
					}
					else {
						this.initConversation(poConversation);
						this.manageVisuParticipantsActivities();
						return of(true);
					}
				}),
				takeUntil(this.destroyed$)
			);
	}

	private onConversationDeleted(): void {
		this.mbIsDeletedConversation = true;

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: "Cette conversation a été supprimée.",
				header: "Erreur",
				backdropDismiss: false,
				buttons: [{ text: "OK", handler: () => { this.isvcPageManager.goBack(); return true; } }]
			})
		);
	}

	/** Initialise la conversation dans laquelle on est entré.
	 * @param poConversation Conversation dans laquelle on est.
	 */
	private initConversation(poConversation: IConversation): void {
		if (!poConversation) {
			const loError: IError = {
				error: "Résultat getConversation() vide.",
				message: "L'initialisation de la conversation a rencontré un problème et ne peut continuer."
			};
			throw loError;
		}
		else {
			if (!StoreDocumentHelper.areDocumentRevisionsEqual(this.conversation, poConversation)) {
				StoreHelper.updateDocumentCacheData(poConversation, StoreHelper.getDocumentCacheData(this.conversation));
				this.conversation = poConversation; // Sauvegarde de la conversation.
			}

			if (this.changeParentPageTitle)
				this.setParentPageTitle();

			this.setPropertiesFromConvCacheData();
			this.detectChanges();
		}
	}

	/** Met à jour le titre de la page.
	 * @param psTitle Si vide, affiche le titre par défaut.
	 */
	private setParentPageTitle(psTitle: string = this.isvcConversation.getDefaultTitle(this.conversation)): void {
		this.moParentPage!.title = psTitle;
	}

	/** Récupération de la liste des messages de la conversation. */
	private getMessages(): Observable<boolean> {
		return this.isvcConversation.getMessages(this.moDataSource)
			.pipe(
				catchError(poError =>
					throwError(() => ({ error: poError, message: "Une erreur est survenue lors de la récupération des messages de la conversation sur la base de données." }) as IError)
				),
				tap((paMessageResults: Array<Message>) => {

					/** Vérifie que l'on fait partie des destinataires des messages, selon le champs recipientsPathList, et retire le message si ce n'est pas le cas. */
					paMessageResults = paMessageResults.filter((poMessage: Message) =>
						(poMessage.recipientsPathList?.some((psRecipientPath: string) => psRecipientPath.indexOf(this.currentContactId) >= 0) ?? true) ||
						(poMessage.recipientsPathList?.length === 0)
					);

					this.messages.push(...paMessageResults); // On ajoute les nouveaux messages récupérés de la base de données dans le tableau actuel des messages.
					this.updateMessagesStatus();
				}),
				mapTo(true)
			);
	}

	/** Permet de trouver le participant dans les participants en mémoire.
	 * @param psContactId Id du contact lié au participant.
	 */
	private findParticipant(psContactId: string): IParticipant<IContact> | undefined {
		return this.moUserParticipant?.participantId === psContactId ?
			this.moUserParticipant : this.isvcConversation.findParticipant(this.otherParticipants, psContactId);
	}

	/** Ajoute une tâche de mise à jour d'activité.
	 * @param peActivityStatus Statut d'activité à utiliser pour la mise à jour ('o', 'q', 't').
	 */
	private addUpdateUserActivityTask(peActivityStatus: EActivityStatus = EActivityStatus.online): void {

		if (this.moUserParticipant) {
			if (this.moUserParticipant.activity) {
				this.addTask(() => this.updateUserActivity(peActivityStatus, this.moUserParticipant?.activity?.isInVisio), this.moUserParticipant.activity._id, EConversationType.activity);
			}
			else
				console.error(`${ConversationComponent.C_LOG_ID}No activity for user participant '${this.moUserParticipant.participantId}', can not update user activity !`);
		}
		else
			console.error(`${ConversationComponent.C_LOG_ID}No user participant declared, can not update user activity for user '${UserData.current?._id}' !`);
	}

	/** Crée l'indicateur de lecture d'un participant.
	 * @param poParticipant Participant dont on veut créer l'indicateur de lecture.
	 */
	private createReadIndicator(poParticipant: IParticipant<IContact>): IReadIndicator | undefined {
		if (poParticipant.activity?._id || poParticipant.model) {
			console.error(`${ConversationComponent.C_LOG_ID}Conversation ${this.conversation._id} of the participant ${poParticipant.model?._id} : the current user and/or his activity document are missing.`);
			return undefined;
		}
		else {
			return {
				activityId: poParticipant.activity!._id,
				avatar: ContactsService.createContactAvatar(poParticipant.model!, EAvatarSize.small)
			};
		}
	}

	/** Initialise le composant. */
	private init(): void {
		this.startDequeue();
		this.initConversationLoading();
	}

	private initConversationLoading(): void {
		let loLoader: Loader;

		from(this.isvcLoading.create(ApplicationService.C_LOAD_DATA_LOADER_TEXT))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.loadConversation()), // Récupération de la conversation une première fois en local.
				filter((_, pnIndex: number) => pnIndex === 0), // On filtre pour n'effectuer la routine d'initialisation que lors de la première réception de résultat.
				mergeMap(_ => this.getMessages()),
				mergeMap(_ => this.isvcEntityLink.trySetCurrentEntity(this.conversation)),
				tap(_ => this.observableHasMessageForSummarize.value = this.isvcAiConversation.hasMessagesToSummarize(this.messages)),
				tap(_ => this.manageLiveReplication()),
				tap(_ => this.initProperties()),
				catchError((poError: IError | any) => {
					const loError: IError = (poError as IError).error ?
						poError : { error: poError, message: "Une erreur est survenue lors de l'initialisation de la conversation." };
					loLoader.dismiss();
					return this.isvcConversation.onError(loError.error)
						.pipe(catchError(poOtherError => { this.isvcPageManager.goBack(); return throwError(() => poOtherError); }));
				}),
				tap(_ => {
					this.mbInitialized = true;
					this.refresh();
					loLoader.dismiss(); // On retire le loader quand les données sont chargées.
				}),
				finalize(() => {
					loLoader.dismiss(); // On retire le loader en cas d'erreur ou si le flux est clôturé avant d'avoir atteint le tap (ex: Retour avec bouton physique).
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Récupère un template de message qu'on peut remplir
	 * @param psMessage Texte du message.
	 * @param peMessageType Type de message à récupérer.
	 */
	private createMessage(psMessage: string): Message {
		return this.isvcConversation.createMessage(psMessage);
	}

	/** Initialise les écouteurs. */
	private initEventsListeners(): void {
		this.ioPlatform.pause
			.pipe(
				tap(() => this.onPause()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.ioPlatform.resume
			.pipe(
				tap(() => this.onResume()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Mise à jour des messages de la conversation à l'aide de l'événement de mise à jour des participants.
		this.isvcConversation.getConversationUiObservable()
			.pipe(
				filter((poEvent: IConversationUiEvent) => poEvent.type === EConversationEvent.update && poEvent.convId === this.convId),
				tap((poEvent: IConversationUiEvent) => {
					if (ArrayHelper.hasElements(poEvent.newMessages)) {
						this.messages.push(...poEvent.newMessages);
						this.isvcConversation.sortMessages(this.messages);
					}
					else
						console.warn(`${ConversationComponent.C_LOG_ID}No new messages to update the conversation ${poEvent.convId}.`);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.msUrl = this.ioRouter.url;

		this.ioRouter.events
			.pipe(
				tap((poEvent: REvent) => {
					if (poEvent instanceof RouterEvent && !StringHelper.isBlank(poEvent.url))
						this.moIsActivePageSubject.next(poEvent.url === this.msUrl);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Met à jour le titre de la conversation dans le header à chaque fois qu'on revient sur la page.
		this.ioRouter.events
			.pipe(
				filter((poNavigation: REvent) => poNavigation instanceof NavigationEnd),
				delay(10),	// Le PageInfo change le titre dans le header après, sauf si on fait un delay.
				tap(_ => this.refreshPageTitle()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Initialise les propriétés. */
	private initProperties(): void {
		this.currentContactId = this.moUserParticipant?.model?._id ?? "";

		if (!ArrayHelper.hasElements(this.messages))
			this.messages = [];

		if (this.galleryAcceptFiles === undefined)
			this.galleryAcceptFiles = ConversationComponent.C_DEFAULT_GALLERY_FILES_TYPE;

		this.moParentPage!.hasHomeButton = !UserData.current?.isGuest;
		this.moParentPage!.hasBackButton = !UserData.current?.isGuest;

		this.updateGroups().subscribe();
	}

	/** Récupère les groupes à partir des ids des participants et assigne la variable `maGroups`. */
	private updateGroups(): Observable<IGroup[]> {
		return this.isvcGroups.getDisplayableGroups(this.getGroupMemberIds())
			.pipe(
				tap((paGroups: IGroup[]) => this.maGroups = paGroups),
				takeUntil(this.destroyed$)
			);
	}

	/** Gère la date de dernière activité d'un participant et ajuste son statut ('q'/'o') de manière purement visuelle (pas de modification en base de données). */
	private manageVisuParticipantsActivities(): void {
		const lfManage: () => void = () => {
			this.otherParticipantsMap.forEach((poParticipant: IParticipant) => {
				const lbIsInCall = !!poParticipant.activity?.isInVisio;

				// Si la date actuelle est supérieure de Xmin ou plus de la date d'activité ; 60000 = ms vers min.
				// Cependant s'il est en Visio on le considère offline à partir de deux heures
				if (lbIsInCall ?
					poParticipant.activity?.activityExpirationDate && DateHelper.compareTwoDates(new Date(), DateHelper.addHours(new Date(poParticipant.activity.activityExpirationDate), 2)) > 0 :
					poParticipant.activity?.activityExpirationDate && DateHelper.compareTwoDates(new Date(), new Date(poParticipant.activity.activityExpirationDate)) > 0) {
					if (poParticipant.activity) {
						poParticipant.activity.activity = EActivityStatus.quit;
						poParticipant.activity.isInVisio = false;
					}
					this.detectChanges();
				}
			});
		};

		lfManage();
		this.mnChekParticipantsActivitiesInterval = window.setInterval(lfManage, ConversationComponent.C_CHECK_PARTICIPANTS_ACTIVITIES_INTERVAL_MS);
	}

	/** Initialise le traitement des messages en fonction de leur état (envoyés sur le serveur ou non) pour l'affichage des coches. */
	private initMessagesStatus(): Observable<void> {
		return this.isvcConversation.getPendingMessages(this.msConvGuid, true)
			.pipe(
				catchError(poError => this.isvcConversation.onError(
					poError,
					`Une erreur est survenue lors de la récupération des messages non envoyés du statut de conversation ${this.msConvGuid}.`
				)),
				map((paPendings: IStoreDocument[]) => {
					this.maPendingMessages = paPendings;
					this.updateMessagesStatus();
				})
			);
	}

	/** Traite les messages en fonction de leur état (envoyés sur le serveur ou non) pour l'affichage des 'coches'. */
	private updateMessagesStatus(): void {
		for (let i = this.messages.length - 1; i >= 0; --i) {
			this.messages[i].isSyncToServer = true; // On donne une valeur par défaut.
			for (let j = this.maPendingMessages.length - 1; j >= 0; --j) {
				if (this.messages[i]._id === this.isvcConversation.getMessageIdFromPendingId(this.maPendingMessages[j]._id))
					this.messages[i].isSyncToServer = false;
			}
		}

		this.onMessagesStatusUpdated();
	}

	/** Une modification d'activité d'un utilisateur a été détectée, il faut la mettre à jour.
	 * @param poActivity objet correspondant à une activité liée à un utilisateur.
	 */
	private onActivityChanged(poActivity: IConversationActivity): void {
		console.debug(`${ConversationComponent.C_LOG_ID}${poActivity._id === this.moUserParticipant?.activity?._id ? "User" : "Participant"} activity '${poActivity._id}' changed to "${poActivity.activity}".`, poActivity);

		const loParticipant: IParticipant<IContact> | undefined = this.findParticipant(ConversationService.getContactIdFromActivity(poActivity));

		if (loParticipant && poActivity._deleted) { // Si le participant existe et que l'activité est à l'état supprimé.
			this.otherParticipantsMap.delete(loParticipant.participantPath);
			ArrayHelper.removeElementByProperty(this.conversation.participants, "participantPath", loParticipant.participantPath);
		}
		// Si la révision de l'activité reçue est supérieure à celle en mémoire.
		else if (loParticipant && StoreDocumentHelper.getRevisionNumber(poActivity) > (loParticipant.activity ? StoreDocumentHelper.getRevisionNumber(loParticipant.activity) : 0)) {
			loParticipant.activity = poActivity;

			// Si c'est pas l'activité de l'utilisateur, màj indicateur dernier message lu.
			if (this.moUserParticipant?.participantId !== loParticipant.participantId)
				this.changeReadIndicator(loParticipant);
		}
		else
			console.debug(`${ConversationComponent.C_LOG_ID}Obsolete activity detected, it will be ignored.`);
	}

	/** Une modification sur un contact a été détectée, il faut le mettre à jour.
	 * @param poContact objet correspondant à un contact.
	 */
	private onContactChanged(poContact: IContact): void {
		// TODO
	}

	/** Une modification sur une conversation a été détectée, il faut la mettre à jour.
	 * @param poConversation objet correspondant à une conversation.
	 */
	private onConversationChanged(poConversation: IConversation): void {
		// Si la modification de la conversation est plus récente que l'actuelle (la réplication peut amener des révisions obsolètes), on fait la modification.
		if (StoreDocumentHelper.getRevisionNumber(poConversation) > StoreDocumentHelper.getRevisionNumber(this.conversation)) {
			this.initConversation(poConversation);

			merge(
				this.updateGroups(),
				this.isvcEntityLink.trySetCurrentEntity(this.conversation)
			)
				.pipe(takeUntil(this.destroyed$))
				.subscribe();
		}
	}

	/** Redirige un événement reçu du service vers la fonction appropriée compte-tenu de son type.
	 * @param poEvent Objet d'événement reçu du service, contenant les changements opérés.
	 */
	private onEventRedirection(poEvent: IConversationEvent): void {
		// Si l'id de cette conversation est inclus dans l'id du document ou l'id reçu, alors il est destiné à cette conversation.
		if ((poEvent.data.document && (poEvent.data.document._id as string).indexOf(this.msConvGuid) >= 0) ||
			(!StringHelper.isBlank(poEvent.data.reSendMessageId) && poEvent.data.reSendMessageId.indexOf(this.msConvGuid) >= 0)) {

			if (poEvent.data.eventType === EConversationEvent.default)
				this.onEventDispatcher(poEvent.data.document);
		}
	}

	/** Réceptionne les modifications qui ont lieu dans une conversation, vérifie que cette modification est destinée à cette instance ou non
	 * et réalise les actions nécessaires.
	 * @param poEvent Objet correspondant à l'événement.
	 * @param poDocument Objet document correspondant au document modifié (ajout, modification, suppression).
	 */
	private onEventDispatcher(poDocument: IStoreDocument & { type: EConversationType }): void {
		let lfFunction: (() => Observable<void | IConversationActivity>) | undefined;

		switch (poDocument.type) {
			case EConversationType.activity:
				lfFunction = () => of(this.onActivityChanged(poDocument as IConversationActivity));
				break;

			case EConversationType.contact:
				lfFunction = () => of(this.onContactChanged(poDocument as any as IContact));
				break;

			case EConversationType.conversation:
				lfFunction = () => of(this.onConversationChanged(poDocument as IConversation));
				break;

			case EConversationType.message:
				lfFunction = () => {
					this.onMessageChanged(ModelResolver.toClass(Message, poDocument));
					this.refreshMessages();

					return of(this.addUpdateUserActivityTask());
				};
				break;

			default:
				console.error(`${ConversationComponent.C_LOG_ID}Unknown conversation document type ${poDocument.type} for document ${poDocument._id}.`);
				break;
		}

		if (lfFunction)
			this.addTask(lfFunction, poDocument._id, poDocument.type);
	}

	/** Événement qui survient lors d'un changement de fichier dans la galerie.
	 * @param paFiles Fichiers de la galerie
	 */
	public onFilesChanged(paFiles: GalleryFile[]): void {
		this.files = paFiles;
		// Si la galerie a des fichiers sélectionnés ou qu'il y a un texte entré valide alors on autorise l'envoi de message.
		this.canSend = ArrayHelper.hasElements(paFiles) || !StringHelper.isBlank(this.newMessage.body);

		paFiles.forEach((poFile: GalleryFile) =>
			ArrayHelper.pushIfNotPresent(
				this.newMessage.attachments,
				new MessageFileAttachment({ dmsData: poFile }),
				(poAttachment: MessageAttachment) => poAttachment instanceof MessageFileAttachment && poAttachment.dmsData.guid === poFile.guid
			)
		);
	}

	public onDocumentPicked(poDocument: Document): void {
		if (poDocument instanceof FormDocument)
			this.newMessage.attachments.push(new MessageEntityAttachment({ entityPath: Store.getDocumentPath(poDocument), entity: poDocument }));
		else
			this.newMessage.attachments.push(new MessageFileAttachment({ dmsData: poDocument }));

		this.canSend = ArrayHelper.hasElements(this.newMessage.attachments) || !StringHelper.isBlank(this.newMessage.body);
	}

	/** Détruit la conversation proprement (abonnements, ...) lorsque l'événement "willLeave" du cycle de vie ionic est lancé. */
	private onViewWillLeave(): void {
		this.stopTappingTimer();

		clearInterval(this.mnChekParticipantsActivitiesInterval);

		this.isvcEntityLink.clearCurrentEntity(this.conversation._id).subscribe();
	}

	/** Actualise l'UI si nécessaire pour tenir compte du changement détecté sur un message.
	 * @param poProcessingMessage Message à traiter.
	 * @returns `false` si le message a été ignoré, `true` sinon.
	 */
	private onMessageChanged(poProcessingMessage: Message): boolean {
		const loCachedMessage: IMessageModelItem = this.getMessageModelItem(poProcessingMessage._id);

		if (loCachedMessage.index >= 0)
			return this.updateMessageModel(loCachedMessage, poProcessingMessage);

		else
			return this.addMessageModel(poProcessingMessage);
	}

	/** Tri la liste des messages, rafraîchit la vue et met à jour l'activité de l'utilisateur. */
	private refreshMessages(): void {
		this.isvcConversation.sortMessages(this.messages);
		this.refresh(true);
	}

	/** Recherche un message parmi ceux présents en cache dans le modèle.
	 * @param psMessageId Identifiant du message à récupérer.
	 * @returns L'index dans le modèle des messages et l'objet message correspondant.
	 */
	private getMessageModelItem(psMessageId: string): IMessageModelItem {
		const lnModelMessageIndex: number = this.messages.findIndex((poMessage: Message) => poMessage._id === psMessageId);
		let loModelMessage: Message | undefined;

		if (lnModelMessageIndex >= 0)
			loModelMessage = this.messages[lnModelMessageIndex];

		return { index: lnModelMessageIndex, message: loModelMessage };
	}

	/** Détermine si le changement détecté doit être ignoré ou si le message doit être mis à jour dans le modèle,
	 *  actualise le modèle le cas échéant.
	 * @return `true` si le modèle a été mis à jour, `false` sinon.
	 */
	private updateMessageModel(poMessageModelItem: IMessageModelItem, poProcessingMessage: Message): boolean {

		if (StringHelper.isValid(poMessageModelItem.message) && (StoreDocumentHelper.getRevisionNumber(poProcessingMessage) > StoreDocumentHelper.getRevisionNumber(poMessageModelItem.message!))) {
			this.prepareMessage(poProcessingMessage);
			this.messages[poMessageModelItem.index] = poProcessingMessage;

			return true; // Message effectivement mis à jour.
		}
		else
			return false; // Message ignoré.
	}

	/** Ajoute un message au tableau des messages à afficher en ajustant ses propriétés.
	 * @param poMessage Nouveau message qu'il faut traiter et ajouter au tableau des messages à afficher.
	 */
	private addMessageModel(poMessage: Message): boolean {
		this.prepareMessage(poMessage);

		if ((poMessage.recipientsPathList?.some(val => val.indexOf(this.currentContactId) >= 0) ?? true) || (poMessage.recipientsPathList?.length === 0))
			this.messages.push(poMessage);

		return true;
	}

	/** Ajoute un tableau d'indicateurs de messages lus au message ainsi qu'un booléen indiquant que le message est synchronisé avec le serveur.
	 * @param poReceivedMessage Message reçu qu'il faut préparer.
	 */
	private prepareMessage(poReceivedMessage: Message): void {
		poReceivedMessage.isSyncToServer = true;
	}

	/** Prépare et fait les vérifications nécessaires avant d'envoyer le message entré par l'utilisateur. */
	public onMessageSend(): void {
		if (this.canSend) { // Si on peut envoyer un message (pièce jointe ou texte ou les deux), on poursuit, sinon on ne fait rien (attente).
			this.canSend = false;
			this.stopTappingTimer(); // On supprime le timer afin de ne pas mettre à jour l'activité de l'utilisateur inutilement.
			this.innerOnSendMessage();
		}
	}

	private innerOnSendMessage(): void {
		const loMessage: Message = this.newMessage;
		this.addUpdateUserActivityTask(EActivityStatus.online);

		this.files = [];
		this.newMessage = this.createMessage(""); // On réinitialise le message pour en taper un nouveau.
		this.sendMessage(loMessage).subscribe();
	}

	/** Application mise en arrière plan. */
	private onPause(): void {
		this.mbStopDequeue = true; // On arrête le défilement des tâches.
		clearInterval(this.mnChekParticipantsActivitiesInterval); // On arrête la vérification des activités des participants.

		this.addUpdateUserActivityTask(EActivityStatus.quit); // On met à jour l'activité utilisateur à 'q' parce qu'on passe en arrière plan.
	}

	/** Application revient au premier plan. */
	private onResume(): void {
		this.mbStopDequeue = false; // On autorise de nouveau le défilement des tâches.
		this.manageVisuParticipantsActivities(); // On reprend la vérification des activités des participants.
		this.startDequeue(); // On reprend le défilement des tâches.
		this.addUpdateUserActivityTask(EActivityStatus.online); // On ajoute une tâche pour mettre l'activité utilisateur à jour ('o').
	}

	/** Termine la récupération des messages en mettant à jour l'activité de l'utilisateur et en rafraîchissant la vue. */
	private onMessagesStatusUpdated(): void {
		if (ArrayHelper.hasElements(this.messages) && this.moUserParticipant?.activity)
			this.moUserParticipant.activity.lastReadMessageId = ArrayHelper.getLastElement(this.messages)._id;

		this.mbIsDequeueEventBusy = false;
	}

	/** Rafraîchit la vue pour mettre à jour les bindings.
	 * @param pbScrollToBottom Indique si l'on doit scroller jusqu'en bas de la page.
	 */
	private refresh(pbScrollToBottom: boolean = false): void {
		if (!this.mbIsViewDestroy) {
			if (pbScrollToBottom && this.messagesContainer)
				this.messagesContainer.scrollToTop(ConversationComponent.C_SCROLL_ANIMATION_MS);

			this.detectChanges();
		}
	}

	/** Trie la file pour remettre dans l'ordre les différents documents modifiés que l'on a reçus.
	 * Le tri est chronologique et par ordre d'importance (importance en fonction du type du document).
	 */
	private reorganizeQueue(): void {
		let lnCurrentIndex = 0;
		let lsOutputId: string | undefined;
		let lbIsSorted = false;

		// tri par date.
		this.maConversationTasksQueue.sort((poItemA: IConversationTask, poItemB: IConversationTask) => {
			if (!poItemA.params.lastActivityDate)
				return 1;
			else if (!poItemB.params.lastActivityDate)
				return -1;
			else
				return (+new Date(poItemA.params.lastActivityDate) || +new Date(poItemA.params.createDate) || +new Date()) -
					(+new Date(poItemB.params.lastActivityDate) || +new Date(poItemB.params.createDate) || +new Date());
		});

		// tri par type, les messages d'abord puis le reste en conservant le tri par date.
		while (lnCurrentIndex < this.maConversationTasksQueue.length && !lbIsSorted) {

			if (this.maConversationTasksQueue[lnCurrentIndex].params.type === EConversationType.message) // si le type de l'élément est un message,
				++lnCurrentIndex; // on passe à l'élément suivant.
			else {

				if (lsOutputId && lsOutputId === this.maConversationTasksQueue[lnCurrentIndex].params._id) // si l'id de sortie vaut l'id courant,
					lbIsSorted = true; // alors on a terminé de trier.
				else {
					// on met l'élément courant à la fin de la file.
					ArrayHelper.moveElement(this.maConversationTasksQueue, lnCurrentIndex, this.maConversationTasksQueue.length - 1);

					if (!lsOutputId) // si l'id de sortie n'est pas valable,
						lsOutputId = ArrayHelper.getLastElement(this.maConversationTasksQueue).params._id; // on le renseigne avec l'id de l'élément actuel.
				}
			}
		}
	}

	/** Envoi le message.
	 * @param poMessage Message à envoyer.
	 */
	private sendMessage(poMessage: Message): Observable<boolean> {
		this.messages.unshift(poMessage);
		this.observableIsMessageSending.value = true;

		return this.isvcConversation.sendMessage$(this.conversation, poMessage)
			.pipe(
				tap(_ => {
					this.isvcConversation.sortMessages(this.messages);
					this.addUpdateUserActivityTask(EActivityStatus.online);
					this.refresh();
				}),
				mapTo(true),
				catchError(poSendError =>
					this.isvcConversation.onError(poSendError, "Une erreur est survenue lors de l'enregistrement du message en base de données.").pipe(mapTo(false))
				),
				finalize(() => this.observableIsMessageSending.value = false)
			);
	}

	/** Simule une file d'attente de fonctions à exécuter afin de ne pas avoir de conflits sur pouch dû à l'asynchronisme
	 * (accès multiples à la bdd). Une exécution à la fois, puis la méthode se rappelle pour passer à l'exécution suivante.
	 */
	private startDequeue(): void {
		// Si la file n'est pas occupée et qu'au moins un élément est présent, on l'exécute.
		if (!this.mbIsDequeueEventBusy && ArrayHelper.hasElements(this.maConversationTasksQueue))
			this.dequeue();

		else if (!this.mbIsViewDestroy) {
			// Temps d'attente opur le défilement : temps si file occupée / temps si file vide.
			const lnWait: number = this.mbIsDequeueEventBusy ? ConversationComponent.C_WAIT_DEQUEUE_MS : ConversationComponent.C_WAIT_NEW_ELEMENT_QUEUE_MS;
			setTimeout(() => this.startDequeue(), lnWait); // on attend un certains temps avant de tenter un nouveau défilement.
		}
	}

	/** Démarre la réplication de la base de données des conversations pour écouter les changements opérés dessus. */
	private startLiveReplication(): Observable<boolean> {
		return defer(() => {
			this.isDownloading = true;
			this.detectChanges();
			return this.isvcConversation.downloadConversationDetails(this.msConvGuid);
		})
			.pipe(
				tap(_ => this.mbDownloadConversationDetailsFinished = true),
				mergeMap((poResponse: IStoreReplicationResponse) => {
					return from(poResponse.docs ?? [])
						.pipe(
							groupBy((poDocument: IStoreDocument) => poDocument._id),
							mergeMap((poGroupedDocument$: GroupedObservable<string, IStoreDocument>) => {
								return poGroupedDocument$
									.pipe(
										reduce((poLastDocument: IStoreDocument, poCurrentDocument: IStoreDocument) =>
											poLastDocument = StoreDocumentHelper.getObjectWithMaxRevisionFromObjects(poLastDocument, poCurrentDocument)
										)
									);
							}),
							toArray()
						);
				}),
				tap((paDocuments: IStoreDocument[]) => {
					// Gestion des activités supprimées ailleurs.
					this.processConversationDetailsChanges(
						paDocuments.filter((poDocument: IStoreDocument) => !poDocument._deleted && !IdHelper.hasPrefixId(poDocument._id, EPrefix.activity))
					);
					this.manageUserActivity();
					this.addUpdateUserActivityTask(EActivityStatus.online); // Après que la réplication et les traitements sont terminés, on passe à 'online'.
					this.mbDownloadConversationDetailsFinished = true;
					this.isDownloading = false;
				}),
				mergeMap(_ => this.isvcConversation.startConversationDetailsLiveReplication((poEvent: IConversationEvent) =>
					this.onEventRedirection(poEvent), this.msConvGuid)
				),
				catchError(poError => throwError(() => ({ error: poError, message: "Erreur lors du démarrage du canal de discussion." }) as IError)),
				mapTo(true)
			)
			.pipe(catchError((poError: IError) => this.isvcConversation.onError(poError.error)));
	}

	/** Met à jour les activités et messages de la conversation avec les documents répliqués.
	 * @param paDocuments Tableau des documents répliqués qu'il faut traiter.
	 */
	private processConversationDetailsChanges(paChangedDocuments: IStoreDocument[]): void {
		if (ArrayHelper.hasElements(paChangedDocuments)) {
			const lsConversationDatabaseId: string = StoreHelper.getDatabaseIdFromCacheData(this.conversation);

			paChangedDocuments.forEach((poDocument: IStoreDocument) => {
				switch (IdHelper.getPrefixFromId(poDocument._id)) {

					case EPrefix.activity:
						this.onActivityChanged(poDocument as IConversationActivity);
						break;

					case EPrefix.message:
						if (!StoreHelper.hasCacheData(poDocument))
							StoreHelper.updateDocumentCacheData(poDocument, { databaseId: lsConversationDatabaseId });

						this.onMessageChanged(ModelResolver.toClass(Message, poDocument));
						break;

					case EPrefix.conversation:
						this.onConversationChanged(poDocument as IConversation);
						break;
				}
			});

			this.refreshMessages(); // On ne rafraîchit l'UI qu'au dernier document traité.
		}
	}

	/** Gère la mise à jour de l'activité de l'utilisateur */
	private manageUserActivity(): void {
		merge( // On fusionne 2 sources d'événements afin de pouvoir mettre à jour l'activité de l'utilisateur à des moments clé.
			fromEvent(document.body, ConversationComponent.C_ACTIVITY_TOUCHSTART_EVENT), // Flux du touché sur l'écran.
			this.isvcFlag.observeFlag(ENetworkFlag.isOnlineReliable).pipe(filter((poFlag: IFlag) => poFlag.value)) // Flux du retour réseau.
		)
			.pipe(
				debounceTime(ConversationComponent.C_CHECK_PARTICIPANTS_ACTIVITIES_INTERVAL_MS),
				filter(_ => !!this.moUserParticipant?.activity),
				map(_ => {
					const loCurrentDate = new Date();
					// Si la date de dernière activité + X secondes est inférieure à la date actuelle, alors on met à jour l'activité car l'utilisateur agit.
					if ((this.moUserParticipant?.activity?.lastActivityDate) &&
						(+(this.moUserParticipant.activity.lastActivityDate) + ConversationComponent.C_CHECK_PARTICIPANTS_ACTIVITIES_INTERVAL_MS < +loCurrentDate)) {
						this.moUserParticipant.activity.lastActivityDate = loCurrentDate;
						const loStatus: EActivityStatus = this.moUserParticipant.activity.activity === EActivityStatus.tapping ? EActivityStatus.tapping : EActivityStatus.online;
						this.addUpdateUserActivityTask(loStatus);
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Gère s'il faut débuter une réplication live ou la stopper en fonction des flags du réseau qui arrivent et si l'appareil va en arrière plan. */
	private manageLiveReplication(): void {
		this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true) // S'il y a du réseau.
			.pipe(
				mergeMap(_ => this.startLiveReplication()),
				retryWhen((poErrors$: Observable<any>) => this.isvcStore.getRequestRetryStrategy(poErrors$)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** L'utilisateur entre un caractère pour écrire un message. Met à jour le statut de l'utilisateur ('tapping' / 'online').
	 * @param psNewValue Chaîne de caractères correspondant au nouveau contenu du message en cours d'écriture.
	 */
	public onTapping(psNewValue: string): void {
		if (!StringHelper.isBlank(psNewValue)) { // Si le texte entré est valide, on peut envoyer un message et on met à jour notre activité.
			this.canSend = true;

			if (this.mnTappingTimer)  // est en train d'écrire.
				this.stopTappingTimer();

			else // n'écrit pas.
				this.addUpdateUserActivityTask(EActivityStatus.tapping);

			// Timer pour refaire passer l'activité de l'utilisateur à 'online' au lieu de 'tapping'.
			this.mnTappingTimer = window.setTimeout(
				() => {
					this.addUpdateUserActivityTask(EActivityStatus.online);
					this.mnTappingTimer = undefined;
				},
				ConversationComponent.C_TAPPING_MS
			);
		}
	}

	/** Arrête le timer indiquant que l'utilisateur est en train d'écrire. */
	private stopTappingTimer(): void {
		clearTimeout(this.mnTappingTimer);
		this.mnTappingTimer = undefined;
	}

	/** Détection de changement dans une collection de données pour indiquer à Angular quel élément il doit rafraîchir.
	 * @param pnIndex index généré par le ngFor.
	 */
	public trackByIndex(pnIndex: number): number {
		return pnIndex;
	}


	/** Met à jour l'activité de l'utilisateur lorsqu'il y a un nouveau message ou lors de l'ouverture de la conversation.
	 * @param peActivityStatus Nouvelle activité de l'utilisateur, `o` par défaut.
	 */
	@Queue<ConversationComponent, Parameters<ConversationComponent["updateUserActivity"]>, ReturnType<ConversationComponent["updateUserActivity"]>>()
	private updateUserActivity(
		peActivityStatus: EActivityStatus = EActivityStatus.online,
		pbIsInCall?: boolean
	): Observable<IConversationActivity | undefined> {
		if (!this.moUserParticipant) {
			console.error(`${ConversationComponent.C_LOG_ID}Can not update user (${UserData.current?._id ?? ""}) activity because user participant is not defined !`);
			return of(undefined);
		}
		else if (!this.moUserParticipant.activity) {
			console.error(`${ConversationComponent.C_LOG_ID}Can not update user activity beacuse no activity is defined !`);
			return of(undefined);
		}
		else {
			this.moUserParticipant.activity.activity = peActivityStatus;
			this.moUserParticipant.activity.isInVisio = pbIsInCall;

			this.observableHasMessageForSummarize.value = this.isvcAiConversation.hasMessagesToSummarize(this.messages);

			// On peut mettre à jour l'activité de l'utilisateur qu'à partir du moment où le téléchargement des données est terminé, sinon conflits possibles.
			return this.isvcConversation.asyncHasNetworkConnection()
				.pipe(
					mergeMap((pbHasNetwork: boolean) => {
						if (this.canUpdateUserActivity(pbHasNetwork))
							return this.execUpdateUserActivity();
						else {
							this.logCannotUpdateUserActivity();
							return of(undefined);
						}
					})
				);
		}
	}

	private canUpdateUserActivity(pbHasNetwork: boolean): boolean {
		return this.mbDownloadConversationDetailsFinished && !this.mbIsDeletedConversation && pbHasNetwork;
	}

	private execUpdateUserActivity(): Observable<IConversationActivity | undefined> {
		const lsLastReadMessageId: string = ArrayHelper.hasElements(this.messages) ? ArrayHelper.getFirstElement(this.messages)._id : "";

		console.debug(`${ConversationComponent.C_LOG_ID}User activity changed to ${this.moUserParticipant?.activity?.activity || 'Ø'}, last read message ID is ${lsLastReadMessageId || "Ø"}.`);

		if (!this.moUserParticipant?.activity) {
			console.error(`${ConversationComponent.C_LOG_ID}Conversation ${this.conversation._id} : current user has no activity document.`);
			return of(undefined);
		}

		if (!StringHelper.isBlank(lsLastReadMessageId))
			this.moUserParticipant.activity.lastReadMessageId = lsLastReadMessageId;

		return defer(() => StringHelper.isBlank(this.moUserParticipant!.activity!._rev) ?
			this.isvcConversation.createActivity(this.moUserParticipant!.activity!) : this.isvcConversation.updateActivity(this.moUserParticipant!.activity!)
		)
			.pipe(
				tap(
					(poConvActivity: IConversationActivity) => this.moUserParticipant!.activity = poConvActivity,
					poError => console.error(`${ConversationComponent.C_LOG_ID}Error when update user activity '${this.moUserParticipant!.activity!._id}' :`, poError)
				)
			);
	}

	private logCannotUpdateUserActivity(): void {
		if (this.mbIsDeletedConversation)
			console.debug(`${ConversationComponent.C_LOG_ID}User activity change canceled, conversation deleted.`);
		else
			console.debug(`${ConversationComponent.C_LOG_ID}User activity change deferred, waiting for network or conversation details sync to complete.`);
	}

	/** Permet d'ajouter ou de supprimer des participants à la conversation. */
	@CanExecute({
		permission: "canEdit$",
		showHasNotPermissionsPopup: (poThis: ConversationComponent) => {
			poThis.isvcUiMessage.showPopupMessage(
				new ShowMessageParamsPopup({
					header: ConversationComponent.C_REQUIRED_PERMISSION,
					message: "Vous n'avez pas la permission requise pour modifier les participants de la conversation."
				})
			);
		}
	})
	public updateParticipants(): void {
		if (StringHelper.isBlank(this.conversation._id)) { // Si _id non valide, c'est que la conversation n'est pas encore initialisée.
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({ message: "Veuillez attendre l'initialisation complète de la conversation avant d'ajouter de nouveaux participants." })
			);
		}
		else {
			const laOldMembers: Array<IContact | IGroup | undefined> = this.getContactMembers();

			this.isvcGroups.getDisplayableGroups(this.getGroupMemberIds())
				.pipe(
					tap((paGroups: IGroup[]) => laOldMembers.push(...paGroups)),
					mergeMap(_ => this.openContactsSelectorModal(laOldMembers as (IContact | IGroup)[])),
					catchError(poError => this.isvcConversation.onError(poError, "Erreur lors de la mise à jour des participants.")),
					// Permet de ne prendre en compte que si on a validé la sélection de contacts (paContacts sera valide).
					filter((paMembers: Array<IContact | IGroup>) => ArrayHelper.hasElements(paMembers)),
					mergeMap((paMembers: Array<IContact | IGroup>) => this.isvcConversation.updateConversation(this.conversation, paMembers, laOldMembers as (IContact | IGroup)[])),
					mergeMap(_ => this.isvcConversation.hydrateConversation(this.conversation, this.moUserParticipant?.model?._id)),
					tap(_ => {
						this.refreshPageTitle();
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	private openContactsSelectorModal(paOldMembers: Array<IContact | IGroup>): Observable<IGroupMember[]> {
		const loContactsSelectorParams: IContactsPickerParams = {
			excludeCurrentUser: true,
			hasSearchbox: true,
			preSelectedIds: paOldMembers.map((poOldMember: IContact | IGroup) => poOldMember._id),
			type: EContactsType.contactsAndGroups,
			disableItemFunction: (poContact: IContact) => !ConversationHelper.isParticipantEligible(poContact),
			hideAllSelectionButton: ConfigData.conversation?.hideAllSelectionButton,
			sort: EContactsPickerSort.byPreSelectedParticipants,
			min: 1,
			defaultTab: ConfigData.conversation?.defaultConversationTab ?? 0
		};

		return this.isvcContacts.openContactsSelectorAsModal(loContactsSelectorParams);
	}

	/** Retourne les contacts membres de la conversation, sans le contact utilisateur ni les contacts appartenant à un groupe de conversation. */
	private getContactMembers(): (IContact | undefined)[] {
		const lsUserParticipantId: string = this.moUserParticipant?.participantId ?? UserHelper.getUserContactId();
		const laContacts: (IContact | undefined)[] = this.contacts; // Pour éviter d'appeler à chaque fois le getter qui fait des opérations.

		return this.conversation.participants
			.filter((poParticipant: IParticipant) => ContactsService.isContact(poParticipant.participantId) && poParticipant.participantId !== lsUserParticipantId)
			.map((poParticipant: IParticipant) => laContacts.find((poContact?: IContact) => poContact && poContact._id === poParticipant.participantId))
			.filter((poContact: IContact) => !!poContact);
	}

	/** Retourne les identifiants de groupes membres de la conversation. */
	private getGroupMemberIds(): string[] {
		return this.conversation.participants
			.filter((poParticipant: IParticipant) => GroupsService.isGroup(poParticipant.participantId))
			.map((poParticipant: IParticipant) => poParticipant.participantId);
	}

	/** Récupère l'avatar d'un participant à partir de son chemin de contact.
	 * @param psContactPath Chemin vers le contact dont il faut récupérer l'avatar.
	 */
	public getAvatar(psContactPath: string): IAvatar | undefined {
		const loParticipant: IParticipant | undefined = this.otherParticipantsMap.get(psContactPath);

		return loParticipant ? loParticipant.avatar : undefined;
	}

	/** Navigue vers la fiche contact d'un participant.
	 * @param poParticipant Participant vers lequel on veut naviguer.
	 */
	public goToParticipant(poParticipant: IParticipant<IContact>): void {
		if (poParticipant.model)
			this.isvcContacts.routeToContact(poParticipant.model);
		else
			console.error(`${ConversationComponent.C_LOG_ID}Error when navigating to a participant's contact form : contact is missing.`);
	}

	/** Affiche un message d'avertissement si la fiche du contact est incomplète.
	 * @param poParticipant Participant pour lequel on souhaite afficher le message d'avertissement.
	 */
	public warningToParticipant(poParticipant: IParticipant<IContact>): void {
		if (StringHelper.isBlank(poParticipant.model?.email) && !UserHelper.isUser(poParticipant.model)) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsToast({ message: "Ce contact ne peut pas intervenir dans la conversation, veuillez saisir son adresse email." })
			);
		}
	}

	/** Charge la suite des messages.
	 * @param poInfiniteScroll Composant infiniteScroll qui permet de déclencher cette méthode.
	 */
	public loadMoreMessages(poInfiniteScroll: IonInfiniteScroll): void {
		if (!this.moDataSource.viewParams) {
			this.moDataSource.viewParams = {
				startkey: undefined,
				skip: undefined
			};
		}
		this.moDataSource.viewParams.startkey = ArrayHelper.getLastElement(this.messages)._id;
		this.moDataSource.viewParams.skip = 1;

		this.getMessages()
			.pipe(
				auditTime(500),
				finalize(() => {
					poInfiniteScroll.complete();
					if (ArrayHelper.hasElements(this.messages) && this.moDataSource.viewParams?.startkey === ArrayHelper.getLastElement(this.messages)._id)
						this.infiniteScrollEnabled = false;
					this.detectChanges();
				})
			)
			.subscribe();
	}

	/** Ouvre le menu contextuel d'un message.
	 * @param poEvent Événement reçu de l'appui long.
	 * @param poMessage Message concerné par l'ouverture du menu contextuel.
	 * @param poPopoverAnchor Ancre servant au positionnement du menu contextuel.
	 */
	public async openMessagePopover(poEvent: Event, poMessage: Message): Promise<void> {
		await this.isvcPopover.showCustomPopoverAsync(
			PopoverComponent,
			{ items: this.getOpenMessagePopoverItems(poMessage), componentId: GuidHelper.newGuid() },
			poEvent as MouseEvent
		);
		this.detectChanges();
	}

	private getOpenMessagePopoverItems(poMessage: Message): IPopoverItemParams[] {
		const laPopoverItems: IPopoverItemParams[] = [];
		let lbIsMessageCreatedByUser: boolean;

		if (poMessage.senderContactPath) // Pour rétro-compat des messages avec uniquement la propriété "sender".
			lbIsMessageCreatedByUser = Store.getDocumentIdFromPath(poMessage.senderContactPath) === this.currentContactId;
		else
			lbIsMessageCreatedByUser = GuidHelper.extractGuid((poMessage as any).sender) === GuidHelper.extractGuid(this.currentContactId);

		// Si l'envoyeur est l'utilisateur et que le message peut être supprimé.
		if (lbIsMessageCreatedByUser && !poMessage.isAutoGenerated)
			laPopoverItems.push(this.getOpenMessagePopoverDeleteOrRestoreItem(poMessage));

		laPopoverItems.push({
			title: "Copier le message",
			icon: "copy-outline",
			action: () => {
				// Si il s'agit d'un message de synthèse via IA, on copie ce message et non le body
				if (this.isAiSummary(poMessage)) {
					return of(Clipboard.write({ string: this.getMessageAttachedAiSummary(poMessage) }));
				}

				return of(Clipboard.write({ string: poMessage.body }));
			}
		});

		return laPopoverItems;
	}

	private getOpenMessagePopoverDeleteOrRestoreItem(poMessage: Message): IPopoverItemParams {
		return {
			title: poMessage.deleted ? "Restaurer" : "Supprimer",
			icon: poMessage.deleted ? "refresh-outline" : "trash-outline",
			action: () => {
				if (this.isAiSummary(poMessage)) {
					ArrayHelper.removeElement(this.messages, poMessage);
					return this.isvcConversation.deletePermanentlyMessage$(poMessage).pipe(
						mergeMap(_ => {
							this.refresh();
							return of(undefined);
						})
					);
				}
				return this.isvcConversation.deleteOrRestoreMessage(poMessage)
					.pipe(
						mergeMap(_ => {
							if (this.conversation.lastMessage?._id === poMessage._id) {
								this.observableHasMessageForSummarize.value = this.isvcAiConversation.hasMessagesToSummarize(this.messages);
								this.conversation.lastMessage = poMessage;
								return this.isvcConversation.updateConversation(this.conversation);
							}
							else
								return of(undefined);
						})
					);
			}
		};
	}

	/** Ouvre un sélecteur d'entités pour lier une/des entité(s) à la conversation. */
	@CanExecute({
		permission: "canEdit$",
		showHasNotPermissionsPopup: (poThis: ConversationComponent) => {
			poThis.isvcUiMessage.showPopupMessage(
				new ShowMessageParamsPopup({
					header: ConversationComponent.C_REQUIRED_PERMISSION,
					message: "Vous n'avez pas la permission requise pour lier une donnée à la conversation."
				})
			);
		}
	})
	public selectEntities(): void {
		if (this.canAddLink)
			this.selectEntities$().subscribe();
	}

	private selectEntities$(poSelectorParams?: ISelectorParams<EntityLink>): Observable<Entity[]> {
		return EMPTY; // TODO TB
	}

	public async openEntity(poAttachedEntity: Entity): Promise<void> {
		const loModalParams: IEntityModalParams = {
			entityDescGuid: this.isvcEntities.getEntityDescGuidFromEntityDescId(this.isvcEntities.getEntityDescriptor(poAttachedEntity)?._id),
			entityGuid: GuidHelper.extractGuid(poAttachedEntity._id)
		};

		const loModalOptions: ModalOptions = {
			component: EntityModalComponent,
			componentProps: loModalParams
		};

		return this.isvcModal.open<void>(loModalOptions).toPromise();
	}

	/** Met à jour certains éléments de l'IU après modification de la conversation. */
	private refreshPageTitle(): void {
		this.setParentPageTitle();
	}

	@CanExecute({
		permission: "canEdit$",
		showHasNotPermissionsPopup: (poThis: ConversationComponent) => {
			poThis.isvcUiMessage.showPopupMessage(
				new ShowMessageParamsPopup({
					header: ConversationComponent.C_REQUIRED_PERMISSION,
					message: "Vous n'avez pas la permission requise pour modifier la conversation."
				})
			);
		}
	})
	public routeToConversationEdit(): void {
		this.isvcConversation.routeToConversationEdit(this.conversation, this.contacts, this.maGroups);
	}

	/** Permet de naviguer vers une entité liée.
	 * @param poLinkedEntity
	 */
	@CanExecute({
		permission: "canRead",
		showHasNotPermissionsPopup: (poThis: ConversationComponent) => {
			poThis.isvcUiMessage.showPopupMessage(
				new ShowMessageParamsPopup({
					header: ConversationComponent.C_REQUIRED_PERMISSION,
					message: "Vous n'avez pas la permission requise pour afficher cette donnée."
				})
			);
		}
	})
	public goToLinkedEntity(poLinkedEntity: IEntity): void {
		this.isvcEntities.navigateToEntityViewAsync(poLinkedEntity, this.ioRoute);
	}

	/** Récupère le nom à afficher de l'expéditeur du message.
	 * @param poMessage message envoyé.
	 */
	public getSenderDisplayName(poMessage: Message): string {
		return ContactHelper.getCompleteFormattedName(this.otherParticipantsMap.get(poMessage.senderContactPath!)?.model);
	}

	public getMessageAttachedAiSummary(poMessage: Message): string {
		return poMessage.attachments?.filter((poMessageAttachment: MessageAttachment) => poMessageAttachment instanceof MessageAiSummaryAttachment)
			.map((poMessageAiSummaryAttachment: MessageAiSummaryAttachment) => poMessageAiSummaryAttachment.summaryText)
			.join(" ");
	}

	public isAiSummary(poMessage: Message): boolean {
		return poMessage.attachments && poMessage.attachments.some((poMessageAttachment: MessageAttachment) => poMessageAttachment instanceof MessageAiSummaryAttachment);
	}

	public getMessageAttachedFiles(poMessage: Message): IDmsDocumentData[] {
		const laAttachedFiles: IDmsDocumentData[] = [];

		poMessage?.attachments?.forEach((poMessageAttachment: MessageAttachment) => {
			if (poMessageAttachment instanceof MessageFileAttachment)
				laAttachedFiles.push(poMessageAttachment.dmsData);
		});

		return laAttachedFiles;
	}

	public getMessageAttachedEntities$(poMessage: Message): Observable<Entity[]> {
		const laPaths: string[] = [];
		const laPathsToRequest: string[] = [];
		const laProperties: ObservableProperty<Entity>[] = [];

		poMessage?.attachments?.forEach((poMessageAttachment: MessageAttachment) => {
			if (poMessageAttachment instanceof MessageEntityAttachment) {
				laPaths.push(poMessageAttachment.entityPath);

				let loObservableEntity: ObservableProperty<Entity> | undefined = this.moObservableEntitiesCacheByPath.get(poMessageAttachment.entityPath);
				if (!loObservableEntity) {
					laPathsToRequest.push(poMessageAttachment.entityPath);
					this.moObservableEntitiesCacheByPath.set(poMessageAttachment.entityPath, loObservableEntity = new ObservableProperty);
				}

				laProperties.push(loObservableEntity);
			}
		});

		this.isvcEntities.getModels$(laPathsToRequest).pipe(
			tap((paEntities: Entity[]) => paEntities.forEach((poEntity: Entity) => {
				const loProperty: ObservableProperty<Entity> | undefined = this.moObservableEntitiesCacheByPath.get(Store.getDocumentPath(poEntity));
				if (loProperty)
					loProperty.value = poEntity;
			})),
			secure(this)
		).subscribe();

		return combineLatest(laProperties.map((poObservableProperty: ObservableProperty<Entity>) => poObservableProperty.value$));
	}

	public getEntityCategory(poEntity: Entity): IEntityCategory {
		return this.isvcEntities.getEntityCategory(poEntity);
	}

	public getEntityName(poEntity: Entity): string {
		return this.isvcEntities.getEntityName(poEntity);
	}

	public getAttachmentName(poAttachment: MessageAttachment): string {
		if (poAttachment instanceof MessageFileAttachment)
			return poAttachment.dmsData.name;
		else if (poAttachment instanceof MessageEntityAttachment)
			return this.isvcEntities.getEntityName(poAttachment.entity);
		else
			return "Pièce jointe inconnue";
	}

	public removeAttachment(poAttachment: MessageAttachment): void {
		if (poAttachment instanceof MessageFileAttachment)
			ArrayHelper.removeElementByFinder(this.files, (poFile: GalleryFile) => poAttachment.dmsData.guid === poFile.guid);
		ArrayHelper.removeElement(this.newMessage.attachments, poAttachment);
		this.detectChanges();
	}

	public async openPendingAttachmentAsync(poAttachment: MessageAttachment): Promise<void> {
		if (poAttachment instanceof MessageFileAttachment)
			await this.isvcGallery.openFile(poAttachment.dmsData).toPromise();
		else if (poAttachment instanceof MessageEntityAttachment && poAttachment.entity)
			await this.openEntity(poAttachment.entity);
	}

	public async openModalAiSummary(): Promise<void> {
		// La détection de l'absence d'internet ne fonctionne pas sur navigateur
		if (!await this.isvcNetwork.hasNetworkAsync()) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Le service IA a besoin d'une connexion internet.", header: "Erreur" }));
			return;
		}
		if (this.observableUserUsage.value?.isQuotaExcedeed) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: `Votre quota d’utilisation IA est atteint. Il se réinitialisera le ${this.observableUserUsage.value.formattedDate}.​` }));
			return;
		}

		const loResult = await this.isvcModal.open<IAiConversationSummaryModalResponse>(
			{ component: AiConversationSummaryModalComponent },
			EModalSize.small
		).toPromise();
		if (loResult && loResult.summarize) {
			const laMessagesForPrompt: string[] = [];

			const laMessages = await this.isvcConversation.getMessages({
				databaseId: this.isvcConversation.databaseId,
				viewParams: {
					include_docs: true,
					startkey: EPrefix.message + this.msConvGuid,
					endkey: EPrefix.message + this.msConvGuid + Store.C_ANYTHING_CODE_ASCII,
					limit: 200
				}
			}).toPromise();

			laMessages.filter(poMessage =>
				(poMessage.recipientsPathList?.some(val => val.indexOf(this.currentContactId) >= 0) ?? true) ||
				(poMessage.recipientsPathList?.length === 0)
			).reverse().forEach((loMessage) => {
				if (this.isvcAiConversation.isSummarizable(loMessage)) {
					let lsSender = this.getSenderDisplayName(loMessage);
					if (StringHelper.isBlank(lsSender)) {
						lsSender = "Moi";
					}
					laMessagesForPrompt.push(`${lsSender} : \`\`${loMessage.body}\`\`\n`);
				}
			});

			const loResultGeneration = await this.isvcAiConversation.generateConversationSummary(laMessagesForPrompt, this.conversation?.title ?? "");

			const loMessage = this.createMessage("");

			loMessage.isAutoGenerated = true;
			loMessage.attachments.push(new MessageAiSummaryAttachment({ summaryText: (loResultGeneration as any).text }));
			loMessage.recipientsPathList = [loMessage.senderContactPath ?? this.currentContactId];

			await this.sendMessage(loMessage).toPromise();
		}
	}

	public deleteMessageDefinitely(poMessage: Message): void {
		ArrayHelper.removeElement(this.messages, poMessage);

		this.isvcConversation.deletePermanentlyMessage$(poMessage).pipe(
			tap(_ => this.refresh())
		).subscribe();
	}

	//#endregion

}