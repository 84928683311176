<div>
	<ng-container *ngIf="observableTaskCount.value$ | async as taskCount">
		<div class="rounded task">
			<ion-icon [name]="observableTaskIcon.value$ | async"></ion-icon><ion-label class="count">x {{ taskCount
				}}</ion-label>
		</div>
	</ng-container>
	<ng-container *ngIf="observableEventCount.value$ | async as eventCount">
		<div class="rounded event">
			<ion-icon [name]="observableEventIcon.value$ | async"></ion-icon><ion-label class="count">x {{ eventCount
				}}</ion-label>
		</div>
	</ng-container>
</div>