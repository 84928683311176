import { ArrayHelper } from "@calaosoft/osapp/helpers/arrayHelper";
import { ContactHelper } from "@calaosoft/osapp/helpers/contactHelper";
import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { EPrefix } from "@calaosoft/osapp/model/EPrefix";
import { IContact } from "@calaosoft/osapp/model/contacts/IContact";
import { BaseEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence";
import { IEventOccurrence } from "@calaosoft/osapp/modules/calendar-events/models/ievent-occurrence";
import { ModelMatch } from "@calaosoft/osapp/modules/utils/models/decorators/model-match.decorator";
import { Exclude } from "class-transformer";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ETradeEventType } from "./etrade-event-type";

@ModelMatch((poOccurrence: IEventOccurrence) => poOccurrence.eventType === ETradeEventType.standard, BaseEventOccurrence)
export class EventOccurrence extends BaseEventOccurrence {

	//#region PROPERTIES

	@Exclude()
	public override readonly participantsLabel$: Observable<string> = this.getParticipantsLabel$();

	//#endregion PROPERTIES

	//#region METHODS

	protected override getParticipantsLabel$(): Observable<string> {
		return combineLatest([this.observableAuthorId.value$, this.observableParticipants.changes$]).pipe(
			map(([psAuthorId, paContacts]: [string, IContact[]]) => {
				const laContacts: IContact[] = [...paContacts];
				const lsAuthorContactId: string = IdHelper.buildId(EPrefix.contact, IdHelper.extractIdWithoutPrefix(psAuthorId, EPrefix.user));
				ArrayHelper.removeElementByFinder(laContacts, (poContact: IContact) => poContact._id === lsAuthorContactId);
				return laContacts;
			}),
			map((paContacts: IContact[]) => paContacts.map((poContact: IContact) => {
				return ContactHelper.isCurrentUser(poContact) ? EventOccurrence.C_CURRENT_USER_LABEL : ContactHelper.getCompleteFormattedName(poContact);
			}).join(", "))
		);
	}

	//#endregion METHODS

}