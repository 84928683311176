import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EApplicationEventType } from '../../../model/application/EApplicationEventType';
import { ITaskParams } from '../../../model/backgroundTask/taskParams/ITaskParams';
import { IAppointment } from '../../../model/booking/IAppointment';
import { IPurchase } from '../../../model/booking/IPurchase';
import { TaskBase } from '../../../services/backgroundTask/TaskBase';
import { TaskDescriptor } from '../../../services/backgroundTask/TaskDescriptor';
import { InjectorService } from '../../../services/injector.service';
import { BookingApiClient } from '../../booking/bookingApiClient';
import { EventsService } from '../../events/events.service';

export interface ISavePurchaseTaskParams extends ITaskParams {
	purchase: IPurchase,
	appointment: IAppointment
}

export class SavePurchaseTask extends TaskBase<ISavePurchaseTaskParams> {
	//#region FIELDS

	private moApiClient: BookingApiClient;
	private mosvcEvent: EventsService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<ISavePurchaseTaskParams>) {
		super(poDescriptor);

		this.moApiClient = new BookingApiClient(InjectorService.instance.get(HttpClient));
		this.mosvcEvent = InjectorService.instance.get(EventsService);
	}

	public execTask$(): Observable<any> {
		if (!this.descriptor.params?.purchase)
			return throwError(() => "Purchase parameter missing.");

		if (!this.descriptor.params?.appointment)
			return throwError(() => "Appointment parameter missing.");

		return this.moApiClient.saveAppointmentPurchase(
			this.descriptor.params.appointment,
			this.descriptor.params.purchase
		)
			.pipe(tap(_ => this.mosvcEvent.raiseEvent(EApplicationEventType.purchaseEvent)));
	}

	//#endregion
}
