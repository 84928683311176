import { Transform } from "class-transformer";
import { ArrayHelper } from "../../../helpers/arrayHelper";
import { PathHelper } from "../../../helpers/path-helper";
import { IPathPermissionSet } from "./IPathPermissionSet";
import { IDocumentType } from "./idocument-type";
import { IFolderConfig } from "./ifolder-config";

export class FolderConfig implements IFolderConfig {

	//#region PROPERTIES

	/** @implements */
	@Transform(({ value }: { value: string; }): string => PathHelper.parsePath(value), { toClassOnly: true })
	@Transform(({ value }: { value: string; }): string => PathHelper.preparePath(value), { toPlainOnly: true })
	public path: string;
	/** @implements */
	public icon: string;
	/** @implements */
	public name: string;
	/** @implements */
	public shortName: string;
	/** @implements */
	public documentTypes: IDocumentType;
	/** @implements */
	public color?: string;
	/** @implements */
	public style?: string;
	/** @implements */
	public permissions?: IPathPermissionSet;
	/** @implements */
	public permissionScope?: string;

	private msLastPathPart: string;
	/** Dernière partie du chemin. */
	public get lastPathPart(): string {
		if (this.msLastPathPart)
			return this.msLastPathPart;
		return this.msLastPathPart = ArrayHelper.getLastElement(this.path.split("/"));
	}

	//#endregion

}
