<div class="container">
	<ng-container *ngIf="!(observableIsLoading.value$ | async); else loading">
		<ng-container *ngIf="!(observableHasError.value$ | async); else error">
			<div>
				<h2>Partage d'agenda</h2>
				<p>
					{{ message$ | async }}
				</p>
			</div>

			<div *ngIf="(observableCalendarUrl.value$ | async) as url" class="url-container">
				<span>{{ url }}</span>
				<ion-button class="validate-btn" color="primary" shape="round" (click)="copyUrl()">
					Copier
				</ion-button>
			</div>

			<div class="buttons">
				<ion-button fill="clear" color="medium" shape="round" (click)="onSecondaryButtonClick()">
					{{ secondaryButtonText$ | async }}
				</ion-button>
				<ion-button class="validate-btn" color="primary" shape="round" (click)="onMainButtonClick()">
					{{ mainButtonText$ | async }}
				</ion-button>
			</div>
		</ng-container>
	</ng-container>
</div>

<ng-template #loading>
	<h2>Partage d'agenda</h2>
	<ion-spinner></ion-spinner>
</ng-template>

<ng-template #error>
	<h2>Partage d'agenda</h2>
	<calao-error-message></calao-error-message>
</ng-template>